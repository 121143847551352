import { Autocomplete as MUIAutocomplete, TextField } from '@mui/material';

function Autocomplete({
    formik,
    option,
    fieldName,
    clearable = false,
    label,
    selectedIndex = null,
    actions = false,
    freeSolo = false,
    disabled = false,
    value = false
}) {
    const { title, id } = fieldName;

    return (
        <MUIAutocomplete
            disabled={disabled}
            id={fieldName.title}
            name={fieldName.title}
            options={option || []}
            size="small"
            value={value || option ? option[selectedIndex] : ''}
            onChange={(e, value) => {
                if (value) {
                    formik.setFieldValue(title, value[fieldName.title]);
                    formik.setFieldValue(id, value[fieldName.id]);
                    if (actions) {
                        actions(value[fieldName.id], value[fieldName.title]);
                    }
                } else {
                    formik.setFieldValue(title, '');
                    formik.setFieldValue(id, 0);
                }
            }}
            getOptionLabel={(option) => option[fieldName.title]}
            renderInput={(params) => (
                <TextField
                    size="small"
                    {...params}
                    label={label}
                    error={formik.touched[fieldName.title] && Boolean(formik.errors[fieldName.title])}
                    helperText={formik.touched[fieldName.title] && formik.errors[fieldName.title]}
                    fullWidth
                />
            )}
            fullWidth
            disableClearable={!clearable}
            freeSolo={freeSolo}
        />
    );
}

export default Autocomplete;
