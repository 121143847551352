import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import Logo from '../../../assets/images/svg_penboox.svg';
// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link component={RouterLink} to={DASHBOARD_PATH}>
        <img src={Logo} style={{ width: 180 }} alt="PenBoox" />
    </Link>
);

export default LogoSection;
