import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import MuiChip from '@mui/material/Chip';

// ==============================|| CHIP ||============================== //

const ChipWH = ({ chipcolor, disabled, sx = {}, variant, ...others }) => {
    const theme = useTheme();

    let defaultSX = {
        color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main,
        bgcolor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.light
    };

    let outlineSX = {
        color: theme.palette.primary.main,
        bgcolor: 'transparent',
        border: '1px solid',
        borderColor: theme.palette.primary.main
    };

    switch (chipcolor) {
        case 'secondary':
            if (variant === 'outlined')
                outlineSX = {
                    color: theme.palette.secondary.main,
                    bgcolor: 'transparent',
                    border: '1px solid',
                    borderColor: theme.palette.secondary.main
                };
            else
                defaultSX = {
                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.main,
                    bgcolor: theme.palette.mode === 'dark' ? theme.palette.secondary.dark : theme.palette.secondary.light
                };
            break;
        case 'success':
            if (variant === 'outlined')
                outlineSX = {
                    color: theme.palette.success.dark,
                    bgcolor: 'transparent',
                    border: '1px solid',
                    borderColor: theme.palette.success.dark
                };
            else
                defaultSX = {
                    color: theme.palette.mode === 'dark' ? theme.palette.success.light : theme.palette.success.dark,
                    bgcolor: theme.palette.mode === 'dark' ? theme.palette.success.dark : theme.palette.success.light + 60
                };
            break;
        case 'error':
            if (variant === 'outlined')
                outlineSX = {
                    color: theme.palette.error.main,
                    bgcolor: 'transparent',
                    border: '1px solid',
                    borderColor: theme.palette.error.main
                };
            else
                defaultSX = {
                    color: theme.palette.mode === 'dark' ? theme.palette.error.light : theme.palette.error.dark,
                    bgcolor: theme.palette.mode === 'dark' ? theme.palette.error.dark : theme.palette.error.light + 60
                };
            break;
        case 'orange':
            if (variant === 'outlined')
                outlineSX = {
                    color: theme.palette.orange?.dark,
                    bgcolor: 'transparent',
                    border: '1px solid',
                    borderColor: theme.palette.orange?.main
                };
            else
                defaultSX = {
                    color: theme.palette.orange?.dark,
                    bgcolor: theme.palette.orange?.light
                };
            break;
        case 'warning':
            if (variant === 'outlined')
                outlineSX = {
                    color: theme.palette.warning.dark,
                    bgcolor: 'transparent',
                    border: '1px solid',
                    borderColor: theme.palette.warning.dark
                };
            else
                defaultSX = {
                    color: theme.palette.warning.dark,
                    bgcolor: theme.palette.warning.light
                };
            break;
        case 'purple':
            if (variant === 'outlined')
                outlineSX = {
                    color: 'rgb(94, 53, 177)',
                    bgcolor: 'rgb(237, 231, 246)',
                    border: '1px solid',
                    borderColor: 'rgb(237, 231, 246)'
                };
            else
                defaultSX = {
                    color: 'rgb(94, 53, 177)',
                    bgcolor: 'rgb(237, 231, 246)'
                };
            break;
        case 'defaultCustom':
            if (variant === 'outlined')
                outlineSX = {
                    color: 'rgb(51 51 51)',
                    bgcolor: 'rgb(228 228 228)',
                    border: '1px solid',
                    borderColor: 'rgb(237, 231, 246)'
                };
            else
                defaultSX = {
                    color: 'rgb(51 51 51)',
                    bgcolor: 'rgb(228 228 228)'
                };
            break;
        case 'purple-fill':
            if (variant === 'outlined')
                outlineSX = {
                    color: '#ffffff',
                    bgcolor: '#674188b3',
                    border: '1px solid',
                    borderColor: 'rgb(237, 231, 246)'
                };
            else
                defaultSX = {
                    color: '#ffffff',
                    bgcolor: '#674188b3'
                };
            break;
        default:
    }

    if (disabled) {
        if (variant === 'outlined')
            outlineSX = {
                color: theme.palette.grey[500],
                bgcolor: 'transparent',
                border: '1px solid',
                borderColor: theme.palette.grey[500]
            };
        else
            defaultSX = {
                color: theme.palette.grey[500],
                bgcolor: theme.palette.grey[50]
            };
    }

    let SX = defaultSX;
    if (variant === 'outlined') {
        SX = outlineSX;
    }
    SX = { ...SX, ...sx };
    return <MuiChip {...others} sx={SX} />;
};

ChipWH.propTypes = {
    sx: PropTypes.object,
    chipcolor: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool
};

export default ChipWH;
