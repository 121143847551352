// material-ui
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import { useDispatch, useSelector } from 'store';
import LogoSection from '../LogoSection';
import CustomBottomNavigation from './MobileSection/CustomBottomNavigation';
import ProfileSection from './ProfileSection';

// assets
import useAuth from 'hooks/useAuth';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const { defaultRole } = useAuth();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    return (
        <>
            {/* logo & toggler button */}
            {/* <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                        '&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                        }
                    }}
                    onClick={() => dispatch(openDrawer(!drawerOpen))}
                    color="inherit"
                >
                    <IconMenu2 stroke={1.5} size="20px" />
                </Avatar>
            </Box> */}

            {/* Logo */}
            {/* <Box>
                <span style={{ fontWeight: 800, fontSize: 24 }}>PBXPBXP</span>
            </Box> */}
            {/* <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                <LogoSection />
            </Box> */}
            <Box sx={{ display: { sm: 'block' } }}>
                <Box sx={{ display: 'flex', pl: 0.5, pt: 0.5, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>

            {/* New Header Search */}
            {/* <Box sx={{ flexGrow: 1 }} />
            <NewSearchIcon /> */}

            {/* New two header search */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />
            {/* <NewTwoSearchIcon /> */}

            {/* header search */}
            {/* <SearchSection />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} /> */}

            {/* mega-menu */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <MegaMenuSection />
            </Box> */}

            {/* live customization & localization */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box> */}

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            <ProfileSection />

            {/* mobile header */}
            {defaultRole !== 'securitygaurd' &&
            defaultRole !== 'accountant' &&
            defaultRole !== 'conductor' &&
            defaultRole !== 'administrator' ? (
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    {/* <MobileSection /> */}
                    <CustomBottomNavigation />
                </Box>
            ) : null}
        </>
    );
};

export default Header;
