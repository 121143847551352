import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Student Imports
const StudentHome = Loadable(lazy(() => import('views/student/Home')));
const StudentClasses = Loadable(lazy(() => import('views/student/Classes')));
const StudentNotice = Loadable(lazy(() => import('views/student/Notice')));
const StudentExams = Loadable(lazy(() => import('views/student/Exams')));
const StudentResults = Loadable(lazy(() => import('views/student/Results')));
const ResultsByExam = Loadable(lazy(() => import('views/student/Results/ResultDetails/ViewResult')));
const StudentAttendance = Loadable(lazy(() => import('views/student/Attendance')));
// const StudentViewAttendance = Loadable(lazy(() => import('views/student/AttendanceOld/ViewAttendance')));
const StudentFindSchool = Loadable(lazy(() => import('views/common/FindSchool')));
// const StudentDetailsAndExamResult = Loadable(lazy(() => import('views/student/ResultOld/ViewResult')));
const Profile = Loadable(lazy(() => import('views/common/Profile')));
const BasicInfo = Loadable(lazy(() => import('views/common/Profile/BasicInfo')));
const PermanentAddress = Loadable(lazy(() => import('views/common/Profile/PermanentAddress')));
const LocalAddress = Loadable(lazy(() => import('views/common/Profile/LocalAddress')));
const FatherDetails = Loadable(lazy(() => import('views/common/Profile/FatherDetails')));
const MotherDetails = Loadable(lazy(() => import('views/common/Profile/MotherDetails')));
const AccessControl = Loadable(lazy(() => import('views/common/Profile/AccessControl')));
const Account = Loadable(lazy(() => import('views/common/Account')));
const StudentFees = Loadable(lazy(() => import('views/student/Fees')));
const StudentSubjects = Loadable(lazy(() => import('views/student/Subjects')));
const OnlineClasses = Loadable(lazy(() => import('views/student/OnlineClasses')));
const HomeWork = Loadable(lazy(() => import('views/student/HomeWork')));

// ==============================|| MAIN ROUTING ||============================== //

const StudentRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <StudentHome />
        },
        {
            path: '/classes',
            element: <StudentClasses />
        },
        {
            path: '/notice',
            element: <StudentNotice />
        },
        {
            path: '/exam',
            element: <StudentExams />
        },
        {
            path: 'result',
            element: <StudentResults />
            // children: [
            //     {
            //         path: ':resultId',
            //         element: <StudentDetailsAndExamResult />
            //     }
            // ]
        },
        {
            path: 'result/resultbyexam',
            element: <ResultsByExam />
        },
        {
            path: '/attendance',
            element: <StudentAttendance />
        },
        // {
        //     path: '/attendance/view-attendance',
        //     element: <StudentViewAttendance />
        // },
        // {
        //     path: '/findschool',
        //     element: <StudentFindSchool />
        // },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/profile/basic-info',
            element: <BasicInfo />
        },
        {
            path: '/profile/permanent-address',
            element: <PermanentAddress />
        },
        {
            path: '/profile/local-address',
            element: <LocalAddress />
        },
        {
            path: '/profile/father-details',
            element: <FatherDetails />
        },
        {
            path: '/profile/mother-details',
            element: <MotherDetails />
        },
        {
            path: '/profile/access-control',
            element: <AccessControl />
        },
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/fees',
            element: <StudentFees />
        },
        {
            path: '/subjects',
            element: <StudentSubjects />
        },
        {
            path: '/onlineclasses',
            element: <OnlineClasses />
        },
        {
            path: '/homework',
            element: <HomeWork />
        }
    ]
};

export default StudentRoutes;
