import { Divider, Grid, Typography } from '@mui/material';
import SearchField from './SearchField';
import Select from './Select';
import SessionSelect from './SessionSelect';
import Title from './Title';
import Button from './Button';
import CSVExport from './CSVExport';

const CustomTitle = ({ title }) => (
    <Grid item sm={title?.gridSize?.sm} display="flex" alignItems="center">
        <Title title={title?.titleName} />
    </Grid>
);

const CustomButton = ({ button = false, csvExport = false, title }) => (
    <>
        {button || csvExport ? (
            <Grid
                item
                sm={button?.gridSize?.sm || csvExport?.gridSize?.sm}
                width={button?.width || button?.width}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
            >
                {button ? (
                    <Button
                        handleOpen={button?.action}
                        width={button?.width}
                        buttonName={button?.btnName}
                        animateSX={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
                    />
                ) : null}
                {csvExport ? (
                    <Grid item sm={5} display="flex" justifyContent="flex-end" alignItems="center" sx={{ ml: 1.5 }}>
                        <CSVExport title={title} csvExport={csvExport} selectedCSVOption={csvExport?.selectedCSVOption} />
                    </Grid>
                ) : null}
            </Grid>
        ) : null}
    </>
);

const CustomSearch = ({ search }) => (
    <Grid item sm={search?.gridSize?.sm} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <SearchField name={search?.name} filter={search?.filter} />
    </Grid>
);

const CustomSession = ({ session }) => (
    <Grid item sm={session?.gridSize?.sm} sx={{ display: 'flex', justifyContent: 'flex-end', pr: session?.gridSize?.sm === 6 ? 1 : 0 }}>
        <SessionSelect handleSessionChange={session?.action} />
    </Grid>
);

const CustomSelect = ({ select }) => (
    <Grid item sm={select?.gridSize?.sm} display="flex" justifyContent="flex-end" alignItems="center">
        <Grid item sm={6} display="flex" justifyContent="flex-end">
            <Select
                options={select.options}
                selectedOption={select.selectedOption}
                handleOptionSelect={select.action}
                label={select?.label}
            />
        </Grid>
        {select?.button ? (
            <Grid item sm={6}>
                <CustomButton button={select?.button} csvExport={false} />
            </Grid>
        ) : null}
    </Grid>
);

const getRightComponent = (session, search, select) => {
    let rightComponent;
    if (search && select) return null;
    if (session && select) {
        return (
            <>
                <Grid sx={{ mr: 1.5 }}>
                    <CustomSession session={session} />
                </Grid>
                <CustomSelect select={select} />
            </>
        );
    }
    if (session && search) {
        return (
            <>
                <Grid sx={{ mr: 1.5 }}>
                    <CustomSession session={session} />
                </Grid>
                <CustomSearch search={search} />
            </>
        );
    }
    if (select) {
        rightComponent = <CustomSelect select={select} />;
    } else if (search) {
        rightComponent = <CustomSearch search={search} />;
    } else if (session) {
        rightComponent = <CustomSession session={session} />;
    }
    return rightComponent;
};

function Header({ button = false, title = false, session = false, search = false, select = false, csvExport = false }) {
    return (
        <>
            <Grid container>
                <Grid
                    item
                    sm={12}
                    display="flex"
                    sx={{
                        borderBottom:
                            (title && button && search) || (title && csvExport && search) || (search && select) ? '1px solid #eeeeee' : 0,
                        marginBottom: (title && button && search) || (title && csvExport && search) || (search && select) ? '12px' : 0,
                        px: 1.5,
                        pb: 1.5
                    }}
                >
                    {title ? <CustomTitle title={title} /> : null}
                    {button || csvExport ? (
                        <CustomButton button={button} title={title} csvExport={csvExport} />
                    ) : (
                        getRightComponent(session, search, select)
                    )}
                </Grid>

                {(title && button && search) || (title && csvExport && search) || (search && select) ? (
                    <>
                        <Divider />

                        <Grid item sm={12} display="flex" sx={{ px: 1.5 }}>
                            <Grid item sm={3}>
                                {search ? <CustomSearch search={search} /> : null}
                            </Grid>
                            <Grid item sm={9} display="flex" justifyContent="flex-end">
                                {session ? (
                                    <Grid item sm={session.sm} lg={session.lg} xl={session.xl} sx={{ mr: select ? 1.5 : 0 }}>
                                        <CustomSession session={session} />
                                    </Grid>
                                ) : null}
                                {select ? (
                                    <Grid item sm={select.sm} lg={select.lg} xl={select.xl}>
                                        <CustomSelect select={select} />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Grid>
                    </>
                ) : null}
            </Grid>
        </>
    );
}

export default Header;
