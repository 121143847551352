// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    HomeOutlined,
    MenuBookOutlined,
    NotificationsNoneOutlined,
    CalendarMonthOutlined,
    RateReviewOutlined,
    InventoryOutlined,
    InfoOutlined,
    ClassOutlined,
    SupervisorAccountOutlined,
    FamilyRestroomOutlined,
    BadgeOutlined,
    CurrencyRupeeOutlined,
    Security,
    DirectionsBus,
    Storage,
    AccountBalanceWallet,
    Wysiwyg,
    DirectionsWalk,
    Storefront,
    Download
} from '@mui/icons-material';

// constant
const icons = {
    HomeOutlined,
    MenuBookOutlined,
    NotificationsNoneOutlined,
    RateReviewOutlined,
    InventoryOutlined,
    CalendarMonthOutlined,
    InfoOutlined,
    ClassOutlined,
    SupervisorAccountOutlined,
    FamilyRestroomOutlined,
    BadgeOutlined,
    CurrencyRupeeOutlined,
    Security,
    DirectionsBus,
    Storage,
    AccountBalanceWallet,
    Wysiwyg,
    DirectionsWalk,
    Storefront,
    Download
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const managments = {
    id: 'managments',
    type: 'group',
    children: [
        // {
        //     id: 'home',
        //     title: <FormattedMessage id="home" />,
        //     type: 'item',
        //     url: '/home',
        //     icon: icons.HomeOutlined,
        //     breadcrumbs: true
        // },
        {
            id: 'info',
            title: <FormattedMessage id="info" />,
            type: 'item',
            url: '/info',
            icon: icons.InfoOutlined,
            breadcrumbs: false
        },
        {
            id: 'classes',
            title: <FormattedMessage id="classes" />,
            type: 'item',
            url: '/classes',
            icon: icons.ClassOutlined,
            breadcrumbs: false
        },
        {
            id: 'attendance',
            title: <FormattedMessage id="attendance" />,
            type: 'item',
            url: '/attendance',
            icon: icons.CalendarMonthOutlined,
            breadcrumbs: false
        },
        {
            id: 'parents',
            title: <FormattedMessage id="parents" />,
            type: 'item',
            url: '/parents',
            icon: icons.FamilyRestroomOutlined,
            breadcrumbs: false
        },
        {
            id: 'notice',
            title: <FormattedMessage id="notice" />,
            type: 'item',
            url: '/notice',
            icon: icons.NotificationsNoneOutlined,
            breadcrumbs: false
        },
        {
            id: 'exams',
            title: <FormattedMessage id="exams" />,
            type: 'item',
            url: '/exams',
            icon: icons.RateReviewOutlined,
            breadcrumbs: false
        },
        {
            id: 'results',
            title: <FormattedMessage id="results" />,
            type: 'item',
            url: '/results',
            icon: icons.InventoryOutlined,
            breadcrumbs: false
        },
        {
            id: 'teachers',
            title: <FormattedMessage id="teachers" />,
            type: 'item',
            url: '/teachers',
            icon: icons.SupervisorAccountOutlined,
            breadcrumbs: false
        },
        {
            id: 'staffs',
            title: <FormattedMessage id="staffs" />,
            type: 'item',
            url: '/staffs',
            icon: icons.BadgeOutlined,
            breadcrumbs: false
        },
        {
            id: 'finance',
            title: <FormattedMessage id="finance" />,
            type: 'collapse',
            icon: icons.CurrencyRupeeOutlined,
            children: [
                {
                    id: 'fee',
                    title: <FormattedMessage id="fees" />,
                    type: 'item',
                    url: '/fees',
                    breadcrumbs: false
                },
                {
                    id: 'not-fees-students',
                    title: <FormattedMessage id="not-fees" />,
                    type: 'item',
                    url: '/not-fees-students',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'stationery',
            title: <FormattedMessage id="stationery" />,
            type: 'collapse',
            icon: icons.Storefront,
            children: [
                {
                    id: 'inventory',
                    title: <FormattedMessage id="inventory" />,
                    type: 'item',
                    url: '/inventory',
                    breadcrumbs: false
                },
                {
                    id: 'sales',
                    title: <FormattedMessage id="sales" />,
                    type: 'item',
                    url: '/sales',
                    breadcrumbs: false
                },
                {
                    id: 'orders',
                    title: <FormattedMessage id="orders" />,
                    type: 'item',
                    url: '/orders',
                    breadcrumbs: false
                },
                {
                    id: 'store',
                    title: <FormattedMessage id="store" />,
                    type: 'item',
                    url: '/store',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'permission',
            title: <FormattedMessage id="permission" />,
            type: 'item',
            url: '/permission',
            icon: icons.Security,
            breadcrumbs: false
        },
        {
            id: 'recharge',
            title: <FormattedMessage id="recharge" />,
            type: 'item',
            url: '/recharge',
            icon: icons.AccountBalanceWallet,
            breadcrumbs: false
        },
        // {
        //     id: 'template',
        //     title: <FormattedMessage id="template" />,
        //     type: 'item',
        //     url: '/template',
        //     icon: icons.Wysiwyg,
        //     breadcrumbs: false
        // },
        {
            id: 'template',
            title: <FormattedMessage id="template" />,
            type: 'collapse',
            icon: icons.Wysiwyg,
            children: [
                {
                    id: 'templates',
                    title: <FormattedMessage id="templates" />,
                    type: 'item',
                    url: '/templates',
                    breadcrumbs: false
                },
                {
                    id: 'selected',
                    title: <FormattedMessage id="selected" />,
                    type: 'item',
                    url: '/selected-templates',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'records',
            title: <FormattedMessage id="records" />,
            type: 'collapse',
            icon: icons.Storage,
            children: [
                {
                    id: 'create-account',
                    title: <FormattedMessage id="create-account" />,
                    type: 'item',
                    url: '/create-account',
                    breadcrumbs: false
                },
                {
                    id: 'new-record',
                    title: <FormattedMessage id="new-record" />,
                    type: 'item',
                    url: '/new-record',
                    breadcrumbs: false
                },
                {
                    id: 'update-record',
                    title: <FormattedMessage id="update-record" />,
                    type: 'item',
                    url: '/update-record',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'transport',
            title: <FormattedMessage id="transport" />,
            type: 'collapse',
            icon: icons.DirectionsBus,
            children: [
                {
                    id: 'vehicle-routes',
                    title: <FormattedMessage id="vehicle-routes" />,
                    type: 'item',
                    url: '/vehicle-routes',
                    breadcrumbs: false
                },
                {
                    id: 'vehicles',
                    title: <FormattedMessage id="vehicles" />,
                    type: 'item',
                    url: '/vehicles',
                    breadcrumbs: false
                },
                {
                    id: 'vehicle-reports',
                    title: <FormattedMessage id="reports" />,
                    type: 'item',
                    url: '/vehicle-reports',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'visitors',
            title: <FormattedMessage id="visitors" />,
            type: 'item',
            url: '/visitors',
            icon: icons.DirectionsWalk,
            breadcrumbs: false
        },
        {
            id: 'download',
            title: <FormattedMessage id="download" />,
            type: 'item',
            url: '/download',
            icon: icons.Download,
            breadcrumbs: false
        }
    ]
};

export default managments;
