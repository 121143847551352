import { Delete, DirectionsBus, KeyboardArrowDown, KeyboardArrowUp, OpenInNew, Print, Upload, Visibility } from '@mui/icons-material';
import { Button, ButtonGroup, Card, FormControlLabel, Grid, IconButton, LinearProgress, Stack, Switch, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Fragment, useState } from 'react';
import { useDispatch } from 'store';
import { gridSpacing } from 'store/constant';
import { setFilteredTableData } from 'store/slices/common';
import SubCard from 'ui-component/cards/SubCard';
import Chip from 'ui-component/extended/Chip';
import ChipWH from 'ui-component/extended/ChipWH';
import '../custom-card.css';
import { nanoid } from 'nanoid';

const handleAlign = (e) => {
    if (e === 'right') {
        return 'flex-end';
    }
    if (e === 'center') {
        return 'center';
    }
    return 'flex-start';
};

const BorderLinearProgress = withStyles(() => ({
    root: {
        height: 10,
        borderRadius: 5
    },
    bar: {
        borderRadius: 5
    }
}))(LinearProgress);

function Records({ headCells, filteredTableData }) {
    const [noticeId, setNoticeId] = useState('');
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const handleRemove = (e) => {
        const data = filteredTableData.filter((i) => i.id !== e);
        dispatch(setFilteredTableData(data));
    };

    const onchange = (e) => {
        if (noticeId === e) {
            setOpen(!open);
        } else {
            setOpen(true);
        }
        setNoticeId(e);
    };

    return (
        <>
            {filteredTableData.map((row, rowIndex) => (
                <Grid key={`row-${row.type}-${row.id}-${rowIndex}`} item xs={12} lg={12} md={12}>
                    {/* <SubCard className="pbx-annoucement-subcard-grid"> */}
                    {/* <Grid container spacing={gridSpacing} className="pbx-annoucement-spacingbtt"> */}
                    <Card sx={{ border: '1px solid #eeeeee', p: 1.5 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container>
                                    {headCells.map((item, itemIndex) => (
                                        <Fragment key={`column-${item.type}-${item.id}-${itemIndex}`}>
                                            {item.type === 'object' && row[item.id]?.label ? (
                                                <Grid
                                                    item
                                                    xs={item.gridSize}
                                                    display="flex"
                                                    flexWrap="wrap"
                                                    flexDirection="column"
                                                    justifyContent="flex-end"
                                                    sx={{ ...item.sx }}
                                                >
                                                    <Stack alignItems={item.alignM}>
                                                        <Typography variant="caption" align="center" fontSize={10}>
                                                            {item.label}
                                                        </Typography>
                                                        <Chip
                                                            chipcolor={row[item.id]?.chipColor ? row[item.id].chipColor : 'primary'}
                                                            sx={{
                                                                height: 24,
                                                                borderRadius: 1
                                                                // maxWidth: 50,
                                                                // whiteSpace: 'nowrap',
                                                                // overflow: 'hidden',
                                                                // textOverflow: 'ellipsis'
                                                            }}
                                                            label={row[item.id]?.label}
                                                            size="small"
                                                            onClick={
                                                                item.action
                                                                    ? (e) =>
                                                                          item.action(e, {
                                                                              rowId: row.id,
                                                                              headId: item.id,
                                                                              transportId:
                                                                                  row.transportId && row.rowType === 'transportFees'
                                                                          })
                                                                    : null
                                                            }
                                                        />
                                                        {row.nowOfRow === 2 && row[item.id]?.label1 ? (
                                                            <Chip
                                                                chipcolor={row[item.id]?.chipColor1 ? row[item.id].chipColor1 : 'primary'}
                                                                sx={{
                                                                    height: 24,
                                                                    borderRadius: 1,
                                                                    // maxWidth: 50,
                                                                    // whiteSpace: 'nowrap',
                                                                    // overflow: 'hidden',
                                                                    // textOverflow: 'ellipsis'
                                                                    mt: 0.75
                                                                }}
                                                                label={row[item.id]?.label1}
                                                                size="small"
                                                                onClick={
                                                                    item.action
                                                                        ? (e) =>
                                                                              item.action(e, {
                                                                                  rowId: row.id,
                                                                                  headId: item.id,
                                                                                  transportId: row.transportId
                                                                              })
                                                                        : null
                                                                }
                                                            />
                                                        ) : null}
                                                    </Stack>
                                                </Grid>
                                            ) : null}
                                            {(!item.disableMobile && item.type === 'text') || item.type === 'button' ? (
                                                <Grid
                                                    item
                                                    xs={item.gridSize}
                                                    display="flex"
                                                    flexWrap="wrap"
                                                    flexDirection="column"
                                                    justifyContent="flex-end"
                                                >
                                                    <Typography variant="caption" align={item.alignM ? item.alignM : 'left'}>
                                                        {item.label}
                                                    </Typography>

                                                    {item.chip ? (
                                                        // <Typography align={item.alignM ? item.alignM : 'left'}>
                                                        <>
                                                            {row[item.id] || item.cellLabel ? (
                                                                <Stack direction="row" alignItems="center" justifyContent={item.alignM}>
                                                                    {row.transportId ? <DirectionsBus /> : null}
                                                                    <Chip
                                                                        chipcolor={item.chipColor ? item.chipColor : 'primary'}
                                                                        sx={{ height: 24, borderRadius: 1 }}
                                                                        label={item.type === 'button' ? item.cellLabel : row[item.id]}
                                                                        size="small"
                                                                        onClick={item.action ? (e) => item.action(e, row.id) : null}
                                                                    />
                                                                </Stack>
                                                            ) : (
                                                                <Typography align={item.alignM ? item.alignM : 'left'}>N/A</Typography>
                                                            )}
                                                        </>
                                                    ) : // </Typography>
                                                    null}
                                                    {item.chipWH ? (
                                                        // <Typography align={item.alignM ? item.alignM : 'left'}>
                                                        <>
                                                            {row[item.id] ? (
                                                                <ChipWH
                                                                    chipcolor={item.chipColor ? item.chipColor : 'primary'}
                                                                    sx={{ height: 24, borderRadius: 1 }}
                                                                    label={row[item.id]}
                                                                    size="small"
                                                                />
                                                            ) : (
                                                                <Typography align={item.alignM ? item.alignM : 'left'}>N/A</Typography>
                                                            )}
                                                        </>
                                                    ) : // </Typography>
                                                    null}

                                                    {!item.chipWH && !item.chip ? (
                                                        <Typography variant="h6" align={item.alignM ? item.alignM : 'left'}>
                                                            {row[item.id] ? row[item.id] : 'N/A'}
                                                        </Typography>
                                                    ) : null}
                                                </Grid>
                                            ) : null}
                                            {item.type === 'switch' ? (
                                                <Grid
                                                    item
                                                    xs={item.gridSize}
                                                    display="flex"
                                                    flexWrap="wrap"
                                                    flexDirection="column"
                                                    justifyContent="flex-end"
                                                    align={item.alignM ? item.alignM : 'left'}
                                                >
                                                    <Typography variant="caption" align={item.alignM ? item.alignM : 'left'}>
                                                        {item.label}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            '.MuiFormControlLabel-root': {
                                                                mt: '-8px'
                                                            }
                                                        }}
                                                        variant="h6"
                                                        align={item.alignM ? item.alignM : 'left'}
                                                    >
                                                        <FormControlLabel
                                                            sx={{ mr: -1.2, mb: -1.2 }}
                                                            control={
                                                                <Switch
                                                                    checked={row[item.id]}
                                                                    onClick={item.action ? (e) => item.action(e, row.id) : null}
                                                                />
                                                            }
                                                        />
                                                    </Typography>
                                                </Grid>
                                            ) : null}
                                            {item.type === 'removeBtn' ? (
                                                <Grid item xs={item.gridSize} display="flex" justifyContent={handleAlign(item.alignM)}>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={item.action ? (e) => item.action(e, row.id) : null}
                                                    >
                                                        <Delete color="error" />
                                                    </IconButton>
                                                </Grid>
                                            ) : null}
                                            {item.type === 'progress' ? (
                                                <Grid
                                                    item
                                                    xs={item.gridSize}
                                                    display="flex"
                                                    flexWrap="wrap"
                                                    flexDirection="column"
                                                    justifyContent="flex-end"
                                                >
                                                    <Typography
                                                        variant="caption"
                                                        align={item.alignM ? item.alignM : 'left'}
                                                        sx={{ mb: 0.5 }}
                                                    >
                                                        {item.label}
                                                    </Typography>
                                                    <Grid item sx={{ mb: 0.5 }}>
                                                        <BorderLinearProgress variant="determinate" color="success" value={row[item.id]} />
                                                    </Grid>
                                                </Grid>
                                            ) : null}
                                            {item.type === 'link' && row[item.id] ? (
                                                <Grid
                                                    item
                                                    xs={item.gridSize}
                                                    display="flex"
                                                    flexWrap="wrap"
                                                    flexDirection="column"
                                                    justifyContent="flex-end"
                                                    align={item.alignM ? item.alignM : 'left'}
                                                >
                                                    <Typography variant="caption" align={item.alignM ? item.alignM : 'left'}>
                                                        {item.label}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            '.MuiFormControlLabel-root': {
                                                                mt: '-8px'
                                                            }
                                                        }}
                                                        variant="h6"
                                                        align={item.alignM ? item.alignM : 'left'}
                                                    >
                                                        <IconButton
                                                            aria-label="Open in new tab"
                                                            component="a"
                                                            href={row[item.id]}
                                                            target="_blank"
                                                            color="primary"
                                                            size="small"
                                                        >
                                                            <OpenInNew />
                                                        </IconButton>
                                                    </Typography>
                                                </Grid>
                                            ) : null}
                                            {item.type === 'upload' ? (
                                                <Grid
                                                    item
                                                    xs={item.gridSize}
                                                    display="flex"
                                                    flexWrap="wrap"
                                                    flexDirection="column"
                                                    justifyContent="flex-end"
                                                    align={item.alignM ? item.alignM : 'left'}
                                                >
                                                    <Typography variant="caption" align={item.alignM ? item.alignM : 'left'}>
                                                        {item.label}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            '.MuiFormControlLabel-root': {
                                                                mt: '-8px'
                                                            }
                                                        }}
                                                        variant="h6"
                                                        align={item.alignM ? item.alignM : 'left'}
                                                    >
                                                        <ButtonGroup disableElevation variant="outlined" size="small">
                                                            <Button onClick={item.action ? () => item.action(0, row.id) : null}>
                                                                <Upload sx={{ fontSize: '16px !important' }} />
                                                            </Button>
                                                            <Button onClick={item.action ? () => item.action(1, row.id) : null}>
                                                                <Visibility sx={{ fontSize: '16px !important' }} />
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Typography>
                                                </Grid>
                                            ) : null}
                                            {item.type === 'accordian' ? (
                                                <Grid item xs={12}>
                                                    <Grid item xs={12} container>
                                                        <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Typography variant="body1" fontWeight="bold">
                                                                {item.label}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2} display="flex" justifyContent="flex-end">
                                                            <IconButton
                                                                aria-label="expand row"
                                                                size="small"
                                                                id={row.id}
                                                                onClick={() => onchange(row.id)}
                                                            >
                                                                {open && noticeId === row.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>

                                                    {open ? (
                                                        <Typography variant="body1" align="left">
                                                            {row.arrordData}
                                                        </Typography>
                                                    ) : null}
                                                </Grid>
                                            ) : null}
                                            {item.type === 'print' ? (
                                                <Grid item xs={item.gridSize} display="flex" justifyContent={handleAlign(item.alignM)}>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={item.action ? (e) => item.action(e, row.id) : null}
                                                    >
                                                        <Print />
                                                    </IconButton>
                                                </Grid>
                                            ) : null}
                                        </Fragment>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            ))}
        </>
    );
}

export default Records;
