import PropTypes from 'prop-types';
import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import { Close, DirectionsBus, Print } from '@mui/icons-material';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import DatePicker from '../UtilsComponent/BasicDate';
import useFeeStatusChange from './hooks/useFeeStatusChange';
import useTPFeeStatusChange from './hooks/useTransportFeeStatusChange';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { isEmpty, toNumber } from 'lodash';
// import './fees.css';

const validationSchema = yup.object({
    amount: yup.number().typeError('Must be a number type').min(0, 'Must be a positve number').required('Please enter fee amount'),
    mode: yup.string().required('Please select the payment mode').nullable(),
    transactionNo: yup.string().nullable(),
    date: yup.string().nullable(),
    updatedBy: yup.string().nullable()
});

const getTotalFees = (totalFees) => {
    if (totalFees) return toNumber(totalFees);
    return '';
};

const getMode = (mode) => {
    if (mode) return mode;
    return 'Cash';
};

const FeesStatusForm = ({ open, close, monthName, handleFeeUpdate, isClassFee, selectedRecord, isFormDisabled }) => {
    const location = useLocation();
    const { user } = useAuth();
    const [getFeeDeatails, updateFeeDetails, handleDueFees, responseData, isLoading, isFeeUpdated] = useFeeStatusChange();
    const [getTPFeeDeatails, updateTPFeeDetails, handleTPDueFees, responseTPData, isTPLoading, isTPFeeUpdated] = useTPFeeStatusChange();

    const getSelectedMonthFeeDeatails = async () => {
        if (selectedRecord.id && isClassFee) {
            await getFeeDeatails(monthName, selectedRecord.id);
        } else if (selectedRecord.id) {
            await getTPFeeDeatails(monthName, selectedRecord.id);
        }
    };

    useEffect(() => {
        if (open) {
            getSelectedMonthFeeDeatails();
        }
    }, []);

    const getIntialValue = (type) => {
        let initialValue;
        if (type === 'amount')
            if (isClassFee) {
                initialValue =
                    !isEmpty(responseData) && responseData.amount ? responseData.amount : getTotalFees(selectedRecord?.totalFees);
            } else {
                initialValue =
                    !isEmpty(responseTPData) && responseTPData.amount ? responseTPData.amount : getTotalFees(selectedRecord?.transportFees);
            }

        if (type === 'mode')
            if (isClassFee) {
                initialValue = !isEmpty(responseData) ? getMode(responseData.mode) : 'Cash';
            } else {
                initialValue = !isEmpty(responseTPData) ? getMode(responseTPData.mode) : 'Cash';
            }

        if (type === 'transactionNo')
            if (isClassFee) {
                initialValue = !isEmpty(responseData) ? responseData.transactionNo : '';
            } else {
                initialValue = !isEmpty(responseTPData) ? responseTPData.transactionNo : '';
            }

        if (type === 'date')
            if (isClassFee) {
                initialValue = !isEmpty(responseData) && responseData.date ? responseData.date : format(new Date(), 'yyyy-MM-dd');
            } else {
                initialValue = !isEmpty(responseTPData) && responseTPData.date ? responseTPData.date : format(new Date(), 'yyyy-MM-dd');
            }

        if (type === 'updatedBy')
            if (isClassFee) {
                initialValue = !isEmpty(responseData) ? responseData.updatedBy : '';
            } else {
                initialValue = !isEmpty(responseTPData) ? responseTPData.updatedBy : '';
            }

        return initialValue;
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: getIntialValue('amount'),
            mode: getIntialValue('mode'),
            transactionNo: getIntialValue('transactionNo'),
            date: getIntialValue('date'),
            updatedBy: getIntialValue('updatedBy')
        },
        validationSchema,
        onSubmit: (values) => {
            if (isClassFee) {
                updateFeeDetails(monthName, selectedRecord.id, '', '', user.email, values, handleFeeUpdate);
            } else {
                updateTPFeeDetails(monthName, selectedRecord.id, '', '', user.email, values, handleFeeUpdate);
            }
        }
    });

    const handleMarkAsDue = (monthName, studentId) => {
        if (isClassFee) {
            handleDueFees(monthName, studentId, handleFeeUpdate);
        } else {
            handleTPDueFees(monthName, studentId, handleFeeUpdate);
        }
    };

    return (
        <>
            {isLoading || isTPLoading ? (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading || isTPLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <Dialog
                    open={open && (!isEmpty(responseData) || !isEmpty(responseTPData))}
                    keepMounted
                    onClose={close}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    sx={{
                        '& .MuiDialog-paper': {
                            p: 0,
                            width: 300
                        }
                    }}
                >
                    {open && (
                        <Grid item xs={12} md={12} lg={12}>
                            {(isFeeUpdated || isTPFeeUpdated) && (
                                <Alert variant="filled" severity="success" sx={{ borderRadius: '4px 4px 0px 0px' }}>
                                    Fees successfully taken.
                                </Alert>
                            )}
                            <MainCard
                                title={
                                    <Stack direction="row" alignItems="center">
                                        {`${
                                            location.pathname !== '/vehicles/students' ? selectedRecord.rollNo : selectedRecord.studentName
                                        } - ${monthName}`}{' '}
                                        {isClassFee ? null : <DirectionsBus />}
                                    </Stack>
                                }
                                secondary={
                                    <IconButton sx={{ p: 0, pb: 0 }} onClick={close} size="large">
                                        <Close fontSize="small" />
                                    </IconButton>
                                }
                                sx={{
                                    '.MuiCardHeader-root': {
                                        p: 1.5
                                    },
                                    '.MuiCardContent-root': {
                                        p: 2
                                    },
                                    borderRadius: '0px 0px 4px 4px'
                                }}
                            >
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Grid item xs={12}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                name="amount"
                                                value={formik.values.amount}
                                                onChange={formik.handleChange}
                                                error={formik.touched.amount && Boolean(formik.errors.amount)}
                                                helperText={formik.touched.amount && formik.errors.amount}
                                                fullWidth
                                                label="Amount"
                                                size="small"
                                                disabled={isFormDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="mode">Payment Mode</InputLabel>
                                                <Select
                                                    id="mode"
                                                    name="mode"
                                                    value={formik.values.mode ? formik.values.mode : ''}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.mode && Boolean(formik.errors.mode)}
                                                    fullWidth
                                                    label="Payment Mode"
                                                    size="small"
                                                    disabled={isFormDisabled}
                                                >
                                                    <MenuItem value="Cash">Cash</MenuItem>
                                                    <MenuItem value="Cheque">Cheque</MenuItem>
                                                    <MenuItem value="UPI">UPI</MenuItem>
                                                    <MenuItem value="Internet Banking">Internet Banking</MenuItem>
                                                </Select>
                                                <FormHelperText sx={{ color: 'red' }}>
                                                    {formik.touched.mode && formik.errors.mode}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                name="transactionNo"
                                                value={formik.values.transactionNo ? formik.values.transactionNo : ''}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.values.transactionNo && Boolean(formik.errors.transactionNo)
                                                        ? Boolean(formik.errors.transactionNo)
                                                        : false
                                                }
                                                helperText={
                                                    formik.touched.transactionNo && formik.errors.transactionNo
                                                        ? formik.errors.transactionNo
                                                        : ''
                                                }
                                                fullWidth
                                                label="Transaction No"
                                                size="small"
                                                disabled={formik.values.mode === 'Cash' || isFormDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                id="date"
                                                name="date"
                                                value={formik.values.date}
                                                handleFormik={formik}
                                                error={formik.touched.date && Boolean(formik.errors.date)}
                                                helperText={formik.touched.date && formik.errors.date}
                                                fullWidth
                                                label="Date"
                                                size="small"
                                                disabled={isFormDisabled}
                                            />
                                        </Grid>
                                        {(responseData?.invoiceNo || responseTPData?.invoiceNo) && (
                                            <>
                                                <Grid item xs={10} display="flex" alignItems="center">
                                                    {/* {`${'Invoice No - '}`} */}
                                                    <Typography style={{ fontSize: 11 }}>Invoice No -</Typography>
                                                    <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                                                        &nbsp;{responseData.invoiceNo || responseTPData?.invoiceNo}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} justifyContent="right" display="flex">
                                                    <IconButton disabled size="small" color="primary">
                                                        <Print />
                                                    </IconButton>
                                                </Grid>

                                                <Grid item xs={12} sx={{ pt: '4px !important' }} display="flex" alignItems="center">
                                                    {/* <Grid item xs={3.2} sx={{ p: '0px !important' }}>
                                                        <p>Taken By - </p>
                                                    </Grid>{' '} */}
                                                    <Typography style={{ fontSize: 11 }}>Taken By - </Typography>
                                                    <Typography style={{ fontSize: 14, fontWeight: 550 }}>
                                                        &nbsp;
                                                        {responseData?.updatedBy || responseTPData?.updatedBy
                                                            ? responseData?.updatedBy || responseTPData?.updatedBy
                                                            : ''}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}

                                        {!isFormDisabled ? (
                                            <>
                                                <Grid item xs={6}>
                                                    <Stack direction="row" spacing={1} justifyContent="center">
                                                        <AnimateButton>
                                                            <Button variant="contained" type="submit" size="small">
                                                                Submit Fee
                                                            </Button>
                                                        </AnimateButton>
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Stack direction="row" spacing={1} justifyContent="center">
                                                        <AnimateButton>
                                                            <Button
                                                                color="error"
                                                                variant="contained"
                                                                size="small"
                                                                onClick={() => handleMarkAsDue(monthName, selectedRecord.id)}
                                                            >
                                                                Mark Due
                                                            </Button>
                                                        </AnimateButton>
                                                    </Stack>
                                                </Grid>
                                            </>
                                        ) : null}
                                    </Grid>
                                </form>
                            </MainCard>
                        </Grid>
                    )}
                </Dialog>
            )}
        </>
    );
};

FeesStatusForm.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func
};

export default FeesStatusForm;
