import PrintableRecord from './PrintableRecord';
import './printable.css';

const PrintableList = ({ schoolInfo, studentInfo, fees, isClassFee, templateId }) => (
    <div
        className="printable-container"
        style={{
            margin: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'center'
        }}
    >
        <PrintableRecord schoolInfo={schoolInfo} studentInfo={studentInfo} fees={fees} isClassFee={isClassFee} templateId={templateId} />
    </div>
);

export default PrintableList;
