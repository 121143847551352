import { FormControl, Select as MuiSelect, InputLabel, MenuItem, useTheme } from '@mui/material';

// const handleOptionSelect = () => {};

function Select({ options = [], selectedOption = '', handleOptionSelect }) {
    const theme = useTheme();

    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Teacher</InputLabel>
            <MuiSelect
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedOption}
                label="Select Teacher"
                onChange={(e) => handleOptionSelect(e)}
                size="small"
                sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.divider
                    }
                }}
            >
                {options?.map((item, i) => (
                    <MenuItem key={i} value={item.teacherMail}>
                        {item.teacherName}
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
    );
}

export default Select;
