import PropTypes from 'prop-types';
import { Card, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { gridSpacing } from 'store/constant';
import Avatar from 'ui-component/extended/Avatar';
import { useQuery } from '@apollo/client';
import { GET_TEACHER_STUDENT_PARENT_DETAILS } from 'services/teacher/query';
import { useState } from 'react';

const ParentInfo = ({ id }) => {
    const theme = useTheme();

    const [parentInformation, setParentInformation] = useState({});

    useQuery(GET_TEACHER_STUDENT_PARENT_DETAILS, {
        variables: {
            studentId: id
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            // console.log(data);
            if (data?.school_students_by_pk) {
                setParentInformation({
                    fatherName: data.school_students_by_pk?.student_father_detail?.father_name,
                    fatherPhon1: data.school_students_by_pk?.student_father_detail?.father_contact1,
                    fatherPhone2: data.school_students_by_pk?.student_father_detail?.father_contact2,
                    motherName: data.school_students_by_pk?.student_mother_detail?.mother_name,
                    motherPhone1: data.school_students_by_pk?.student_mother_detail?.mother_contact1,
                    motherPhone2: data.school_students_by_pk?.student_mother_detail?.mother_contact2
                });
            }
        },
        onError: (error) => {
            // console.log(error);
        }
    });

    return (
        <Card
            sx={{
                p: 2,
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                border: theme.palette.mode === 'dark' ? '1px solid transparent' : `1px solid${theme.palette.grey[100]}`,
                '&:hover': {
                    borderColor: theme.palette.primary.main
                }
            }}
        >
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center">
                    <Typography variant="h4" component="div">
                        Father Details
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ pt: '8px !important' }}>
                    <Grid container sx={{ pb: { xs: 1, sm: 1 } }}>
                        <Grid item xs={12}>
                            <Typography variant="caption">Father Name</Typography>
                            <Typography variant="h6">{parentInformation.fatherName}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} sx={{ pb: { xs: 1, sm: 'auto' } }}>
                            <Typography variant="caption">Father Phone 1</Typography>
                            <Typography variant="h6">{parentInformation.fatherPhon1}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption">Father Phone 2</Typography>
                            <Typography variant="h6">{parentInformation.fatherPhone2}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center">
                    <Typography variant="h4" component="div">
                        Mother Details
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ pt: '8px !important' }}>
                    <Grid container sx={{ pb: { xs: 1, sm: 1 } }}>
                        <Grid item xs={12}>
                            <Typography variant="caption">Mother Name</Typography>
                            <Typography variant="h6">{parentInformation.motherName}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} sx={{ pb: { xs: 1, sm: 'auto' } }}>
                            <Typography variant="caption">Mother Phone 1</Typography>
                            <Typography variant="h6">{parentInformation.motherPhone1}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption">Mother Phone 2</Typography>
                            <Typography variant="h6">{parentInformation.motherPhone2}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

ParentInfo.propTypes = {
    id: PropTypes.string
};

export default ParentInfo;
