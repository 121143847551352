/* eslint-disable import/prefer-default-export */
import { createSlice } from '@reduxjs/toolkit';
import { getCurrentSession } from 'utils/common';

const initialState = {
    newStaff: {},
    securityGaurdMailId: '',
    securityGaurdCanAccess: false,
    feesClassCMData: {},
    studentClassInfo: {},
    classId: '',
    className: '',
    classSection: '',
    subjectsList: [],
    attenClassDetails: {},
    selectedSession: getCurrentSession(),
    resultCListSltTab: 0,
    resultSltClassDet: {},
    resultSltCRoomDet: {},
    resultSltExamDet: {},
    sltClassDet: {},
    classesSltCRooomDet: {},
    isFeesSearchByRN: false
};

export const managmentSlice = createSlice({
    name: 'managment',
    initialState,
    reducers: {
        setNewStaff: (state, action) => {
            state.newStaff = action.payload;
        },
        setSecurityGaurdMailId: (state, action) => {
            state.securityGaurdMailId = action.payload;
        },
        setSecurityGaurdCanAccess: (state, action) => {
            state.securityGaurdCanAccess = action.payload;
        },
        setFeesClassCMData: (state, action) => {
            state.feesClassCMData = action.payload;
        },
        setStudentClassInfo: (state, action) => {
            state.studentClassInfo = action.payload;
        },
        setClassId: (state, action) => {
            state.classId = action.payload;
        },
        setClassName: (state, action) => {
            state.className = action.payload;
        },
        setClassSection: (state, action) => {
            state.classSection = action.payload;
        },
        setSubjectsList: (state, action) => {
            state.subjectsList = action.payload;
        },
        setAttenClassDetails: (state, action) => {
            state.attenClassDetails = action.payload;
        },
        setResultCListSltTab: (state, action) => {
            state.resultCListSltTab = action.payload;
        },
        setResultSltClassDet: (state, action) => {
            state.resultSltClassDet = action.payload;
        },
        setResultSltCRoomDet: (state, action) => {
            state.resultSltCRoomDet = action.payload;
        },
        setResultSltExamDet: (state, action) => {
            state.resultSltExamDet = action.payload;
        },
        setSelectedSession: (state, action) => {
            state.selectedSession = action.payload;
        },
        setClassesSltCRooomDet: (state, action) => {
            state.classesSltCRooomDet = action.payload;
        },
        setSltClassDet: (state, action) => {
            state.sltClassDet = action.payload;
        },
        setIsFeesSearchByRN: (state, action) => {
            state.isFeesSearchByRN = action.payload;
        }
    }
});

export const {
    setNewStaff,
    setSecurityGaurdMailId,
    setSecurityGaurdCanAccess,
    setFeesClassCMData,
    setStudentClassInfo,
    setClassId,
    setClassName,
    setClassSection,
    setSubjectsList,
    setClassStartDate,
    setAttenClassDetails,
    setResultCListSltTab,
    setResultSltClassDet,
    setResultSltCRoomDet,
    setResultSltExamDet,
    setSelectedSession,
    setClassesSltCRooomDet,
    setSltClassDet,
    setIsFeesSearchByRN
} = managmentSlice.actions;

export default managmentSlice.reducer;
