import { Grid } from '@mui/material';
import Button from './Button';
import CSVExport from './CSVExport';
import SearchField from './SearchField';
import Select from './Select';
import SessionSelect from './SessionSelect';
import Title from './Title';

const CustomTitle = ({ title }) => (
    <Grid item xs={title.xs} sx={title.sx}>
        <Title title={title.titleName} />
    </Grid>
);

const CustomSearch = ({ search }) => (
    <Grid item xs={search?.xs} sx={search?.sx}>
        <SearchField name={search?.name} filter={search?.filter} />
    </Grid>
);

const CustomButton = ({ button }) => (
    <Grid item xs={button?.xs} sx={button.sx}>
        <Button width={button?.width} btnName={button?.btnName} handleNewModal={button?.action} />
    </Grid>
);

const CustomSession = ({ session }) => (
    <Grid item xs={session?.xs} sx={session?.sx}>
        <SessionSelect handleSessionChange={session?.action} />
    </Grid>
);

const CustomSelect = ({ select }) => (
    <>
        <Grid item xs={select.xs} sx={select?.sx}>
            <Select options={select.options} selectedOption={select.selectedOption} handleOptionSelect={select.action} />
        </Grid>
        <CustomButton button={select?.button} />
    </>
);

const CustomCSVExport = ({ title, csvExport }) => (
    <Grid item xs={csvExport.xs} sx={csvExport?.sx} display="flex" flexDirection="flex-end">
        <CSVExport title={title} csvExport={csvExport} selectedCSVOption={csvExport.selectedCSVOption} />
    </Grid>
);

const defaultTitle = (title) => ({
    isTitle: title && title?.isTitle ? title.isTitle : false,
    position: title && title?.position ? title.position : 1,
    titleName: title && title?.titleName ? title.titleName : 'Title',
    xs: title && title?.xs ? title.xs : 5,
    sx: title && title?.sx ? title.sx : null
});

const defaultSearch = (search) => ({
    isSearch: search && search?.isSearch ? search.isSearch : false,
    position: search && search?.position ? search.position : 2,
    xs: search && search?.xs ? search?.xs : 7,
    sx: {
        display: search && search?.sx?.display ? search.sx.display : 'flex',
        justifyContent: search && search?.sx?.justifyContent ? search.sx.justifyContent : 'right',
        ...search.sx
    },
    name: search && search?.name ? search.name : '',
    filter: search && search?.filter ? search.filter : false
});

const defaultButton = (button) => ({
    btnName: button && button?.btnName ? button.btnName : 'New Record',
    isButton: button && button?.isButton ? button.isButton : false,
    position: button && button?.position ? button && button?.position : 3,
    xs: button && button?.xs ? button.xs : 5,
    sx: button && button?.sx ? button.sx : 1,
    action: button && button?.action ? button.action : false,
    width: button && button?.width ? button.width : '125px'
});

const defaultSession = (session) => ({
    isSession: session && session?.isSession ? session.isSession : false,
    position: session && session?.position ? session.position : 4,
    xs: session && session?.xs ? session.xs : 7,
    sx: {
        display: session && session?.sx?.display ? session.sx.display : 'flex',
        justifyContent: session && session?.sx?.justifyContent ? session.sx.justifyContent : 'flex-end',
        pt: session && session?.sx?.pt ? session.sx.pt : 1,
        ...session.sx
    },
    action: session && session?.action ? session.action : false
});

const defaultSelect = (select) => ({
    ...select,
    isSelect: select?.isSelect ? select.isSelect : false,
    position: select?.position ? select.position : 5,
    xs: select?.gridSize?.xs ? select.gridSize.xs : 7,
    sx: {
        display: select?.sx?.display ? select.sx.display : 'flex',
        justifyContent: select?.sx?.justifyContent ? select.sx.justifyContent : 'flex-end',
        pt: select?.sx?.pt ? select.sx.pt : 1.75,
        ...select.sx
    },
    width: select?.width ? select.width : 'inherit',
    button: {
        ...select.button,
        btnName: select?.button?.btnName ? select.button.btnName : 'Select',
        xs: select?.button?.xs ? select.button.xs : 5,
        sx: { pt: select?.button?.sx ? select.button.sx : 1.75 },
        action: select?.button?.action ? select.button.action : false,
        width: select?.button?.width ? select.button.width : '125px'
    }
});

function Header({ title, search, button, session, select, csvExport }) {
    title = defaultTitle(title);
    search = defaultSearch(search);
    button = defaultButton(button);
    session = defaultSession(session);
    select = defaultSelect(select);

    return (
        <Grid container alignItems="center" justifyContent="space-between">
            {/* Position 1 */}
            {title.position === 1 && title.isTitle ? <CustomTitle title={title} /> : null}
            {search.position === 1 && search.isSearch ? <CustomSearch search={search} /> : null}
            {button.position === 1 && button.isButton ? <CustomButton button={button} /> : null}
            {session.position === 1 && session.isSession ? <CustomSession session={session} /> : null}
            {select.position === 1 && select.isSelect ? <CustomSelect select={select} /> : null}
            {csvExport.position === 1 && csvExport.isCsvExport ? <CustomCSVExport title={title} csvExport={csvExport} /> : null}

            {/* Position 2 */}
            {title.position === 2 && title.isTitle ? <CustomTitle title={title} /> : null}
            {search.position === 2 && search.isSearch ? <CustomSearch search={search} /> : null}
            {button.position === 2 && button.isButton ? <CustomButton button={button} /> : null}
            {session.position === 2 && session.isSession ? <CustomSession session={session} /> : null}
            {select.position === 2 && select.isSelect ? <CustomSelect select={select} /> : null}
            {csvExport.position === 2 && csvExport.isCsvExport ? <CustomCSVExport title={title} csvExport={csvExport} /> : null}

            {/* Position 3 */}
            {title.position === 3 && title.isTitle ? <CustomTitle title={title} /> : null}
            {search.position === 3 && search.isSearch ? <CustomSearch search={search} /> : null}
            {button.position === 3 && button.isButton ? <CustomButton button={button} /> : null}
            {session.position === 3 && session.isSession ? <CustomSession session={session} /> : null}
            {select.position === 3 && select.isSelect ? <CustomSelect select={select} /> : null}
            {csvExport.position === 3 && csvExport.isCsvExport ? <CustomCSVExport title={title} csvExport={csvExport} /> : null}

            {/* Position 4 */}
            {title.position === 4 && title.isTitle ? <CustomTitle title={title} /> : null}
            {search.position === 4 && search.isSearch ? <CustomSearch search={search} /> : null}
            {button.position === 4 && button.isButton ? <CustomButton button={button} /> : null}
            {session.position === 4 && session.isSession ? <CustomSession session={session} /> : null}
            {select.position === 4 && select.isSelect ? <CustomSelect select={select} /> : null}
            {csvExport.position === 4 && csvExport.isCsvExport ? <CustomCSVExport title={title} csvExport={csvExport} /> : null}

            {/* Position 5 */}
            {title.position === 5 && title.isTitle ? <CustomTitle title={title} /> : null}
            {search.position === 5 && search.isSearch ? <CustomSearch search={search} /> : null}
            {button.position === 5 && button.isButton ? <CustomButton button={button} /> : null}
            {session.position === 5 && session.isSession ? <CustomSession session={session} /> : null}
            {select.position === 5 && select.isSelect ? <CustomSelect select={select} /> : null}
            {csvExport.position === 5 && csvExport.isCsvExport ? <CustomCSVExport title={title} csvExport={csvExport} /> : null}
        </Grid>
    );
}

export default Header;
