import { FormControl, InputLabel, MenuItem, Select, useTheme } from '@mui/material';
import { getCurrentSession } from 'utils/common';
import { sessionDropdownList } from 'utils/staticData';

function SessionSelect({ handleSessionChange }) {
    const theme = useTheme();

    return (
        <FormControl sx={{ display: 'flex', alignItems: 'center' }}>
            <InputLabel id="select-session-label">Session</InputLabel>
            <Select
                labelId="select-session-label"
                label="Session"
                defaultValue={getCurrentSession()}
                onChange={(e) => handleSessionChange(e)}
                size="small"
                sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.divider
                    }
                }}
            >
                {sessionDropdownList.map((item, i) => (
                    <MenuItem key={i} value={item.label}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default SessionSelect;
