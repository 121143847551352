import { useLazyQuery, useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { customAlphabet } from 'nanoid';
import { useState } from 'react';
import {
    UPDATE_ESSENTIAL_ADMFEE_STATUS,
    UPDATE_ESSENTIAL_F2_STATUS,
    UPDATE_ESSENTIAL_F3_STATUS,
    UPDATE_ESSENTIAL_F4_STATUS,
    UPDATE_ESSENTIAL_F5_STATUS,
    UPDATE_ESSENTIAL_F6_STATUS,
    UPDATE_ESSENTIAL_F7_STATUS,
    UPDATE_ESSENTIAL_F8_STATUS,
    UPDATE_ESSENTIAL_F9_STATUS,
    UPDATE_ESSENTIAL_F10_STATUS
} from 'services/accountant/mutation';
import { ADMFEE, F2FEE, F3FEE, F4FEE, F5FEE, F6FEE, F7FEE, F8FEE, F9FEE, F10FEE } from 'services/accountant/query';

function useEssentialFee() {
    const [updateAdmFee] = useMutation(UPDATE_ESSENTIAL_ADMFEE_STATUS);
    const [updateF2Fee] = useMutation(UPDATE_ESSENTIAL_F2_STATUS);
    const [updateF3Fee] = useMutation(UPDATE_ESSENTIAL_F3_STATUS);
    const [updateF4Fee] = useMutation(UPDATE_ESSENTIAL_F4_STATUS);
    const [updateF5Fee] = useMutation(UPDATE_ESSENTIAL_F5_STATUS);
    const [updateF6Fee] = useMutation(UPDATE_ESSENTIAL_F6_STATUS);
    const [updateF7Fee] = useMutation(UPDATE_ESSENTIAL_F7_STATUS);
    const [updateF8Fee] = useMutation(UPDATE_ESSENTIAL_F8_STATUS);
    const [updateF9Fee] = useMutation(UPDATE_ESSENTIAL_F9_STATUS);
    const [updateF10Fee] = useMutation(UPDATE_ESSENTIAL_F10_STATUS);

    const [getAdmFee] = useLazyQuery(ADMFEE);
    const [getF2Fee] = useLazyQuery(F2FEE);
    const [getF3Fee] = useLazyQuery(F3FEE);
    const [getF4Fee] = useLazyQuery(F4FEE);
    const [getF5Fee] = useLazyQuery(F5FEE);
    const [getF6Fee] = useLazyQuery(F6FEE);
    const [getF7Fee] = useLazyQuery(F7FEE);
    const [getF8Fee] = useLazyQuery(F8FEE);
    const [getF9Fee] = useLazyQuery(F9FEE);
    const [getF10Fee] = useLazyQuery(F10FEE);

    const [responseEFData, setEFResponseData] = useState({});
    const [isEFLoading, setIsEFLoading] = useState(false);
    const [isEFUpdated, setIsEFUpdated] = useState(false);

    const getEFeeDeatails = async (selectedBtn, studentId) => {
        if (selectedBtn === 'adm') {
            setIsEFLoading(true);
            setIsEFUpdated(false);
            getAdmFee({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setEFResponseData({
                        amount: response.data?.essen_fees_by_pk?.admfee,
                        mode: response.data?.essen_fees_by_pk?.admmode,
                        transactionNo: response.data?.essen_fees_by_pk?.admtxn,
                        date: response.data?.essen_fees_by_pk?.admdt,
                        updatedBy: response.data?.essen_fees_by_pk?.admudb,
                        invoiceNo: response.data?.essen_fees_by_pk?.adminv
                    });
                    setIsEFLoading(false);
                })
                .catch((error) => {});
        }
        if (selectedBtn === 'f2') {
            setIsEFLoading(true);
            setIsEFUpdated(false);
            getF2Fee({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setEFResponseData({
                        amount: response.data?.essen_fees_by_pk?.f2,
                        mode: response.data?.essen_fees_by_pk?.f2mode,
                        transactionNo: response.data?.essen_fees_by_pk?.f2txn,
                        date: response.data?.essen_fees_by_pk?.f2dt,
                        updatedBy: response.data?.essen_fees_by_pk?.f2udb,
                        invoiceNo: response.data?.essen_fees_by_pk?.f2inv
                    });
                    setIsEFLoading(false);
                })
                .catch((error) => {});
        }
        if (selectedBtn === 'f3') {
            setIsEFLoading(true);
            setIsEFUpdated(false);
            getF3Fee({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setEFResponseData({
                        amount: response.data?.essen_fees_by_pk?.f3,
                        mode: response.data?.essen_fees_by_pk?.f3mode,
                        transactionNo: response.data?.essen_fees_by_pk?.f3txn,
                        date: response.data?.essen_fees_by_pk?.f3dt,
                        updatedBy: response.data?.essen_fees_by_pk?.f3udb,
                        invoiceNo: response.data?.essen_fees_by_pk?.f3inv
                    });
                    setIsEFLoading(false);
                })
                .catch((error) => {});
        }
        if (selectedBtn === 'f4') {
            setIsEFLoading(true);
            setIsEFUpdated(false);
            getF4Fee({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setEFResponseData({
                        amount: response.data?.essen_fees_by_pk?.f4,
                        mode: response.data?.essen_fees_by_pk?.f4mode,
                        transactionNo: response.data?.essen_fees_by_pk?.f4txn,
                        date: response.data?.essen_fees_by_pk?.f4dt,
                        updatedBy: response.data?.essen_fees_by_pk?.f4udb,
                        invoiceNo: response.data?.essen_fees_by_pk?.f4inv
                    });
                    setIsEFLoading(false);
                })
                .catch((error) => {});
        }
        if (selectedBtn === 'f5') {
            setIsEFLoading(true);
            setIsEFUpdated(false);
            getF5Fee({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setEFResponseData({
                        amount: response.data?.essen_fees_by_pk?.f5,
                        mode: response.data?.essen_fees_by_pk?.f5mode,
                        transactionNo: response.data?.essen_fees_by_pk?.f5txn,
                        date: response.data?.essen_fees_by_pk?.f5dt,
                        updatedBy: response.data?.essen_fees_by_pk?.f5udb,
                        invoiceNo: response.data?.essen_fees_by_pk?.f5inv
                    });
                    setIsEFLoading(false);
                })
                .catch((error) => {});
        }
        if (selectedBtn === 'f6') {
            setIsEFLoading(true);
            setIsEFUpdated(false);
            getF6Fee({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setEFResponseData({
                        amount: response.data?.essen_fees_by_pk?.f6,
                        mode: response.data?.essen_fees_by_pk?.f6mode,
                        transactionNo: response.data?.essen_fees_by_pk?.f6txn,
                        date: response.data?.essen_fees_by_pk?.f6dt,
                        updatedBy: response.data?.essen_fees_by_pk?.f6udb,
                        invoiceNo: response.data?.essen_fees_by_pk?.f6inv
                    });
                    setIsEFLoading(false);
                })
                .catch((error) => {});
        }
        if (selectedBtn === 'f7') {
            setIsEFLoading(true);
            setIsEFUpdated(false);
            getF7Fee({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setEFResponseData({
                        amount: response.data?.essen_fees_by_pk?.f7,
                        mode: response.data?.essen_fees_by_pk?.f7mode,
                        transactionNo: response.data?.essen_fees_by_pk?.f7txn,
                        date: response.data?.essen_fees_by_pk?.f7dt,
                        updatedBy: response.data?.essen_fees_by_pk?.f7udb,
                        invoiceNo: response.data?.essen_fees_by_pk?.f7inv
                    });
                    setIsEFLoading(false);
                })
                .catch((error) => {});
        }
        if (selectedBtn === 'f8') {
            setIsEFLoading(true);
            setIsEFUpdated(false);
            getF8Fee({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setEFResponseData({
                        amount: response.data?.essen_fees_by_pk?.f8,
                        mode: response.data?.essen_fees_by_pk?.f8mode,
                        transactionNo: response.data?.essen_fees_by_pk?.f8txn,
                        date: response.data?.essen_fees_by_pk?.f8dt,
                        updatedBy: response.data?.essen_fees_by_pk?.f8udb,
                        invoiceNo: response.data?.essen_fees_by_pk?.f8inv
                    });
                    setIsEFLoading(false);
                })
                .catch((error) => {});
        }
        if (selectedBtn === 'f9') {
            setIsEFLoading(true);
            setIsEFUpdated(false);
            getF9Fee({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setEFResponseData({
                        amount: response.data?.essen_fees_by_pk?.f9,
                        mode: response.data?.essen_fees_by_pk?.f9mode,
                        transactionNo: response.data?.essen_fees_by_pk?.f9txn,
                        date: response.data?.essen_fees_by_pk?.f9dt,
                        updatedBy: response.data?.essen_fees_by_pk?.f9udb,
                        invoiceNo: response.data?.essen_fees_by_pk?.f9inv
                    });
                    setIsEFLoading(false);
                })
                .catch((error) => {});
        }
        if (selectedBtn === 'f10') {
            setIsEFLoading(true);
            setIsEFUpdated(false);
            getF10Fee({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setEFResponseData({
                        amount: response.data?.essen_fees_by_pk?.f10,
                        mode: response.data?.essen_fees_by_pk?.f10mode,
                        transactionNo: response.data?.essen_fees_by_pk?.f10txn,
                        date: response.data?.essen_fees_by_pk?.f10dt,
                        updatedBy: response.data?.essen_fees_by_pk?.f10udb,
                        invoiceNo: response.data?.essen_fees_by_pk?.f10inv
                    });
                    setIsEFLoading(false);
                })
                .catch((error) => {});
        }
    };

    const updateEFeeDetails = async (selectedBtn, studentId, className, rollNo, updatedBy, values, handleFeeUpdate) => {
        const invoiceNo = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 9);
        if (selectedBtn === 'adm') {
            updateAdmFee({
                variables: {
                    studentId,
                    admFee: values.amount,
                    admMode: values.mode,
                    admTxn: values.transactionNo,
                    admDt: format(new Date(values.date), 'yyyy-MM-dd'),
                    admUdb: updatedBy,
                    admInv: `ESN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        selectedBtn: 'adm',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_essen_fees_by_pk?.adminv
                    });
                    setIsEFUpdated(true);
                }
            });
        }
        if (selectedBtn === 'f2') {
            updateF2Fee({
                variables: {
                    studentId,
                    f2: values.amount,
                    f2Mode: values.mode,
                    f2Txn: values.transactionNo,
                    f2Dt: format(new Date(values.date), 'yyyy-MM-dd'),
                    f2Udb: updatedBy,
                    f2Inv: `ESN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        selectedBtn: 'f2',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_essen_fees_by_pk?.f2inv
                    });
                    setIsEFUpdated(true);
                }
            });
        }
        if (selectedBtn === 'f3') {
            updateF3Fee({
                variables: {
                    studentId,
                    f3: values.amount,
                    f3Mode: values.mode,
                    f3Txn: values.transactionNo,
                    f3Dt: format(new Date(values.date), 'yyyy-MM-dd'),
                    f3Udb: updatedBy,
                    f3Inv: `ESN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        selectedBtn: 'f3',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_essen_fees_by_pk?.f3inv
                    });
                    setIsEFUpdated(true);
                }
            });
        }
        if (selectedBtn === 'f4') {
            updateF4Fee({
                variables: {
                    studentId,
                    f4: values.amount,
                    f4Mode: values.mode,
                    f4Txn: values.transactionNo,
                    f4Dt: format(new Date(values.date), 'yyyy-MM-dd'),
                    f4Udb: updatedBy,
                    f4Inv: `ESN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        selectedBtn: 'f4',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_essen_fees_by_pk?.f4inv
                    });
                    setIsEFUpdated(true);
                }
            });
        }
        if (selectedBtn === 'f5') {
            updateF5Fee({
                variables: {
                    studentId,
                    f5: values.amount,
                    f5Mode: values.mode,
                    f5Txn: values.transactionNo,
                    f5Dt: format(new Date(values.date), 'yyyy-MM-dd'),
                    f5Udb: updatedBy,
                    f5Inv: `ESN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        selectedBtn: 'f5',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_essen_fees_by_pk?.f5inv
                    });
                    setIsEFUpdated(true);
                }
            });
        }
        if (selectedBtn === 'f6') {
            updateF6Fee({
                variables: {
                    studentId,
                    f6: values.amount,
                    f6Mode: values.mode,
                    f6Txn: values.transactionNo,
                    f6Dt: format(new Date(values.date), 'yyyy-MM-dd'),
                    f6Udb: updatedBy,
                    f6Inv: `ESN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        selectedBtn: 'f6',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_essen_fees_by_pk?.f6inv
                    });
                    setIsEFUpdated(true);
                }
            });
        }
        if (selectedBtn === 'f7') {
            updateF7Fee({
                variables: {
                    studentId,
                    f7: values.amount,
                    f7Mode: values.mode,
                    f7Txn: values.transactionNo,
                    f7Dt: format(new Date(values.date), 'yyyy-MM-dd'),
                    f7Udb: updatedBy,
                    f7Inv: `ESN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        selectedBtn: 'f7',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_essen_fees_by_pk?.f7inv
                    });
                    setIsEFUpdated(true);
                }
            });
        }
        if (selectedBtn === 'f8') {
            updateF8Fee({
                variables: {
                    studentId,
                    f8: values.amount,
                    f8Mode: values.mode,
                    f8Txn: values.transactionNo,
                    f8Dt: format(new Date(values.date), 'yyyy-MM-dd'),
                    f8Udb: updatedBy,
                    f8Inv: `ESN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        selectedBtn: 'f8',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_essen_fees_by_pk?.f8inv
                    });
                    setIsEFUpdated(true);
                }
            });
        }
        if (selectedBtn === 'f9') {
            updateF9Fee({
                variables: {
                    studentId,
                    f9: values.amount,
                    f9Mode: values.mode,
                    f9Txn: values.transactionNo,
                    f9Dt: format(new Date(values.date), 'yyyy-MM-dd'),
                    f9Udb: updatedBy,
                    f9Inv: `ESN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        selectedBtn: 'f9',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_essen_fees_by_pk?.f9inv
                    });
                    setIsEFUpdated(true);
                }
            });
        }
        if (selectedBtn === 'f10') {
            updateF10Fee({
                variables: {
                    studentId,
                    f10: values.amount,
                    f10Mode: values.mode,
                    f10Txn: values.transactionNo,
                    f10Dt: format(new Date(values.date), 'yyyy-MM-dd'),
                    f10Udb: updatedBy,
                    f10Inv: `ESN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        selectedBtn: 'f10',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_essen_fees_by_pk?.f10inv
                    });
                    setIsEFUpdated(true);
                }
            });
        }
    };

    const handleEDueFees = async (selectedBtn, studentId, handleFeeUpdate) => {
        if (selectedBtn === 'adm') {
            updateAdmFee({
                variables: {
                    studentId,
                    admFee: null,
                    admMode: null,
                    admTxn: null,
                    admDt: null,
                    admUdb: null,
                    admInv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        selectedBtn: 'adm',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (selectedBtn === 'f2') {
            updateF2Fee({
                variables: {
                    studentId,
                    f2: null,
                    f2Mode: null,
                    f2Txn: null,
                    f2Dt: null,
                    f2Udb: null,
                    f2Inv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        selectedBtn: 'f2',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (selectedBtn === 'f3') {
            updateF3Fee({
                variables: {
                    studentId,
                    f3: null,
                    f3Mode: null,
                    f3Txn: null,
                    f3Dt: null,
                    f3Udb: null,
                    f3Inv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        selectedBtn: 'f3',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (selectedBtn === 'f4') {
            updateF4Fee({
                variables: {
                    studentId,
                    f4: null,
                    f4Mode: null,
                    f4Txn: null,
                    f4Dt: null,
                    f4Udb: null,
                    f4Inv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        selectedBtn: 'f4',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (selectedBtn === 'f5') {
            updateF5Fee({
                variables: {
                    studentId,
                    f5: null,
                    f5Mode: null,
                    f5Txn: null,
                    f5Dt: null,
                    f5Udb: null,
                    f5Inv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        selectedBtn: 'f5',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (selectedBtn === 'f6') {
            updateF6Fee({
                variables: {
                    studentId,
                    f6: null,
                    f6Mode: null,
                    f6Txn: null,
                    f6Dt: null,
                    f6Udb: null,
                    f6Inv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        selectedBtn: 'f6',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (selectedBtn === 'f7') {
            updateF7Fee({
                variables: {
                    studentId,
                    f7: null,
                    f7Mode: null,
                    f7Txn: null,
                    f7Dt: null,
                    f7Udb: null,
                    f7Inv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        selectedBtn: 'f7',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (selectedBtn === 'f8') {
            updateF8Fee({
                variables: {
                    studentId,
                    f8: null,
                    f8Mode: null,
                    f8Txn: null,
                    f8Dt: null,
                    f8Udb: null,
                    f8Inv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        selectedBtn: 'f8',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (selectedBtn === 'f9') {
            updateF9Fee({
                variables: {
                    studentId,
                    f9: null,
                    f9Mode: null,
                    f9Txn: null,
                    f9Dt: null,
                    f9Udb: null,
                    f9Inv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        selectedBtn: 'f9',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (selectedBtn === 'f10') {
            updateF10Fee({
                variables: {
                    studentId,
                    f10: null,
                    f10Mode: null,
                    f10Txn: null,
                    f10Dt: null,
                    f10Udb: null,
                    f10Inv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        selectedBtn: 'f10',
                        studentId: data?.update_essen_fees_by_pk?.student_id
                    });
                    setEFResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
    };

    return [getEFeeDeatails, updateEFeeDetails, handleEDueFees, responseEFData, isEFLoading, isEFUpdated];
}

export default useEssentialFee;
