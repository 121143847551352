import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const TeacherHome = Loadable(lazy(() => import('views/teacher/Home')));
const TeacherStudents = Loadable(lazy(() => import('views/teacher/Students')));
const TeacherExams = Loadable(lazy(() => import('views/teacher/Exams')));
const TeacherResults = Loadable(lazy(() => import('views/teacher/Results')));
const ResultsByExam = Loadable(lazy(() => import('views/common/Results/ResultsByExam')));
const TeacherNotice = Loadable(lazy(() => import('views/teacher/Notice')));
const TeacherAttendance = Loadable(lazy(() => import('views/teacher/Attendance')));
const ViewAttendance = Loadable(lazy(() => import('views/common/ViewAttendance')));
const TakeAttendance = Loadable(lazy(() => import('views/common/TakeAttendance')));

const Fees = Loadable(lazy(() => import('views/teacher/Fees')));
const Subjects = Loadable(lazy(() => import('views/teacher/Subjects')));
const Teachings = Loadable(lazy(() => import('views/teacher/Teachings')));
const HomeWork = Loadable(lazy(() => import('views/common/HomeWorkTch')));

const Profile = Loadable(lazy(() => import('views/common/Profile')));
const Account = Loadable(lazy(() => import('views/common/Account')));

const TeacherRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <TeacherHome />
        },
        {
            path: '/students',
            element: <TeacherStudents />
        },
        {
            path: '/exams',
            element: <TeacherExams />
        },
        {
            path: '/results',
            element: <TeacherResults />
        },
        {
            path: '/results/exams/results-by-exams',
            element: <ResultsByExam />
        },
        {
            path: '/notice',
            element: <TeacherNotice />
        },
        {
            path: '/attendance',
            element: <TeacherAttendance />
        },
        {
            path: '/attendance/view-take/view-attendance',
            element: <ViewAttendance />
        },
        {
            path: '/attendance/view-take/take-attendance',
            element: <TakeAttendance />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/fees',
            element: <Fees />
        },
        {
            path: '/subjects',
            element: <Subjects />
        },
        {
            path: '/teachings',
            element: <Teachings />
        },
        {
            path: '/teachings/homework',
            element: <HomeWork />
        }
    ]
};

export default TeacherRoutes;
