// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { HomeOutlined, PeopleAltOutlined, Business, Approval, DirectionsBus } from '@mui/icons-material';

// constant
const icons = {
    HomeOutlined,
    PeopleAltOutlined,
    Business,
    Approval,
    DirectionsBus
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const parent = {
    id: 'parent',
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/home',
            icon: icons.HomeOutlined,
            breadcrumbs: false
        },
        {
            id: 'children',
            title: <FormattedMessage id="children" />,
            type: 'item',
            url: '/children',
            icon: icons.PeopleAltOutlined,
            breadcrumbs: false
        },
        {
            id: 'approval',
            title: <FormattedMessage id="approval" />,
            type: 'item',
            url: '/approval',
            icon: icons.Approval,
            breadcrumbs: false
        },
        {
            id: 'transport',
            title: <FormattedMessage id="transport" />,
            type: 'item',
            url: '/transport',
            icon: icons.DirectionsBus,
            breadcrumbs: false
        }
        // ,
        // {
        //     id: 'findSchools',
        //     title: <FormattedMessage id="findSchools" />,
        //     type: 'item',
        //     url: '/findschool',
        //     icon: icons.Business,
        //     breadcrumbs: false
        // }
    ]
};

export default parent;
