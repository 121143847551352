import { AddCircleOutlineTwoTone, FileDownload } from '@mui/icons-material';
import { Button as ButtonMUI } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';

function Button({ handleOpen, width, buttonName, animateSX }) {
    return (
        <AnimateButton sx={animateSX}>
            <ButtonMUI
                variant="contained"
                sx={{ width: { width } }}
                startIcon={buttonName === 'Export' ? <FileDownload /> : <AddCircleOutlineTwoTone />}
                onClick={() => handleOpen()}
                type="submit"
                size="medium"
            >
                {buttonName}
            </ButtonMUI>
        </AnimateButton>
    );
}

export default Button;
