import { Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import CustomCard from '../CustomCard';
import CustomTable from '../CustomTable';

function Records({
    headCells = false,
    records = [],
    title = false,
    search = false,
    button = false,
    session = false,
    select = false,
    header = false,
    pagination = false,
    accordianBtn = false,
    selectedOrder = 'asc',
    selectedOrderBy = '',
    isLoading = false,
    csvExport = false,
    isCardOnly = false
}) {
    return (
        <>
            {!isCardOnly ? (
                <Grid container spacing={gridSpacing} className="deskContent" sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Grid item xs={12} lg={12} md={12}>
                        <Grid container spacing={gridSpacing} className="deskContent">
                            <CustomTable
                                accordianBtn={accordianBtn}
                                header={header}
                                pagination={pagination}
                                headCells={headCells}
                                title={title}
                                search={search}
                                button={button}
                                session={session}
                                select={select}
                                records={records}
                                selectedOrder={selectedOrder}
                                selectedOrderBy={selectedOrderBy}
                                isLoading={isLoading}
                                csvExport={csvExport}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}

            {!isCardOnly ? (
                <Grid container spacing={gridSpacing} className="phoneContent" sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <Grid item xs={12} lg={12} md={12}>
                        <CustomCard
                            headCells={headCells}
                            records={records}
                            title={title}
                            search={search}
                            button={button}
                            session={session}
                            select={select}
                            isLoading={isLoading}
                            csvExport={csvExport}
                        />
                    </Grid>
                </Grid>
            ) : null}

            {isCardOnly ? (
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} lg={12} md={12}>
                        <CustomCard
                            headCells={headCells}
                            records={records}
                            title={title}
                            search={search}
                            button={button}
                            session={session}
                            select={select}
                            isLoading={isLoading}
                            csvExport={csvExport}
                        />
                    </Grid>
                </Grid>
            ) : null}
        </>
    );
}

export default Records;
