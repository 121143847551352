import { InputAdornment, TextField, useTheme } from '@mui/material';
import { IconSearch } from '@tabler/icons';

function SearchField({ name, filter }) {
    const theme = useTheme();
    return (
        <TextField
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'right',
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: `${theme.palette.divider} !important`
                }
            }}
            placeholder="Search"
            value={name}
            onChange={filter}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <IconSearch stroke={1.5} size="18px" />
                    </InputAdornment>
                )
            }}
            size="small"
        />
    );
}

export default SearchField;
