import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function BasicDate(props) {
    const { id, name, label, handleFormik, error, helperText, disabled = false } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                inputFormat="dd/MM/yyyy"
                id={id}
                name={name}
                value={handleFormik && handleFormik.values[name]}
                label={label}
                onChange={(e) => {
                    handleFormik.setFieldValue(name, e);
                }}
                renderInput={(params) => (
                    <TextField size="small" {...params} placeholder="DD/MM/YYYY" error={error} helperText={helperText} />
                )}
                disabled={disabled}
            />
        </LocalizationProvider>
    );
}
