import { FormControl, Select as MuiSelect, InputLabel, MenuItem, useTheme } from '@mui/material';

// const handleOptionSelect = () => {};

function Select({ options = [], selectedOption = '', handleOptionSelect, label = 'Select Teacher' }) {
    const theme = useTheme();
    return (
        <FormControl fullWidth sx={{ mr: 1 }}>
            <InputLabel id="select-teacher-label">{label}</InputLabel>
            <MuiSelect
                labelId="select-teacher-label"
                id="select-teacher"
                value={selectedOption}
                label={label}
                onChange={(e) => handleOptionSelect(e)}
                size="small"
                sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.divider
                    }
                }}
            >
                {options?.map((item, i) => (
                    <MenuItem key={i} value={item.teacherMail}>
                        {item.teacherName}
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
    );
}

export default Select;
