/* eslint-disable array-callback-return */
import { isEmpty } from 'lodash';
import { useDispatch } from 'store';
import { setCSVExportData, setCSVExportHeader } from 'store/slices/commonWP';
import { getColor, getLabel } from 'utils/common';
import FeesStatusForm from '../FeeStatusModal';
import Records from '../Records';

function EssentialFeeByClass({ title, open, setOpen, essentialFeeList = [], selectedRecord, selectedBtnName, headersList, label, fees }) {
    const dispatch = useDispatch();

    const handleExportCSV = async (monthName, ref) => {
        const formatedHeadCells = [];
        headersList.map((item) => {
            if (item.csvId) {
                formatedHeadCells.push({
                    label: item.label,
                    key: item.csvId
                });
            }
        });

        await dispatch(setCSVExportHeader(formatedHeadCells));
        await dispatch(setCSVExportData(essentialFeeList));
        ref.current.link.click();
    };

    const handleUpsert = (values) => {
        if (values.type === 1 && !isEmpty(essentialFeeList)) {
            essentialFeeList.find((item) => item.id === values.studentId)[values.selectedBtn].label = getLabel(values.amount);
            essentialFeeList.find((item) => item.id === values.studentId)[values.selectedBtn].chipColor = getColor(values.amount, fees);
        }
    };

    return (
        <>
            {open ? (
                <FeesStatusForm
                    open={open}
                    close={() => setOpen()}
                    selectedBtn={selectedBtnName}
                    selectedRecord={selectedRecord}
                    feeType="essen"
                    handleFeeUpdate={handleUpsert}
                    label={label}
                />
            ) : null}

            <Records
                header
                title={{
                    isTitle: true,
                    position: 1,
                    titleName: title ? `${title}, Essential Fees` : 'Essential Fees',
                    xs: 8.5,
                    gridSize: { sm: 9 },
                    sx: { pl: 1.5, pt: 1.5 }
                }}
                csvExport={{
                    isCsvExport: true,
                    gridSize: { sm: 3 },
                    action: (monthName, ref) => handleExportCSV(monthName, ref),
                    xs: 3.5,
                    sx: { pt: 1.5, pr: 1.5, display: 'flex', justifyContent: 'flex-end' },
                    animateSX: { width: '100%' }
                }}
                headCells={headersList}
                records={essentialFeeList}
            />
        </>
    );
}

export default EssentialFeeByClass;
