import { FormControl, InputLabel, MenuItem, Select, useTheme } from '@mui/material';
import { getCurrentSession } from 'utils/common';
import { sessionDropdownList } from 'utils/staticData';

function SessionSelect({ handleSessionChange }) {
    const theme = useTheme();
    return (
        // <Box sx={{ display: 'flex', alignItems: 'center' }}>
        //     <Typography sx={{ fontWeight: '500' }} marginRight={1}>
        //         Session
        //     </Typography>
        //     <Box>
        //         <FormControl sx={{ minWidth: 120 }}>
        //             <Select
        //                 defaultValue={getCurrentSession()}
        //                 sx={{
        //                     '.MuiSelect-select.MuiSelect-outlined': {
        //                         paddingY: '9px !important'
        //                     },
        //                     '.MuiOutlinedInput-notchedOutline': {
        //                         borderColor: theme.palette.divider
        //                     },
        //                     height: 42
        //                 }}
        //                 onChange={(e) => handleSessionChange(e)}
        //                 size="small"
        //             >
        //                 {sessionDropdownList.map((item, i) => (
        //                     <MenuItem key={i} value={item.label}>
        //                         {item.label}
        //                     </MenuItem>
        //                 ))}
        //             </Select>
        //         </FormControl>
        //     </Box>
        // </Box>
        <FormControl sx={{ display: 'flex', alignItems: 'center' }}>
            <InputLabel id="select-session-label">Session</InputLabel>
            <Select
                labelId="select-session-label"
                label="Session"
                defaultValue={getCurrentSession()}
                onChange={(e) => handleSessionChange(e)}
                size="small"
                sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.divider
                    }
                }}
            >
                {sessionDropdownList.map((item, i) => (
                    <MenuItem key={i} value={item.label}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default SessionSelect;
