import { toNumber } from 'lodash';
import Invoice001 from './Invoice001';

function Invoice({ schoolInfo, studentInfo, fees, isClassFee, templateId }) {
    return (
        <>
            {toNumber(templateId) === 1 ? (
                <Invoice001 schoolInfo={schoolInfo} studentInfo={studentInfo} fees={fees} isClassFee={isClassFee} />
            ) : null}
        </>
    );
}

export default Invoice;
