import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project imports
import { DASHBOARD_PATH } from 'config';
import useAuth from 'hooks/useAuth';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn, defaultRole, user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (isLoggedIn && defaultRole !== 'free') {
            if (user?.isEmailVerfied === false) {
                navigate('/verifyemail', { replace: true });
            } else {
                navigate('/home', { replace: true });
            }
        } else if (isLoggedIn && (location.pathname === '/login' || location.pathname === '/register')) {
            navigate('/', { replace: true });
        }
        // if (isLoggedIn) {
        //     navigate(DASHBOARD_PATH, { replace: true });
        // }
    }, [isLoggedIn, navigate]);

    return <>{children}</>;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
