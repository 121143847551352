import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const Title = ({ title }) => (
    <Typography variant="h4" fontSize="1.5em">
        {title}
    </Typography>
);

Title.prototype = {
    title: PropTypes.string.isRequired
};

export default Title;
