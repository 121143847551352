import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import NavMotion from 'layout/NavMotion';
import AppBar from 'ui-component/extended/AppBar';
import Advantages from './Advantages';
import CustomizedTimeline from './CustomizedTimeline';
import Footer from './Footer';
import Header from './Header';
import Reviews from './Reviews';
import Subscribe from './Subscribe';
import CustomWeb from './CustomWeb';
import { Container } from '@mui/material';

const HeaderWrapper = styled('div')(({ theme }) => ({
    paddingTop: 30,
    overflowX: 'hidden',
    overflowY: 'clip',
    [theme.breakpoints.down('md')]: {
        paddingTop: 42
    },
    backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 1) 7%, rgb(187 192 215 / 63%) 100%);`,

    backgroundRepeat: 'repeat-x',
    position: 'relative'
}));

const Landing = () => {
    const theme = useTheme();
    return (
        <>
            <AppBar />

            <NavMotion>
                <HeaderWrapper id="home">
                    <Header />
                </HeaderWrapper>

                <CustomizedTimeline />

                <Box
                    sx={{
                        backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.alternate.main,
                        py: 10
                    }}
                >
                    <Container>
                        <CustomWeb />
                    </Container>
                </Box>

                <Box sx={{ backgroundColor: '#D4D7E6' }}>
                    <Advantages />
                </Box>
                <Reviews />
                <Box id="contact-us">
                    <Subscribe />
                </Box>
                <Footer />
            </NavMotion>
        </>
    );
};

export default Landing;
