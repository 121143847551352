import { Grid, Typography } from '@mui/material';

function EmptyRecords() {
    return (
        <Grid item xs={12} md={12} className="pbx-annoucement-heading">
            <Typography variant="h5">No results found!</Typography>
        </Grid>
    );
}

export default EmptyRecords;
