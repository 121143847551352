import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Administrator = Loadable(lazy(() => import('views/administrator')));
const Account = Loadable(lazy(() => import('views/common/Account')));

const AdministratorRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <Administrator />
        },
        {
            path: '/account',
            element: <Account />
        }
    ]
};

export default AdministratorRoutes;
