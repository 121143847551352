/* eslint-disable array-callback-return */
import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { getEssentialFeesByClass } from 'services/managment/query';
import { useSelector } from 'store';
import { fullName, getColor, getLabel } from 'utils/common';
import EssentialFeesDD from './EssentialFees';
import NoDataTable from 'views/common/NoData/NoDataTable';
import { Card, CircularProgress } from '@mui/material';

function EssentialFees() {
    const [headersList, setHeadersList] = useState([]);
    const [essentialFees, setEssentialFees] = useState([]);

    const { classRoomInfo } = useSelector((state) => state.accountant);

    const { loading } = useQuery(getEssentialFeesByClass, {
        variables: {
            id: classRoomInfo.classId
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data?.school_class_details_by_pk?.school_student_classes) {
                const headers = [];
                if (data.school_class_details_by_pk?.class_feetype?.f2) {
                    headers.push({
                        id: 'f2',
                        label: data.school_class_details_by_pk.class_feetype.f2,
                        fees: data.school_class_details_by_pk.class_feetype.f2f
                    });
                }
                if (data.school_class_details_by_pk?.class_feetype?.f3) {
                    headers.push({
                        id: 'f3',
                        label: data.school_class_details_by_pk.class_feetype.f3,
                        fees: data.school_class_details_by_pk.class_feetype.f3f
                    });
                }
                if (data.school_class_details_by_pk?.class_feetype?.f4) {
                    headers.push({
                        id: 'f4',
                        label: data.school_class_details_by_pk.class_feetype.f4,
                        fees: data.school_class_details_by_pk.class_feetype.f4f
                    });
                }
                if (data.school_class_details_by_pk?.class_feetype?.f5) {
                    headers.push({
                        id: 'f5',
                        label: data.school_class_details_by_pk.class_feetype.f5,
                        fees: data.school_class_details_by_pk.class_feetype.f5f
                    });
                }
                if (data.school_class_details_by_pk?.class_feetype?.f6) {
                    headers.push({
                        id: 'f6',
                        label: data.school_class_details_by_pk.class_feetype.f6,
                        fees: data.school_class_details_by_pk.class_feetype.f6f
                    });
                }
                if (data.school_class_details_by_pk?.class_feetype?.f7) {
                    headers.push({
                        id: 'f7',
                        label: data.school_class_details_by_pk.class_feetype.f7,
                        fees: data.school_class_details_by_pk.class_feetype.f3f
                    });
                }
                if (data.school_class_details_by_pk?.class_feetype?.f8) {
                    headers.push({
                        id: 'f8',
                        label: data.school_class_details_by_pk.class_feetype.f8,
                        fees: data.school_class_details_by_pk.class_feetype.f8f
                    });
                }
                if (data.school_class_details_by_pk?.class_feetype?.f9) {
                    headers.push({
                        id: 'f9',
                        label: data.school_class_details_by_pk.class_feetype.f9,
                        fees: data.school_class_details_by_pk.class_feetype.f9f
                    });
                }
                if (data.school_class_details_by_pk?.class_feetype?.f10) {
                    headers.push({
                        id: 'f10',
                        label: data.school_class_details_by_pk.class_feetype.f10,
                        fees: data.school_class_details_by_pk.class_feetype.f10f
                    });
                }
                setHeadersList([...headers]);

                const feeTypesList = {};
                const essentialFeeListLc = [];
                data.school_class_details_by_pk.school_student_classes.map((item, index) => {
                    headers.map((headItem) => {
                        if (headItem.id === 'f2') {
                            feeTypesList.f2 = {
                                label: getLabel(item?.essen_fee?.f2),
                                chipColor: getColor(item?.essen_fee?.f2, headItem.fees)
                            };
                        }
                        if (headItem.id === 'f3') {
                            feeTypesList.f3 = {
                                label: getLabel(item?.essen_fee?.f3),
                                chipColor: getColor(item?.essen_fee?.f3, headItem.fees)
                            };
                        }
                        if (headItem.id === 'f4') {
                            feeTypesList.f4 = {
                                label: getLabel(item?.essen_fee?.f4),
                                chipColor: getColor(item?.essen_fee?.f4, headItem.fees)
                            };
                        }
                        if (headItem.id === 'f5') {
                            feeTypesList.f5 = {
                                label: getLabel(item?.essen_fee?.f5),
                                chipColor: getColor(item?.essen_fee?.f5, headItem.fees)
                            };
                        }
                        if (headItem.id === 'f6') {
                            feeTypesList.f6 = {
                                label: getLabel(item?.essen_fee?.f6),
                                chipColor: getColor(item?.essen_fee?.f6, headItem.fees)
                            };
                        }
                        if (headItem.id === 'f7') {
                            feeTypesList.f7 = {
                                label: getLabel(item?.essen_fee?.f7),
                                chipColor: getColor(item?.essen_fee?.f7, headItem.fees)
                            };
                        }
                        if (headItem.id === 'f8') {
                            feeTypesList.f8 = {
                                label: getLabel(item?.essen_fee?.f8),
                                chipColor: getColor(item?.essen_fee?.f8, headItem.fees)
                            };
                        }
                        if (headItem.id === 'f9') {
                            feeTypesList.f9 = {
                                label: getLabel(item?.essen_fee?.f9),
                                chipColor: getColor(item?.essen_fee?.f9, headItem.fees)
                            };
                        }
                        if (headItem.id === 'f10') {
                            feeTypesList.f10 = {
                                label: getLabel(item?.essen_fee?.f10),
                                chipColor: getColor(item?.essen_fee?.f10, headItem.fees)
                            };
                        }
                    });
                    essentialFeeListLc.push({
                        key: index + 1,
                        id: item.student_id,
                        rollNo: item.roll_no,
                        studentName: fullName(
                            item.school_student?.first_name,
                            item.school_student?.middle_name,
                            item.school_student?.last_name
                        ),
                        nowOfRow: 1,
                        adm: {
                            label: getLabel(item?.essen_fee?.admfee),
                            chipColor: getColor(item?.essen_fee?.admfee, 1)
                        },
                        ...feeTypesList
                    });
                });
                setEssentialFees(essentialFeeListLc);
            }
        }
    });

    return (
        <>
            {loading ? (
                <Card sx={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 24px)', alignItems: 'center' }}>
                    <CircularProgress />
                </Card>
            ) : null}
            {!isEmpty(headersList) || loading ? (
                <EssentialFeesDD title={classRoomInfo?.className} essentialFeeList={essentialFees} headersList={headersList} />
            ) : (
                <Card sx={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 24px)', alignItems: 'center' }}>
                    <NoDataTable />
                </Card>
            )}
        </>
    );
}

export default EssentialFees;
