import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { useState } from 'react';
import { getSchoolFeesByClass } from 'services/managment/query';
import { useSelector } from 'store';
import { containsEnglish, formatFeeDetails, fullName, getColor, getLabel } from 'utils/common';
import FeesStatusBySession from '../../../common/FeesStatusBySession';
import { SCHOOL_MEDIUM } from 'services/common/query';
import useAuth from 'hooks/useAuth';

function ClassFee() {
    const { user } = useAuth();
    const { classRoomInfo } = useSelector((state) => state.accountant);
    const { accountantDashInfo } = useSelector((state) => state.accountant);
    const [feesStatusList, setFeesStatusList] = useState([]);
    const [medium, setMedium] = useState('');

    useQuery(getSchoolFeesByClass, {
        variables: {
            id: classRoomInfo.classId
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data.school_class_details_by_pk?.school_student_classes) {
                const formateFeesList = [];
                const formatedFeeDetails = formatFeeDetails(data.school_class_details_by_pk?.school_student_classes);
                // console.log(formatedFeeDetails);
                data.school_class_details_by_pk?.school_student_classes?.map((item, index) =>
                    formateFeesList.push({
                        key: index + 1,
                        id: item.student_id,
                        rollNo: item.roll_no,
                        studentName: fullName(
                            item.school_student?.first_name,
                            item.school_student?.middle_name,
                            item.school_student?.last_name
                        ),
                        studentNameCSVId: fullName(
                            item.school_student?.first_name,
                            item.school_student?.middle_name,
                            item.school_student?.last_name
                        ),
                        nowOfRow: item.transport_id ? 2 : 1,
                        classFees: classRoomInfo.classFees,
                        totalFees: classRoomInfo.classFees,
                        transportFees: item.transport_fee?.stopage?.fees,
                        jan: {
                            label: getLabel(item.class_fee?.jan),
                            chipColor: getColor(item.class_fee?.jan, classRoomInfo.classFees),
                            label1: getLabel(item.transport_fee?.jan),
                            chipColor1: getColor(item.transport_fee?.jan, item.transport_fee?.stopage?.fees)
                        },
                        janCSVId: getLabel(item.class_fee?.jan),
                        feb: {
                            label: getLabel(item.class_fee?.feb),
                            chipColor: getColor(item.class_fee?.feb, classRoomInfo.classFees),
                            label1: getLabel(item.transport_fee?.feb),
                            chipColor1: getColor(item.transport_fee?.feb, item.transport_fee?.stopage?.fees)
                        },
                        febCSVId: getLabel(item.class_fee?.feb),
                        mar: {
                            label: getLabel(item.class_fee?.mar),
                            chipColor: getColor(item.class_fee?.mar, classRoomInfo.classFees),
                            label1: getLabel(item.transport_fee?.mar),
                            chipColor1: getColor(item.transport_fee?.mar, item.transport_fee?.stopage?.fees)
                        },
                        marCSVId: getLabel(item.class_fee?.mar),
                        apr: {
                            label: getLabel(item.class_fee?.apr),
                            chipColor: getColor(item.class_fee?.apr, classRoomInfo.classFees),
                            label1: getLabel(item.transport_fee?.apr),
                            chipColor1: getColor(item.transport_fee?.apr, item.transport_fee?.stopage?.fees)
                        },
                        aprCSVId: getLabel(item.class_fee?.apr),
                        may: {
                            label: getLabel(item.class_fee?.may),
                            chipColor: getColor(item.class_fee?.may, classRoomInfo.classFees),
                            label1: getLabel(item.transport_fee?.may),
                            chipColor1: getColor(item.transport_fee?.may, item.transport_fee?.stopage?.fees)
                        },
                        mayCSVId: getLabel(item.class_fee?.may),
                        jun: {
                            label: getLabel(item.class_fee?.jun),
                            chipColor: getColor(item.class_fee?.jun, classRoomInfo.classFees),
                            label1: getLabel(item.transport_fee?.jun),
                            chipColor1: getColor(item.transport_fee?.jun, item.transport_fee?.stopage?.fees)
                        },
                        junCSVId: getLabel(item.class_fee?.jun),
                        jul: {
                            label: getLabel(item.class_fee?.jul),
                            chipColor: getColor(item.class_fee?.jul, classRoomInfo.classFees),
                            label1: getLabel(item.transport_fee?.jul),
                            chipColor1: getColor(item.transport_fee?.jul, item.transport_fee?.stopage?.fees)
                        },
                        julCSVId: getLabel(item.class_fee?.jul),
                        aug: {
                            label: getLabel(item.class_fee?.aug),
                            chipColor: getColor(item.class_fee?.aug, classRoomInfo.classFees),
                            label1: getLabel(item.transport_fee?.aug),
                            chipColor1: getColor(item.transport_fee?.aug, item.transport_fee?.stopage?.fees)
                        },
                        augCSVId: getLabel(item.class_fee?.aug),
                        sep: {
                            label: getLabel(item.class_fee?.sep),
                            chipColor: getColor(item.class_fee?.sep, classRoomInfo.classFees),
                            label1: getLabel(item.transport_fee?.sep),
                            chipColor1: getColor(item.transport_fee?.sep, item.transport_fee?.stopage?.fees)
                        },
                        sepCSVId: getLabel(item.class_fee?.sep),
                        oct: {
                            label: getLabel(item.class_fee?.oct),
                            chipColor: getColor(item.class_fee?.oct, classRoomInfo.classFees),
                            label1: getLabel(item.transport_fee?.oct),
                            chipColor1: getColor(item.transport_fee?.oct, item.transport_fee?.stopage?.fees)
                        },
                        octCSVId: getLabel(item.class_fee?.oct),
                        nov: {
                            label: getLabel(item.class_fee?.nov),
                            chipColor: getColor(item.class_fee?.nov, classRoomInfo.classFees),
                            label1: getLabel(item.transport_fee?.nov),
                            chipColor1: getColor(item.transport_fee?.nov, item.transport_fee?.stopage?.fees)
                        },
                        novCSVId: getLabel(item.class_fee?.nov),
                        dec: {
                            label: getLabel(item.class_fee?.dec),
                            chipColor: getColor(item.class_fee?.dec, classRoomInfo.classFees),
                            label1: getLabel(item.transport_fee?.dec),
                            chipColor1: getColor(item.transport_fee?.dec, item.transport_fee?.stopage?.fees)
                        },
                        invoice: {
                            label: 'Class',
                            chipColor: 'primary',
                            label1: 'Transport',
                            chipColor1: 'primary'
                        },
                        decCSVId: getLabel(item.class_fee?.dec),
                        lastPayment: format(new Date(item.class_fee?.updated_at), 'dd/MM/yyyy'),
                        tLastPayment: item.transport_id ? format(new Date(item.transport_fee?.updated_at), 'dd/MM/yyyy') : '',
                        lastPaymentCSVId: item.class_fee?.updated_at ? format(new Date(item.class_fee?.updated_at), 'dd/MM/yyyy') : '',
                        transportId: item.transport_id
                    })
                );

                setFeesStatusList(formateFeesList);
            }
        },
        onError: (error) => {
            // console.log(error);
        }
    });

    useQuery(SCHOOL_MEDIUM, {
        variables: { schoolId: accountantDashInfo.school_id },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data.school_info_by_pk?.school_medium) {
                setMedium(containsEnglish(data.school_info_by_pk?.school_medium) ? 'med1' : 'med2');
            }
        },
        onError: (error) => {
            // console.log(error);
        }
    });

    return <FeesStatusBySession title={`${classRoomInfo.className}, Fees`} feeDetailsList={feesStatusList} isInvoice medium={medium} />;
}

export default ClassFee;
