import { lazy } from 'react';

import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// ==============================|| MAIN ROUTING ||============================== //
const ConductorHome = Loadable(lazy(() => import('views/conductor')));
const StudentsFeeDetails = Loadable(lazy(() => import('views/conductor/FeeDetails')));
const Account = Loadable(lazy(() => import('views/common/Account')));
const ManageExpense = Loadable(lazy(() => import('views/conductor/ManageExpense')));
const ManageKM = Loadable(lazy(() => import('views/conductor/ManageKm')));

const ConductorRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <ConductorHome />
        },
        {
            path: '/home/students',
            element: <StudentsFeeDetails />
        },
        {
            path: '/home/expense',
            element: <ManageExpense />
        },
        {
            path: '/home/km',
            element: <ManageKM />
        },
        {
            path: '/account',
            element: <Account />
        }
    ]
};

export default ConductorRoutes;
