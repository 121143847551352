import { forwardRef, useEffect, useRef, useState } from 'react';
import {
    Approval,
    CalendarMonthOutlined,
    CurrencyRupeeOutlined,
    DirectionsBus,
    InfoOutlined,
    ListAltOutlined,
    MenuBookOutlined,
    NotificationsNoneOutlined,
    PeopleAltOutlined,
    AssignmentOutlined
} from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import AppsIcon from '@mui/icons-material/Apps';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import MuiTooltip from '@mui/material/Tooltip';
import useAuth from 'hooks/useAuth';
import SelectedListItem from './SelectedListItem';

import './navigation.css';

export default function Home() {
    const { defaultRole } = useAuth();
    const ref = useRef(null);
    const location = useLocation();
    const [selectedMenu, setSelectedMenu] = useState();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (location.pathname === '/home') {
            if (defaultRole !== 'managment') {
                setSelectedMenu(0);
            } else {
                setSelectedMenu(null);
            }
        }
    }, [location.pathname]);

    const handleTooltipOpen = () => {
        setOpen(!open);
    };

    const handleMenuSelect = (menuName) => {
        setSelectedMenu(menuName);
        if (menuName !== 2) setOpen(false);
    };

    const handleMenuIcon = (iconName) => (
        <>
            {iconName === 'Home' && <HomeOutlinedIcon />}
            {(iconName === 'Info' || iconName === 'About PBX') && <InfoOutlined />}
            {iconName === 'Notice' && <NotificationsNoneOutlined />}
            {iconName === 'Fees' && <CurrencyRupeeOutlined />}
            {iconName === 'Classes' && <MenuBookOutlined />}
            {iconName === 'Students' && <ListAltOutlined />}
            {iconName === 'Children' && <PeopleAltOutlined />}
            {iconName === 'Attendance' && <CalendarMonthOutlined />}
            {iconName === 'Approval' && <Approval />}
            {iconName === 'Transport' && <DirectionsBus />}
            {iconName === 'Biz Center' && <HomeOutlinedIcon />}
            {iconName === 'Role Assign' && <AssignmentOutlined />}
        </>
    );

    const leftItem = [];
    const rightItem = [];

    if (defaultRole === 'managment') {
        leftItem.push({ leftName: 'Info', leftLink: '/info' });
        leftItem.push({ leftName: 'Classes', leftLink: '/classes' });
        rightItem.push({ rightName: 'Notice', rightLink: '/notice' });
        rightItem.push({ rightName: 'Fees', rightLink: '/fees' });
    }
    if (defaultRole === 'student') {
        leftItem.push({ leftName: 'Home', leftLink: '/home' });
        leftItem.push({ leftName: 'Classes', leftLink: '/classes' });
        rightItem.push({ rightName: 'Notice', rightLink: '/notice' });
        rightItem.push({ rightName: 'Attendance', rightLink: '/attendance' });
    }
    if (defaultRole === 'teacher') {
        leftItem.push({ leftName: 'Home', leftLink: '/home' });
        leftItem.push({ leftName: 'Students', leftLink: '/students' });
        rightItem.push({ rightName: 'Notice', rightLink: '/notice' });
        rightItem.push({ rightName: 'Attendance', rightLink: '/attendance' });
    }
    if (defaultRole === 'parent') {
        leftItem.push({ leftName: 'Home', leftLink: '/home' });
        leftItem.push({ leftName: 'Children', leftLink: '/children' });
        rightItem.push({ rightName: 'Approval', rightLink: '/approval' });
        rightItem.push({ rightName: 'Transport', rightLink: '/transport' });
    }
    if (defaultRole === 'anon' || defaultRole === 'free') {
        leftItem.push({ leftName: 'Home', leftLink: '/home' });
        leftItem.push({ leftName: 'About PBX', leftLink: '/aboutus' });
    }
    if (defaultRole === 'zcon') {
        leftItem.push({ leftName: 'Biz Center', leftLink: '/home' });
        leftItem.push({ leftName: 'Role Assign', leftLink: '/role-Assign' });
    }

    const itemTarget = '_self';
    const listItemProps = (link) => ({
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={link} target={itemTarget} />)
    });

    return (
        <Box ref={ref}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0
                        }}
                    >
                        <BottomNavigation
                            showLabels
                            sx={{
                                backgroundColor: '#3F51B5',
                                height: 52,
                                color: '#ffffff',
                                '& 	.MuiBottomNavigationAction-root': {
                                    minWidth: '20%',
                                    alignContent: 'center',
                                    display: 'flex',
                                    color: '#d6e2eb'
                                },
                                '& .Mui-selected': {
                                    bgcolor: '#bdc3e8',
                                    color: '#3F51B5'
                                }
                            }}
                            value={selectedMenu}
                            onChange={(e, newValue) => {
                                handleMenuSelect(newValue);
                            }}
                        >
                            {defaultRole !== 'securitygaurd' &&
                                defaultRole !== 'accountant' &&
                                defaultRole !== 'conductor' &&
                                defaultRole !== 'administrator' && (
                                    <BottomNavigationAction
                                        {...listItemProps(leftItem[0]?.leftLink)}
                                        label={<Typography sx={{ fontSize: '0.70rem' }}>{leftItem[0]?.leftName}</Typography>}
                                        icon={handleMenuIcon(leftItem[0]?.leftName)}
                                    />
                                )}

                            {defaultRole !== 'securitygaurd' &&
                                defaultRole !== 'accountant' &&
                                defaultRole !== 'conductor' &&
                                defaultRole !== 'administrator' && (
                                    <BottomNavigationAction
                                        {...listItemProps(leftItem[1]?.leftLink)}
                                        label={<Typography sx={{ fontSize: '0.70rem' }}>{leftItem[1]?.leftName}</Typography>}
                                        icon={handleMenuIcon(leftItem[1]?.leftName)}
                                    />
                                )}

                            {(defaultRole === 'managment' || defaultRole === 'student' || defaultRole === 'teacher') && (
                                <MuiTooltip
                                    PopperProps={{
                                        disablePortal: true
                                    }}
                                    onClose={() => handleTooltipOpen()}
                                    open={open}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={<SelectedListItem handleTooltipOpen={handleTooltipOpen} />}
                                    arrow
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: '#3F51B5',
                                                '& .MuiTooltip-arrow': {
                                                    color: '#3F51B5',
                                                    height: 70,
                                                    width: 99,
                                                    marginBottom: '-68px !important'
                                                },
                                                marginBottom: '12px !important',
                                                padding: '7px 7px',
                                                width: 150
                                            }
                                        }
                                    }}
                                >
                                    <BottomNavigationAction
                                        sx={{
                                            justifyContent: 'center',
                                            zIndex: 1501
                                        }}
                                        label={<Typography sx={{ fontSize: '0.70rem' }}>Menu</Typography>}
                                        icon={<AppsIcon />}
                                        onClick={() => handleTooltipOpen()}
                                    />
                                </MuiTooltip>
                            )}
                            {(defaultRole === 'managment' ||
                                defaultRole === 'student' ||
                                defaultRole === 'teacher' ||
                                defaultRole === 'parent') && (
                                <BottomNavigationAction
                                    {...listItemProps(rightItem[0]?.rightLink)}
                                    label={<Typography sx={{ fontSize: '0.70rem' }}>{rightItem[0]?.rightName}</Typography>}
                                    icon={handleMenuIcon(rightItem[0]?.rightName)}
                                />
                            )}
                            {(defaultRole === 'managment' ||
                                defaultRole === 'student' ||
                                defaultRole === 'teacher' ||
                                defaultRole === 'parent') && (
                                <BottomNavigationAction
                                    {...listItemProps(rightItem[1]?.rightLink)}
                                    label={<Typography sx={{ fontSize: '0.70rem' }}>{rightItem[1]?.rightName}</Typography>}
                                    icon={handleMenuIcon(rightItem[1]?.rightName)}
                                />
                            )}
                        </BottomNavigation>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}
