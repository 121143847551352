import { Card, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { gridSpacing } from 'store/constant';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { fullName } from 'utils/common';
import { useQuery } from '@apollo/client';
import { GET_TEACHER_STUDENT_GAURDIAN_DETAILS } from 'services/teacher/query';
import { useState } from 'react';

const GuardianInfo = ({ id }) => {
    const theme = useTheme();
    const [guardianInformation, setGuardianInformation] = useState({});

    useQuery(GET_TEACHER_STUDENT_GAURDIAN_DETAILS, {
        variables: {
            studentId: id
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            // console.log(data);
            if (data?.student_guardian_details_by_pk) {
                setGuardianInformation({
                    name: data.student_guardian_details_by_pk?.guardian_name,
                    phone1: data.student_guardian_details_by_pk?.guardian_contact1,
                    phone2: data.student_guardian_details_by_pk?.guardian_contact2,
                    email: data.student_guardian_details_by_pk?.guardian_email,
                    homeAddress: data.student_guardian_details_by_pk?.guardian_home_address,
                    officAddress: data.student_guardian_details_by_pk?.guardian_office_address,
                    relation: data.student_guardian_details_by_pk?.relation
                });
            }
        },
        onError: (error) => {
            // console.log(error);
        }
    });
    return (
        <Card
            sx={{
                p: 2,
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                border: theme.palette.mode === 'dark' ? '1px solid transparent' : `1px solid${theme.palette.grey[100]}`,
                '&:hover': {
                    borderColor: theme.palette.primary.main
                }
            }}
        >
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Grid container sx={{ pb: { xs: 1, sm: 1 } }}>
                        <Grid item xs={12}>
                            <Typography variant="caption">Guardian Name</Typography>
                            <Typography variant="h6">{guardianInformation.name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ pb: { xs: 1, sm: 1 } }}>
                        <Grid item xs={12}>
                            <Typography variant="caption">Relation</Typography>
                            <Typography variant="h6">{guardianInformation.relation}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} sx={{ pb: { xs: 1, sm: 'auto' } }}>
                            <Typography variant="caption">Phone 1</Typography>
                            <Typography variant="h6">{guardianInformation.phone1}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption">Phone 2</Typography>
                            <Typography variant="h6">{guardianInformation.phone2}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="caption">Email</Typography>
                            <Typography variant="h6">{guardianInformation.email}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ py: { xs: 1, sm: 'auto' } }}>
                            <Typography variant="caption">Home Address</Typography>
                            <Typography variant="h6">{guardianInformation.homeAddress}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">Office Address</Typography>
                            <Typography variant="h6">{guardianInformation.officAddress}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

GuardianInfo.propTypes = {
    id: PropTypes.string
};

export default GuardianInfo;
