import PropTypes from 'prop-types';
import { Box, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { nanoid } from 'nanoid';
import { Fragment } from 'react';

function TableHeader({ order, orderBy, onRequestSort, headCells }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => (
                    <Fragment key={`${headCell.type}-${headCell.id}-${index}`}>
                        {headCell.type === 'text' && headCell.type !== 'accordian' ? (
                            <TableCell
                                align={headCell.align}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : undefined}
                                className="pbx-annoucement-title-tbl"
                                size="small"
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    <Typography
                                        sx={{
                                            maxWidth: headCell.width ? headCell.width : 'auto',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                        variant="h4"
                                        align={headCell.align}
                                        fontSize={14}
                                    >
                                        {headCell.label}
                                    </Typography>
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        ) : null}

                        {(headCell.type === 'print' ||
                            headCell.type === 'upload' ||
                            headCell.type === 'button' ||
                            headCell.type === 'switch' ||
                            headCell.type === 'removeBtn' ||
                            headCell.type === 'progress' ||
                            headCell.type === 'link' ||
                            headCell.type === 'object') &&
                        headCell.type !== 'accordian' ? (
                            <TableCell
                                size="small"
                                sortDirection={false}
                                className="pbx-annoucement-title-tbl"
                                align={headCell.align}
                                width={headCell.width ? headCell.width : 'auto'}
                            >
                                <Typography
                                    sx={{
                                        maxWidth: headCell.width ? headCell.width : 'auto',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                    variant="h4"
                                    align={headCell.align}
                                    fontSize={14}
                                >
                                    {headCell.label}
                                </Typography>
                            </TableCell>
                        ) : null}

                        {headCell.type === 'accordian' ? (
                            <TableCell size="small" className="pbx-annoucement-title-tbl" align="right">
                                {' '}
                            </TableCell>
                        ) : null}
                    </Fragment>
                ))}
            </TableRow>
        </TableHead>
    );
}

TableHeader.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    headCells: PropTypes.array
};

export default TableHeader;
