/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import LaptopSkeletonIllustration from 'assets/illustration/LaptopSkeleton';
import { Button, Link } from '@mui/material';

const CustomWeb = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });

    const LeftSide = () => (
        <Box>
            <Box marginBottom={2}>
                <Typography component="span" variant="h1" sx={{ fontWeight: 700, color: 'common.white' }}>
                    School Website
                </Typography>
            </Box>
            <Typography variant="h5" component="p" sx={{ fontSize: '1.125rem', fontWeight: 400, color: 'common.white' }}>
                Forward thinking schools use websites for student engagement, We also provide websites for schools, ensuring data
                reliability and safety.
            </Typography>
            {/* <Box display="flex" flexWrap="wrap" justifyContent="flex-start" marginTop={2}>
                <Button
                    component="a"
                    variant="contained"
                    color="primary"
                    sx={{
                        color: '#0C6BCC',
                        bgcolor: 'white',
                        ':hover': { bgcolor: 'white' },
                        fontWeight: 'bold'
                    }}
                    size="large"
                    fullWidth={!isMd}
                    href="https://www.demoschools.com/"
                    target="blank"
                >
                    Demo Website
                </Button>
            </Box> */}
        </Box>
    );

    return (
        <Grid container spacing={4}>
            <Grid item container alignItems="center" xs={12} md={6}>
                <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
                    <LeftSide />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box
                    sx={{
                        position: 'relative',
                        marginX: 'auto'
                    }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            marginX: 'auto'
                        }}
                    >
                        <Box>
                            <Box position="relative" zIndex={2} maxWidth={1} height="auto" sx={{ verticalAlign: 'middle' }}>
                                <LaptopSkeletonIllustration />
                            </Box>
                            <Box
                                position="absolute"
                                top="8.4%"
                                left="12%"
                                width="76%"
                                height="83%"
                                border={`1px solid ${theme.palette.primary.dark}`}
                                zIndex={3}
                                sx={{
                                    '& .lazy-load-image-loaded': {
                                        height: 1,
                                        width: 1
                                    }
                                }}
                            >
                                <Box
                                    component={LazyLoadImage}
                                    effect="blur"
                                    src="https://firebasestorage.googleapis.com/v0/b/xstzero.appspot.com/o/demoschools.png?alt=media&token=859e7931-f0e1-4e03-88dd-389704b8f2ac"
                                    alt="Image Description"
                                    width={1}
                                    height={1}
                                    sx={{
                                        objectFit: 'cover',
                                        filter: theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none'
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default CustomWeb;
