/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const UPDATE_STUDENT_APRIL_FEE_STATUS = gql`
    mutation UpdateFeeDetailApr(
        $studentId: String!
        $apr: Int
        $aprmode: String
        $aprtxn: String
        $aprdt: String
        $aprudb: String
        $aprinv: String
    ) {
        update_class_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { apr: $apr, aprmode: $aprmode, aprtxn: $aprtxn, aprdt: $aprdt, aprudb: $aprudb, aprinv: $aprinv }
        ) {
            student_id
            aprinv
        }
    }
`;
export const UPDATE_STUDENT_MAY_FEE_STATUS = gql`
    mutation UpdateFeeDetailMay(
        $studentId: String!
        $may: Int
        $maymode: String
        $maytxn: String
        $maydt: String
        $mayudb: String
        $mayinv: String
    ) {
        update_class_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { may: $may, maymode: $maymode, maytxn: $maytxn, maydt: $maydt, mayudb: $mayudb, mayinv: $mayinv }
        ) {
            student_id
            mayinv
        }
    }
`;
export const UPDATE_STUDENT_JUNE_FEE_STATUS = gql`
    mutation UpdateFeeDetailJun(
        $studentId: String!
        $jun: Int
        $junmode: String
        $juntxn: String
        $jundt: String
        $junudb: String
        $juninv: String
    ) {
        update_class_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { jun: $jun, junmode: $junmode, juntxn: $juntxn, jundt: $jundt, junudb: $junudb, juninv: $juninv }
        ) {
            student_id
            juninv
        }
    }
`;
export const UPDATE_STUDENT_JULY_FEE_STATUS = gql`
    mutation UpdateFeeDetailJul(
        $studentId: String!
        $jul: Int
        $julmode: String
        $jultxn: String
        $juldt: String
        $juludb: String
        $julinv: String
    ) {
        update_class_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { jul: $jul, julmode: $julmode, jultxn: $jultxn, juldt: $juldt, juludb: $juludb, julinv: $julinv }
        ) {
            student_id
            julinv
        }
    }
`;
export const UPDATE_STUDENT_AUGUST_FEE_STATUS = gql`
    mutation UpdateFeeDetailAug(
        $studentId: String!
        $aug: Int
        $augmode: String
        $augtxn: String
        $augdt: String
        $augudb: String
        $auginv: String
    ) {
        update_class_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { aug: $aug, augmode: $augmode, augtxn: $augtxn, augdt: $augdt, augudb: $augudb, auginv: $auginv }
        ) {
            student_id
            auginv
        }
    }
`;
export const UPDATE_STUDENT_SEPTEMBER_FEE_STATUS = gql`
    mutation UpdateFeeDetailSep(
        $studentId: String!
        $sep: Int
        $sepmode: String
        $septxn: String
        $sepdt: String
        $sepudb: String
        $sepinv: String
    ) {
        update_class_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { sep: $sep, sepmode: $sepmode, septxn: $septxn, sepdt: $sepdt, sepudb: $sepudb, sepinv: $sepinv }
        ) {
            student_id
            sepinv
        }
    }
`;
export const UPDATE_STUDENT_OCTOBER_FEE_STATUS = gql`
    mutation UpdateFeeDetailOct(
        $studentId: String!
        $oct: Int
        $octmode: String
        $octtxn: String
        $octdt: String
        $octudb: String
        $octinv: String
    ) {
        update_class_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { oct: $oct, octmode: $octmode, octtxn: $octtxn, octdt: $octdt, octudb: $octudb, octinv: $octinv }
        ) {
            student_id
            octinv
        }
    }
`;
export const UPDATE_STUDENT_NOVEMBER_FEE_STATUS = gql`
    mutation UpdateFeeDetailNov(
        $studentId: String!
        $nov: Int
        $novmode: String
        $novtxn: String
        $novdt: String
        $novudb: String
        $novinv: String
    ) {
        update_class_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { nov: $nov, novmode: $novmode, novtxn: $novtxn, novdt: $novdt, novudb: $novudb, novinv: $novinv }
        ) {
            student_id
            novinv
        }
    }
`;
export const UPDATE_STUDENT_DECEMBER_FEE_STATUS = gql`
    mutation UpdateFeeDetailDec(
        $studentId: String!
        $dec: Int
        $decmode: String
        $dectxn: String
        $decdt: String
        $decudb: String
        $decinv: String
    ) {
        update_class_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { dec: $dec, decmode: $decmode, dectxn: $dectxn, decdt: $decdt, decudb: $decudb, decinv: $decinv }
        ) {
            student_id
            decinv
        }
    }
`;
export const UPDATE_STUDENT_JANUARY_FEE_STATUS = gql`
    mutation UpdateFeeDetailJan(
        $studentId: String!
        $jan: Int
        $janmode: String
        $jantxn: String
        $jandt: String
        $janudb: String
        $janinv: String
    ) {
        update_class_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { jan: $jan, janmode: $janmode, jantxn: $jantxn, jandt: $jandt, janudb: $janudb, janinv: $janinv }
        ) {
            student_id
            janinv
        }
    }
`;
export const UPDATE_STUDENT_FEBRUARY_FEE_STATUS = gql`
    mutation UpdateFeeDetailFeb(
        $studentId: String!
        $feb: Int
        $febmode: String
        $febtxn: String
        $febdt: String
        $febudb: String
        $febinv: String
    ) {
        update_class_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { feb: $feb, febmode: $febmode, febtxn: $febtxn, febdt: $febdt, febudb: $febudb, febinv: $febinv }
        ) {
            student_id
            febinv
        }
    }
`;
export const UPDATE_STUDENT_MARCH_FEE_STATUS = gql`
    mutation UpdateFeeDetailMar(
        $studentId: String!
        $mar: Int
        $marmode: String
        $martxn: String
        $mardt: String
        $marudb: String
        $marinv: String
    ) {
        update_class_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { mar: $mar, marmode: $marmode, martxn: $martxn, mardt: $mardt, marudb: $marudb, marinv: $marinv }
        ) {
            student_id
            marinv
        }
    }
`;

export const UPDATE_TRANSPORT_APRIL_FEE_STATUS = gql`
    mutation UpdateFeeDetailApr(
        $studentId: String!
        $apr: Int
        $aprmode: String
        $aprtxn: String
        $aprdt: String
        $aprudb: String
        $aprinv: String
    ) {
        update_transport_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { apr: $apr, aprmode: $aprmode, aprtxn: $aprtxn, aprdt: $aprdt, aprudb: $aprudb, aprinv: $aprinv }
        ) {
            student_id
            aprinv
        }
    }
`;
export const UPDATE_TRANSPORT_MAY_FEE_STATUS = gql`
    mutation UpdateFeeDetailMay(
        $studentId: String!
        $may: Int
        $maymode: String
        $maytxn: String
        $maydt: String
        $mayudb: String
        $mayinv: String
    ) {
        update_transport_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { may: $may, maymode: $maymode, maytxn: $maytxn, maydt: $maydt, mayudb: $mayudb, mayinv: $mayinv }
        ) {
            student_id
            mayinv
        }
    }
`;
export const UPDATE_TRANSPORT_JUNE_FEE_STATUS = gql`
    mutation UpdateFeeDetailJun(
        $studentId: String!
        $jun: Int
        $junmode: String
        $juntxn: String
        $jundt: String
        $junudb: String
        $juninv: String
    ) {
        update_transport_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { jun: $jun, junmode: $junmode, juntxn: $juntxn, jundt: $jundt, junudb: $junudb, juninv: $juninv }
        ) {
            student_id
            juninv
        }
    }
`;
export const UPDATE_TRANSPORT_JULY_FEE_STATUS = gql`
    mutation UpdateFeeDetailJul(
        $studentId: String!
        $jul: Int
        $julmode: String
        $jultxn: String
        $juldt: String
        $juludb: String
        $julinv: String
    ) {
        update_transport_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { jul: $jul, julmode: $julmode, jultxn: $jultxn, juldt: $juldt, juludb: $juludb, julinv: $julinv }
        ) {
            student_id
            julinv
        }
    }
`;
export const UPDATE_TRANSPORT_AUGUST_FEE_STATUS = gql`
    mutation UpdateFeeDetailAug(
        $studentId: String!
        $aug: Int
        $augmode: String
        $augtxn: String
        $augdt: String
        $augudb: String
        $auginv: String
    ) {
        update_transport_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { aug: $aug, augmode: $augmode, augtxn: $augtxn, augdt: $augdt, augudb: $augudb, auginv: $auginv }
        ) {
            student_id
            auginv
        }
    }
`;
export const UPDATE_TRANSPORT_SEPTEMBER_FEE_STATUS = gql`
    mutation UpdateFeeDetailSep(
        $studentId: String!
        $sep: Int
        $sepmode: String
        $septxn: String
        $sepdt: String
        $sepudb: String
        $sepinv: String
    ) {
        update_transport_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { sep: $sep, sepmode: $sepmode, septxn: $septxn, sepdt: $sepdt, sepudb: $sepudb, sepinv: $sepinv }
        ) {
            student_id
            sepinv
        }
    }
`;
export const UPDATE_TRANSPORT_OCTOBER_FEE_STATUS = gql`
    mutation UpdateFeeDetailOct(
        $studentId: String!
        $oct: Int
        $octmode: String
        $octtxn: String
        $octdt: String
        $octudb: String
        $octinv: String
    ) {
        update_transport_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { oct: $oct, octmode: $octmode, octtxn: $octtxn, octdt: $octdt, octudb: $octudb, octinv: $octinv }
        ) {
            student_id
            octinv
        }
    }
`;
export const UPDATE_TRANSPORT_NOVEMBER_FEE_STATUS = gql`
    mutation UpdateFeeDetailNov(
        $studentId: String!
        $nov: Int
        $novmode: String
        $novtxn: String
        $novdt: String
        $novudb: String
        $novinv: String
    ) {
        update_transport_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { nov: $nov, novmode: $novmode, novtxn: $novtxn, novdt: $novdt, novudb: $novudb, novinv: $novinv }
        ) {
            student_id
            novinv
        }
    }
`;
export const UPDATE_TRANSPORT_DECEMBER_FEE_STATUS = gql`
    mutation UpdateFeeDetailDec(
        $studentId: String!
        $dec: Int
        $decmode: String
        $dectxn: String
        $decdt: String
        $decudb: String
        $decinv: String
    ) {
        update_transport_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { dec: $dec, decmode: $decmode, dectxn: $dectxn, decdt: $decdt, decudb: $decudb, decinv: $decinv }
        ) {
            student_id
            decinv
        }
    }
`;
export const UPDATE_TRANSPORT_JANUARY_FEE_STATUS = gql`
    mutation UpdateFeeDetailJan(
        $studentId: String!
        $jan: Int
        $janmode: String
        $jantxn: String
        $jandt: String
        $janudb: String
        $janinv: String
    ) {
        update_transport_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { jan: $jan, janmode: $janmode, jantxn: $jantxn, jandt: $jandt, janudb: $janudb, janinv: $janinv }
        ) {
            student_id
            janinv
        }
    }
`;
export const UPDATE_TRANSPORT_FEBRUARY_FEE_STATUS = gql`
    mutation UpdateFeeDetailFeb(
        $studentId: String!
        $feb: Int
        $febmode: String
        $febtxn: String
        $febdt: String
        $febudb: String
        $febinv: String
    ) {
        update_transport_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { feb: $feb, febmode: $febmode, febtxn: $febtxn, febdt: $febdt, febudb: $febudb, febinv: $febinv }
        ) {
            student_id
            febinv
        }
    }
`;
export const UPDATE_TRANSPORT_MARCH_FEE_STATUS = gql`
    mutation UpdateFeeDetailMar(
        $studentId: String!
        $mar: Int
        $marmode: String
        $martxn: String
        $mardt: String
        $marudb: String
        $marinv: String
    ) {
        update_transport_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { mar: $mar, marmode: $marmode, martxn: $martxn, mardt: $mardt, marudb: $marudb, marinv: $marinv }
        ) {
            student_id
            marinv
        }
    }
`;

export const UPDATE_NOTICE_PUBLISHED_INFO = gql`
    mutation SchoolNoticePublished($Id: String!, $isActive: Boolean) {
        update_school_notice_by_pk(pk_columns: { id: $Id }, _set: { published: $isActive }) {
            published
            id
        }
    }
`;

export const DELETE_NOTICE_PUBLISHED_INFO = gql`
    mutation DeleteSchoolNoticeByID($id: String!) {
        delete_school_notice_by_pk(id: $id) {
            id
        }
    }
`;

export const INSERT_SCHOOL_NOTICE = gql`
    mutation InserSchoolNoticeOne($Id: String, $level: String, $subject: String, $message: String, $schoolId: String, $session: String) {
        insert_school_notice_one(
            object: { id: $Id, level: $level, subject: $subject, message: $message, school_id: $schoolId, session: $session }
        ) {
            id
            level
            created_at
            subject
            published
        }
    }
`;

export const UPDATE_EXAM_PUBLISHED_INFO = gql`
    mutation SchoolNoticePublished($Id: String!, $publish: Boolean) {
        update_school_exams_by_pk(pk_columns: { exam_id: $Id }, _set: { published: $publish }) {
            published
            exam_id
        }
    }
`;

export const UPDATE_EXAM_CUNDUCTED_INFO = gql`
    mutation SchoolNoticePublished($Id: String!, $examConducted: Boolean) {
        update_school_exams_by_pk(pk_columns: { exam_id: $Id }, _set: { exam_conducted: $examConducted, published: $examConducted }) {
            published
            exam_conducted
            exam_id
        }
    }
`;

export const INSERT_SCHOOL_EXAM = gql`
    mutation SchoolExamOne(
        $examId: String
        $examFor: String
        $examName: String
        $examDate: String
        $schoolId: String
        $examType: String
        $session: String
    ) {
        insert_school_exams_one(
            object: {
                exam_id: $examId
                exam_for_class: $examFor
                exam_name: $examName
                exam_date: $examDate
                school_id: $schoolId
                exam_type: $examType
                session: $session
            }
        ) {
            exam_id
            exam_for_class
            exam_name
            exam_date
            school_id
            exam_type
            session
            created_at
            published
            exam_conducted
        }
    }
`;

export const UPDATE_SCHOOL_EXAM = gql`
    mutation UpdateExamDetails($examId: String!, $examForClass: String, $examName: String, $examDate: String, $examType: String) {
        update_school_exams_by_pk(
            pk_columns: { exam_id: $examId }
            _set: { exam_for_class: $examForClass, exam_name: $examName, exam_date: $examDate, exam_type: $examType }
        ) {
            exam_id
            exam_for_class
            exam_name
            exam_date
            exam_type
        }
    }
`;

export const DELETE_SCHOOL_EXAM = gql`
    mutation DeleteExam($examId: String!) {
        delete_school_exams_by_pk(exam_id: $examId) {
            exam_id
        }
    }
`;

export const INSERT_SECURITY_GAURD_ACCESS = gql`
    mutation ISchoolSG($schoolId: String!, $sguid: String!, $emailId: String!) {
        insert_school_secguard_one(object: { sg_uid: $sguid, email_id: $emailId, school_id: $schoolId }) {
            email_id
            can_check
        }
    }
`;

export const UPDATE_SECURITY_GAURD_ACCESS = gql`
    mutation USchoolSG($schoolId: String!, $emailId: String, $sguid: String) {
        update_school_secguard_by_pk(pk_columns: { school_id: $schoolId }, _set: { email_id: $emailId, sg_uid: $sguid }) {
            can_check
            email_id
        }
    }
`;

export const UPDATE_SECURITY_GAURD_CAN_CHECK = gql`
    mutation USecCC($schoolId: String!, $canCheck: Boolean) {
        update_school_secguard_by_pk(pk_columns: { school_id: $schoolId }, _set: { can_check: $canCheck }) {
            can_check
        }
    }
`;

export const INSERT_ROUTE = gql`
    mutation InsertRouteOne($trid: String, $schoolId: String, $rtName: String) {
        insert_trans_route_one(object: { tr_id: $trid, school_id: $schoolId, rtname: $rtName }) {
            tr_id
            rtname
        }
    }
`;

export const UPDATE_ROUTE = gql`
    mutation UpdateRoute($trId: String!, $rtName: String) {
        update_trans_route_by_pk(pk_columns: { tr_id: $trId }, _set: { rtname: $rtName }) {
            rtname
            tr_id
        }
    }
`;

export const DELETE_ROUTE = gql`
    mutation DeleteRouteByID($trId: String!) {
        delete_trans_route_by_pk(tr_id: $trId) {
            tr_id
        }
    }
`;

export const INSERT_VEHICLE = gql`
    mutation TransDataOne(
        $tdid: String
        $schoolId: String
        $trid: String
        $vehName: String
        $vehNo: String
        $inServ: String
        $drvId: String
        $condId: String
        $vtsLive: String
        $mobLive: String
    ) {
        insert_trans_data_one(
            object: {
                td_id: $tdid
                school_id: $schoolId
                tr_id: $trid
                veh_name: $vehName
                veh_no: $vehNo
                in_serv: $inServ
                drv_id: $drvId
                cond_id: $condId
                vtslive: $vtsLive
                moblive: $mobLive
            }
        ) {
            td_id
            veh_name
            veh_no
            in_serv
            driver {
                staff_name
                school_id
            }
            conductor {
                staff_name
                staff_id
            }
            trans_route {
                rtname
                tr_id
            }
            moblive
            vtslive
            transport_fees_aggregate {
                aggregate {
                    count(columns: student_id)
                }
            }
        }
    }
`;

export const UPDATE_VEHICLE = gql`
    mutation UpdateTransDataByID(
        $tdid: String!
        $trid: String
        $vehName: String
        $vehNo: String
        $inServ: String
        $drvId: String
        $condId: String
        $vtsLive: String
        $mobLive: String
    ) {
        update_trans_data_by_pk(
            pk_columns: { td_id: $tdid }
            _set: {
                veh_name: $vehName
                veh_no: $vehNo
                tr_id: $trid
                in_serv: $inServ
                drv_id: $drvId
                cond_id: $condId
                vtslive: $vtsLive
                moblive: $mobLive
            }
        ) {
            td_id
            veh_name
            veh_no
            in_serv
            driver {
                staff_name
                school_id
            }
            conductor {
                staff_name
                staff_id
            }
            trans_route {
                rtname
                tr_id
            }
            moblive
            vtslive
        }
    }
`;

export const INSERT_ACCOUNTANT_ONE = gql`
    mutation InsertAccountantOne($sauid: String, $schoolId: String, $emailId: String) {
        insert_school_accounts_one(object: { sa_uid: $sauid, school_id: $schoolId, email_id: $emailId }) {
            sa_uid
            read
            mod
        }
    }
`;

export const UPDATE_ACCOUNTANT_READ = gql`
    mutation UpdateAccountantRead($schoolId: String!, $read: Boolean) {
        update_school_accounts_by_pk(pk_columns: { school_id: $schoolId }, _set: { read: $read }) {
            sa_uid
            read
        }
    }
`;

export const UPDATE_ACCOUNTANT_MOD = gql`
    mutation UpdateAccountantMod($schoolId: String!, $mod: Boolean) {
        update_school_accounts_by_pk(pk_columns: { school_id: $schoolId }, _set: { mod: $mod }) {
            sa_uid
            mod
        }
    }
`;

export const DELETE_ACCOUNTANT_ONE = gql`
    mutation DeleteAccountantOne($schoolId: String!) {
        delete_school_accounts_by_pk(school_id: $schoolId) {
            sa_uid
            email_id
        }
    }
`;

export const INSERT_SEC_GUARD_ONE = gql`
    mutation InsertSecGuardOne($sguid: String, $schoolId: String, $emailId: String) {
        insert_school_secguard_one(object: { sg_uid: $sguid, school_id: $schoolId, email_id: $emailId }) {
            read
            mod
        }
    }
`;

export const UPDATE_SEC_GUARD_READ = gql`
    mutation UpdateSecGuardRead($schoolId: String!, $read: Boolean) {
        update_school_secguard_by_pk(pk_columns: { school_id: $schoolId }, _set: { read: $read }) {
            sg_uid
            read
        }
    }
`;

export const UPDATE_SEC_GUARD_MOD = gql`
    mutation UpdateSecGuardMod($schoolId: String!, $mod: Boolean) {
        update_school_secguard_by_pk(pk_columns: { school_id: $schoolId }, _set: { mod: $mod }) {
            sg_uid
            mod
        }
    }
`;

export const DELETE_SEC_GUARD_ONE = gql`
    mutation DeleteSecGuardOne($schoolId: String!) {
        delete_school_secguard_by_pk(school_id: $schoolId) {
            sg_uid
            email_id
        }
    }
`;

export const ASSIGN_CONDUCTOR_ROLE = gql`
    mutation ConductorRole($staffId: String!, $guid: String) {
        update_school_staff_by_pk(pk_columns: { staff_id: $staffId }, _set: { staff_guid: $guid }) {
            staff_active
        }
    }
`;

export const UPDATE_STAFF_ACTIVE_BY_ID = gql`
    mutation UpdateStaffActiveByID($staffId: String!, $read: Boolean) {
        update_school_staff_by_pk(pk_columns: { staff_id: $staffId }, _set: { read: $read }) {
            staff_id
            read
        }
    }
`;

export const UPDATE_CLASS_SUB1 = gql`
    mutation UpdateCSub1($classId: String!, $s1Id: String, $s1: String, $s1t: String, $s1cp: Int, $s1tp: Int, $s1ad: String) {
        update_class_sub_by_pk(
            pk_columns: { class_id: $classId }
            _set: { s1Id: $s1Id, s1: $s1, s1t: $s1t, s1cp: $s1cp, s1tp: $s1tp, s1ad: $s1ad }
        ) {
            s1Id
        }
    }
`;

export const UPDATE_CLASS_SUB2 = gql`
    mutation UpdateCSub2($classId: String!, $s2Id: String, $s2: String, $s2t: String, $s2cp: Int, $s2tp: Int, $s2ad: String) {
        update_class_sub_by_pk(
            pk_columns: { class_id: $classId }
            _set: { s2Id: $s2Id, s2: $s2, s2t: $s2t, s2cp: $s2cp, s2tp: $s2tp, s2ad: $s2ad }
        ) {
            s2Id
        }
    }
`;

export const UPDATE_CLASS_SUB3 = gql`
    mutation UpdateCSub3($classId: String!, $s3Id: String, $s3: String, $s3t: String, $s3cp: Int, $s3tp: Int, $s3ad: String) {
        update_class_sub_by_pk(
            pk_columns: { class_id: $classId }
            _set: { s3Id: $s3Id, s3: $s3, s3t: $s3t, s3cp: $s3cp, s3tp: $s3tp, s3ad: $s3ad }
        ) {
            s3Id
        }
    }
`;

export const UPDATE_CLASS_SUB4 = gql`
    mutation UpdateCSub4($classId: String!, $s4Id: String, $s4: String, $s4t: String, $s4cp: Int, $s4tp: Int, $s4ad: String) {
        update_class_sub_by_pk(
            pk_columns: { class_id: $classId }
            _set: { s4Id: $s4Id, s4: $s4, s4t: $s4t, s4cp: $s4cp, s4tp: $s4tp, s4ad: $s4ad }
        ) {
            s4Id
        }
    }
`;

export const UPDATE_CLASS_SUB5 = gql`
    mutation UpdateCSub5($classId: String!, $s5Id: String, $s5: String, $s5t: String, $s5cp: Int, $s5tp: Int, $s5ad: String) {
        update_class_sub_by_pk(
            pk_columns: { class_id: $classId }
            _set: { s5Id: $s5Id, s5: $s5, s5t: $s5t, s5cp: $s5cp, s5tp: $s5tp, s5ad: $s5ad }
        ) {
            s5Id
        }
    }
`;

export const UPDATE_CLASS_SUB6 = gql`
    mutation UpdateCSub6($classId: String!, $s6Id: String, $s6: String, $s6t: String, $s6cp: Int, $s6tp: Int, $s6ad: String) {
        update_class_sub_by_pk(
            pk_columns: { class_id: $classId }
            _set: { s6Id: $s6Id, s6: $s6, s6t: $s6t, s6cp: $s6cp, s6tp: $s6tp, s6ad: $s6ad }
        ) {
            s6Id
        }
    }
`;

export const UPDATE_CLASS_SUB7 = gql`
    mutation UpdateCSub7($classId: String!, $s7Id: String, $s7: String, $s7t: String, $s7cp: Int, $s7tp: Int, $s7ad: String) {
        update_class_sub_by_pk(
            pk_columns: { class_id: $classId }
            _set: { s7Id: $s7Id, s7: $s7, s7t: $s7t, s7cp: $s7cp, s7tp: $s7tp, s7ad: $s7ad }
        ) {
            s7Id
        }
    }
`;

export const UPDATE_CLASS_SUB8 = gql`
    mutation UpdateCSub8($classId: String!, $s8Id: String, $s8: String, $s8t: String, $s8cp: Int, $s8tp: Int, $s8ad: String) {
        update_class_sub_by_pk(
            pk_columns: { class_id: $classId }
            _set: { s8Id: $s8Id, s8: $s8, s8t: $s8t, s8cp: $s8cp, s8tp: $s8tp, s8ad: $s8ad }
        ) {
            s8Id
        }
    }
`;

export const UPDATE_CLASS_SUB9 = gql`
    mutation UpdateCSub9($classId: String!, $s9Id: String, $s9: String, $s9t: String, $s9cp: Int, $s9tp: Int, $s9ad: String) {
        update_class_sub_by_pk(
            pk_columns: { class_id: $classId }
            _set: { s9Id: $s9Id, s9: $s9, s9t: $s9t, s9cp: $s9cp, s9tp: $s9tp, s9ad: $s9ad }
        ) {
            s9Id
        }
    }
`;

export const UPDATE_CLASS_SUB10 = gql`
    mutation UpdateCSub10($classId: String!, $s10Id: String, $s10: String, $s10t: String, $s10cp: Int, $s10tp: Int, $s10ad: String) {
        update_class_sub_by_pk(
            pk_columns: { class_id: $classId }
            _set: { s10Id: $s10Id, s10: $s10, s10t: $s10t, s10cp: $s10cp, s10tp: $s10tp, s10ad: $s10ad }
        ) {
            s10Id
        }
    }
`;

export const UPDATE_CLASS_SUB11 = gql`
    mutation UpdateCSub11($classId: String!, $s11Id: String, $s11: String, $s11t: String, $s11cp: Int, $s11tp: Int, $s11ad: String) {
        update_class_sub_by_pk(
            pk_columns: { class_id: $classId }
            _set: { s11Id: $s11Id, s11: $s11, s11t: $s11t, s11cp: $s11cp, s11tp: $s11tp, s11ad: $s11ad }
        ) {
            s11Id
        }
    }
`;

export const UPDATE_CLASS_SUB12 = gql`
    mutation UpdateCSub12($classId: String!, $s12Id: String, $s12: String, $s12t: String, $s12cp: Int, $s12tp: Int, $s12ad: String) {
        update_class_sub_by_pk(
            pk_columns: { class_id: $classId }
            _set: { s12Id: $s12Id, s12: $s12, s12t: $s12t, s12cp: $s12cp, s12tp: $s12tp, s12ad: $s12ad }
        ) {
            s12Id
        }
    }
`;

export const UPDATE_CLASS_SUB13 = gql`
    mutation UpdateCSub13($classId: String!, $s13Id: String, $s13: String, $s13t: String, $s13cp: Int, $s13tp: Int, $s13ad: String) {
        update_class_sub_by_pk(
            pk_columns: { class_id: $classId }
            _set: { s13Id: $s13Id, s13: $s13, s13t: $s13t, s13cp: $s13cp, s13tp: $s13tp, s13ad: $s13ad }
        ) {
            s13Id
        }
    }
`;

export const UPDATE_CLASS_SUB14 = gql`
    mutation UpdateCSub14($classId: String!, $s14Id: String, $s14: String, $s14t: String, $s14cp: Int, $s14tp: Int, $s14ad: String) {
        update_class_sub_by_pk(
            pk_columns: { class_id: $classId }
            _set: { s14Id: $s14Id, s14: $s14, s14t: $s14t, s14cp: $s14cp, s14tp: $s14tp, s14ad: $s14ad }
        ) {
            s14Id
        }
    }
`;

export const UPDATE_CLASS_SUB15 = gql`
    mutation UpdateCSub15($classId: String!, $s15Id: String, $s15: String, $s15t: String, $s15cp: Int, $s15tp: Int, $s15ad: String) {
        update_class_sub_by_pk(
            pk_columns: { class_id: $classId }
            _set: { s15Id: $s15Id, s15: $s15, s15t: $s15t, s15cp: $s15cp, s15tp: $s15tp, s15ad: $s15ad }
        ) {
            s15Id
        }
    }
`;

export const INSERT_CLASS_SUBJECT = gql`
    mutation InsertClassSub($classId: String, $sid: String, $sn: String, $st: String, $scp: Int, $stp: Int, $sad: String) {
        insert_csub_one(object: { sid: $sid, class_id: $classId, sn: $sn, scp: $scp, stp: $stp, st: $st, sad: $sad }) {
            sid
            sn
            scp
            stp
            st
            sad
        }
    }
`;

export const UPDATE_CLASS_SUBJECT = gql`
    mutation UpdateSubByID($sid: String!, $sn: String, $st: String, $scp: Int, $stp: Int, $sad: String) {
        update_csub_by_pk(pk_columns: { sid: $sid }, _set: { sn: $sn, st: $st, scp: $scp, stp: $stp, sad: $sad }) {
            sid
            sn
            scp
            stp
            st
            sad
        }
    }
`;

export const DELETE_SUB_BY_ID = gql`
    mutation DeleteSubByID($sid: String!) {
        delete_csub_by_pk(sid: $sid) {
            sid
        }
    }
`;

export const UPDATE_SUB_TIMING_BY_SID = gql`
    mutation UpdateSubByID($sid: String!, $seq: Int, $timing: String, $days: String) {
        update_csub_by_pk(pk_columns: { sid: $sid }, _set: { seq: $seq, timing: $timing, days: $days }) {
            timing
            seq
            days
        }
    }
`;

export const INSERT_RECHARGE_DETAIL = gql`
    mutation InsertRechargeDetail($remail: String, $schoolId: String) {
        insert_credtxn_one(object: { remail: $remail, school_id: $schoolId }) {
            id
        }
    }
`;

export const INSERT_FEE_TYPE_ROW = gql`
    mutation InsertFeeType($data: [class_feetype_insert_input!]!) {
        insert_class_feetype(objects: $data) {
            affected_rows
        }
    }
`;

export const INSERT_FEE_TYPE_BY_CLASS_ID = gql`
    mutation InsertFeeTypeOne($classId: String) {
        insert_class_feetype_one(object: { class_id: $classId }) {
            class_id
        }
    }
`;

export const UPDATE_FEE_TYPE_BY_CGID = gql`
    mutation UpdateFeeType(
        $cgId: String
        $adm: Int
        $f2: String
        $f2f: Int
        $f3: String
        $f3f: Int
        $f4: String
        $f4f: Int
        $f5: String
        $f5f: Int
        $f6: String
        $f6f: Int
        $f7: String
        $f7f: Int
        $f8: String
        $f8f: Int
        $f9: String
        $f9f: Int
        $f10: String
        $f10f: Int
    ) {
        update_class_feetype(
            where: { school_class_detail: { cg_id: { _eq: $cgId } } }
            _set: {
                adm: $adm
                f2: $f2
                f2f: $f2f
                f3: $f3
                f3f: $f3f
                f4: $f4
                f4f: $f4f
                f5: $f5
                f5f: $f5f
                f6: $f6
                f6f: $f6f
                f7: $f7
                f7f: $f7f
                f8: $f8
                f8f: $f8f
                f9: $f9
                f9f: $f9f
                f10: $f10
                f10f: $f10f
            }
        ) {
            affected_rows
        }
    }
`;

export const UPDATE_FEE_TYPE_BY_CLASS_ID = gql`
    mutation UpdateFeeTypeSingle(
        $classId: String!
        $adm: Int
        $f2: String
        $f2f: Int
        $f3: String
        $f3f: Int
        $f4: String
        $f4f: Int
        $f5: String
        $f5f: Int
        $f6: String
        $f6f: Int
        $f7: String
        $f7f: Int
        $f8: String
        $f8f: Int
        $f9: String
        $f9f: Int
        $f10: String
        $f10f: Int
    ) {
        update_class_feetype_by_pk(
            pk_columns: { class_id: $classId }
            _set: {
                f2: $f2
                f2f: $f2f
                f3: $f3
                f3f: $f3f
                f4: $f4
                f4f: $f4f
                f5: $f5
                f5f: $f5f
                f6: $f6
                f6f: $f6f
                f7: $f7
                f7f: $f7f
                f8: $f8
                f8f: $f8f
                f9: $f9
                f9f: $f9f
                f10: $f10
                f10f: $f10f
                adm: $adm
            }
        ) {
            class_id
        }
    }
`;

export const UPLOAD_STUDENT_PHOTO = gql`
    mutation UpdateStudentPhoto($studentId: String!, $photo: String) {
        update_school_students_by_pk(pk_columns: { student_id: $studentId }, _set: { student_photo: $photo }) {
            student_id
        }
    }
`;

export const UPLOAD_TEACHER_PHOTO = gql`
    mutation TeacherPhoto($teacherId: String!, $photo: String) {
        update_school_teachers_by_pk(pk_columns: { teacher_id: $teacherId }, _set: { teacher_photo: $photo }) {
            teacher_id
        }
    }
`;

export const UPLOAD_STAFF_PHOTO = gql`
    mutation StaffPhoto($staffId: String!, $photo: String) {
        update_school_staff_by_pk(pk_columns: { staff_id: $staffId }, _set: { staff_photo: $photo }) {
            staff_id
        }
    }
`;

export const UPDADTER_TEACHER_ROLE = gql`
    mutation TeachRole($email: String, $schoolId: String, $guid: String) {
        update_school_teachers(where: { teacher_email: { _eq: $email }, school_id: { _eq: $schoolId } }, _set: { teacher_guid: $guid }) {
            affected_rows
        }
    }
`;

export const UPDATE_STUDENT_EMAIL = gql`
    mutation UpdateStudentEmail($guid: String!, $email: String) {
        update_school_students(where: { student_guid: { _eq: $guid } }, _set: { email_id: $email }) {
            affected_rows
        }
        update_user_info_by_pk(pk_columns: { guid: $guid }, _set: { email_id: $email }) {
            email_id
        }
        update_users_by_pk(pk_columns: { uid: $guid }, _set: { email: $email }) {
            email
        }
    }
`;

export const INSERT_STOPPAGE = gql`
    mutation InsertStopage($trId: String, $stop: String, $fees: Int) {
        insert_stopage_one(object: { tr_id: $trId, stop: $stop, fees: $fees }) {
            st_id
            stop
            fees
        }
    }
`;

export const UPDATE_STOPPAGE = gql`
    mutation UpdateStopage($stId: Int!, $stop: String, $fees: Int) {
        update_stopage_by_pk(pk_columns: { st_id: $stId }, _set: { stop: $stop, fees: $fees }) {
            st_id
            stop
            fees
        }
    }
`;

export const DELETE_STOPPAGE = gql`
    mutation DeleteStoppage($stId: Int!) {
        delete_stopage_by_pk(st_id: $stId) {
            st_id
        }
    }
`;

export const INSERT_ID_CARD_INFO = gql`
    mutation InsertIDData($schoolId: String, $sName: String, $session: String, $contact: String, $address: String) {
        insert_idcard_data_one(object: { school_id: $schoolId, s_name: $sName, session: $session, contact: $contact, address: $address }) {
            s_name
            session
            contact
            address
        }
    }
`;

export const UPDATE_ID_CARD_INFO = gql`
    mutation UpdateIDData($schoolId: String!, $sName: String, $session: String, $contact: String, $address: String) {
        update_idcard_data_by_pk(
            pk_columns: { school_id: $schoolId }
            _set: { s_name: $sName, session: $session, contact: $contact, address: $address }
        ) {
            s_name
            session
            contact
            address
        }
    }
`;

export const INSERT_MARKSHEET_INFO = gql`
    mutation InsertMarksheetData($schoolId: String, $schoolName: String, $address: String, $contact: String) {
        insert_marksheet_data_one(object: { school_id: $schoolId, school_name: $schoolName, address: $address, contact: $contact }) {
            school_name
            address
            contact
        }
    }
`;

export const UPDATE_MARKSHEET_INFO = gql`
    mutation UpdateMarksheetData($schoolId: String!, $schoolName: String, $address: String, $contact: String) {
        update_marksheet_data_by_pk(
            pk_columns: { school_id: $schoolId }
            _set: { school_name: $schoolName, address: $address, contact: $contact }
        ) {
            school_name
            address
            contact
        }
    }
`;

export const UPDATE_LOGO = gql`
    mutation UpdateSchoolLogo($schoolId: String!, $logo: String) {
        update_school_info_by_pk(pk_columns: { gusid: $schoolId }, _set: { logo: $logo }) {
            gusid
        }
    }
`;
export const UPDATE_SIGN = gql`
    mutation UpdatePSign($schoolId: String!, $pSign: String) {
        update_school_info_by_pk(pk_columns: { gusid: $schoolId }, _set: { p_sign: $pSign }) {
            gusid
        }
    }
`;

export const BULK_ADMISSION_WITH_STUDENT_ACCESS = gql`
    mutation BulkAdmission(
        $students: [school_students_insert_input!]!
        $fathers: [student_father_details_insert_input!]!
        $mothers: [student_mother_details_insert_input!]!
        $guardians: [student_guardian_details_insert_input!]!
        $classD: [school_student_classes_insert_input!]!
        $essenF: [essen_fees_insert_input!]!
        $classF: [class_fees_insert_input!]!
        $apr: [apr_insert_input!]!
        $may: [may_insert_input!]!
        $jun: [jun_insert_input!]!
        $jul: [jul_insert_input!]!
        $aug: [aug_insert_input!]!
        $sep: [sep_insert_input!]!
        $oct: [oct_insert_input!]!
        $nov: [nov_insert_input!]!
        $dec: [dec_insert_input!]!
        $jan: [jan_insert_input!]!
        $feb: [feb_insert_input!]!
        $mar: [mar_insert_input!]!
    ) {
        insert_school_students(objects: $students) {
            affected_rows
        }
        insert_student_father_details(objects: $fathers) {
            affected_rows
        }
        insert_student_mother_details(objects: $mothers) {
            affected_rows
        }
        insert_student_guardian_details(objects: $guardians) {
            affected_rows
        }
        insert_school_student_classes(objects: $classD) {
            affected_rows
        }
        insert_essen_fees(objects: $essenF) {
            affected_rows
        }
        insert_class_fees(objects: $classF) {
            affected_rows
        }
        insert_apr(objects: $apr) {
            affected_rows
        }
        insert_may(objects: $may) {
            affected_rows
        }
        insert_jun(objects: $jun) {
            affected_rows
        }
        insert_jul(objects: $jul) {
            affected_rows
        }
        insert_aug(objects: $aug) {
            affected_rows
        }
        insert_sep(objects: $sep) {
            affected_rows
        }
        insert_oct(objects: $oct) {
            affected_rows
        }
        insert_nov(objects: $nov) {
            affected_rows
        }
        insert_dec(objects: $dec) {
            affected_rows
        }
        insert_jan(objects: $jan) {
            affected_rows
        }
        insert_feb(objects: $feb) {
            affected_rows
        }
        insert_mar(objects: $mar) {
            affected_rows
        }
    }
`;

export const UPDATE_PIN_CODE = gql`
    mutation UpdatePCode($schoolId: String!, $pcode: String) {
        update_school_info_by_pk(pk_columns: { gusid: $schoolId }, _set: { pcode: $pcode }) {
            pcode
        }
    }
`;

export const UPDATE_PAST_DUE_FEES = gql`
    mutation UpdatePastDue($studentId: String!, $pastDue: Int, $pdudt: String, $pdudb: String) {
        update_class_fees_by_pk(pk_columns: { student_id: $studentId }, _set: { past_due: $pastDue, pdudt: $pdudt, pdudb: $pdudb }) {
            student_id
            past_due
        }
    }
`;
