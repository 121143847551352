import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */
export const GET_RESULTS_LIST = gql`
    query getResultsList($schoolId: String!, $className: String, $session: String) {
        school_exams(
            where: {
                school_id: { _eq: $schoolId }
                session: { _eq: $session }
                exam_conducted: { _eq: true }
                _and: { _or: [{ exam_for_class: { _eq: $className } }, { exam_for_class: { _eq: "School" } }] }
            }
            order_by: { created_at: asc }
        ) {
            exam_id
            created_at
            exam_for_class
            exam_name
            exam_date
        }
    }
`;

export const GET_EXAM_SUBJECT_NAME_AND_ID = gql`
    query examSubjectNameAndId($classId: String!) {
        school_class_details_by_pk(class_id: $classId) {
            result_subjects {
                subjects_of_exam
                subject_id
            }
        }
    }
`;

export const GET_SUBJECT_AND_MARK_BY_ID = gql`
    query SubjectAndMarkById($subjectId: String!) {
        result_subjects_by_pk(subject_id: $subjectId) {
            subject_id
            subjects_of_exam
            sub1
            total1
            sub2
            total2
            sub3
            total3
            sub4
            total4
            sub5
            total5
            sub6
            total6
            sub7
            total7
            sub8
            total8
            sub9
            total9
            sub10
            total10
            sub11
            total11
            sub12
            total12
            sub13
            total13
            sub14
            total14
            sub15
            total15
        }
    }
`;

export const GET_STUDENT_RESULT_1 = gql`
    query StudentResult1($classId: String) {
        student_result_1(where: { class_id: { _eq: $classId } }) {
            subject_id
            result_id
            mark1
            mark2
            mark3
            mark4
            mark5
            mark6
            mark7
            mark8
            mark9
            mark10
            obtained
            total_marks
            grade
            status
            school_student {
                first_name
                middle_name
                last_name
                school_student_class {
                    roll_no
                }
            }
        }
    }
`;

export const GET_STUDENT_RESULT_2 = gql`
    query StudentResult2($classId: String) {
        student_result_2(where: { class_id: { _eq: $classId } }) {
            subject_id
            result_id
            mark1
            mark2
            mark3
            mark4
            mark5
            mark6
            mark7
            mark8
            mark9
            mark10
            obtained
            total_marks
            grade
            status
            school_student {
                first_name
                middle_name
                last_name
                school_student_class {
                    roll_no
                }
            }
        }
    }
`;

export const GET_STUDENT_RESULT_3 = gql`
    query StudentResult3($classId: String) {
        student_result_3(where: { class_id: { _eq: $classId } }) {
            subject_id
            result_id
            mark1
            mark2
            mark3
            mark4
            mark5
            mark6
            mark7
            mark8
            mark9
            mark10
            obtained
            total_marks
            grade
            status
            school_student {
                first_name
                middle_name
                last_name
                school_student_class {
                    roll_no
                }
            }
        }
    }
`;

export const GET_STUDENT_RESULT_4 = gql`
    query StudentResult4($classId: String) {
        student_result_4(where: { class_id: { _eq: $classId } }) {
            subject_id
            result_id
            mark1
            mark2
            mark3
            mark4
            mark5
            mark6
            mark7
            mark8
            mark9
            mark10
            obtained
            total_marks
            grade
            status
            school_student {
                first_name
                middle_name
                last_name
                school_student_class {
                    roll_no
                }
            }
        }
    }
`;

// Updating Result

export const DISABLE_SUB_DD_1 = gql`
    query StudentResult1($classId: String) {
        student_result_1(where: { class_id: { _eq: $classId } }) {
            subject_id
        }
    }
`;

export const DISABLE_SUB_DD_2 = gql`
    query StudentResult2($classId: String) {
        student_result_2(where: { class_id: { _eq: $classId } }) {
            subject_id
        }
    }
`;

export const DISABLE_SUB_DD_3 = gql`
    query StudentResult3($classId: String) {
        student_result_3(where: { class_id: { _eq: $classId } }) {
            subject_id
        }
    }
`;

export const DISABLE_SUB_DD_4 = gql`
    query StudentResult4($classId: String) {
        student_result_4(where: { class_id: { _eq: $classId } }) {
            subject_id
        }
    }
`;

// Student, Teacher And Staff Details Modal

export const GET_STUDENT_DETAILS = gql`
    query StudentIModol($studentId: String!) {
        school_student_classes_by_pk(student_id: $studentId) {
            roll_no
            admission_date
            school_class_detail {
                class_name
                class_section
            }
            school_student {
                birth_date
                first_name
                middle_name
                last_name
                gender
                email_id
                contact_no
                student_photo
                adm_no
            }
        }
    }
`;

export const GET_GAURDIAN_DETAILS = gql`
    query TeacherStudentGuardianDetails($studentId: String) {
        school_students(where: { student_id: { _eq: $studentId } }) {
            student_guardian_details {
                guardian_name
                guardian_contact1
                guardian_contact2
                guardian_email
                guardian_home_address
                guardian_office_address
                relation
            }
        }
    }
`;

export const GET_PARENT_DETAILS = gql`
    query TeacherStudentParentDetails($studentId: String) {
        school_students(where: { student_id: { _eq: $studentId } }) {
            student_father_details {
                father_name
                father_contact1
                father_contact2
            }
            student_mother_details {
                mother_name
                mother_contact1
                mother_contact2
            }
        }
    }
`;

export const GET_TEACHER_DETAILS = gql`
    query TeacherModalOne($teacherId: String!) {
        school_teachers_by_pk(teacher_id: $teacherId) {
            teacher_name
            teaching_subject
            teacher_mob
            teacher_email
            teacher_joindate
            teacher_qualification
            teacher_active
            teacher_photo
        }
    }
`;

export const GET_STAFF_DETAILS = gql`
    query StaffModalOne($staffId: String!) {
        school_staff_by_pk(staff_id: $staffId) {
            staff_name
            staff_role
            staff_mob
            staff_email
            staff_joindate
            staff_qualification
            staff_active
            staff_photo
        }
    }
`;

export const GET_BIZ_CENTER = gql`
    query BizCenter {
        school_info(order_by: { created_at: desc }) {
            gusid
            school_name
            email
            status
            claims
            credits
        }
    }
`;

export const GET_BANK_NAME = gql`
    query BankList {
        banks(order_by: { bname: asc }) {
            id
            bname
        }
    }
`;

export const GET_RASSIGN_URL = gql`
    query RASSIGNURL {
        urls(where: { type: { _eq: "mgmt" } }) {
            url
        }
    }
`;

export const GET_RM_URL = gql`
    query RMURL {
        urls(where: { type: { _eq: "rclaim" } }) {
            url
        }
    }
`;

export const GET_AM_URL = gql`
    query AMURL {
        urls(where: { type: { _eq: "mcccca" } }) {
            url
        }
    }
`;

export const GET_HOME_WORK_LIST = gql`
    query SubjectHomeWork($sid: String) {
        hw(where: { sid: { _eq: $sid } }, order_by: { created_at: desc }) {
            id
            task
            created_at
        }
    }
`;

export const GET_TASK = gql`
    query TaskModal($id: Int!) {
        hw_by_pk(id: $id) {
            task
        }
    }
`;

export const GET_ONLINE_CLASS = gql`
    query SubjectOC($sid: String!) {
        oc_by_pk(sid: $sid) {
            topic
            murl
            time
        }
    }
`;

export const STUDENT_LIST_TAKE_ATTENDANCE = gql`
    query StudentListForAttendance($classId: String) {
        school_student_classes(where: { class_id: { _eq: $classId } }, order_by: { roll_no: asc }) {
            student_id
            roll_no
            school_student {
                first_name
                middle_name
                last_name
            }
        }
    }
`;

export const CLASS_LIST_BY_GROUP = gql`
    query ClassGroup($schoolId: String, $session: String) {
        class_group(where: { school_id: { _eq: $schoolId }, session: { _eq: $session } }, order_by: { created_at: asc }) {
            cg_name
            cg_id
        }
    }
`;

export const CLASS_LIST_BY_CLASS_ROOM = gql`
    query SchoolClassesSummaryOne($cgId: String) {
        school_class_details(where: { cg_id: { _eq: $cgId } }, order_by: { created_at: asc }) {
            class_id
            class_name
            class_section
            session
            class_start_date
            fees
            school_teacher {
                teacher_name
                teacher_id
            }
            stream {
                stream
            }
            school_student_classes_aggregate {
                aggregate {
                    count(columns: class_id)
                }
            }
        }
    }
`;

export const EXAMS_LIST_BY_CLASS_ROOM = gql`
    query TeacherExams($schoolId: String, $session: String, $className: String, $classNameSection: String) {
        school_exams(
            where: {
                school_id: { _eq: $schoolId }
                session: { _eq: $session }
                exam_conducted: { _eq: true }
                _and: {
                    _or: [
                        { exam_for_class: { _eq: "School" } }
                        { exam_for_class: { _eq: $className } }
                        { exam_for_class: { _eq: $classNameSection } }
                    ]
                }
            }
            order_by: { created_at: asc }
        ) {
            created_at
            exam_name
            exam_date
            exam_type
            exam_id
        }
    }
`;

export const EXAM_SUBJECT = gql`
    query ExamSubject($classId: String, $examId: String) {
        exam_subject(where: { class_id: { _eq: $classId }, exam_id: { _eq: $examId } }) {
            result_subject {
                subjects_of_exam
            }
            results_aggregate {
                aggregate {
                    count(columns: exsu_id)
                }
            }
        }
    }
`;

export const EXAM_FINAL_SUBJECT = gql`
    query FinalExamSubject($classId: String, $examId: String) {
        exam_subject(where: { class_id: { _eq: $classId }, exam_id: { _eq: $examId } }) {
            result_subject {
                subjects_of_exam
            }
            final_results_aggregate {
                aggregate {
                    count(columns: exsu_id)
                }
            }
        }
    }
`;

export const ALL_SELECTED_SUBJECT = gql`
    query FinalExamSubject($classId: String) {
        exam_subject(where: { class_id: { _eq: $classId } }) {
            exam_id
            sub_id
            result_subject {
                subjects_of_exam
            }
        }
    }
`;

export const EXAM_SUB_TMARKS = gql`
    query ExamSubject($classId: String, $examId: String) {
        exam_subject(where: { class_id: { _eq: $classId }, exam_id: { _eq: $examId } }) {
            exsu_id
            result_subject {
                sub1
                total1
                sub2
                total2
                sub3
                total3
                sub4
                total4
                sub5
                total5
                sub6
                total6
                sub7
                total7
                sub8
                total8
                sub9
                total9
                sub10
                total10
                sub11
                total11
                sub12
                total12
                sub13
                total13
                sub14
                total14
                sub15
                total15
            }
        }
    }
`;

export const STUDENT_RESULT_LIST = gql`
    query StudentListForResult($exsuId: String) {
        results(where: { exsu_id: { _eq: $exsuId } }) {
            student_id
            result_id
            m1
            m2
            m3
            m4
            m5
            m6
            m7
            m8
            m9
            m10
            m11
            m12
            m13
            m14
            m15
            status
            grade
            rank
            obtained
        }
    }
`;

export const STUDENT_FINAL_RESULT_LIST = gql`
    query StudentListForResult($exsuId: String) {
        final_result(where: { exsu_id: { _eq: $exsuId } }) {
            student_id
            result_id
            m1
            m2
            m3
            m4
            m5
            m6
            m7
            m8
            m9
            m10
            m11
            m12
            m13
            m14
            m15
            status
            grade
            rank
            obtained
        }
    }
`;

export const INVENTORY_LIST = gql`
    query InventoryList($schoolId: String) {
        inventory(where: { school_id: { _eq: $schoolId } }, order_by: { iid: asc }) {
            iid
            product {
                item
                desc
            }
            e_name
            n_desc
            class
            qty
            price
        }
    }
`;

export const CATEGORY_LIST = gql`
    query Category {
        products(order_by: { category: asc }, distinct_on: category) {
            category
        }
    }
`;

export const PRODUCT_LIST = gql`
    query ProductList($category: String) {
        products(where: { category: { _eq: $category } }, order_by: { item: asc }) {
            id
            item
            desc
        }
    }
`;

export const GET_INVENTRY_DESCRIPTION = gql`
    query ItemDesc($iid: Int!) {
        inventory_by_pk(iid: $iid) {
            n_desc
            product {
                desc
            }
        }
    }
`;

export const GET_QUANTITY_HISTORY = gql`
    query ItemStockHistory($iid: Int, $session: String) {
        stock_data(where: { iid: { _eq: $iid }, session: { _eq: $session } }, order_by: { s_id: desc }) {
            qty
            created_at
        }
    }
`;

export const GET_STUDENT_NAME = gql`
    query StudentIModol($studentId: String!) {
        school_student_classes_by_pk(student_id: $studentId) {
            school_student {
                first_name
                middle_name
                last_name
            }
        }
    }
`;

export const ADD_ITEM_PRODUCT_LIST = gql`
    query ItemByCategory($category: String) {
        inventory(where: { product: { category: { _eq: $category } } }) {
            iid
            e_name
            n_desc
            price
            class
            qty
            product {
                item
                desc
            }
        }
    }
`;

export const GET_SALES_LIST = gql`
    query SalesData($schoolId: String!, $gte: String, $lte: String) {
        school_info_by_pk(gusid: $schoolId) {
            inventories {
                iid
                e_name
                n_desc
                product {
                    item
                    desc
                }
                orders_aggregate(where: { pdate: { _gte: $gte, _lte: $lte } }) {
                    aggregate {
                        sum {
                            qty
                            amount
                        }
                    }
                }
            }
        }
    }
`;

export const GET_TOTAL_SALES = gql`
    query ItemSales($iid: Int!, $gte: String, $lte: String) {
        inventory_by_pk(iid: $iid) {
            orders(where: { pdate: { _gte: $gte, _lte: $lte } }) {
                order_id
                pdate
                qty
                amount
                payment
                school_student_class {
                    student_id
                    school_student {
                        first_name
                        middle_name
                        last_name
                    }
                }
            }
        }
    }
`;

export const SCHOOL_MEDIUM = gql`
    query SchoolMedium($schoolId: String!) {
        school_info_by_pk(gusid: $schoolId) {
            school_medium
        }
    }
`;

export const SCHOOL_MEDIUM_BY_STUDENT_ID = gql`
    query SchoolMediumByStudentId($guid: String) {
        school_students(order_by: { created_at: desc }, limit: 1, where: { student_guid: { _eq: $guid } }) {
            school_student_class {
                school_info {
                    school_medium
                }
            }
        }
    }
`;

export const FEE_STATUS_BY_MONTH = {
    jan: gql`
        query JanFeeStatus($studentId: String!) {
            class_fees_by_pk(student_id: $studentId) {
                jan
                school_info {
                    feedue
                }
            }
        }
    `,
    feb: gql`
        query FebFeeStatus($studentId: String!) {
            class_fees_by_pk(student_id: $studentId) {
                feb
                school_info {
                    feedue
                }
            }
        }
    `,
    mar: gql`
        query MarFeeStatus($studentId: String!) {
            class_fees_by_pk(student_id: $studentId) {
                mar
                school_info {
                    feedue
                }
            }
        }
    `,
    apr: gql`
        query AprFeeStatus($studentId: String!) {
            class_fees_by_pk(student_id: $studentId) {
                apr
                school_info {
                    feedue
                }
            }
        }
    `,
    may: gql`
        query MayFeeStatus($studentId: String!) {
            class_fees_by_pk(student_id: $studentId) {
                may
                school_info {
                    feedue
                }
            }
        }
    `,
    jun: gql`
        query JunFeeStatus($studentId: String!) {
            class_fees_by_pk(student_id: $studentId) {
                jun
                school_info {
                    feedue
                }
            }
        }
    `,
    jul: gql`
        query JulFeeStatus($studentId: String!) {
            class_fees_by_pk(student_id: $studentId) {
                jul
                school_info {
                    feedue
                }
            }
        }
    `,
    aug: gql`
        query AugFeeStatus($studentId: String!) {
            class_fees_by_pk(student_id: $studentId) {
                aug
                school_info {
                    feedue
                }
            }
        }
    `,
    sep: gql`
        query SepFeeStatus($studentId: String!) {
            class_fees_by_pk(student_id: $studentId) {
                sep
                school_info {
                    feedue
                }
            }
        }
    `,
    oct: gql`
        query OctFeeStatus($studentId: String!) {
            class_fees_by_pk(student_id: $studentId) {
                oct
                school_info {
                    feedue
                }
            }
        }
    `,
    nov: gql`
        query NovFeeStatus($studentId: String!) {
            class_fees_by_pk(student_id: $studentId) {
                nov
                school_info {
                    feedue
                }
            }
        }
    `,
    dec: gql`
        query DecFeeStatus($studentId: String!) {
            class_fees_by_pk(student_id: $studentId) {
                dec
                school_info {
                    feedue
                }
            }
        }
    `
};

export const PARENT_FEE_STATUS_BY_MONTH = {
    jan: gql`
        query ChildFeesJan($guardianGuid: String!) {
            school_students(where: { guardian_guid: { _eq: $guardianGuid } }, distinct_on: student_guid) {
                first_name
                middle_name
                last_name
                class_fee {
                    jan
                }
                school_info {
                    feedue
                }
            }
        }
    `,
    feb: gql`
        query ChildFeesFeb($guardianGuid: String!) {
            school_students(where: { guardian_guid: { _eq: $guardianGuid } }, distinct_on: student_guid) {
                first_name
                middle_name
                last_name
                class_fee {
                    feb
                }
                school_info {
                    feedue
                }
            }
        }
    `,
    mar: gql`
        query ChildFeesMar($guardianGuid: String!) {
            school_students(where: { guardian_guid: { _eq: $guardianGuid } }, distinct_on: student_guid) {
                first_name
                middle_name
                last_name
                class_fee {
                    mar
                }
                school_info {
                    feedue
                }
            }
        }
    `,
    apr: gql`
        query ChildFeesApr($guardianGuid: String!) {
            school_students(where: { guardian_guid: { _eq: $guardianGuid } }, distinct_on: student_guid) {
                first_name
                middle_name
                last_name
                class_fee {
                    apr
                }
                school_info {
                    feedue
                }
            }
        }
    `,
    may: gql`
        query ChildFeesMay($guardianGuid: String!) {
            school_students(where: { guardian_guid: { _eq: $guardianGuid } }, distinct_on: student_guid) {
                first_name
                middle_name
                last_name
                class_fee {
                    may
                }
                school_info {
                    feedue
                }
            }
        }
    `,
    jun: gql`
        query ChildFeesJun($guardianGuid: String!) {
            school_students(where: { guardian_guid: { _eq: $guardianGuid } }, distinct_on: student_guid) {
                first_name
                middle_name
                last_name
                class_fee {
                    jun
                }
                school_info {
                    feedue
                }
            }
        }
    `,
    jul: gql`
        query ChildFeesJul($guardianGuid: String!) {
            school_students(where: { guardian_guid: { _eq: $guardianGuid } }, distinct_on: student_guid) {
                first_name
                middle_name
                last_name
                class_fee {
                    jul
                }
                school_info {
                    feedue
                }
            }
        }
    `,
    aug: gql`
        query ChildFeesAug($guardianGuid: String!) {
            school_students(where: { guardian_guid: { _eq: $guardianGuid } }, distinct_on: student_guid) {
                first_name
                middle_name
                last_name
                class_fee {
                    aug
                }
                school_info {
                    feedue
                }
            }
        }
    `,
    sep: gql`
        query ChildFeesSep($guardianGuid: String!) {
            school_students(where: { guardian_guid: { _eq: $guardianGuid } }, distinct_on: student_guid) {
                first_name
                middle_name
                last_name
                class_fee {
                    sep
                }
                school_info {
                    feedue
                }
            }
        }
    `,
    oct: gql`
        query ChildFeesOct($guardianGuid: String!) {
            school_students(where: { guardian_guid: { _eq: $guardianGuid } }, distinct_on: student_guid) {
                first_name
                middle_name
                last_name
                class_fee {
                    oct
                }
                school_info {
                    feedue
                }
            }
        }
    `,
    nov: gql`
        query ChildFeesNov($guardianGuid: String!) {
            school_students(where: { guardian_guid: { _eq: $guardianGuid } }, distinct_on: student_guid) {
                first_name
                middle_name
                last_name
                class_fee {
                    nov
                }
                school_info {
                    feedue
                }
            }
        }
    `,
    dec: gql`
        query ChildFeesDec($guardianGuid: String!) {
            school_students(where: { guardian_guid: { _eq: $guardianGuid } }, distinct_on: student_guid) {
                first_name
                middle_name
                last_name
                class_fee {
                    dec
                }
                school_info {
                    feedue
                }
            }
        }
    `
};

export const GET_SCHOOLS_LIST = gql`
    query SchoolsList($userId: String!) {
        multi_branch_by_pk(smuid: $userId) {
            school_infos {
                gusid
                school_name
                adm_credit
                credits
                school_students_aggregate {
                    aggregate {
                        count
                    }
                }
            }
        }
    }
`;
