import { TextField as MUITextField } from '@mui/material';

function TextField({ formik, fieldName, label, rows = false, disabled = false }) {
    const handleEmailValue = (formik, fieldName, e) => {
        if (fieldName.includes('email') || fieldName.includes('Email')) {
            const lowercaseEmail = e.target.value.toLowerCase();
            formik.setFieldValue(fieldName, lowercaseEmail);
        } else {
            formik?.handleChange(e);
        }
    };

    return (
        <MUITextField
            InputLabelProps={{ shrink: !!formik?.values[fieldName] }}
            id={fieldName}
            name={fieldName}
            value={formik?.values[fieldName] ? formik?.values[fieldName] : ''}
            onChange={(e) => handleEmailValue(formik, fieldName, e)}
            error={formik?.touched[fieldName] && Boolean(formik?.errors[fieldName])}
            helperText={formik?.touched[fieldName] && formik?.errors[fieldName]}
            fullWidth
            label={label}
            size="small"
            sx={{ '.MuiInputBase-root .MuiInputBase-inputSizeSmall': { p: '8.5px 14px' } }}
            multiline={!!rows}
            rows={rows || 1}
            disabled={disabled}
        />
    );
}

export default TextField;
