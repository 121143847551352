import MenuIcon from '@mui/icons-material/Menu';
import {
    Box,
    Container,
    Drawer,
    IconButton,
    Link,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Button as MUIButton,
    AppBar as MuiAppBar,
    Stack,
    Toolbar,
    Typography,
    useMediaQuery,
    useScrollTrigger
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { IconCreditCard, IconDashboard, IconHome2 } from '@tabler/icons';
import { DASHBOARD_PATH } from 'config';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Logo from 'assets/images/svg_penboox.svg';
import useAuth from 'hooks/useAuth';
import ProfileSection from 'layout/MainLayout/Header/ProfileSection';
import PropTypes from 'prop-types';
import React from 'react';
import { Campaign, Payments } from '@mui/icons-material';

const Button = styled(MUIButton)(({ theme }) => ({
    fontWeight: '550',
    fontSize: '14px',
    color: '#727c7d'
}));

function ElevationScroll({ children, window }) {
    const theme = useTheme();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window
    });
    const darkBorder = theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.grey[200];

    return React.cloneElement(children, {
        elevation: trigger ? 2 : 0,
        style: {
            backgroundColor: theme.palette.background.default,
            borderBottom: trigger ? 'none' : '1px solid',
            borderColor: trigger ? '' : darkBorder,
            color: theme.palette.text.dark
            // position: 'sticky'
        }
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.node,
    window: PropTypes.object
};

const AppBar = ({ ...others }) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const isDesk = useMediaQuery('(min-width:1279px)');
    const [drawerToggle, setDrawerToggle] = React.useState(false);
    const drawerToggler = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerToggle(open);
    };

    const handleContactUs = () => {
        let targetElement = document.getElementById('contact-us');
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            navigate('/');
            // setTimeout(() => {
            //     targetElement = document.getElementById('contact-us');
            //     if (targetElement) targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            // }, 5);

            const intervalId = setInterval(() => {
                targetElement = document.getElementById('contact-us');
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    clearInterval(intervalId);
                }
            }, 2);
        }
    };

    const handleHome = () => {
        const targetElement = document.getElementById('home');
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            navigate('/');
        }
    };

    return (
        // <Container>
        <ElevationScroll {...others}>
            <MuiAppBar>
                <Container>
                    <Toolbar sx={{ px: '0px !important' }}>
                        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <IconButton color="inherit" onClick={drawerToggler(true)} size="small" sx={{ p: 0, pr: 1 }}>
                                <MenuIcon />
                            </IconButton>
                            <Drawer anchor="left" open={drawerToggle} onClose={drawerToggler(false)}>
                                {drawerToggle && (
                                    <Box
                                        sx={{ width: 'auto' }}
                                        role="presentation"
                                        onClick={drawerToggler(false)}
                                        onKeyDown={drawerToggler(false)}
                                    >
                                        <List>
                                            <ListItemButton component={RouterLink} to="/">
                                                <ListItemIcon>
                                                    <IconHome2 />
                                                </ListItemIcon>
                                                <ListItemText primary="Home" />
                                            </ListItemButton>
                                            <ListItemButton component={RouterLink} to="pricing">
                                                <ListItemIcon>
                                                    <Payments />
                                                </ListItemIcon>
                                                <ListItemText primary="Pricing" />
                                            </ListItemButton>
                                            <ListItemButton onClick={() => handleContactUs()}>
                                                <ListItemIcon>
                                                    <Campaign />
                                                </ListItemIcon>
                                                <ListItemText primary="Contact Us" />
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                )}
                            </Drawer>
                        </Box>
                        <Typography component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
                            {isDesk ? (
                                <Link component={RouterLink} to={DASHBOARD_PATH}>
                                    <img src={Logo} style={{ width: 180 }} alt="Gowboox" />
                                </Link>
                            ) : (
                                <Link component={RouterLink} to={DASHBOARD_PATH}>
                                    <img src={Logo} style={{ width: 124, height: 30, display: 'flex' }} alt="Growboox" />
                                </Link>
                            )}
                        </Typography>
                        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                            {isLoggedIn ? (
                                <Box>
                                    <ProfileSection />
                                </Box>
                            ) : (
                                <Button color="inherit" component={RouterLink} to="login">
                                    Log In
                                </Button>
                            )}
                        </Box>
                        <Stack direction="row" sx={{ display: { xs: 'none', sm: 'flex' } }} spacing={2}>
                            <Button color="inherit" onClick={() => handleHome()}>
                                Home
                            </Button>
                            <Button color="inherit" component={RouterLink} to="pricing">
                                Pricing
                            </Button>
                            <Button color="inherit" onClick={() => handleContactUs()}>
                                Contact Us
                            </Button>
                            {isLoggedIn ? (
                                <Box>
                                    <ProfileSection />
                                </Box>
                            ) : (
                                <Button color="inherit" component={RouterLink} to="login">
                                    Log In
                                </Button>
                            )}
                            {!isLoggedIn ? (
                                <Button
                                    component={RouterLink}
                                    to="register"
                                    disableElevation
                                    variant="contained"
                                    color="secondary"
                                    sx={{
                                        color: 'white'
                                    }}
                                >
                                    Sign Up
                                </Button>
                            ) : null}
                        </Stack>
                    </Toolbar>
                </Container>
            </MuiAppBar>
        </ElevationScroll>
        // </Container>
    );
};

export default AppBar;
