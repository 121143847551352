import useAuth from 'hooks/useAuth';
import { useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import ParentRoutes from './ParentRoutes';
import StudentRoutes from './StudentRoutes';
import TeacherRoutes from './TeacherRoutes';
import ManagmentRoutes from './ManagmentRoutes';
import AnonymousUserRoute from './AnonymousUserRoute';
import SecurityGaurdRoutes from './SecurityGaurdRoutes';
import MainLayout from 'layout/MainLayout';
import Error from 'views/common/Error';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import GuestGuard from 'utils/route-guard/GuestGuard';
import AuthGuard from 'utils/route-guard/AuthGuard';
import AccountantRoutes from './AccountantRoutes';
import ConductorRoutes from './ConductorRoutes';
import ZconRoutes from './ZconRoutes';
import AdministratorRoutes from './AdministratorRoutes';
import Landing from 'views/landing';

const VerifyEmail = Loadable(lazy(() => import('views/authentication/authentication/VerifyEmail')));
const EmailVerfied = Loadable(lazy(() => import('views/authentication/authentication/EmailVerfied')));
const Action = Loadable(lazy(() => import('views/common/Action')));
const AuthLogin = Loadable(lazy(() => import('views/authentication/authentication/Login')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { defaultRole, isLoggedIn, user } = useAuth();
    const anonroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <Landing />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        {
            path: '/verifyemail',
            element: <VerifyEmail />
        },
        {
            path: '/accountverified',
            element: <Action />
        },
        LoginRoutes,
        AnonymousUserRoute
    ]);
    const managmentroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        ManagmentRoutes
    ]);
    const teacherroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        TeacherRoutes
    ]);
    const parentroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        ParentRoutes
    ]);
    const studentroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        StudentRoutes
    ]);
    const securitygaurdroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        SecurityGaurdRoutes
    ]);
    const accountantroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        AccountantRoutes
    ]);
    const conductorroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        ConductorRoutes
    ]);
    const zconroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        ZconRoutes
    ]);
    const administratorroutes = useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <MainLayout />
                </GuestGuard>
            )
        },
        { path: '*', exact: true, element: <Error /> },
        LoginRoutes,
        AdministratorRoutes
    ]);
    if (defaultRole === 'anon' || !user.isEmailVerfied) {
        return anonroutes;
    }
    if (defaultRole === 'managment' && isLoggedIn !== false && user.isEmailVerfied) {
        return managmentroutes;
    }
    if (defaultRole === 'teacher' && isLoggedIn !== false && user.isEmailVerfied) {
        return teacherroutes;
    }
    if (defaultRole === 'parent' && isLoggedIn !== false && user.isEmailVerfied) {
        return parentroutes;
    }
    if (defaultRole === 'student' && isLoggedIn !== false && user.isEmailVerfied) {
        return studentroutes;
    }
    if (defaultRole === 'securitygaurd' && isLoggedIn !== false && user.isEmailVerfied) {
        return securitygaurdroutes;
    }
    if (defaultRole === 'accountant' && isLoggedIn !== false && user.isEmailVerfied) {
        return accountantroutes;
    }
    if (defaultRole === 'conductor' && isLoggedIn !== false && user.isEmailVerfied) {
        return conductorroutes;
    }
    if (defaultRole === 'zcon' && isLoggedIn !== false && user.isEmailVerfied) {
        return zconroutes;
    }
    if (defaultRole === 'administrator' && isLoggedIn !== false && user.isEmailVerfied) {
        return administratorroutes;
    }
    return anonroutes;
}
