import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Parent Imports
const SecurityGaurdHome = Loadable(lazy(() => import('views/security-guard')));
const Account = Loadable(lazy(() => import('views/common/Account')));
const SchoolVisiter = Loadable(lazy(() => import('views/security-guard/SchoolVisiter')));
const StudentVisiter = Loadable(lazy(() => import('views/security-guard/StudentVisiter')));
const NewVisiter = Loadable(lazy(() => import('views/security-guard/SchoolVisiter/NewVisiter')));
const SearchVisiter = Loadable(lazy(() => import('views/security-guard/SchoolVisiter/SearchVisiter')));
const TodaysVisiter = Loadable(lazy(() => import('views/security-guard/SchoolVisiter/TodaysVisiters')));
const StudentTodaysVisitors = Loadable(lazy(() => import('views/security-guard/StudentVisiter/TodaysVisitors')));

// ==============================|| MAIN ROUTING ||============================== //

const SecurityGaurdRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <SecurityGaurdHome />
        },
        {
            path: '/home/school-visiter',
            element: <SchoolVisiter />
        },
        {
            path: '/home/school-visiter/NewVisiter',
            element: <NewVisiter />
        },
        {
            path: '/home/school-visiter/SearchVisiter',
            element: <SearchVisiter />
        },
        {
            path: '/home/school-visiter/TodaysVisiter',
            element: <TodaysVisiter />
        },
        {
            path: '/home/student-visiter',
            element: <StudentVisiter />
        },
        {
            path: '/home/student-visiter/todays-visitors',
            element: <StudentTodaysVisitors />
        },
        {
            path: '/account',
            element: <Account />
        }
    ]
};

export default SecurityGaurdRoutes;
