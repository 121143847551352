/* eslint-disable array-callback-return */
import { capitalize, concat, find, update } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'store';
import { setCSVExportData, setCSVExportHeader } from 'store/slices/commonWP';
import PropTypes from 'prop-types';
import Records from '../Records';
import FeesStatusForm from './FeesStatusForm';
import StudentProfileDialog from '../StudentProfileDialog';
import Invoice from '../Print/Invoice';
import { useLazyQuery, useQuery } from '@apollo/client';
import { SCHOOL_MEDIUM } from 'services/common/query';
import PDDialog from './PDDialog';
import { PAST_DUE_FEES_BY_ID } from 'services/managment/query';

function FeesStatusBySession({
    title = 'Fees Details',
    feeDetailsList = [],
    updateDisabled = false,
    isRollNo = true,
    isCardOnly = false,
    disableExport = false,
    isFormDisabled = false,
    isHideNameRoleNo = false,
    isInvoice = false,
    medium = 'med1',
    isPD = true
}) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [studenProfileOpen, setStudentProfileOpen] = useState(false);
    const [selectedStudentId, setSelectedStudentId] = useState('');
    const [selectedRecord, setSelectedRecord] = useState({});
    const [monthName, setMonthName] = useState('');
    const [isClassFee, setIsClassFee] = useState(false);
    const [openInvoicePrint, setOpenInvoicePrint] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [openPDDialog, setOpenPDDialog] = useState(false);

    const [getPastDueFees] = useLazyQuery(PAST_DUE_FEES_BY_ID);

    const handleClass = (e, id) => {
        if (id) {
            setOpen(true);
            setSelectedRecord(feeDetailsList.filter((item) => item.id === id.rowId)[0]);
            setMonthName(capitalize(id.headId));
            setIsClassFee(!id.transportId);
        } else {
            setOpen(false);
            setIsClassFee(false);
        }
    };

    const handleStudentProfile = (e, id) => {
        setSelectedStudentId(id);
        setStudentProfileOpen(!studenProfileOpen);
    };

    const handlePDFees = (e, id) => {
        if (id || id === 0) {
            const result = feeDetailsList.filter((item) => item.id === id.rowId);
            getPastDueFees({
                variables: {
                    studentId: result[0].id
                },
                fetchPolicy: 'cache-and-network',
                onCompleted: (data) => {
                    const response = data.class_fees_by_pk;
                    if (data.class_fees_by_pk) {
                        setSelectedRecord({
                            id: result[0].id,
                            studentName: result[0].studentName,
                            rollNo: result[0].rollNo,
                            pdFees: response.past_due,
                            updatedBy: response.pdudb,
                            date: response.pdudt
                        });
                        setOpenPDDialog(!openPDDialog);
                    }
                },
                onError: (error) => {
                    // console.log(error);
                }
            });
        } else {
            setSelectedRecord({});
            setOpenPDDialog(!openPDDialog);
        }
    };

    const handlePrintInvoice = (e, id) => {
        if (id) {
            setSelectedId(id.rowId);
            setIsClassFee(!id.transportId);
            setOpenInvoicePrint(true);
        } else {
            setOpenInvoicePrint(false);
            setIsClassFee(false);
        }
    };

    let conditionalHeadCells = [];
    if (isHideNameRoleNo) {
        conditionalHeadCells = [];
    } else if (isRollNo && !isHideNameRoleNo) {
        conditionalHeadCells.push(
            {
                id: 'rollNo',
                label: 'RN',
                align: 'left',
                noOfRow: 1,
                gridSize: 4,
                type: 'text',
                numeric: false,
                alignM: 'left',
                csvId: 'rollNo'
            },
            {
                id: 'studentName',
                label: 'Student Name',
                align: 'left',
                gridSize: 8,
                type: 'text',
                numeric: false,
                alignM: 'right',
                chip: true,
                action: handleStudentProfile,
                csvId: 'studentNameCSVId'
            }
        );
    } else {
        conditionalHeadCells.push(
            {
                id: 'className',
                label: 'CN',
                align: 'left',
                noOfRow: 1,
                gridSize: 4,
                type: 'text',
                numeric: false,
                alignM: 'left',
                csvId: 'className'
            },
            {
                id: 'studentName',
                label: 'Student Name',
                align: 'left',
                gridSize: 8,
                type: 'text',
                numeric: false,
                alignM: 'right',
                chip: true,
                action: handleStudentProfile,
                csvId: 'studentNameCSVId'
            }
        );
    }

    const med1 = [
        {
            id: 'apr',
            label: 'Apr',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'aprCSVId'
        },
        {
            id: 'may',
            label: 'May',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'mayCSVId'
        },
        {
            id: 'jun',
            label: 'Jun',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'junCSVId'
        },
        {
            id: 'jul',
            label: 'Jul',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'julCSVId'
        },
        {
            id: 'aug',
            label: 'Aug',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'augCSVId'
        },
        {
            id: 'sep',
            label: 'Sep',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'sepCSVId'
        },
        {
            id: 'oct',
            label: 'Oct',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'octCSVId'
        },
        {
            id: 'nov',
            label: 'Nov',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'novCSVId'
        },
        {
            id: 'dec',
            label: 'Dec',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'decCSVId'
        },
        {
            id: 'jan',
            label: 'Jan',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'janCSVId'
        },
        {
            id: 'feb',
            label: 'Feb',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'febCSVId'
        },
        {
            id: 'mar',
            label: 'Mar',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'marCSVId'
        }
    ];

    const med2 = [
        {
            id: 'jul',
            label: 'Jul',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { pr: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'julCSVId'
        },
        {
            id: 'aug',
            label: 'Aug',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'augCSVId'
        },
        {
            id: 'sep',
            label: 'Sep',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'sepCSVId'
        },
        {
            id: 'oct',
            label: 'Oct',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'octCSVId'
        },
        {
            id: 'nov',
            label: 'Nov',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'novCSVId'
        },
        {
            id: 'dec',
            label: 'Dec',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { pl: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'decCSVId'
        },
        {
            id: 'jan',
            label: 'Jan',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { pr: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'janCSVId'
        },
        {
            id: 'feb',
            label: 'Feb',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'febCSVId'
        },
        {
            id: 'mar',
            label: 'Mar',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'marCSVId'
        },
        {
            id: 'apr',
            label: 'Apr',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'aprCSVId'
        },
        {
            id: 'may',
            label: 'May',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { px: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'mayCSVId'
        },
        {
            id: 'jun',
            label: 'Jun',
            align: 'center',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { pl: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: 'junCSVId'
        }
    ];

    const definedHeadCells = [
        !isInvoice
            ? {
                  id: 'lastPayment',
                  label: 'Last Payment',
                  align: 'right',
                  gridSize: 6,
                  type: 'text',
                  numeric: false,
                  alignM: 'left',
                  disableMobile: true,
                  csvId: 'lastPaymentCSVId'
              }
            : {
                  id: 'invoice',
                  label: 'Invoice',
                  align: 'center',
                  gridSize: 6,
                  type: 'object',
                  numeric: false,
                  alignM: 'left',
                  sx: { pl: 0.3, pt: 1 },
                  chip: true,
                  action: handlePrintInvoice,
                  csvId: 'decCSVId'
              }
    ];

    if (isPD)
        definedHeadCells.push({
            id: 'pd',
            label: 'PD',
            align: 'center',
            gridSize: 6,
            type: 'object',
            numeric: false,
            alignM: 'right',
            sx: { pl: 0.3, pt: 1 },
            chip: true,
            action: handlePDFees,
            csvId: 'pdId'
        });

    const conditionalDefinedHeadCells = concat(medium === 'med1' ? med1 : med2, definedHeadCells);

    const headCells = concat(conditionalHeadCells, conditionalDefinedHeadCells);

    const handleExportCSV = async (monthName, ref) => {
        const formatedHeadCells = [];
        headCells.map((item) => {
            if (item.csvId) {
                formatedHeadCells.push({
                    label: item.label,
                    key: item.csvId
                });
            }
        });

        await dispatch(setCSVExportHeader(formatedHeadCells));
        await dispatch(setCSVExportData(feeDetailsList));
        ref.current.link.click();
    };

    const getLabel = (fee) => {
        if (fee) return fee;
        return 'Due';
    };

    const getColor = (fee, totalFees) => {
        if (fee >= totalFees) return 'success';
        if (!fee) return 'error';
        return 'warning';
    };

    const handleUpsert = (values) => {
        // console.log('Handle Upsert', values);
        if (values.type === 0) {
            if (values.monthNo === 3) {
                feeDetailsList.find((item) => item.id === values.studentId).apr.label = getLabel(values.amount);
                feeDetailsList.find((item) => item.id === values.studentId).apr.chipColor = getColor(
                    values.amount,
                    find(feeDetailsList, (o) => o.id === values.studentId).classFees
                );
            }
            if (values.monthNo === 4) {
                feeDetailsList.find((item) => item.id === values.studentId).may.label = getLabel(values.amount);
                feeDetailsList.find((item) => item.id === values.studentId).may.chipColor = getColor(
                    values.amount,
                    find(feeDetailsList, (o) => o.id === values.studentId).classFees
                );
            }
            if (values.monthNo === 5) {
                feeDetailsList.find((item) => item.id === values.studentId).jun.label = getLabel(values.amount);
                feeDetailsList.find((item) => item.id === values.studentId).jun.chipColor = getColor(
                    values.amount,
                    find(feeDetailsList, (o) => o.id === values.studentId).classFees
                );
            }
            if (values.monthNo === 6) {
                feeDetailsList.find((item) => item.id === values.studentId).jul.label = getLabel(values.amount);
                feeDetailsList.find((item) => item.id === values.studentId).jul.chipColor = getColor(
                    values.amount,
                    find(feeDetailsList, (o) => o.id === values.studentId).classFees
                );
            }
            if (values.monthNo === 7) {
                feeDetailsList.find((item) => item.id === values.studentId).aug.label = getLabel(values.amount);
                feeDetailsList.find((item) => item.id === values.studentId).aug.chipColor = getColor(
                    values.amount,
                    find(feeDetailsList, (o) => o.id === values.studentId).classFees
                );
            }
            if (values.monthNo === 8) {
                feeDetailsList.find((item) => item.id === values.studentId).sep.label = getLabel(values.amount);
                feeDetailsList.find((item) => item.id === values.studentId).sep.chipColor = getColor(
                    values.amount,
                    find(feeDetailsList, (o) => o.id === values.studentId).classFees
                );
            }
            if (values.monthNo === 9) {
                feeDetailsList.find((item) => item.id === values.studentId).oct.label = getLabel(values.amount);
                feeDetailsList.find((item) => item.id === values.studentId).oct.chipColor = getColor(
                    values.amount,
                    find(feeDetailsList, (o) => o.id === values.studentId).classFees
                );
            }
            if (values.monthNo === 10) {
                feeDetailsList.find((item) => item.id === values.studentId).nov.label = getLabel(values.amount);
                feeDetailsList.find((item) => item.id === values.studentId).nov.chipColor = getColor(
                    values.amount,
                    find(feeDetailsList, (o) => o.id === values.studentId).classFees
                );
            }
            if (values.monthNo === 11) {
                feeDetailsList.find((item) => item.id === values.studentId).dec.label = getLabel(values.amount);
                feeDetailsList.find((item) => item.id === values.studentId).dec.chipColor = getColor(
                    values.amount,
                    find(feeDetailsList, (o) => o.id === values.studentId).classFees
                );
            }
            if (values.monthNo === 0) {
                feeDetailsList.find((item) => item.id === values.studentId).jan.label = getLabel(values.amount);
                feeDetailsList.find((item) => item.id === values.studentId).jan.chipColor = getColor(
                    values.amount,
                    find(feeDetailsList, (o) => o.id === values.studentId).classFees
                );
            }
            if (values.monthNo === 1) {
                feeDetailsList.find((item) => item.id === values.studentId).feb.label = getLabel(values.amount);
                feeDetailsList.find((item) => item.id === values.studentId).feb.chipColor = getColor(
                    values.amount,
                    find(feeDetailsList, (o) => o.id === values.studentId).classFees
                );
            }
            if (values.monthNo === 2) {
                feeDetailsList.find((item) => item.id === values.studentId).mar.label = getLabel(values.amount);
                feeDetailsList.find((item) => item.id === values.studentId).mar.chipColor = getColor(
                    values.amount,
                    find(feeDetailsList, (o) => o.id === values.studentId).classFees
                );
            }
        }
        if (values.type === 1) {
            if (values.monthNo === 3) {
                if (feeDetailsList.find((item) => item.id === values.studentId).apr?.label1) {
                    feeDetailsList.find((item) => item.id === values.studentId).apr.label1 = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).apr.chipColor1 = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                } else {
                    feeDetailsList.find((item) => item.id === values.studentId).apr.label = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).apr.chipColor = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                }
            }
            if (values.monthNo === 4) {
                if (feeDetailsList.find((item) => item.id === values.studentId).may?.label1) {
                    feeDetailsList.find((item) => item.id === values.studentId).may.label1 = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).may.chipColor1 = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                } else {
                    feeDetailsList.find((item) => item.id === values.studentId).may.label = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).may.chipColor = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                }
            }
            if (values.monthNo === 5) {
                if (feeDetailsList.find((item) => item.id === values.studentId).jun?.label1) {
                    feeDetailsList.find((item) => item.id === values.studentId).jun.label1 = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).jun.chipColor1 = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                } else {
                    feeDetailsList.find((item) => item.id === values.studentId).jun.label = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).jun.chipColor = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                }
            }
            if (values.monthNo === 6) {
                if (feeDetailsList.find((item) => item.id === values.studentId).jul?.label1) {
                    feeDetailsList.find((item) => item.id === values.studentId).jul.label1 = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).jul.chipColor1 = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                } else {
                    feeDetailsList.find((item) => item.id === values.studentId).jul.label = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).jul.chipColor = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                }
            }
            if (values.monthNo === 7) {
                if (feeDetailsList.find((item) => item.id === values.studentId).aug?.label1) {
                    feeDetailsList.find((item) => item.id === values.studentId).aug.label1 = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).aug.chipColor1 = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                } else {
                    feeDetailsList.find((item) => item.id === values.studentId).aug.label = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).aug.chipColor = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                }
            }
            if (values.monthNo === 8) {
                if (feeDetailsList.find((item) => item.id === values.studentId).sep?.label1) {
                    feeDetailsList.find((item) => item.id === values.studentId).sep.label1 = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).sep.chipColor1 = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                } else {
                    feeDetailsList.find((item) => item.id === values.studentId).sep.label = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).sep.chipColor = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                }
            }
            if (values.monthNo === 9) {
                if (feeDetailsList.find((item) => item.id === values.studentId).oct?.label1) {
                    feeDetailsList.find((item) => item.id === values.studentId).oct.label1 = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).oct.chipColor1 = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                } else {
                    feeDetailsList.find((item) => item.id === values.studentId).oct.label = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).oct.chipColor = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                }
            }
            if (values.monthNo === 10) {
                if (feeDetailsList.find((item) => item.id === values.studentId).nov?.label1) {
                    feeDetailsList.find((item) => item.id === values.studentId).nov.label1 = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).nov.chipColor1 = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                } else {
                    feeDetailsList.find((item) => item.id === values.studentId).nov.label = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).nov.chipColor = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                }
            }
            if (values.monthNo === 11) {
                if (feeDetailsList.find((item) => item.id === values.studentId).dec?.label1) {
                    feeDetailsList.find((item) => item.id === values.studentId).dec.label1 = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).dec.chipColor1 = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                } else {
                    feeDetailsList.find((item) => item.id === values.studentId).dec.label = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).dec.chipColor = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                }
            }
            if (values.monthNo === 0) {
                if (feeDetailsList.find((item) => item.id === values.studentId).jan?.label1) {
                    feeDetailsList.find((item) => item.id === values.studentId).jan.label1 = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).jan.chipColor1 = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                } else {
                    feeDetailsList.find((item) => item.id === values.studentId).jan.label = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).jan.chipColor = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                }
            }
            if (values.monthNo === 1) {
                if (feeDetailsList.find((item) => item.id === values.studentId).feb?.label1) {
                    feeDetailsList.find((item) => item.id === values.studentId).feb.label1 = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).feb.chipColor1 = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                } else {
                    feeDetailsList.find((item) => item.id === values.studentId).feb.label = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).feb.chipColor = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                }
            }
            if (values.monthNo === 2) {
                if (feeDetailsList.find((item) => item.id === values.studentId).feb?.label1) {
                    feeDetailsList.find((item) => item.id === values.studentId).mar.label1 = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).mar.chipColor1 = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                } else {
                    feeDetailsList.find((item) => item.id === values.studentId).mar.label = getLabel(values.amount);
                    feeDetailsList.find((item) => item.id === values.studentId).mar.chipColor = getColor(
                        values.amount,
                        find(feeDetailsList, (o) => o.id === values.studentId).transportFees
                    );
                }
            }
        }
    };

    const handlePDUpsert = (values) => {
        if (values.pd) {
            feeDetailsList.find((item) => item.id === values.id).pd.label = values.pd;
        }
    };

    return (
        <>
            {openInvoicePrint ? (
                <Invoice
                    open={openInvoicePrint}
                    close={() => {
                        setOpenInvoicePrint(false);
                        setIsClassFee(false);
                    }}
                    isClassFee={isClassFee}
                    id={selectedId}
                />
            ) : null}
            {!updateDisabled && open ? (
                <FeesStatusForm
                    open={open}
                    close={() => setOpen()}
                    monthName={monthName}
                    selectedRecord={selectedRecord}
                    isClassFee={isClassFee}
                    handleFeeUpdate={handleUpsert}
                    isFormDisabled={isFormDisabled}
                />
            ) : null}

            {studenProfileOpen ? (
                <StudentProfileDialog open={studenProfileOpen} close={setStudentProfileOpen} id={selectedStudentId} />
            ) : null}

            {openPDDialog ? (
                <PDDialog
                    open={openPDDialog}
                    close={handlePDFees}
                    selectedRecord={selectedRecord}
                    isFormDisabled={isFormDisabled}
                    handlePDUpsert={handlePDUpsert}
                />
            ) : null}

            <Records
                header
                title={{
                    isTitle: !isCardOnly,
                    position: 1,
                    titleName: title,
                    xs: 8.5,
                    gridSize: { sm: 9 },
                    sx: { pl: 1.5, pt: 1.5 }
                }}
                csvExport={
                    !disableExport
                        ? {
                              isCsvExport: !isCardOnly,
                              gridSize: { sm: 3 },
                              action: (monthName, ref) => handleExportCSV(monthName, ref),
                              xs: 3.5,
                              sx: { pt: 1.5, pr: 1.5, display: 'flex', justifyContent: 'flex-end' },
                              animateSX: { width: '100%' }
                          }
                        : false
                }
                headCells={headCells}
                records={feeDetailsList}
                isCardOnly={isCardOnly}
            />
        </>
    );
}

FeesStatusBySession.propTypes = {
    feeDetailsList: PropTypes.array.isRequired,
    updateDisabled: PropTypes.bool
};

export default FeesStatusBySession;
