import Close from '@mui/icons-material/Close';
import { Dialog, Grid, IconButton, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import SubCard from 'ui-component/cards/SubCard';

function Modal({ children, title, open, close, height = 'auto', width = 'auto' }) {
    const handleClose = () => {
        close();
    };
    return (
        <Dialog
            open={open}
            keepMounted
            onClose={() => handleClose()}
            sx={{
                '& .MuiDialog-paper': {
                    p: 0
                }
            }}
            disableEnforceFocus
        >
            <Grid container direction="column" spacing={gridSpacing}>
                <Grid item xs={12}>
                    <SubCard
                        darkTitle
                        title={title}
                        sx={{
                            overflow: 'auto',
                            p: 0,
                            '.MuiCardHeader-root': {
                                p: 1.5
                            },
                            '.MuiCardContent-root': {
                                p: 1.5,
                                pb: '12px !important'
                            }
                        }}
                        secondary={
                            <IconButton onClick={() => handleClose()} size="small">
                                <Close fontSize="small" />
                            </IconButton>
                        }
                        contentSX={{ height, width: { xs: 'auto', sm: width }, pb: '16px !important' }}
                    >
                        {children}
                    </SubCard>
                </Grid>
            </Grid>
        </Dialog>
    );
}

export default Modal;
