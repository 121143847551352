/* eslint-disable import/prefer-default-export */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    studentDetailsByMN: {}
};

export const securityGuardWPSlice = createSlice({
    name: 'managment',
    initialState,
    reducers: {
        setStudentDetailsByMN: (state, action) => {
            state.studentDetailsByMN = action.payload;
        }
    }
});

export const { setStudentDetailsByMN } = securityGuardWPSlice.actions;

export default securityGuardWPSlice.reducer;
