import { lazy } from 'react';

import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ClassFee from 'views/accountant/FeeDetails/AllStudent/ClassFee';
import EssentialFee from 'views/accountant/FeeDetails/AllStudent/EssentialFees';

// ==============================|| MAIN ROUTING ||============================== //
const AccountantHome = Loadable(lazy(() => import('views/accountant')));
const TutionAndEssen = Loadable(lazy(() => import('views/accountant/TutionAndEssen')));
const TransportFees = Loadable(lazy(() => import('views/accountant/Transport')));
const VehicleStudents = Loadable(lazy(() => import('views/common/VehicleStudents')));
const FeeDetails = Loadable(lazy(() => import('views/accountant/FeeDetails')));
// const FeesDetailsOfAllStudent = Loadable(lazy(() => import('views/common/FeesStatusByClassOld/FeesStatusByMonths')));

const AccountantRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <AccountantHome />
        },
        {
            path: '/tution-transport-fee',
            element: <TutionAndEssen />
        },
        {
            path: '/transport-fee',
            element: <TransportFees />
        },
        {
            path: '/transport-fee/students',
            element: <VehicleStudents />
        },
        {
            path: '/tution-transport-fee/fee-details',
            element: <FeeDetails />
        },
        {
            path: '/tution-transport-fee/fee-details/all-student-class-fee',
            element: <ClassFee />
        },
        {
            path: '/tution-transport-fee/fee-details/all-student-essential-fee',
            element: <EssentialFee />
        }
    ]
};

export default AccountantRoutes;
