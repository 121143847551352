import { useLazyQuery, useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { customAlphabet } from 'nanoid';
import { useState } from 'react';
import {
    UPDATE_TRANSPORT_APRIL_FEE_STATUS,
    UPDATE_TRANSPORT_AUGUST_FEE_STATUS,
    UPDATE_TRANSPORT_DECEMBER_FEE_STATUS,
    UPDATE_TRANSPORT_FEBRUARY_FEE_STATUS,
    UPDATE_TRANSPORT_JANUARY_FEE_STATUS,
    UPDATE_TRANSPORT_JULY_FEE_STATUS,
    UPDATE_TRANSPORT_JUNE_FEE_STATUS,
    UPDATE_TRANSPORT_MARCH_FEE_STATUS,
    UPDATE_TRANSPORT_MAY_FEE_STATUS,
    UPDATE_TRANSPORT_NOVEMBER_FEE_STATUS,
    UPDATE_TRANSPORT_OCTOBER_FEE_STATUS,
    UPDATE_TRANSPORT_SEPTEMBER_FEE_STATUS
} from 'services/managment/mutation';
import {
    GET_TRANSPORT_FEE_APR,
    GET_TRANSPORT_FEE_AUG,
    GET_TRANSPORT_FEE_DEC,
    GET_TRANSPORT_FEE_FEB,
    GET_TRANSPORT_FEE_JAN,
    GET_TRANSPORT_FEE_JUL,
    GET_TRANSPORT_FEE_JUN,
    GET_TRANSPORT_FEE_MAR,
    GET_TRANSPORT_FEE_MAY,
    GET_TRANSPORT_FEE_NOV,
    GET_TRANSPORT_FEE_OCT,
    GET_TRANSPORT_FEE_SEP
} from 'services/managment/query';

function useTPFeeStatusChange() {
    const [updateAprilFeeTPStatus] = useMutation(UPDATE_TRANSPORT_APRIL_FEE_STATUS);
    const [updateMayTPFeeStatus] = useMutation(UPDATE_TRANSPORT_MAY_FEE_STATUS);
    const [updateJuneFeeTPStatus] = useMutation(UPDATE_TRANSPORT_JUNE_FEE_STATUS);
    const [updateJulyTPFeeStatus] = useMutation(UPDATE_TRANSPORT_JULY_FEE_STATUS);
    const [updateAugustTPFeeStatus] = useMutation(UPDATE_TRANSPORT_AUGUST_FEE_STATUS);
    const [updateSeptemberTPFeeStatus] = useMutation(UPDATE_TRANSPORT_SEPTEMBER_FEE_STATUS);
    const [updateOctoberTPFeeStatus] = useMutation(UPDATE_TRANSPORT_OCTOBER_FEE_STATUS);
    const [updateNovemberTPFeeStatus] = useMutation(UPDATE_TRANSPORT_NOVEMBER_FEE_STATUS);
    const [updateDecemberTPFeeStatus] = useMutation(UPDATE_TRANSPORT_DECEMBER_FEE_STATUS);
    const [updateJanuaryTPFeeStatus] = useMutation(UPDATE_TRANSPORT_JANUARY_FEE_STATUS);
    const [updateFebruaryTPFeeStatus] = useMutation(UPDATE_TRANSPORT_FEBRUARY_FEE_STATUS);
    const [updateMarchTPFeeStatus] = useMutation(UPDATE_TRANSPORT_MARCH_FEE_STATUS);

    const [getAprTPFeeDetails] = useLazyQuery(GET_TRANSPORT_FEE_APR);
    const [getMayTPFeeDetails] = useLazyQuery(GET_TRANSPORT_FEE_MAY);
    const [getJunTPFeeDetails] = useLazyQuery(GET_TRANSPORT_FEE_JUN);
    const [getJulTPFeeDetails] = useLazyQuery(GET_TRANSPORT_FEE_JUL);
    const [getAugTPFeeDetails] = useLazyQuery(GET_TRANSPORT_FEE_AUG);
    const [getSepTPFeeDetails] = useLazyQuery(GET_TRANSPORT_FEE_SEP);
    const [getOctTPFeeDetails] = useLazyQuery(GET_TRANSPORT_FEE_OCT);
    const [getNovTPFeeDetails] = useLazyQuery(GET_TRANSPORT_FEE_NOV);
    const [getDecTPFeeDetails] = useLazyQuery(GET_TRANSPORT_FEE_DEC);
    const [getJanTPFeeDetails] = useLazyQuery(GET_TRANSPORT_FEE_JAN);
    const [getFebTPFeeDetails] = useLazyQuery(GET_TRANSPORT_FEE_FEB);
    const [getMarTPFeeDetails] = useLazyQuery(GET_TRANSPORT_FEE_MAR);

    const [responseTPData, setTPResponseData] = useState({});
    const [isTPLoading, setIsTPLoading] = useState(false);
    const [isTPFeeUpdated, setIsTPFeeUpdated] = useState(false);

    const getTPFeeDeatails = async (monthName, studentId) => {
        if (monthName === 'Apr') {
            setIsTPLoading(true);
            setIsTPFeeUpdated(false);
            getAprTPFeeDetails({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setTPResponseData({
                        amount: response.data?.transport_fees_by_pk?.apr,
                        mode: response.data?.transport_fees_by_pk?.aprmode,
                        transactionNo: response.data?.transport_fees_by_pk?.aprtxn,
                        date: response.data?.transport_fees_by_pk?.aprdt,
                        updatedBy: response.data?.transport_fees_by_pk?.aprudb,
                        invoiceNo: response.data?.transport_fees_by_pk?.aprinv
                    });
                    setIsTPLoading(false);
                })
                .catch((error) => {});
        }
        if (monthName === 'May') {
            setIsTPLoading(true);
            setIsTPFeeUpdated(false);
            getMayTPFeeDetails({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setTPResponseData({
                        amount: response.data?.transport_fees_by_pk?.may,
                        mode: response.data?.transport_fees_by_pk?.maymode,
                        transactionNo: response.data?.transport_fees_by_pk?.maytxn,
                        date: response.data?.transport_fees_by_pk?.maydt,
                        updatedBy: response.data?.transport_fees_by_pk?.mayudb,
                        invoiceNo: response.data?.transport_fees_by_pk?.mayinv
                    });
                    setIsTPLoading(false);
                })
                .catch((error) => {});
        }
        if (monthName === 'Jun') {
            setIsTPLoading(true);
            setIsTPFeeUpdated(false);
            getJunTPFeeDetails({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setTPResponseData({
                        amount: response.data?.transport_fees_by_pk?.jun,
                        mode: response.data?.transport_fees_by_pk?.junmode,
                        transactionNo: response.data?.transport_fees_by_pk?.juntxn,
                        date: response.data?.transport_fees_by_pk?.jundt,
                        updatedBy: response.data?.transport_fees_by_pk?.junudb,
                        invoiceNo: response.data?.transport_fees_by_pk?.juninv
                    });
                    setIsTPLoading(false);
                })
                .catch((error) => {});
        }
        if (monthName === 'Jul') {
            setIsTPLoading(true);
            setIsTPFeeUpdated(false);
            getJulTPFeeDetails({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setTPResponseData({
                        amount: response.data?.transport_fees_by_pk?.jul,
                        mode: response.data?.transport_fees_by_pk?.julmode,
                        transactionNo: response.data?.transport_fees_by_pk?.jultxn,
                        date: response.data?.transport_fees_by_pk?.juldt,
                        updatedBy: response.data?.transport_fees_by_pk?.juludb,
                        invoiceNo: response.data?.transport_fees_by_pk?.julinv
                    });
                    setIsTPLoading(false);
                })
                .catch((error) => {});
        }
        if (monthName === 'Aug') {
            setIsTPLoading(true);
            setIsTPFeeUpdated(false);
            getAugTPFeeDetails({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setTPResponseData({
                        amount: response.data?.transport_fees_by_pk?.aug,
                        mode: response.data?.transport_fees_by_pk?.augmode,
                        transactionNo: response.data?.transport_fees_by_pk?.augtxn,
                        date: response.data?.transport_fees_by_pk?.augdt,
                        updatedBy: response.data?.transport_fees_by_pk?.augudb,
                        invoiceNo: response.data?.transport_fees_by_pk?.auginv
                    });
                    setIsTPLoading(false);
                })
                .catch((error) => {});
        }
        if (monthName === 'Sep') {
            setIsTPLoading(true);
            setIsTPFeeUpdated(false);
            getSepTPFeeDetails({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setTPResponseData({
                        amount: response.data?.transport_fees_by_pk?.sep,
                        mode: response.data?.transport_fees_by_pk?.sepmode,
                        transactionNo: response.data?.transport_fees_by_pk?.septxn,
                        date: response.data?.transport_fees_by_pk?.sepdt,
                        updatedBy: response.data?.transport_fees_by_pk?.sepudb,
                        invoiceNo: response.data?.transport_fees_by_pk?.sepinv
                    });
                    setIsTPLoading(false);
                })
                .catch((error) => {});
        }
        if (monthName === 'Oct') {
            setIsTPLoading(true);
            setIsTPFeeUpdated(false);
            getOctTPFeeDetails({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setTPResponseData({
                        amount: response.data?.transport_fees_by_pk?.oct,
                        mode: response.data?.transport_fees_by_pk?.octmode,
                        transactionNo: response.data?.transport_fees_by_pk?.octtxn,
                        date: response.data?.transport_fees_by_pk?.octdt,
                        updatedBy: response.data?.transport_fees_by_pk?.octudb,
                        invoiceNo: response.data?.transport_fees_by_pk?.octinv
                    });
                    setIsTPLoading(false);
                })
                .catch((error) => {});
        }
        if (monthName === 'Nov') {
            setIsTPLoading(true);
            setIsTPFeeUpdated(false);
            getNovTPFeeDetails({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setTPResponseData({
                        amount: response.data?.transport_fees_by_pk?.nov,
                        mode: response.data?.transport_fees_by_pk?.novmode,
                        transactionNo: response.data?.transport_fees_by_pk?.novtxn,
                        date: response.data?.transport_fees_by_pk?.novdt,
                        updatedBy: response.data?.transport_fees_by_pk?.novudb,
                        invoiceNo: response.data?.transport_fees_by_pk?.novinv
                    });
                    setIsTPLoading(false);
                })
                .catch((error) => {});
        }
        if (monthName === 'Dec') {
            setIsTPLoading(true);
            setIsTPFeeUpdated(false);
            getDecTPFeeDetails({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setTPResponseData({
                        amount: response.data?.transport_fees_by_pk?.dec,
                        mode: response.data?.transport_fees_by_pk?.decmode,
                        transactionNo: response.data?.transport_fees_by_pk?.dectxn,
                        date: response.data?.transport_fees_by_pk?.decdt,
                        updatedBy: response.data?.transport_fees_by_pk?.decudb,
                        invoiceNo: response.data?.transport_fees_by_pk?.decinv
                    });
                    setIsTPLoading(false);
                })
                .catch((error) => {});
        }
        if (monthName === 'Jan') {
            setIsTPLoading(true);
            setIsTPFeeUpdated(false);

            getJanTPFeeDetails({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setTPResponseData({
                        amount: response.data?.transport_fees_by_pk?.jan,
                        mode: response.data?.transport_fees_by_pk?.janmode,
                        transactionNo: response.data?.transport_fees_by_pk?.jantxn,
                        date: response.data?.transport_fees_by_pk?.jandt,
                        updatedBy: response.data?.transport_fees_by_pk?.janudb,
                        invoiceNo: response.data?.transport_fees_by_pk?.janinv
                    });
                    setIsTPLoading(false);
                })
                .catch((error) => {});
        }
        if (monthName === 'Feb') {
            setIsTPLoading(true);
            setIsTPFeeUpdated(false);
            getFebTPFeeDetails({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setTPResponseData({
                        amount: response.data?.transport_fees_by_pk?.feb,
                        mode: response.data?.transport_fees_by_pk?.febmode,
                        transactionNo: response.data?.transport_fees_by_pk?.febtxn,
                        date: response.data?.transport_fees_by_pk?.febdt,
                        updatedBy: response.data?.transport_fees_by_pk?.febudb,
                        invoiceNo: response.data?.transport_fees_by_pk?.febinv
                    });
                    setIsTPLoading(false);
                })
                .catch((error) => {});
        }
        if (monthName === 'Mar') {
            setIsTPLoading(true);
            setIsTPFeeUpdated(false);
            getMarTPFeeDetails({
                variables: {
                    studentId
                },
                refetch: true,
                fetchPolicy: 'cache-and-network'
            })
                .then((response) => {
                    setTPResponseData({
                        amount: response.data?.transport_fees_by_pk?.mar,
                        mode: response.data?.transport_fees_by_pk?.marmode,
                        transactionNo: response.data?.transport_fees_by_pk?.martxn,
                        date: response.data?.transport_fees_by_pk?.mardt,
                        updatedBy: response.data?.transport_fees_by_pk?.marudb,
                        invoiceNo: response.data?.transport_fees_by_pk?.marinv
                    });
                    setIsTPLoading(false);
                })
                .catch((error) => {});
        }
    };

    const updateTPFeeDetails = async (monthName, studentId, className, rollNo, updatedBy, values, handleFeeUpdate) => {
        const invoiceNo = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 9);
        if (monthName === 'Apr') {
            updateAprilFeeTPStatus({
                variables: {
                    studentId,
                    apr: values.amount,
                    aprmode: values.mode,
                    aprtxn: values.transactionNo,
                    aprdt: format(new Date(values.date), 'yyyy-MM-dd'),
                    aprudb: updatedBy,
                    aprinv: `TRN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        monthNo: 3,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_transport_fees_by_pk?.aprinv
                    });
                    setIsTPFeeUpdated(true);
                }
            });
        }
        if (monthName === 'May') {
            updateMayTPFeeStatus({
                variables: {
                    studentId,
                    may: values.amount,
                    maymode: values.mode,
                    maytxn: values.transactionNo,
                    maydt: format(new Date(values.date), 'yyyy-MM-dd'),
                    mayudb: updatedBy,
                    mayinv: `TRN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        monthNo: 4,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_transport_fees_by_pk?.mayinv
                    });
                    setIsTPFeeUpdated(true);
                }
            });
        }
        if (monthName === 'Jun') {
            updateJuneFeeTPStatus({
                variables: {
                    studentId,
                    jun: values.amount,
                    junmode: values.mode,
                    juntxn: values.transactionNo,
                    jundt: format(new Date(values.date), 'yyyy-MM-dd'),
                    junudb: updatedBy,
                    juninv: `TRN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        monthNo: 5,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_transport_fees_by_pk?.juninv
                    });
                    setIsTPFeeUpdated(true);
                }
            });
        }
        if (monthName === 'Jul') {
            updateJulyTPFeeStatus({
                variables: {
                    studentId,
                    jul: values.amount,
                    julmode: values.mode,
                    jultxn: values.transactionNo,
                    juldt: format(new Date(values.date), 'yyyy-MM-dd'),
                    juludb: updatedBy,
                    julinv: `TRN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        monthNo: 6,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_transport_fees_by_pk?.julinv
                    });
                    setIsTPFeeUpdated(true);
                }
            });
        }
        if (monthName === 'Aug') {
            updateAugustTPFeeStatus({
                variables: {
                    studentId,
                    aug: values.amount,
                    augmode: values.mode,
                    augtxn: values.transactionNo,
                    augdt: format(new Date(values.date), 'yyyy-MM-dd'),
                    augudb: updatedBy,
                    auginv: `TRN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        monthNo: 7,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_transport_fees_by_pk?.auginv
                    });
                    setIsTPFeeUpdated(true);
                }
            });
        }
        if (monthName === 'Sep') {
            updateSeptemberTPFeeStatus({
                variables: {
                    studentId,
                    sep: values.amount,
                    sepmode: values.mode,
                    septxn: values.transactionNo,
                    sepdt: format(new Date(values.date), 'yyyy-MM-dd'),
                    sepudb: updatedBy,
                    sepinv: `TRN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        monthNo: 8,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_transport_fees_by_pk?.sepinv
                    });
                    setIsTPFeeUpdated(true);
                }
            });
        }
        if (monthName === 'Oct') {
            updateOctoberTPFeeStatus({
                variables: {
                    studentId,
                    oct: values.amount,
                    octmode: values.mode,
                    octtxn: values.transactionNo,
                    octdt: format(new Date(values.date), 'yyyy-MM-dd'),
                    octudb: updatedBy,
                    octinv: `TRN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        monthNo: 9,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_transport_fees_by_pk?.octinv
                    });
                    setIsTPFeeUpdated(true);
                }
            });
        }
        if (monthName === 'Nov') {
            updateNovemberTPFeeStatus({
                variables: {
                    studentId,
                    nov: values.amount,
                    novmode: values.mode,
                    novtxn: values.transactionNo,
                    novdt: format(new Date(values.date), 'yyyy-MM-dd'),
                    novudb: updatedBy,
                    novinv: `TRN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        monthNo: 10,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_transport_fees_by_pk?.novinv
                    });
                    setIsTPFeeUpdated(true);
                }
            });
        }
        if (monthName === 'Dec') {
            updateDecemberTPFeeStatus({
                variables: {
                    studentId,
                    dec: values.amount,
                    decmode: values.mode,
                    dectxn: values.transactionNo,
                    decdt: format(new Date(values.date), 'yyyy-MM-dd'),
                    decudb: updatedBy,
                    decinv: `TRN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        monthNo: 11,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_transport_fees_by_pk?.decinv
                    });
                    setIsTPFeeUpdated(true);
                }
            });
        }
        if (monthName === 'Jan') {
            updateJanuaryTPFeeStatus({
                variables: {
                    studentId,
                    jan: values.amount,
                    janmode: values.mode,
                    jantxn: values.transactionNo,
                    jandt: format(new Date(values.date), 'yyyy-MM-dd'),
                    janudb: updatedBy,
                    janinv: `TRN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        monthNo: 0,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_transport_fees_by_pk?.janinv
                    });
                    setIsTPFeeUpdated(true);
                }
            });
        }
        if (monthName === 'Feb') {
            updateFebruaryTPFeeStatus({
                variables: {
                    studentId,
                    feb: values.amount,
                    febmode: values.mode,
                    febtxn: values.transactionNo,
                    febdt: format(new Date(values.date), 'yyyy-MM-dd'),
                    febudb: updatedBy,
                    febinv: `TRN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        monthNo: 1,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_transport_fees_by_pk?.febinv
                    });
                    setIsTPFeeUpdated(true);
                }
            });
        }
        if (monthName === 'Mar') {
            updateMarchTPFeeStatus({
                variables: {
                    studentId,
                    mar: values.amount,
                    marmode: values.mode,
                    martxn: values.transactionNo,
                    mardt: format(new Date(values.date), 'yyyy-MM-dd'),
                    marudb: updatedBy,
                    marinv: `TRN${invoiceNo()}`
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: values.amount,
                        monthNo: 2,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: values.amount,
                        mode: values.mode,
                        transactionNo: values.transactionNo,
                        date: format(new Date(values.date), 'yyyy-MM-dd'),
                        updatedBy,
                        invoiceNo: data?.update_transport_fees_by_pk?.marinv
                    });
                    setIsTPFeeUpdated(true);
                }
            });
        }
    };

    const handleTPDueFees = async (monthName, studentId, handleFeeUpdate) => {
        if (monthName === 'Apr') {
            updateAprilFeeTPStatus({
                variables: {
                    studentId,
                    apr: null,
                    aprmode: null,
                    aprtxn: null,
                    aprdt: null,
                    aprudb: null,
                    aprinv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        monthNo: 3,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (monthName === 'May') {
            updateMayTPFeeStatus({
                variables: {
                    studentId,
                    may: null,
                    maymode: null,
                    maytxn: null,
                    maydt: null,
                    mayudb: null,
                    mayinv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        monthNo: 4,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (monthName === 'Jun') {
            updateJuneFeeTPStatus({
                variables: {
                    studentId,
                    jun: null,
                    junmode: null,
                    juntxn: null,
                    jundt: null,
                    junudb: null,
                    juninv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        monthNo: 5,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (monthName === 'Jul') {
            await updateJulyTPFeeStatus({
                variables: {
                    studentId,
                    jul: null,
                    julmode: null,
                    jultxn: null,
                    juldt: null,
                    juludb: null,
                    julinv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        monthNo: 6,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (monthName === 'Aug') {
            await updateAugustTPFeeStatus({
                variables: {
                    studentId,
                    aug: null,
                    augmode: null,
                    augtxn: null,
                    augdt: null,
                    augudb: null,
                    auginv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        monthNo: 7,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (monthName === 'Sep') {
            await updateSeptemberTPFeeStatus({
                variables: {
                    studentId,
                    sep: null,
                    sepmode: null,
                    septxn: null,
                    sepdt: null,
                    sepudb: null,
                    sepinv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        monthNo: 8,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (monthName === 'Oct') {
            await updateOctoberTPFeeStatus({
                variables: {
                    studentId,
                    oct: null,
                    octmode: null,
                    octtxn: null,
                    octdt: null,
                    octudb: null,
                    octinv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        monthNo: 9,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (monthName === 'Nov') {
            await updateNovemberTPFeeStatus({
                variables: {
                    studentId,
                    nov: null,
                    novmode: null,
                    novtxn: null,
                    novdt: null,
                    novudb: null,
                    novinv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        monthNo: 10,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (monthName === 'Dec') {
            await updateDecemberTPFeeStatus({
                variables: {
                    studentId,
                    dec: null,
                    decmode: null,
                    dectxn: null,
                    decdt: null,
                    decudb: null,
                    decinv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        monthNo: 11,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (monthName === 'Jan') {
            await updateJanuaryTPFeeStatus({
                variables: {
                    studentId,
                    jan: null,
                    janmode: null,
                    jantxn: null,
                    jandt: null,
                    janudb: null,
                    janinv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        monthNo: 0,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (monthName === 'Feb') {
            await updateFebruaryTPFeeStatus({
                variables: {
                    studentId,
                    feb: null,
                    febmode: null,
                    febtxn: null,
                    febdt: null,
                    febudb: null,
                    febinv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        monthNo: 1,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
        if (monthName === 'Mar') {
            await updateMarchTPFeeStatus({
                variables: {
                    studentId,
                    mar: null,
                    marmode: null,
                    martxn: null,
                    mardt: null,
                    marudb: null,
                    marinv: null
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'no-cache',
                onCompleted: (data) => {
                    handleFeeUpdate({
                        type: 1,
                        amount: null,
                        monthNo: 2,
                        studentId: data?.update_transport_fees_by_pk?.student_id
                    });
                    setTPResponseData({
                        amount: null,
                        mode: null,
                        transactionNo: null,
                        date: null,
                        updatedBy: null,
                        invoiceNo: null
                    });
                }
            });
        }
    };

    return [getTPFeeDeatails, updateTPFeeDetails, handleTPDueFees, responseTPData, isTPLoading, isTPFeeUpdated];
}

export default useTPFeeStatusChange;
