import { DEFAULT_ROLE, FIREBASE_STATE_CHANGED, LOGIN, LOGOUT, REGISTER, ID_TOKEN } from './actions';
import storage from 'redux-persist/lib/storage';

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    defaultRole: 'anon',
    idToken: ''
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER: {
            const { user } = action.payload;
            return {
                ...state,
                user
            };
        }
        case LOGIN: {
            const { user } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null,
                defaultRole: 'anon'
            };
        }
        case FIREBASE_STATE_CHANGED: {
            return {
                ...state
            };
        }
        case DEFAULT_ROLE: {
            const { role } = action.payload;
            return {
                ...state,
                defaultRole: role
            };
        }
        case ID_TOKEN: {
            const { idToken } = action.payload;
            return {
                ...state,
                idToken
            };
        }
        default: {
            return { ...state };
        }
    }
};

const appReducer = (state, action) => {
    if (action.type === LOGOUT) {
        localStorage.setItem('selctedRole', '');
        storage.removeItem(`pbx-authentication`);
        storage.removeItem(`pbx-student`);
        storage.removeItem(`pbx-parent`);
        storage.removeItem(`pbx-teacher`);
        storage.removeItem(`pbx-common`);
        storage.removeItem(`pbx-profile`);
        storage.removeItem(`pbx-managment`);
        state = {};
    }
    return accountReducer(state, action);
};

export default appReducer;
