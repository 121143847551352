/* eslint-disable array-callback-return */
import { ApolloClient } from '@apollo/client';
import {
    eachWeekendOfMonth,
    eachWeekendOfYear,
    format,
    getDate,
    getDaysInMonth,
    getDaysInYear,
    getMonth,
    getYear,
    isSunday
} from 'date-fns';
import { capitalize, ceil, floor, inRange, join, map, omit, words } from 'lodash';
import { customAlphabet } from 'nanoid';
import { cache } from '../cache';
import Compressor from 'compressorjs';

const day = 1;
/* eslint-disable import/prefer-default-export */
export const sundaysInMonth = (date) => {
    const sundaysOfTheMonth = [];
    const weekendOfTheMonth = eachWeekendOfMonth(date);
    weekendOfTheMonth.map((item) => {
        if (isSunday(item)) {
            const dayResult = getDate(item);
            sundaysOfTheMonth.push(dayResult);
        }
    });
    return sundaysOfTheMonth;
};

export const sundaysInYear = (date) => {
    const sundaysOfTheYear = [];
    const weekendOfTheYear = eachWeekendOfYear(date);
    weekendOfTheYear.map((item) => {
        if (isSunday(item)) {
            const dayResult = getDate(item);
            sundaysOfTheYear.push(dayResult);
        }
    });
    return sundaysOfTheYear;
};

export const getPercentageByInMonth = (classStartDate, holidays, totalPresent, selectedMonthSundays) => {
    const year = getYear(new Date(classStartDate));
    const month = getMonth(new Date());
    const noOfSunday = selectedMonthSundays || sundaysInMonth(new Date(year, month, day)).length;
    const daysInMonth = getDaysInMonth(new Date());
    const noOfWorkingDays = daysInMonth - (noOfSunday + holidays.length);
    const percentage = ceil((totalPresent / noOfWorkingDays) * 100, 2);
    return percentage;
};

export const getPercentageInYear = (classStartDate, holidays, totalPresent) => {
    const daysInYear = getDaysInYear(new Date(classStartDate));
    const year = getYear(new Date(classStartDate));
    const month = getMonth(new Date());
    const noOfSundays = sundaysInYear(new Date(year, month, day)).length;
    const noOfHolidays = holidays;
    const noOfWorkdays = daysInYear - (noOfSundays + noOfHolidays);
    const percentage = floor((totalPresent / noOfWorkdays) * 100, 2);
    return percentage;
};

export const getAbsentCountAndPercentageOfYear = (classStartDate, holidays, totalPresent) => {
    const daysInYear = getDaysInYear(new Date(classStartDate));
    const year = getYear(new Date(classStartDate));
    const month = getMonth(new Date());
    const noOfSundays = sundaysInYear(new Date(year, month, day)).length;
    const noOfHolidays = holidays;
    const noOfWorkdays = daysInYear - (noOfSundays + noOfHolidays);
    const totalAbsent = noOfWorkdays - totalPresent;
    const percentage = ceil((totalAbsent / noOfWorkdays) * 100, 2);
    return { totalAbsent, percentage };
};

export const getCurrentSession = () => {
    const dateToCheck = new Date();
    const startDate = new Date(dateToCheck.getFullYear(), 3, 1);
    const endDate = new Date(dateToCheck.getFullYear() + 1, 11, 31);

    if (inRange(dateToCheck, startDate, endDate)) {
        const year = getYear(new Date());
        const yearPlusOne = String(year + 1);
        const removedLastTwoDigit = yearPlusOne.substr(yearPlusOne.length - 2);
        return `${year}-${removedLastTwoDigit}`;
    }
    const year = getYear(new Date()) - 1;
    const yearPlusOne = String(year + 1);
    const removedLastTwoDigit = yearPlusOne.substr(yearPlusOne.length - 2);
    return `${year}-${removedLastTwoDigit}`;
};

export const getFireabaseErrorMsg = (error) => {
    const removedFirebase = error.replace('Firebase:', '');
    const removedAuthText = removedFirebase.replace(/ *\([^)]*\) */g, '');
    const removedDotFromLast = removedAuthText.replace(/ *\([^)]*\) */g, '').substring(0, removedAuthText.length - 1);
    return removedDotFromLast;
};

export const getRoleName = (defaultRole) => {
    let role;

    if (defaultRole === 'student') {
        role = 'pstp';
    } else if (defaultRole === 'parent') {
        role = 'pgnf';
    } else if (defaultRole === 'teacher') {
        role = 'ptrf';
    } else if (defaultRole === 'managment') {
        role = 'pmtf';
    } else if (defaultRole === 'securitygaurd') {
        role = 'psgf';
    } else if (defaultRole === 'conductor') {
        role = 'pcrf';
    } else if (defaultRole === 'accountant') {
        role = 'pacsf';
    } else if (defaultRole === 'zcon') {
        role = 'zesconta';
    } else if (defaultRole === 'administrator') {
        role = 'psamtf';
    }

    return role;
};

export const client = new ApolloClient({ cache });

export const fullName = (first, middle, last) => {
    let fName;
    if (first && middle && last) {
        fName = `${first} ${middle} ${last}`;
    } else if (first && middle) {
        fName = `${first} ${middle}`;
    } else if (first && last) {
        fName = `${first} ${last}`;
    } else if (first) {
        fName = first;
    } else {
        fName = '';
    }

    return fName;
};

function createFeeData(rollNo, studentName, monthlyFeesStatus, monthlyTPFeesStatus, studentId, transportId, lastPaymentDate) {
    return {
        rollNo,
        studentName,
        monthlyFeesStatus,
        monthlyTPFeesStatus,
        studentId,
        transportId,
        lastPaymentDate
    };
}

export const getLabel = (fee) => {
    if (fee) return fee;
    return 'Due';
};

export const getColor = (fee, totalFees) => {
    if (fee >= totalFees && totalFees) {
        return 'success';
    }
    if (!fee) {
        return 'error';
    }
    return 'warning';
};

// export const formatClassFee = (classFee, fees) => {
//     const apr = { monthName: 'April', feeStatus: getLabel(classFee?.apr), chipColor: getColor(classFee?.apr, fees) };
//     const may = classFee?.may ? { monthName: 'May', feeStatus: classFee?.may } : { monthName: 'May', feeStatus: 'Due' };
//     const jun = classFee?.jun ? { monthName: 'June', feeStatus: classFee?.jun } : { monthName: 'June', feeStatus: 'Due' };
//     const jul = classFee?.jul ? { monthName: 'July', feeStatus: classFee?.jul } : { monthName: 'July', feeStatus: 'Due' };
//     const aug = classFee?.aug ? { monthName: 'August', feeStatus: classFee?.aug } : { monthName: 'August', feeStatus: 'Due' };
//     const sep = classFee?.sep ? { monthName: 'September', feeStatus: classFee?.sep } : { monthName: 'September', feeStatus: 'Due' };
//     const oct = classFee?.oct ? { monthName: 'October', feeStatus: classFee?.oct } : { monthName: 'October', feeStatus: 'Due' };
//     const nov = classFee?.nov ? { monthName: 'November', feeStatus: classFee?.nov } : { monthName: 'November', feeStatus: 'Due' };
//     const dec = classFee?.dec ? { monthName: 'December', feeStatus: classFee?.dec } : { monthName: 'December', feeStatus: 'Due' };
//     const jan = classFee?.jan ? { monthName: 'January', feeStatus: classFee?.jan } : { monthName: 'January', feeStatus: 'Due' };
//     const feb = classFee?.feb ? { monthName: 'February', feeStatus: classFee?.feb } : { monthName: 'February', feeStatus: 'Due' };
//     const mar = classFee?.mar ? { monthName: 'March', feeStatus: classFee?.mar } : { monthName: 'March', feeStatus: 'Due' };
//     return [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec];
// };
export const formatMonthlyFee = (classFee, fees) => {
    const apr = { monthName: 'April', feeStatus: getLabel(classFee?.apr), chipColor: getColor(classFee?.apr, fees) };
    const may = { monthName: 'May', feeStatus: getLabel(classFee?.may), chipColor: getColor(classFee.may, fees) };
    const jun = { monthName: 'June', feeStatus: getLabel(classFee?.jun), chipColor: getColor(classFee.jun, fees) };
    const jul = { monthName: 'July', feeStatus: getLabel(classFee?.jul), chipColor: getColor(classFee.jul, fees) };
    const aug = { monthName: 'August', feeStatus: getLabel(classFee?.aug), chipColor: getColor(classFee.aug, fees) };
    const sep = { monthName: 'September', feeStatus: getLabel(classFee?.sep), chipColor: getColor(classFee.sep, fees) };
    const oct = { monthName: 'October', feeStatus: getLabel(classFee?.oct), chipColor: getColor(classFee.oct, fees) };
    const nov = { monthName: 'November', feeStatus: getLabel(classFee?.nov), chipColor: getColor(classFee.nov, fees) };
    const dec = { monthName: 'December', feeStatus: getLabel(classFee?.dec), chipColor: getColor(classFee.dec, fees) };
    const jan = { monthName: 'January', feeStatus: getLabel(classFee?.jan), chipColor: getColor(classFee.jan, fees) };
    const feb = { monthName: 'February', feeStatus: getLabel(classFee?.feb), chipColor: getColor(classFee.feb, fees) };
    const mar = { monthName: 'March', feeStatus: getLabel(classFee?.mar), chipColor: getColor(classFee.mar, fees) };
    return [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec];
};

const formatTransportFee = (trnasportFee) => {};

export const formatFeeDetails = (reacord, studentId = false) => {
    const sessionFeeListLc = [];
    reacord.forEach((item) => {
        const firstName = item?.school_student?.first_name ? item.school_student.first_name : '';
        const middleName = item?.school_student?.middle_name ? item.school_student.middle_name : '';
        const lastName = item?.school_student?.last_name ? item.school_student.last_name : '';
        const lastPaymentDate = item?.school_student?.last_name ? item.class_fee.update_at : '';
        const apr =
            studentId || item?.class_fee?.apr
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'April', feeStatus: studentId ? (item.apr ? item.apr : 'Due') : item?.class_fee?.apr }
                : { monthName: 'April', feeStatus: 'Due' };
        const aprTP = item?.transport_fee?.apr
            ? { monthName: 'April', feeStatus: item?.transport_fee?.apr }
            : { monthName: 'April', feeStatus: 'Due' };
        const may =
            studentId || item?.class_fee?.may
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'May', feeStatus: studentId ? (item.may ? item.may : 'Due') : item?.class_fee?.may }
                : { monthName: 'May', feeStatus: 'Due' };
        const mayTP = item?.transport_fee?.may
            ? { monthName: 'May', feeStatus: item?.transport_fee?.may }
            : { monthName: 'May', feeStatus: 'Due' };
        const jun =
            studentId || item?.class_fee?.jun
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'June', feeStatus: studentId ? (item.jun ? item.jun : 'Due') : item?.class_fee?.jun }
                : { monthName: 'June', feeStatus: 'Due' };
        const junTP = item?.transport_fee?.jun
            ? { monthName: 'June', feeStatus: item?.transport_fee?.jun }
            : { monthName: 'June', feeStatus: 'Due' };
        const jul =
            studentId || item?.class_fee?.jul
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'July', feeStatus: studentId ? (item.jul ? item.jul : 'Due') : item?.class_fee?.jul }
                : { monthName: 'July', feeStatus: 'Due' };
        const julTP = item?.transport_fee?.jul
            ? { monthName: 'July', feeStatus: item?.transport_fee?.jul }
            : { monthName: 'July', feeStatus: 'Due' };
        const aug =
            studentId || item?.class_fee?.aug
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'August', feeStatus: studentId ? (item.aug ? item.aug : 'Due') : item?.class_fee?.aug }
                : { monthName: 'August', feeStatus: 'Due' };
        const augTP = item?.transport_fee?.aug
            ? { monthName: 'August', feeStatus: item?.transport_fee?.aug }
            : { monthName: 'August', feeStatus: 'Due' };
        const sep =
            studentId || item?.class_fee?.sep
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'September', feeStatus: studentId ? (item.sep ? item.sep : 'Due') : item?.class_fee?.sep }
                : { monthName: 'September', feeStatus: 'Due' };
        const sepTP = item?.transport_fee?.sep
            ? { monthName: 'September', feeStatus: item?.transport_fee?.sep }
            : { monthName: 'September', feeStatus: 'Due' };
        const oct =
            studentId || item?.class_fee?.oct
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'October', feeStatus: studentId ? (item.oct ? item.oct : 'Due') : item?.class_fee?.oct }
                : { monthName: 'October', feeStatus: 'Due' };
        const octTP = item?.transport_fee?.oct
            ? { monthName: 'October', feeStatus: item?.transport_fee?.oct }
            : { monthName: 'October', feeStatus: 'Due' };
        const nov =
            studentId || item?.class_fee?.nov
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'November', feeStatus: studentId ? (item.nov ? item.nov : 'Due') : item?.class_fee?.nov }
                : { monthName: 'November', feeStatus: 'Due' };
        const novTP = item?.transport_fee?.nov
            ? { monthName: 'November', feeStatus: item?.transport_fee?.nov }
            : { monthName: 'November', feeStatus: 'Due' };
        const dec =
            studentId || item?.class_fee?.dec
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'December', feeStatus: studentId ? (item.dec ? item.dec : 'Due') : item?.class_fee?.dec }
                : { monthName: 'December', feeStatus: 'Due' };
        const decTP = item?.transport_fee?.dec
            ? { monthName: 'December', feeStatus: item?.transport_fee?.dec }
            : { monthName: 'December', feeStatus: 'Due' };
        const jan =
            studentId || item?.class_fee?.jan
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'January', feeStatus: studentId ? (item.jan ? item.jan : 'Due') : item?.class_fee?.jan }
                : { monthName: 'January', feeStatus: 'Due' };
        const janTP = item?.transport_fee?.jan
            ? { monthName: 'January', feeStatus: item?.transport_fee?.jan }
            : { monthName: 'January', feeStatus: 'Due' };
        const feb =
            studentId || item?.class_fee?.feb
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'February', feeStatus: studentId ? (item.feb ? item.feb : 'Due') : item?.class_fee?.feb }
                : { monthName: 'February', feeStatus: 'Due' };
        const febTP = item?.transport_fee?.feb
            ? { monthName: 'February', feeStatus: item?.transport_fee?.feb }
            : { monthName: 'February', feeStatus: 'Due' };
        const mar =
            studentId || item?.class_fee?.mar
                ? // eslint-disable-next-line no-nested-ternary
                  { monthName: 'March', feeStatus: studentId ? (item.mar ? item.mar : 'Due') : item?.class_fee?.mar }
                : { monthName: 'March', feeStatus: 'Due' };
        const marTP = item?.transport_fee?.mar
            ? { monthName: 'March', feeStatus: item?.transport_fee?.mar }
            : { monthName: 'March', feeStatus: 'Due' };

        sessionFeeListLc.push(
            createFeeData(
                item.roll_no,
                fullName(firstName, middleName, lastName),
                [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec],
                [janTP, febTP, marTP, aprTP, mayTP, junTP, julTP, augTP, sepTP, octTP, novTP, decTP],
                studentId || item.student_id,
                item.transport_id,
                lastPaymentDate
            )
        );
    });

    return sessionFeeListLc;
};

export const getGeneratedId = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 21);

export const getFormatedDate = (date) => {
    if (date) return format(new Date(date), 'dd/MM/yyyy');
    return '';
};

export const getFormatedDateTime = (date) => {
    if (date) return format(new Date(date), 'dd/MM/yyyy p');
    return '';
};

export const getClassNamePlusSection = (className, section) => {
    if (section) return `${className} - ${section}`;
    return className;
};

export const getPercentage = (obtained, total) => {
    const percentage = (obtained / total) * 100;
    const ceildData = percentage ? `${ceil(percentage, 2)} %` : '';
    return ceildData || '';
};

export const removeHyphensAndCapitalize = (str) => {
    const wordsLc = words(str, /[^-]+/g);
    const capitalizedWords = map(wordsLc, capitalize);
    return join(capitalizedWords, ' ');
};

export const compressImage = (file) =>
    new Promise((resolve, reject) => {
        // eslint-disable-next-line no-new
        new Compressor(file, {
            quality: 0.6,
            mimeType: 'image/jpeg',
            success(result) {
                resolve(result);
            },
            error(err) {
                reject(err);
            }
        });
    });

export const convertToWords = (number) => {
    const units = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const tens = ['ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    const teens = ['eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const scales = ['thousand', 'lakh', 'crore'];

    function capitalizeWords(words) {
        return map(words.split(' '), capitalize).join(' ');
    }

    function convertThreeDigitNumber(num) {
        let result = '';
        const hundred = Math.floor(num / 100);
        const remainder = num % 100;

        if (hundred > 0) {
            result += `${units[hundred]} hundred `;
        }

        if (remainder > 0) {
            if (remainder < 10) {
                result += units[remainder];
            } else if (remainder < 20) {
                result += teens[remainder - 11];
            } else {
                const ten = Math.floor(remainder / 10);
                const unit = remainder % 10;
                result += tens[ten - 1];

                if (unit > 0) {
                    result += ` ${units[unit]}`;
                }
            }
        }

        return result.trim();
    }

    if (number === 0) {
        return units[0];
    }

    const isNegative = number < 0;
    number = Math.abs(number);
    let result = '';

    // eslint-disable-next-line no-plusplus
    for (let i = 0; number > 0; i++) {
        const remainder = number % 1000;
        number = Math.floor(number / 1000);

        if (remainder > 0) {
            const words = convertThreeDigitNumber(remainder);

            if (i > 0) {
                result = `${words} ${scales[i - 1]} ${result}`;
            } else {
                result = words;
            }
        }
    }

    if (isNegative) {
        result = capitalizeWords(`negative ${result}`);
    } else {
        result = capitalizeWords(result);
    }

    return result.trim();
};

export const getAttendanceCountAndArray = (attendance, noDaysOfInCurrentMonth) => {
    const attenArray = [];
    let totalP = 0;
    let totalA = 0;
    let totalH = 0;
    let totalL = 0;
    const sanetizedAttendance = omit(attendance, '__typename');
    if (sanetizedAttendance)
        Object.keys(sanetizedAttendance).forEach((key, index) => {
            if (index < noDaysOfInCurrentMonth) {
                if (sanetizedAttendance[key] === 'P') {
                    attenArray.push('P');
                    totalP += 1;
                } else if (sanetizedAttendance[key] === 'A') {
                    attenArray.push('A');
                    totalA += 1;
                } else if (sanetizedAttendance[key] === 'H') {
                    attenArray.push('H');
                    totalH += 1;
                } else if (sanetizedAttendance[key] === 'L') {
                    attenArray.push('L');
                    totalL += 1;
                } else {
                    attenArray.push('NA');
                }
            }
        });
    return { attenArray, totalP, totalA, totalH, totalL };
};

export const getMonthNo = (monthName) => {
    if (monthName === 'jan') return 0;
    if (monthName === 'feb') return 1;
    if (monthName === 'mar') return 2;
    if (monthName === 'apr') return 3;
    if (monthName === 'may') return 4;
    if (monthName === 'jun') return 5;
    if (monthName === 'jul') return 6;
    if (monthName === 'aug') return 7;
    if (monthName === 'sep') return 8;
    if (monthName === 'oct') return 9;
    if (monthName === 'nov') return 10;
    if (monthName === 'dec') return 11;
    return null;
};

export const getMonthDays = (month, selectedYear) => {
    const currentYear = getYear(new Date());
    const year = selectedYear || currentYear;
    const days = getDaysInMonth(new Date(year, month));
    if (month === 0) return days;
    if (month === 1) return days;
    if (month === 2) return days;
    if (month === 3) return days;
    if (month === 4) return days;
    if (month === 5) return days;
    if (month === 6) return days;
    if (month === 7) return days;
    if (month === 8) return days;
    if (month === 9) return days;
    if (month === 10) return days;
    if (month === 11) return days;
    return days;
};

export const containsEnglish = (inputString) => {
    const regex = /english/i;
    return regex.test(inputString);
};
