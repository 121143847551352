/* eslint-disable react/jsx-fragments */
/* eslint-disable no-else-return */
import { DirectionsBus, KeyboardArrowDown, KeyboardArrowUp, OpenInNew, Print, Upload, Visibility } from '@mui/icons-material';
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    IconButton,
    LinearProgress,
    Stack,
    Switch,
    TableBody as TableBodyMUI,
    TableCell,
    TableRow,
    Typography,
    useTheme
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { IconTrash } from '@tabler/icons';
import { Fragment, useState } from 'react';
import { useSelector } from 'store';
import SubCard from 'ui-component/cards/SubCard';
import Chip from 'ui-component/extended/Chip';
import ChipWH from 'ui-component/extended/ChipWH';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }

    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const getJustifyContent = (align) => {
    if (align === 'center') {
        return 'center';
    }
    if (align === 'right') {
        return 'flex-end';
    }
    return 'flex-start';
};

const BorderLinearProgress = withStyles(() => ({
    root: {
        height: 10,
        borderRadius: 5
    },
    bar: {
        borderRadius: 5
    }
}))(LinearProgress);

function TableBody({ headCells, order, orderBy, filteredTableData = [], handleRemove, isLoading = false }) {
    const [noticeId, setNoticeId] = useState('');
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const { page, rowsPerPage } = useSelector((state) => state.common);

    const onchange = (e) => {
        if (noticeId === e) {
            setOpen(!open);
        } else {
            setOpen(true);
        }
        setNoticeId(e);
    };

    return (
        <TableBodyMUI>
            {rowsPerPage && filteredTableData?.length > 0
                ? //  stableSort(filteredTableData, getComparator(order, orderBy))
                  //       .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  filteredTableData.map((row, index) => (
                      <Fragment key={`table-body-${row.id}-${index}`}>
                          <TableRow hover>
                              {headCells.map((item, itemIndex) => (
                                  <TableCell
                                      key={`table-body-${item.id}-${itemIndex}`}
                                      size="small"
                                      align={item.align}
                                      sx={{
                                          justifyContent: item.type === 'switch' ? 'center' : 'flex-start',
                                          wordBreak: 'break-all',
                                          whiteSpace: 'nowrap'
                                      }}
                                  >
                                      {item.chip && item.type === 'object' && row[item.id]?.label ? (
                                          <Stack>
                                              <Chip
                                                  chipcolor={row[item.id]?.chipColor ? row[item.id].chipColor : 'primary'}
                                                  sx={{
                                                      height: 24,
                                                      borderRadius: 1,
                                                      maxWidth: item.width ? item.width : 'auto'
                                                      // maxWidth: 50,
                                                      // whiteSpace: 'nowrap',
                                                      // overflow: 'hidden',
                                                      // textOverflow: 'ellipsis'
                                                  }}
                                                  label={row[item.id]?.label}
                                                  size="small"
                                                  onClick={
                                                      item.action
                                                          ? (e) =>
                                                                item.action(e, {
                                                                    rowId: row.id,
                                                                    headId: item.id,
                                                                    transportId: row.transportId && row.rowType === 'transportFees',
                                                                    headLabel: item.label,
                                                                    fees: item.fees
                                                                })
                                                          : null
                                                  }
                                              />
                                              {row.nowOfRow === 2 && row[item.id]?.label1 ? (
                                                  <Chip
                                                      chipcolor={row[item.id]?.chipColor1 ? row[item.id].chipColor1 : 'primary'}
                                                      sx={{
                                                          height: 24,
                                                          borderRadius: 1,
                                                          maxWidth: item.width ? item.width : 'auto',
                                                          // maxWidth: 50,
                                                          // whiteSpace: 'nowrap',
                                                          // overflow: 'hidden',
                                                          // textOverflow: 'ellipsis'
                                                          mt: 0.75
                                                      }}
                                                      label={row[item.id]?.label1}
                                                      size="small"
                                                      onClick={
                                                          item.action
                                                              ? (e) =>
                                                                    item.action(e, {
                                                                        rowId: row.id,
                                                                        headId: item.id,
                                                                        transportId: row.transportId,
                                                                        headLabel: item.label,
                                                                        fees: item.fees
                                                                    })
                                                              : null
                                                      }
                                                  />
                                              ) : null}
                                          </Stack>
                                      ) : null}
                                      {item.chip && item.type !== 'object' && (row[item.id] || item.cellLabel) ? (
                                          <Stack direction="row" alignItems="center" justifyContent={getJustifyContent(item.align)}>
                                              <Chip
                                                  chipcolor={item.chipColor ? item.chipColor : 'primary'}
                                                  sx={{
                                                      height: 24,
                                                      borderRadius: 1,
                                                      maxWidth: item.width ? item.width : 'auto'
                                                      // maxWidth: 50,
                                                      // whiteSpace: 'nowrap',
                                                      // overflow: 'hidden',
                                                      // textOverflow: 'ellipsis'
                                                  }}
                                                  label={item.type === 'button' ? item.cellLabel : row[item.id]}
                                                  size="small"
                                                  onClick={item.action ? (e) => item.action(e, row.id) : null}
                                              />
                                              {row.transportId ? <DirectionsBus /> : null}
                                          </Stack>
                                      ) : null}
                                      {item.chipWH && row[item.id] ? (
                                          <ChipWH
                                              chipcolor={item.chipColor ? item.chipColor : 'primary'}
                                              sx={{
                                                  height: 24,
                                                  borderRadius: 1,
                                                  maxWidth: item.width ? item.width : 'auto'
                                                  // maxWidth: 50,
                                                  // whiteSpace: 'nowrap',
                                                  // overflow: 'hidden',
                                                  // textOverflow: 'ellipsis'
                                              }}
                                              label={row[item.id]}
                                              size="small"
                                          />
                                      ) : null}

                                      {!item.chipWH &&
                                      !item.chip &&
                                      item.type !== 'print' &&
                                      item.type !== 'progress' &&
                                      item.type !== 'link' &&
                                      item.type !== 'object' &&
                                      item.type !== 'switch' &&
                                      item.type !== 'removeBtn' ? (
                                          //   <Tooltip title={row[item.id] ? row[item.id] : ''} arrow>
                                          <Stack alignItems={item.align} justifyContent={getJustifyContent(item.align)}>
                                              <Typography
                                                  sx={{
                                                      maxWidth: item.width ? item.width : 'auto',
                                                      whiteSpace: 'nowrap',
                                                      overflow: 'hidden',
                                                      textOverflow: 'ellipsis'
                                                  }}
                                                  variant="subtitle1"
                                              >
                                                  {row[item.id]}
                                              </Typography>
                                              {item.noOfRow !== 1 && row.transportId ? (
                                                  <Typography
                                                      sx={{
                                                          maxWidth: item.width ? item.width : 'auto',
                                                          whiteSpace: 'nowrap',
                                                          overflow: 'hidden',
                                                          textOverflow: 'ellipsis'
                                                      }}
                                                      variant="subtitle1"
                                                  >
                                                      {row.tLastPayment}
                                                  </Typography>
                                              ) : null}
                                          </Stack>
                                      ) : //   </Tooltip>
                                      null}

                                      {item.type === 'switch' ? (
                                          <Switch
                                              size="small"
                                              checked={row[item.id] ? row[item.id] : false}
                                              onClick={item.action ? (e) => item.action(e, row.id) : null}
                                          />
                                      ) : null}

                                      {item.type === 'removeBtn' ? (
                                          <IconButton
                                              aria-label="expand row"
                                              size="small"
                                              onClick={item.action ? (e) => item.action(e, row.id) : () => handleRemove(row.id)}
                                              sx={{ p: 0.25 }}
                                          >
                                              {/* <Delete color="error" /> */}
                                              <IconTrash color="red" size="18px" />
                                          </IconButton>
                                      ) : null}

                                      {item.type === 'accordian' ? (
                                          <IconButton aria-label="expand row" size="small" id={row.id} onClick={() => onchange(row.id)}>
                                              {open && noticeId === row.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                          </IconButton>
                                      ) : null}

                                      {item.type === 'progress' ? (
                                          <BorderLinearProgress variant="determinate" color="success" value={row[item.id]} />
                                      ) : null}
                                      {item.type === 'link' && row[item.id] ? (
                                          <IconButton
                                              size="small"
                                              aria-label="Open in new tab"
                                              component="a"
                                              href={row[item.id]}
                                              target="_blank"
                                              color="primary"
                                          >
                                              <OpenInNew />
                                          </IconButton>
                                      ) : null}

                                      {item.type === 'upload' ? (
                                          <ButtonGroup disableElevation variant="outlined" size="small">
                                              <Button onClick={item.action ? () => item.action(0, row.id) : null}>
                                                  <Upload sx={{ fontSize: '16px !important' }} />
                                              </Button>
                                              <Button onClick={item.action ? () => item.action(1, row.id) : null}>
                                                  <Visibility sx={{ fontSize: '16px !important' }} />
                                              </Button>
                                          </ButtonGroup>
                                      ) : null}

                                      {item.type === 'print' ? (
                                          <IconButton
                                              aria-label="expand row"
                                              size="small"
                                              onClick={item.action ? (e) => item.action(e, row.id) : null}
                                              sx={{ p: 0.25 }}
                                          >
                                              <Print size="18px" />
                                          </IconButton>
                                      ) : null}
                                  </TableCell>
                              ))}
                          </TableRow>
                          {open ? (
                              <TableRow>
                                  <TableCell
                                      colSpan={headCells.length}
                                      in={open}
                                      timeout="auto"
                                      unmountOnExit
                                      style={{ paddingBottom: 0, paddingTop: 0 }}
                                      className={open && noticeId === row.id ? null : 'pbx-annoucement-tablecell-btt'}
                                  >
                                      {open && noticeId === row.id && (
                                          <Box sx={{ m: 1 }}>
                                              <SubCard
                                                  sx={{
                                                      bgcolor: theme.palette.mode === 'dark' ? 'dark.800' : 'grey.50'
                                                  }}
                                                  title={row.level}
                                                  content={false}
                                              >
                                                  {row.arrordData}
                                              </SubCard>
                                          </Box>
                                      )}
                                  </TableCell>
                              </TableRow>
                          ) : null}
                      </Fragment>
                  ))
                : null}

            {rowsPerPage && filteredTableData?.length === 0 && !isLoading ? (
                <TableRow>
                    <TableCell variant="body" className="pbx-annoucement-empty-msg" colSpan={7} align="center">
                        No results found!
                    </TableCell>
                </TableRow>
            ) : null}

            {isLoading ? (
                <TableRow>
                    <TableCell variant="body" className="pbx-annoucement-empty-msg" colSpan={7} align="center">
                        <CircularProgress />
                    </TableCell>
                </TableRow>
            ) : null}
        </TableBodyMUI>
    );
}

export default TableBody;
