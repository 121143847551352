/* eslint-disable array-callback-return */
import { isEmpty } from 'lodash';
import { useState } from 'react';
import EssentialFeeByClass from 'views/common/EssentialFeeByClass';
import StudentProfileDialog from 'views/common/StudentProfileDialog';

function EssentialFees({ title, essentialFeeList = [], headersList = [] }) {
    const [open, setOpen] = useState(false);
    const [studenProfileOpen, setStudentProfileOpen] = useState(false);
    const [selectedStudentId, setSelectedStudentId] = useState('');
    const [selectedRecord, setSelectedRecord] = useState({});
    const [selectedBtnName, setSelectedBtnName] = useState('');
    const [label, setLabel] = useState('');
    const [fees, setFees] = useState(0);

    const handleClass = (e, id) => {
        if (id) {
            setSelectedRecord(essentialFeeList.filter((item) => item.id === id.rowId)[0]);
            setSelectedBtnName(id.headId);
            setLabel(id.headLabel);
            setFees(id.fees);
        }
        setOpen(!open);
    };
    const handleStudentProfile = (e, id) => {
        setSelectedStudentId(id);
        setStudentProfileOpen(!studenProfileOpen);
    };

    const headers = [
        {
            id: 'rollNo',
            label: 'RN',
            align: 'left',
            noOfRow: 1,
            gridSize: 4,
            type: 'text',
            numeric: false,
            alignM: 'left',
            csvId: 'rollNo'
        },
        {
            id: 'studentName',
            label: 'Student Name',
            align: 'left',
            gridSize: 8,
            type: 'text',
            numeric: false,
            alignM: 'right',
            chip: true,
            action: handleStudentProfile,
            csvId: 'studentNameCSVId'
        },
        {
            id: 'adm',
            label: 'Admission',
            align: 'center',
            width: '120px',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { pr: 0.3, pt: 1 },
            chip: true,
            csvId: 'admission',
            action: handleClass,
            fees: 1
        }
    ];

    headersList.map((item) =>
        headers.push({
            id: item.id,
            label: item.label,
            align: 'center',
            width: '120px',
            gridSize: 2,
            type: 'object',
            numeric: false,
            alignM: 'left',
            sx: { pr: 0.3, pt: 1 },
            chip: true,
            action: handleClass,
            csvId: item.label,
            fees: item.fees
        })
    );

    return (
        <>
            {studenProfileOpen ? (
                <StudentProfileDialog open={studenProfileOpen} close={setStudentProfileOpen} id={selectedStudentId} />
            ) : null}
            {!isEmpty(headersList) ? (
                <EssentialFeeByClass
                    title={title}
                    open={open}
                    setOpen={setOpen}
                    essentialFeeList={essentialFeeList}
                    selectedStudentId={selectedStudentId}
                    selectedRecord={selectedRecord}
                    selectedBtnName={selectedBtnName}
                    headersList={headers}
                    label={label}
                    fees={fees}
                />
            ) : null}
        </>
    );
}

export default EssentialFees;
