import { FormattedMessage } from 'react-intl';

import { HomeOutlined, AssignmentOutlined } from '@mui/icons-material';

const icons = {
    HomeOutlined,
    AssignmentOutlined
};

const zcon = {
    id: 'zcon',
    type: 'group',
    children: [
        {
            id: 'bizcenter',
            title: <FormattedMessage id="bizcenter" />,
            type: 'item',
            url: '/home',
            icon: icons.HomeOutlined,
            breadcrumbs: false
        },
        {
            id: 'roleassign',
            title: <FormattedMessage id="roleassign" />,
            type: 'item',
            url: '/role-Assign',
            icon: icons.AssignmentOutlined,
            breadcrumbs: false
        }
    ]
};

export default zcon;
