import { AddCircleOutlineTwoTone, FileDownload } from '@mui/icons-material';
import { Button as ButtonMUI } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';

function Button({ width, handleNewModal, btnName, animateSX }) {
    return (
        <AnimateButton sx={animateSX}>
            <ButtonMUI
                sx={{
                    width: { width },
                    p: '6px 12px'
                }}
                variant="contained"
                startIcon={btnName === 'Export' ? <FileDownload /> : null}
                onClick={() => handleNewModal()}
                type="submit"
            >
                {btnName}
            </ButtonMUI>
        </AnimateButton>
    );
}

export default Button;
