/* eslint-disable array-callback-return */
import PropTypes from 'prop-types';
import React from 'react';
import useConfig from 'hooks/useConfig';
import SubCard from 'ui-component/cards/SubCard';
import { useTheme } from '@mui/material/styles';
import { Box, CardContent, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';

import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        p: 0
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`
    };
}

const titleList = [
    {
        id: 0,
        title: 'User Profile',
        // SubTitle: 'Profile Settings',
        icon: <PersonOutlineTwoToneIcon />
    },
    {
        id: 1,
        title: 'Billing',
        SubTitle: 'Billing Information',
        icon: <DescriptionTwoToneIcon />
    },
    {
        id: 2,
        title: 'Payment',
        // SubTitle: 'Add & Update Card',
        icon: <CreditCardTwoToneIcon />
    },
    {
        id: 3,
        title: 'Change Password',
        SubTitle: 'Update Profile Security',
        icon: <VpnKeyTwoToneIcon />
    }
];

const contentList = [
    {
        id: 0,
        content: (
            <Stack spacing={gridSpacing}>
                <Typography variant="body2">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                </Typography>
                <Typography>
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                </Typography>
            </Stack>
        )
    },
    {
        id: 1,
        content: (
            <Stack spacing={gridSpacing}>
                <Typography variant="body2">
                    3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                </Typography>
                <Typography>
                    Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil
                    anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
                    vice lomo.
                </Typography>
            </Stack>
        )
    },
    {
        id: 2,
        content: (
            <Stack spacing={gridSpacing}>
                <Typography variant="body2">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                </Typography>
                <Typography>
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                </Typography>
            </Stack>
        )
    },
    {
        id: 3,
        content: (
            <Stack spacing={gridSpacing}>
                <Typography variant="body2">
                    3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                </Typography>
                <Typography>
                    Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil
                    anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
                    vice lomo.
                </Typography>
            </Stack>
        )
    }
];

export default function VerticalTab({ title = false, titleList, contentList }) {
    const theme = useTheme();
    const { borderRadius } = useConfig();

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <SubCard darkTitle title={title || false} contentSX={{ p: '0px !important', pb: '0px !important' }}>
            <Grid container>
                <Grid item xs={12} sm={4} md={3}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        orientation="vertical"
                        variant="scrollable"
                        sx={{
                            '& .MuiTabs-flexContainer': {
                                borderBottom: 'none'
                            },
                            '& button': {
                                borderRadius: `${borderRadius}px`,
                                color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[600],
                                minHeight: 'auto',
                                minWidth: '100%',
                                py: 1.2,
                                px: 1.2,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                textAlign: 'left',
                                justifyContent: 'flex-start'
                            },
                            '& button.Mui-selected': {
                                color: theme.palette.primary.main,
                                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50]
                            },
                            '& button > div > span': {
                                display: 'block'
                            },
                            '& > div > span': {
                                display: 'none'
                            },
                            padding: '12px',
                            pb: '12px !important'
                        }}
                    >
                        {titleList.map((item) => (
                            <Tab
                                key={item.id}
                                sx={{
                                    '.MuiSvgIcon-root': {
                                        marginBottom: '0px !important',
                                        marginRight: 1.25,
                                        marginTop: item.SubTitle ? 1.25 : 0.25,
                                        height: 20,
                                        width: 20
                                    }
                                }}
                                iconPosition="top"
                                icon={item.icon}
                                label={
                                    <Grid container direction="column">
                                        <Typography variant="subtitle1" color="inherit">
                                            {item.title}
                                        </Typography>
                                        {item.SubTitle ? (
                                            <Typography component="div" variant="caption" sx={{ textTransform: 'capitalize' }}>
                                                {item.SubTitle}
                                            </Typography>
                                        ) : null}
                                    </Grid>
                                }
                                {...a11yProps(item.id)}
                            />
                        ))}
                    </Tabs>
                </Grid>
                <Grid item xs={12} sm={8} md={9} sx={{ pl: 0, pt: 0 }}>
                    <CardContent
                        sx={{
                            borderLeft: '1px solid',
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
                            height: '100%',
                            padding: '12px',
                            pb: '12px !important'
                        }}
                    >
                        {contentList.map((item) => (
                            <TabPanel key={item.id} value={value} index={item.id}>
                                {item.isSubCard ? (
                                    <SubCard contentSX={{ p: 1.5, pb: '12px !important' }}>{item.content}</SubCard>
                                ) : (
                                    item.content
                                )}
                            </TabPanel>
                        ))}
                    </CardContent>
                </Grid>
            </Grid>
        </SubCard>
    );
}
