import { sumBy } from 'lodash';
import { useEffect } from 'react';
import { convertToWords } from 'utils/common';
import DefaultImage from '../../../../img/default.png';
import './invoice001.css';

const cellHeader = ['Fees Type', 'Invoice No', 'Date', 'Method', 'Amount', 'Paid'];

const Invoice001 = ({ schoolInfo, studentInfo, fees, isClassFee }) => {
    useEffect(() => {
        function adjustZoom() {
            const documentWidth = window.screen.width;
            const documentHeight = window.screen.height;
            const zoomWidth = documentWidth / (23 * 37.795276);
            const zoomHeight = documentHeight / (31.7 * 37.795276);
            const zoomLevel = Math.max(zoomWidth, zoomHeight);
            if (zoomLevel >= 1) return;
            document.getElementById('wrapper-testing').style.transform = `scale(${zoomLevel})`;
        }

        adjustZoom();
        window.addEventListener('resize', adjustZoom);
    }, []);

    return (
        <div id="wrapper-testing">
            <div className="invoice001-container" style={{ backgroundColor: '#ffffff' }}>
                <div className="invoice001-page invoice001-border">
                    <div className="invoice001-header invoice001-border">
                        <p
                            className="invoice001-header-title"
                            style={{
                                fontWeight: 'bold',
                                fontSize: `18px`,
                                color: '#000000'
                            }}
                        >
                            {isClassFee ? `(Invoice Student Copy)` : `(Invoice Transport Copy)`}
                        </p>

                        <hr className="invoice001-header-divider" />

                        <div className="invoice001-header-content">
                            <input
                                type="image"
                                src={schoolInfo?.logo ? schoolInfo.logo : DefaultImage}
                                alt="logo"
                                className="invoice001-header-content-logo"
                            />
                            <div className="invoice001-header-content-right">
                                {/* <h3 className="invoice001-header-content-right-1 invoice001-text-color">Invoice No # 6056</h3> */}
                                <h4 className="invoice001-header-content-right-2 invoice001-text-color">
                                    Date : {fees[fees.length - 1]?.date}
                                </h4>
                                <h4 className="invoice001-header-content-right-3 invoice001-text-color">Status: PAID</h4>
                            </div>
                        </div>
                    </div>

                    <div className="invoice001-content-1">
                        <div className="invoice001-content-1-left">
                            <h3 className="invoice001-content-1-header">Invoice To :</h3>
                            <p className="invoice001-content-1-lineheight">{studentInfo?.name}</p>
                            {studentInfo?.address ? <p className="invoice001-content-1-address">{studentInfo?.address}</p> : null}
                            <p className="invoice001-content-1-lineheight">Class : {studentInfo?.class}</p>
                            <p className="invoice001-content-1-lineheight">Section : {studentInfo?.section}</p>
                            <p className="invoice001-content-1-lineheight">ID No : {studentInfo?.id_no}</p>
                        </div>
                        <div className="invoice001-content-1-right">
                            <h3 className="invoice001-content-1-header">Academic :</h3>
                            <p className="invoice001-content-1-lineheight">{schoolInfo?.name}</p>
                            {schoolInfo?.address ? <p className="invoice001-content-1-address">{schoolInfo?.address}</p> : null}
                            <p className="invoice001-content-1-lineheight">{schoolInfo?.email}</p>
                            <p className="invoice001-content-1-lineheight">{schoolInfo?.contact}</p>
                        </div>
                    </div>
                    <div className="invoice001-content-2">
                        <table className="invoice001-table">
                            <thead>
                                <tr>
                                    {cellHeader.map((item2) => (
                                        <th className="invoice001-table-header">{item2}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {fees.map((item) => (
                                    <tr>
                                        <td className="invoice001-table-body">{item.feetype}</td>
                                        <td className="invoice001-table-body">{item.invoice_no}</td>
                                        <td className="invoice001-table-body">{item.date}</td>
                                        <td className="invoice001-table-body">{item.method}</td>
                                        <td className="invoice001-table-body">₹{item.amount}</td>
                                        <td className="invoice001-table-body">₹{item.paid}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="invoice001-footer">
                        <div>
                            <h3 className="invoice001-footer-heading" style={{ color: '#000000', marginBottom: '36px' }}>
                                Collected By{' '}
                            </h3>

                            {/* <h4 className="invoice001-footer-text" style={{ color: '#000' }}>
                                Mr. MUKAND KUMAR MISHRA
                            </h4> */}
                        </div>
                        <div className="invoice001-footer-right">
                            <h3 className="invoice001-footer-heading" style={{ color: '#000000' }}>
                                Grand Total : ₹{sumBy(fees, 'amount')}
                            </h3>
                            <h4 className="invoice001-footer-text">Total Paid : ₹{sumBy(fees, 'paid')}</h4>
                            <h4 className="invoice001-footer-text">({convertToWords(sumBy(fees, 'paid'))})</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Invoice001;
