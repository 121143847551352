import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const Home = Loadable(lazy(() => import('views/managment/Home')));
const Info = Loadable(lazy(() => import('views/managment/Info/index')));
const Teachers = Loadable(lazy(() => import('views/managment/Teachers')));
const Exams = Loadable(lazy(() => import('views/managment/Exams')));

// Result
const Results = Loadable(lazy(() => import('views/managment/Results')));
const ResultsByCRoom = Loadable(lazy(() => import('views/common/Results')));
const ResultsByExam = Loadable(lazy(() => import('views/common/Results/ResultsByExam')));

const Parents = Loadable(lazy(() => import('views/managment/Parents')));
const Staffs = Loadable(lazy(() => import('views/managment/Staffs')));
const Fees = Loadable(lazy(() => import('views/managment/Fees')));
const FeesReport = Loadable(lazy(() => import('views/managment/Fees/Report')));
const EssentialFees = Loadable(lazy(() => import('views/managment/Fees/EssentialFees')));
const FeesStatusByClass = Loadable(lazy(() => import('views/managment/Fees/FeesStatusByClass')));
const NotFeesStudents = Loadable(lazy(() => import('views/managment/Fees/NotFeesStudents')));

const Notice = Loadable(lazy(() => import('views/managment/Notice')));

// Attendance
const Attendance = Loadable(lazy(() => import('views/managment/Attendance')));
const AttendanceViewTake = Loadable(lazy(() => import('views/managment/Attendance/AttendanceViewTake')));
const ViewAttendance = Loadable(lazy(() => import('views/common/ViewAttendance')));
const TakeAttendance = Loadable(lazy(() => import('views/common/TakeAttendance')));

const AttendanceByClass = Loadable(lazy(() => import('views/common/AttendanceByClass')));
const Classes = Loadable(lazy(() => import('views/managment/Classes')));
const ClassesStudents = Loadable(lazy(() => import('views/managment/Classes/Students')));
const OnlineClass = Loadable(lazy(() => import('views/common/OnlineClasses')));
const HomeWork = Loadable(lazy(() => import('views/common/HomeWork')));

const Profile = Loadable(lazy(() => import('views/common/Profile')));
const Account = Loadable(lazy(() => import('views/common/Account')));

const CreateAccount = Loadable(lazy(() => import('views/managment/Record/CreateAccount')));
const BulkAccount = Loadable(lazy(() => import('views/managment/Record/CreateAccount/BulkAccount')));

const NewRecord = Loadable(lazy(() => import('views/managment/Record/NewRecord')));
const NewTeacher = Loadable(lazy(() => import('views/managment/Record/NewRecord/NewTeacher')));
const NewClasses = Loadable(lazy(() => import('views/managment/Record/NewRecord/NewClasses')));
const NewClassClasses = Loadable(lazy(() => import('views/managment/Record/NewRecord/NewClasses/Classes')));
const NewClassStream = Loadable(lazy(() => import('views/managment/Record/NewRecord/NewClasses/Stream')));
const NewClassClassroom = Loadable(lazy(() => import('views/managment/Record/NewRecord/NewClasses/ClassRoom')));
const NewStaff = Loadable(lazy(() => import('views/managment/Record/NewRecord/NewStaff')));
const NewStudent = Loadable(lazy(() => import('views/managment/Record/NewRecord/NewStudent')));
const BulkAdmission = Loadable(lazy(() => import('views/managment/Record/NewRecord/NewStudent/BulkAdmission')));

const UpdateRecord = Loadable(lazy(() => import('views/managment/Record/UpdateRecord')));
const UpdateTeachers = Loadable(lazy(() => import('views/managment/Record/UpdateRecord/UpdateTeachers')));
const UpdateClasses = Loadable(lazy(() => import('views/managment/Record/UpdateRecord/UpdateClasses')));
const UpdateStaffs = Loadable(lazy(() => import('views/managment/Record/UpdateRecord/UpdateStaffs')));
const UpdateStudent = Loadable(lazy(() => import('views/managment/Record/UpdateRecord/UpdateStudent/indexNew')));
const Students = Loadable(lazy(() => import('views/managment/Record/UpdateRecord/UpdateStudent/Students')));

// const SchoolClassAttendance = Loadable(lazy(() => import('views/managment/AttendanceOld/AttendanceByClass')));
// const SecurityGaurdAccess = Loadable(lazy(() => import('views/managment/SecurityGaurd/SecurityGaurdAccess')));
const Permission = Loadable(lazy(() => import('views/managment/Permission')));

const Guardians = Loadable(lazy(() => import('views/managment/Parents/Guardian')));
const Mothers = Loadable(lazy(() => import('views/managment/Parents/Mother')));
const Fathers = Loadable(lazy(() => import('views/managment/Parents/Father')));
// const FeesDetails = Loadable(lazy(() => import('views/managment/FeesOld/FeesStatusByMonths')));
const Vehicles = Loadable(lazy(() => import('views/managment/Transport/Vehicles')));
const VehiclesStudents = Loadable(lazy(() => import('views/managment/Transport/Vehicles/VehicleStudents')));
const VehicleRoutes = Loadable(lazy(() => import('views/managment/Transport/Routes')));
const VehicleReport = Loadable(lazy(() => import('views/managment/Transport/Report')));
const VehicleManageExpense = Loadable(lazy(() => import('views/managment/Transport/Report/ManageExpense')));
const VehicleManageKM = Loadable(lazy(() => import('views/managment/Transport/Report/ManageKm')));

const Recharge = Loadable(lazy(() => import('views/managment/Recharge')));
const BulkRecharge = Loadable(lazy(() => import('views/managment/Recharge/BulkRecharge')));

const Templates = Loadable(lazy(() => import('views/managment/Template/Templates')));
const TemplateMobile = Loadable(lazy(() => import('views/managment/Template/Mobile')));

const Finalized = Loadable(lazy(() => import('views/managment/Template/Finalized')));
const Subjects = Loadable(lazy(() => import('views/common/Subjects')));

const Visitors = Loadable(lazy(() => import('views/managment/Visitors')));
const SchoolVisitors = Loadable(lazy(() => import('views/managment/Visitors/SchoolVisitors')));
const StudentVisitors = Loadable(lazy(() => import('views/managment/Visitors/StudentVisitors')));

const Inventory = Loadable(lazy(() => import('views/managment/Stationery/Inventory')));
const Orders = Loadable(lazy(() => import('views/managment/Stationery/Orders')));
const Sales = Loadable(lazy(() => import('views/managment/Stationery/Sales')));
const TotalSales = Loadable(lazy(() => import('views/common/StationeryC/SalesC/TotalSales')));
const Store = Loadable(lazy(() => import('views/managment/Stationery/Store')));
const SearchByRN = Loadable(lazy(() => import('views/common/StationeryC/StoreC/SearchByRN')));
const StationeryMobile = Loadable(lazy(() => import('views/managment/Stationery/Mobile')));

const Download = Loadable(lazy(() => import('views/managment/Download')));
const Dashboard = Loadable(lazy(() => import('views/managment/Dashboard')));

const ManagmentRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        // {
        //     path: '/home',
        //     element: <Home />
        // },
        {
            path: '/home',
            element: <Dashboard />
        },
        {
            path: '/info',
            element: <Info />
        },
        {
            path: '/classes',
            element: <Classes />
        },
        {
            path: '/classes/subjects',
            element: <Subjects />
        },
        {
            path: '/classes/online-class',
            element: <OnlineClass />
        },
        {
            path: '/classes/homework',
            element: <HomeWork />
        },
        {
            path: '/teachers',
            element: <Teachers />
        },
        {
            path: '/exams',
            element: <Exams />
        },
        {
            path: '/results',
            element: <Results />
        },
        {
            path: '/results/exams',
            element: <ResultsByCRoom />
        },
        {
            path: '/results/exams/results-by-exams',
            element: <ResultsByExam />
        },
        {
            path: '/parents',
            element: <Parents />
        },
        {
            path: '/staffs',
            element: <Staffs />
        },
        {
            path: '/fees',
            element: <Fees />
        },
        {
            path: '/fees/fees-details',
            element: <FeesStatusByClass />
        },
        {
            path: '/fees/fees-report',
            element: <FeesReport />
        },
        {
            path: '/fees/essential-fees',
            element: <EssentialFees />
        },
        {
            path: '/not-fees-students',
            element: <NotFeesStudents />
        },
        {
            path: '/notice',
            element: <Notice />
        },
        {
            path: '/attendance',
            element: <Attendance />
        },
        {
            path: '/attendance/view-take',
            element: <AttendanceViewTake />
        },
        {
            path: '/attendance/view-take/view-attendance',
            element: <ViewAttendance />
        },
        {
            path: '/attendance/view-take/take-attendance',
            element: <TakeAttendance />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/create-account',
            element: <CreateAccount />
        },
        {
            path: '/create-account/bulk-account',
            element: <BulkAccount />
        },
        {
            path: '/new-record',
            element: <NewRecord />,
            children: [
                {
                    path: '/new-record/new-teacher',
                    element: <NewTeacher />
                },
                {
                    path: '/new-record/new-class',
                    element: <NewClasses />,
                    children: [
                        {
                            path: '/new-record/new-class/classes',
                            element: <NewClassClasses />
                        },
                        {
                            path: '/new-record/new-class/stream',
                            element: <NewClassStream />
                        },
                        {
                            path: '/new-record/new-class/classroom',
                            element: <NewClassClassroom />
                        }
                    ]
                },
                {
                    path: '/new-record/new-staff',
                    element: <NewStaff />
                },
                {
                    path: '/new-record/new-student',
                    element: <NewStudent />
                },
                {
                    path: '/new-record/new-student/bulk-admission',
                    element: <BulkAdmission />
                }
            ]
        },
        {
            path: '/update-record',
            element: <UpdateRecord />,
            children: [
                {
                    path: '/update-record/update-teachers',
                    element: <UpdateTeachers />
                },
                {
                    path: '/update-record/update-classes',
                    element: <UpdateClasses />
                },
                {
                    path: '/update-record/update-staffs',
                    element: <UpdateStaffs />
                },
                {
                    path: '/update-record/update-student',
                    element: <UpdateStudent />
                },
                {
                    path: '/update-record/update-student/students',
                    element: <Students />
                }
            ]
        },
        {
            path: '/classes/students',
            element: <ClassesStudents />
        },
        {
            path: '/classes/attendance',
            element: <AttendanceByClass isHeader />
        },
        {
            path: '/parents/gaudians',
            element: <Guardians />
        },
        {
            path: '/parents/mothers',
            element: <Mothers />
        },
        {
            path: '/parents/fathers',
            element: <Fathers />
        },
        {
            path: '/permission',
            element: <Permission />
        },
        {
            path: '/vehicles',
            element: <Vehicles />
        },
        {
            path: '/vehicles/students',
            element: <VehiclesStudents />
        },
        {
            path: '/vehicle-routes',
            element: <VehicleRoutes />
        },
        {
            path: '/vehicle-routes/vehicles',
            element: <Vehicles />
        },
        {
            path: '/vehicle-reports',
            element: <VehicleReport />
        },
        {
            path: '/vehicle-reports/manage-expense',
            element: <VehicleManageExpense />
        },
        {
            path: '/vehicle-reports/manage-km',
            element: <VehicleManageKM />
        },
        // {
        //     path: '/vehicle-routes/vehicles/students',
        //     element: <FeesDetails />
        // },
        {
            path: '/recharge',
            element: <Recharge />
        },
        {
            path: '/recharge/bulk-recharge',
            element: <BulkRecharge />
        },
        {
            path: '/templates',
            element: <Templates />
        },
        {
            path: '/selected-templates',
            element: <Finalized />
        },
        {
            path: '/template',
            element: <TemplateMobile />
        },
        {
            path: '/visitors',
            element: <Visitors />
        },
        {
            path: '/visitors/school-visitors',
            element: <SchoolVisitors />
        },
        {
            path: '/visitors/student-visitors',
            element: <StudentVisitors />
        },
        {
            path: '/stationery',
            element: <StationeryMobile />
        },
        {
            path: '/inventory',
            element: <Inventory />
        },
        {
            path: '/sales',
            element: <Sales />
        },
        {
            path: '/sales/total-sales',
            element: <TotalSales />
        },
        {
            path: '/orders',
            element: <Orders />
        },
        {
            path: '/store',
            element: <Store />
        },
        {
            path: '/store/search-by-rn',
            element: <SearchByRN />
        },
        {
            path: '/download',
            element: <Download />
        }
    ]
};

export default ManagmentRoutes;
