// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { InfoOutlined, HomeOutlined, SchoolOutlined, Business, Login, Logout } from '@mui/icons-material';

// constant
const icons = {
    HomeOutlined,
    SchoolOutlined,
    InfoOutlined,
    Business,
    Login,
    Logout
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const anonymoususer = {
    id: 'anonymoususer',
    type: 'group',
    children: [
        {
            id: 'findSchools',
            title: <FormattedMessage id="findSchools" />,
            type: 'item',
            url: '/home',
            icon: icons.Business,
            breadcrumbs: false
        },
        {
            id: 'about',
            title: <FormattedMessage id="about" />,
            type: 'item',
            url: '/aboutus',
            icon: icons.InfoOutlined,
            breadcrumbs: false
        },
        {
            id: 'signin',
            title: <FormattedMessage id="signin" />,
            type: 'item',
            url: '/login',
            icon: icons.Login,
            breadcrumbs: false
        },
        {
            id: 'signup',
            title: <FormattedMessage id="signup" />,
            type: 'item',
            url: '/register',
            icon: icons.Logout,
            breadcrumbs: false
        }
    ]
};

export default anonymoususer;
