/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import { FormControl, Select as MuiSelect, InputLabel, MenuItem, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector } from 'store';
import Button from './Button';

function CSVExport({
    options = [
        { teacherMail: 'January', teacherName: 'January' },
        { teacherMail: 'February', teacherName: 'February' },
        { teacherMail: 'March', teacherName: 'March' },
        { teacherMail: 'April', teacherName: 'April' },
        { teacherMail: 'May', teacherName: 'May' },
        { teacherMail: 'June', teacherName: 'June' },
        { teacherMail: 'July', teacherName: 'July' },
        { teacherMail: 'August', teacherName: 'August' },
        { teacherMail: 'September', teacherName: 'September' },
        { teacherMail: 'October', teacherName: 'October' },
        { teacherMail: 'November', teacherName: 'November' },
        { teacherMail: 'December', teacherName: 'December' }
    ],
    selectedCSVOption = false,
    title,
    csvExport
}) {
    const theme = useTheme();
    const csvLinkEl = useRef();
    const { csvExportHeader, csvExportData } = useSelector((state) => state.commonWP);
    const [monthName, setMonthName] = useState({});

    const handleOptionSelect = (e) => {
        setMonthName(e.target.value);
    };

    return (
        <>
            <CSVLink headers={csvExportHeader} filename={`${title.titleName}.csv`} data={csvExportData} ref={csvLinkEl} />
            {selectedCSVOption ? (
                <FormControl fullWidth sx={{ mr: 1 }}>
                    <InputLabel id="select-month-label">Select Month</InputLabel>
                    <MuiSelect
                        labelId="select-month-label"
                        id="select-month"
                        value={selectedCSVOption}
                        label="Select Month"
                        onChange={(e) => handleOptionSelect(e)}
                        size="small"
                        sx={{
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.divider
                            }
                        }}
                    >
                        {options?.map((item, i) => (
                            <MenuItem key={i} value={item.teacherMail}>
                                {item.teacherName}
                            </MenuItem>
                        ))}
                    </MuiSelect>
                </FormControl>
            ) : null}
            <Button
                handleOpen={() => csvExport.action(monthName, csvLinkEl)}
                width="100%"
                buttonName="Export"
                animateSX={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
            />
        </>
    );
}

export default CSVExport;
