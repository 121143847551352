import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';
import axios from 'utils/axios';
import { sortBy } from 'lodash';

const initialState = {
    states: [],
    error: null,
    cities: [],
    findSchoolsList: [],
    isUpdated: false,
    formatedDocList: [],
    isRecordInserted: false,
    selectedMobileMenuItem: 0,
    reset: false,
    page: 0,
    rowsPerPage: 10,
    filteredTableData: [],
    schoolName: '',
    essenFeeId: '',
    selectedSubjectInfo: {},
    selectedChildrenDetails: {},
    selectedVehicleDetails: {},
    securityGaurdDashDetails: {},
    sltClassRoomDet: {},
    sltIdData: {}
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setStates: (state, action) => {
            state.states = action.payload;
        },
        setCities: (state, action) => {
            state.cities = action.payload;
        },
        hasError(state, action) {
            state.error = action.payload;
        },
        setFindSchoolsList(state, action) {
            state.findSchoolsList = action.payload;
        },
        setIsUpdated: (state, action) => {
            state.isUpdated = action.payload;
        },
        setFormatedDocList: (state, action) => {
            state.formatedDocList = action.payload;
        },
        setIsRecordInserted: (state, action) => {
            state.isRecordInserted = action.payload;
        },
        setSelectedMobileMenuItem: (state, action) => {
            state.selectedMobileMenuItem = action.payload;
        },
        setReset: (state, action) => {
            state.reset = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setFilteredTableData: (state, action) => {
            state.filteredTableData = action.payload;
        },
        setSchoolName: (state, action) => {
            state.schoolName = action.payload;
        },
        setEssenFeeId: (state, action) => {
            state.essenFeeId = action.payload;
        },
        setSelectedSubjectInfo: (state, action) => {
            state.selectedSubjectInfo = action.payload;
        },
        setSelectedChildrenDetails: (state, action) => {
            state.selectedChildrenDetails = action.payload;
        },
        setSelectedVehicleDetails: (state, action) => {
            state.selectedVehicleDetails = action.payload;
        },
        setSecurityGaurdDashDetails: (state, action) => {
            state.securityGaurdDashDetails = action.payload;
        },
        setSltClassRoomDet: (state, action) => {
            state.sltClassRoomDet = action.payload;
        },
        setSltIdData: (state, action) => {
            state.sltIdData = action.payload;
        }
    }
});

export const {
    setFindSchoolsList,
    setIsUpdated,
    setFormatedDocList,
    setIsRecordInserted,
    setSelectedMobileMenuItem,
    setReset,
    setPage,
    setRowsPerPage,
    setFilteredTableData,
    setSchoolName,
    setEssenFeeId,
    setSelectedSubjectInfo,
    setSelectedChildrenDetails,
    setSelectedVehicleDetails,
    setSecurityGaurdDashDetails,
    setSltClassRoomDet,
    setSltIdData
} = commonSlice.actions;

export default commonSlice.reducer;

export function setStates() {
    return async () => {
        try {
            const response = await axios.get('https://api.countrystatecity.in/v1/countries/IN/states', {
                headers: {
                    'X-CSCAPI-KEY': 'QzdNejlFS3RGdnhGSEdaRTRhQ1dmVlRuQ2p4MHM5WFJaeWdzR1E5TA=='
                }
            });

            if (response.data) {
                const stateList = [];
                response.data.map((item) =>
                    stateList.push({
                        stateId: item.iso2,
                        stateName: item.name
                    })
                );
                dispatch(commonSlice.actions.setStates(sortBy(stateList, 'stateName')));
            }
        } catch (error) {
            dispatch(commonSlice.actions.hasError(error));
        }
    };
}

export function setCities(stateCode) {
    return async () => {
        try {
            const response = await axios.get(`https://api.countrystatecity.in/v1/countries/IN/states/${stateCode}/cities`, {
                headers: {
                    'X-CSCAPI-KEY': 'QzdNejlFS3RGdnhGSEdaRTRhQ1dmVlRuQ2p4MHM5WFJaeWdzR1E5TA=='
                }
            });
            if (response.data) {
                const districtList = [];
                response.data.map((item) =>
                    districtList.push({
                        districtId: item.id,
                        districtName: item.name
                    })
                );
                dispatch(commonSlice.actions.setCities(sortBy(districtList, 'districtName')));
            }
        } catch (error) {
            dispatch(commonSlice.actions.hasError(error));
        }
    };
}
