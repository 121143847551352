import { Grid } from '@mui/material';
import EmptyRecords from './EmptyRecords';
import Records from './Records';

function Body({ headCells, filteredTableData, removeBtn, handleSwitch }) {
    return (
        <>
            <Grid container spacing={1}>
                {filteredTableData && filteredTableData.length > 0 ? (
                    <Records
                        headCells={headCells}
                        filteredTableData={filteredTableData}
                        removeBtn={removeBtn}
                        handleSwitch={handleSwitch}
                    />
                ) : (
                    <>
                        <EmptyRecords />
                    </>
                )}
            </Grid>
        </>
    );
}

export default Body;
