// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    HomeOutlined,
    MenuBookOutlined,
    NotificationsNoneOutlined,
    CalendarMonthOutlined,
    RateReviewOutlined,
    InventoryOutlined,
    Business,
    CurrencyRupeeOutlined,
    AutoStories,
    Videocam,
    HomeWork
} from '@mui/icons-material';

// constant
const icons = {
    HomeOutlined,
    MenuBookOutlined,
    NotificationsNoneOutlined,
    RateReviewOutlined,
    InventoryOutlined,
    CalendarMonthOutlined,
    Business,
    CurrencyRupeeOutlined,
    AutoStories,
    Videocam,
    HomeWork
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const student = {
    id: 'student',
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/home',
            icon: icons.HomeOutlined,
            breadcrumbs: false
        },
        {
            id: 'classes',
            title: <FormattedMessage id="classes" />,
            type: 'item',
            url: '/classes',
            icon: icons.MenuBookOutlined,
            breadcrumbs: false
        },
        {
            id: 'notice',
            title: <FormattedMessage id="notice" />,
            type: 'item',
            url: '/notice',
            icon: icons.NotificationsNoneOutlined,
            breadcrumbs: false
        },
        {
            id: 'exam',
            title: <FormattedMessage id="exams" />,
            type: 'item',
            url: '/exam',
            icon: icons.RateReviewOutlined,
            breadcrumbs: false
        },
        {
            id: 'result',
            title: <FormattedMessage id="result" />,
            type: 'item',
            url: '/result',
            icon: icons.InventoryOutlined,
            breadcrumbs: false
        },
        {
            id: 'attendance',
            title: <FormattedMessage id="attendance" />,
            type: 'item',
            url: '/attendance',
            icon: icons.CalendarMonthOutlined,
            breadcrumbs: false
        },
        {
            id: 'fees',
            title: <FormattedMessage id="fees" />,
            type: 'item',
            url: '/fees',
            icon: icons.CurrencyRupeeOutlined,
            breadcrumbs: false
        },
        {
            id: 'subjects',
            title: <FormattedMessage id="subjects" />,
            type: 'item',
            url: '/subjects',
            icon: icons.AutoStories,
            breadcrumbs: false
        },
        {
            id: 'onlineclasses',
            title: <FormattedMessage id="onlineclasses" />,
            type: 'item',
            url: '/onlineclasses',
            icon: icons.Videocam,
            breadcrumbs: false
        },
        {
            id: 'homework',
            title: <FormattedMessage id="homework" />,
            type: 'item',
            url: '/homework',
            icon: icons.HomeWork,
            breadcrumbs: false
        }
        // ,
        // {
        //     id: 'findSchools',
        //     title: <FormattedMessage id="findSchools" />,
        //     type: 'item',
        //     url: '/findschool',
        //     icon: icons.Business,
        //     breadcrumbs: false
        // }
    ]
};

export default student;
