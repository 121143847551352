import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Container } from '@mui/material';
// import Container from 'component/Container';

const mock = [
    {
        feedback: `In summary, PenBoox is a game-changer for me. Its user-friendly interface, powerful features, scalability, and top-notch security make it an excellent investment. I highly recommend PenBoox to any school looking to elevate its efficiency and stay ahead of the curve. It's more than just a product; it's a strategic advantage.`,
        name: 'Abhinav Bharti',
        title: 'Adarsh Children School',
        avatar: ''
    },
    {
        feedback:
            'Our team recently implemented PenBoox into our workflow, and it has truly transformed the way we operate. This SAAS solution is nothing short of exceptional, and it has become an integral part of our daily operations.',
        name: 'Gautam Pandey',
        title: 'Jyoti Public School',
        avatar: ''
    },
    {
        feedback:
            'One of the standout features is the user-friendly interface. Navigating through the various modules and functionalities is intuitive, making the onboarding process a breeze for our team. We were up and running in no time, and the learning curve was surprisingly minimal.',
        name: 'Raj Kumar Singh',
        title: 'Srijan National Academy',
        avatar: ''
    }
];

const Reviews = () => {
    const theme = useTheme();

    return (
        <Container sx={{ my: 8 }}>
            <Box>
                <Box marginBottom={4}>
                    <Typography
                        variant="h4"
                        data-aos="fade-up"
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                            marginTop: theme.spacing(1),
                            fontSize: '2.0243rem'
                        }}
                    >
                        Rated 5 out of 5 stars by our customers!
                    </Typography>
                    <Typography variant="h6" color="text.secondary" data-aos="fade-up" sx={{ fontSize: '1.25rem' }}>
                        Schools from across the India have had fantastic experiences using PenBoox.
                        <br />
                        Here’s what they have to say.
                    </Typography>
                </Box>
                <Grid container spacing={4}>
                    {mock.map((item, i) => (
                        <Grid item xs={12} md={4} key={i}>
                            <Box
                                width={1}
                                height={1}
                                data-aos="fade-up"
                                data-aos-delay={i * 100}
                                data-aos-offset={100}
                                data-aos-duration={600}
                                component={Card}
                                display="flex"
                                flexDirection="column"
                                boxShadow={2}
                            >
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Box marginBottom={1}>
                                        <Box display="flex" justifyContent="flex-start">
                                            {[1, 2, 3, 4, 5].map((item) => (
                                                <Box key={item} color={theme.palette.secondary.main}>
                                                    <svg
                                                        width={18}
                                                        height={18}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                    >
                                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                    </svg>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                    <Typography sx={{ fontSize: '1rem' }}>{item.feedback}</Typography>
                                </CardContent>
                                <Box flexGrow={1} />
                                <CardActions sx={{ paddingBottom: 2 }}>
                                    <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                                        <ListItemAvatar>
                                            <Avatar src={item.avatar} />
                                        </ListItemAvatar>
                                        <ListItemText sx={{ margin: 0 }} primary={item.name} secondary={item.title} />
                                    </ListItem>
                                </CardActions>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    );
};

export default Reviews;
