/* eslint-disable import/prefer-default-export */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    studentAndPDetails: {},
    recordStudentsListWP: [],
    admissionDataExist: {},
    routesList: [],
    vehiclesList: [],
    teachersList: [],
    assignedSubjects: [],
    selectedVehicleId: '',
    attendanceList: [],
    isAttendanceUpdated: false,
    staffsList: [],
    noticeList: [],
    examList: [],
    resultStudentList: []
};

export const managmentWPSlice = createSlice({
    name: 'managment',
    initialState,
    reducers: {
        setStudentAndPDetails: (state, action) => {
            state.studentAndPDetails = action.payload;
        },
        setRecordStudentsListWP: (state, action) => {
            state.recordStudentsListWP = action.payload;
        },
        setAdmissionDataExist: (state, action) => {
            state.admissionDataExist = action.payload;
        },
        setVehiclesList: (state, action) => {
            state.vehiclesList = action.payload;
        },
        setRoutesList: (state, action) => {
            state.routesList = action.payload;
        },
        setTeachersList: (state, action) => {
            state.teachersList = action.payload;
        },
        setAssignedSubjects: (state, action) => {
            state.assignedSubjects = action.payload;
        },
        setSelectedVehicleId: (state, action) => {
            state.selectedVehicleId = action.payload;
        },
        setAttendanceList: (state, action) => {
            state.attendanceList = [...action.payload];
        },
        setIsAttendanceUpdated: (state, action) => {
            state.isAttendanceUpdated = action.payload;
        },
        setStaffsList: (state, action) => {
            state.staffsList = action.payload;
        },
        setNoticeList: (state, action) => {
            state.noticeList = action.payload;
        },
        setExamList: (state, action) => {
            state.examList = action.payload;
        },
        setResultStudentList: (state, action) => {
            state.resultStudentList = action.payload;
        }
    }
});

export const {
    setStudentAndPDetails,
    setRecordStudentsListWP,
    setAdmissionDataExist,
    setVehiclesList,
    setRoutesList,
    setTeachersList,
    setAssignedSubjects,
    setSelectedVehicleId,
    setAttendanceList,
    setIsAttendanceUpdated,
    setStaffsList,
    setNoticeList,
    setExamList,
    setResultStudentList
} = managmentWPSlice.actions;

export default managmentWPSlice.reducer;
