import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    studentDetails: {},
    studentDashDetails: {},
    sltResultExamDet: {}
};

export const studentSlice = createSlice({
    name: 'student',
    initialState,
    reducers: {
        setStudentDetails: (state, action) => {
            state.studentDetails = action.payload;
        },
        setStudentDashDetails: (state, action) => {
            state.studentDashDetails = action.payload;
        },
        setSltResultExamDet: (state, action) => {
            state.sltResultExamDet = action.payload;
        }
    }
});

export const { setStudentDetails, setStudentDashDetails, setSltResultExamDet } = studentSlice.actions;

export default studentSlice.reducer;
