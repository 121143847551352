import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */
export const getSchoolClasses = gql`
    query SchoolClassesSummaryOne($schoolId: String, $session: String) {
        school_class_details(where: { school_id: { _eq: $schoolId }, session: { _eq: $session } }, order_by: { created_at: asc }) {
            class_id
            class_name
            class_section
            session
            class_start_date
            school_teacher {
                teacher_name
                teacher_id
            }
            school_student_classes_aggregate {
                aggregate {
                    count(columns: class_id)
                }
            }
        }
    }
`;

export const getSchoolTeachers = gql`
    query SchoolTeacherList($schoolId: String) {
        school_teachers(where: { school_id: { _eq: $schoolId } }) {
            teacher_id
            teacher_name
            teacher_mob
            teacher_joindate
            teaching_subject
            teacher_qualification
            teacher_email
            teacher_active
            take_atten
            mod_res
            read_fees
            fees_value
            school_class_details {
                class_name
                class_section
            }
        }
    }
`;

export const getSchoolStaffs = gql`
    query SchoolStaffList($schoolId: String) {
        school_staff(where: { school_id: { _eq: $schoolId } }) {
            staff_id
            staff_name
            staff_role
            staff_mob
            staff_email
            staff_joindate
            staff_active
            staff_qualification
        }
    }
`;

export const getSchoolParents = gql`
    query SchoolParentSummaryQ1($schoolId: String, $session: String) {
        school_class_details(order_by: { created_at: asc }, where: { school_id: { _eq: $schoolId }, session: { _eq: $session } }) {
            class_id
            class_name
            class_section
            school_student_classes_aggregate {
                aggregate {
                    count(columns: class_id)
                }
            }
        }
    }
`;

export const getSchoolGuardians = gql`
    query SchoolGuardianListByClassQ4($classId: String!) {
        school_class_details_by_pk(class_id: $classId) {
            school_student_classes(order_by: { roll_no: asc }) {
                roll_no
                school_student {
                    student_id
                    first_name
                    middle_name
                    last_name
                    student_guardian_detail {
                        guardian_name
                        relation
                        guardian_contact1
                        guardian_contact2
                        guardian_email
                        guardian_occupation
                        guardian_office_address
                        guardian_home_address
                    }
                }
            }
        }
    }
`;

export const getSchoolMothers = gql`
    query SchoolMotherListByClassQ3($classId: String!) {
        school_class_details_by_pk(class_id: $classId) {
            school_student_classes(order_by: { roll_no: asc }) {
                roll_no
                school_student {
                    first_name
                    middle_name
                    last_name
                    student_id
                    student_mother_detail {
                        mother_name
                        mother_contact1
                        mother_contact2
                        mother_email
                        mother_occupation
                        mother_office_address
                        mother_home_address
                    }
                }
            }
        }
    }
`;

export const getSchoolFathers = gql`
    query SchoolFatherListByClassQ2($classId: String!) {
        school_class_details_by_pk(class_id: $classId) {
            school_student_classes(order_by: { roll_no: asc }) {
                roll_no
                school_student {
                    first_name
                    middle_name
                    last_name
                    student_id
                    student_father_detail {
                        father_name
                        father_contact1
                        father_contact2
                        father_email
                        father_occupation
                        father_office_address
                        father_home_address
                    }
                }
            }
        }
    }
`;

export const getSchoolFees = gql`
    query SchoolClassFeeSummary($schoolId: String, $session: String) {
        school_class_details(where: { school_id: { _eq: $schoolId }, session: { _eq: $session } }, order_by: { created_at: asc }) {
            class_id
            class_name
            class_section
            fees
            school_teacher {
                teacher_name
                teacher_id
            }
            school_student_classes_aggregate {
                aggregate {
                    count(columns: class_id)
                }
            }
        }
    }
`;

export const getSchoolFeesByClass = gql`
    query SchoolClassFeeByStudent($id: String!) {
        school_class_details_by_pk(class_id: $id) {
            school_student_classes(where: { is_free: { _eq: false } }, order_by: { roll_no: asc }) {
                roll_no
                student_id
                transport_id
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                class_fee {
                    past_due
                    apr
                    may
                    jun
                    jul
                    aug
                    sep
                    oct
                    nov
                    dec
                    jan
                    feb
                    mar
                    updated_at
                }
                transport_fee {
                    apr
                    may
                    jun
                    jul
                    aug
                    sep
                    oct
                    nov
                    dec
                    jan
                    feb
                    mar
                    updated_at
                    stopage {
                        fees
                    }
                }
            }
        }
    }
`;

export const getEssentialFeesByClass = gql`
    query SchoolClassFeeByStudent($id: String!) {
        school_class_details_by_pk(class_id: $id) {
            class_feetype {
                f2
                f3
                f4
                f5
                f6
                f7
                f8
                f9
                f10
                f2f
                f3f
                f4f
                f5f
                f6f
                f7f
                f8f
                f9f
                f10f
            }
            school_student_classes(order_by: { roll_no: asc }) {
                roll_no
                student_id
                transport_id
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                essen_fee {
                    admfee
                    f2
                    f3
                    f4
                    f5
                    f6
                    f7
                    f8
                    f9
                    f10
                }
            }
        }
    }
`;

export const getSchoolNotice = gql`
    query SchoolNoticeListForMgmt($schoolId: String, $session: String) {
        school_notice(where: { school_id: { _eq: $schoolId }, session: { _eq: $session } }, order_by: { created_at: desc }) {
            id
            level
            subject
            published
            created_at
        }
    }
`;

export const getNoticeMessageById = gql`
    query SchoolMessageByID($id: String!) {
        school_notice_by_pk(id: $id) {
            message
        }
    }
`;

export const getSchoolExam = gql`
    query SchoolExamListForMgmt($schoolId: String, $session: String) {
        school_exams(where: { school_id: { _eq: $schoolId }, session: { _eq: $session } }, order_by: { created_at: desc }) {
            exam_id
            exam_for_class
            created_at
            exam_name
            exam_date
            exam_type
            published
            exam_conducted
        }
    }
`;

export const GET_SECURITY_GAURD_MAIL = gql`
    query SecGuardD($schoolId: String!) {
        school_secguard_by_pk(school_id: $schoolId) {
            email_id
            can_check
        }
    }
`;

export const GET_CLASS_FEE_APR = gql`
    query CFApr($studentId: String!) {
        class_fees_by_pk(student_id: $studentId) {
            apr
            aprmode
            aprtxn
            aprdt
            aprudb
            aprinv
        }
    }
`;

export const GET_CLASS_FEE_MAY = gql`
    query CFMay($studentId: String!) {
        class_fees_by_pk(student_id: $studentId) {
            may
            maymode
            maytxn
            maydt
            mayudb
            mayinv
        }
    }
`;

export const GET_CLASS_FEE_JUN = gql`
    query CFJun($studentId: String!) {
        class_fees_by_pk(student_id: $studentId) {
            jun
            junmode
            juntxn
            jundt
            junudb
            juninv
        }
    }
`;

export const GET_CLASS_FEE_JUL = gql`
    query CFJul($studentId: String!) {
        class_fees_by_pk(student_id: $studentId) {
            jul
            julmode
            jultxn
            juldt
            juludb
            julinv
        }
    }
`;

export const GET_CLASS_FEE_AUG = gql`
    query CFAug($studentId: String!) {
        class_fees_by_pk(student_id: $studentId) {
            aug
            augmode
            augtxn
            augdt
            augudb
            auginv
        }
    }
`;

export const GET_CLASS_FEE_SEP = gql`
    query CFSep($studentId: String!) {
        class_fees_by_pk(student_id: $studentId) {
            sep
            sepmode
            septxn
            sepdt
            sepudb
            sepinv
        }
    }
`;

export const GET_CLASS_FEE_OCT = gql`
    query CFOct($studentId: String!) {
        class_fees_by_pk(student_id: $studentId) {
            oct
            octmode
            octtxn
            octdt
            octudb
            octinv
        }
    }
`;

export const GET_CLASS_FEE_NOV = gql`
    query CFNov($studentId: String!) {
        class_fees_by_pk(student_id: $studentId) {
            nov
            novmode
            novtxn
            novdt
            novudb
            novinv
        }
    }
`;

export const GET_CLASS_FEE_DEC = gql`
    query CFDec($studentId: String!) {
        class_fees_by_pk(student_id: $studentId) {
            dec
            decmode
            dectxn
            decdt
            decudb
            decinv
        }
    }
`;

export const GET_CLASS_FEE_JAN = gql`
    query CFJan($studentId: String!) {
        class_fees_by_pk(student_id: $studentId) {
            jan
            janmode
            jantxn
            jandt
            janudb
            janinv
        }
    }
`;

export const GET_CLASS_FEE_FEB = gql`
    query CFFeb($studentId: String!) {
        class_fees_by_pk(student_id: $studentId) {
            feb
            febmode
            febtxn
            febdt
            febudb
            febinv
        }
    }
`;

export const GET_CLASS_FEE_MAR = gql`
    query CFMar($studentId: String!) {
        class_fees_by_pk(student_id: $studentId) {
            mar
            marmode
            martxn
            mardt
            marudb
            marinv
        }
    }
`;

export const GET_TRANSPORT_FEE_APR = gql`
    query CFApr($studentId: String!) {
        transport_fees_by_pk(student_id: $studentId) {
            apr
            aprmode
            aprtxn
            aprdt
            aprudb
            aprinv
        }
    }
`;

export const GET_TRANSPORT_FEE_MAY = gql`
    query CFMay($studentId: String!) {
        transport_fees_by_pk(student_id: $studentId) {
            may
            maymode
            maytxn
            maydt
            mayudb
            mayinv
        }
    }
`;

export const GET_TRANSPORT_FEE_JUN = gql`
    query CFJun($studentId: String!) {
        transport_fees_by_pk(student_id: $studentId) {
            jun
            junmode
            juntxn
            jundt
            junudb
            juninv
        }
    }
`;

export const GET_TRANSPORT_FEE_JUL = gql`
    query CFJul($studentId: String!) {
        transport_fees_by_pk(student_id: $studentId) {
            jul
            julmode
            jultxn
            juldt
            juludb
            julinv
        }
    }
`;

export const GET_TRANSPORT_FEE_AUG = gql`
    query CFAug($studentId: String!) {
        transport_fees_by_pk(student_id: $studentId) {
            aug
            augmode
            augtxn
            augdt
            augudb
            auginv
        }
    }
`;

export const GET_TRANSPORT_FEE_SEP = gql`
    query CFSep($studentId: String!) {
        transport_fees_by_pk(student_id: $studentId) {
            sep
            sepmode
            septxn
            sepdt
            sepudb
            sepinv
        }
    }
`;

export const GET_TRANSPORT_FEE_OCT = gql`
    query CFOct($studentId: String!) {
        transport_fees_by_pk(student_id: $studentId) {
            oct
            octmode
            octtxn
            octdt
            octudb
            octinv
        }
    }
`;

export const GET_TRANSPORT_FEE_NOV = gql`
    query CFNov($studentId: String!) {
        transport_fees_by_pk(student_id: $studentId) {
            nov
            novmode
            novtxn
            novdt
            novudb
            novinv
        }
    }
`;

export const GET_TRANSPORT_FEE_DEC = gql`
    query CFDec($studentId: String!) {
        transport_fees_by_pk(student_id: $studentId) {
            dec
            decmode
            dectxn
            decdt
            decudb
            decinv
        }
    }
`;

export const GET_TRANSPORT_FEE_JAN = gql`
    query CFJan($studentId: String!) {
        transport_fees_by_pk(student_id: $studentId) {
            jan
            janmode
            jantxn
            jandt
            janudb
            janinv
        }
    }
`;

export const GET_TRANSPORT_FEE_FEB = gql`
    query CFFeb($studentId: String!) {
        transport_fees_by_pk(student_id: $studentId) {
            feb
            febmode
            febtxn
            febdt
            febudb
            febinv
        }
    }
`;

export const GET_TRANSPORT_FEE_MAR = gql`
    query CFMar($studentId: String!) {
        transport_fees_by_pk(student_id: $studentId) {
            mar
            marmode
            martxn
            mardt
            marudb
            marinv
        }
    }
`;

export const GET_ROUTES = gql`
    query TransRoute($schoolId: String) {
        trans_route(where: { school_id: { _eq: $schoolId } }) {
            tr_id
            rtname
            stopages_aggregate {
                aggregate {
                    count
                }
            }
            trans_data_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const GET_VEHICLES = gql`
    query VehicleList($schoolId: String) {
        trans_data(where: { school_id: { _eq: $schoolId } }) {
            td_id
            veh_name
            veh_no
            in_serv
            driver {
                staff_name
                staff_id
            }
            conductor {
                staff_name
                staff_id
            }
            trans_route {
                rtname
                tr_id
            }
            moblive
            vtslive
            transport_fees_aggregate {
                aggregate {
                    count(columns: student_id)
                }
            }
        }
    }
`;

export const DROPDOWN_DRIVER = gql`
    query DropdownDriver($schoolId: String) {
        school_staff(where: { school_id: { _eq: $schoolId }, _and: { staff_active: { _eq: true }, staff_role: { _eq: "Driver" } } }) {
            staff_name
            staff_id
        }
    }
`;

export const DROPDOWN_CONDUCTOR = gql`
    query DropdownConductor($schoolId: String) {
        school_staff(where: { school_id: { _eq: $schoolId }, _and: { staff_active: { _eq: true }, staff_role: { _eq: "Conductor" } } }) {
            staff_name
            staff_id
        }
    }
`;

export const DROPDOWN_ROUTE = gql`
    query DropdownRoute($schoolId: String) {
        trans_route(where: { school_id: { _eq: $schoolId } }) {
            rtname
            tr_id
        }
    }
`;

export const STUDENTS_LIST_VEHICLE = gql`
    query VehicleStudentList($tdId: String!) {
        trans_data_by_pk(td_id: $tdId) {
            transport_fees {
                student_id
                school_class_detail {
                    class_name
                    class_section
                }
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                apr
                may
                jun
                jul
                aug
                sep
                oct
                nov
                dec
                jan
                feb
                mar
                stopage {
                    fees
                }
                updated_at
            }
        }
    }
`;

export const VEHICLES_BY_ROUTE = gql`
    query RouteVehicles($trid: String!) {
        trans_route_by_pk(tr_id: $trid) {
            trans_data {
                veh_name
                veh_no
                in_serv
                driver {
                    staff_name
                    staff_id
                }
                conductor {
                    staff_name
                    staff_id
                }
            }
        }
    }
`;

export const SCHOOL_CLASS_FEE_BY_STUDENT = gql`
    query SchoolClassFeeByStudent($id: String!) {
        class_fees_by_pk(student_id: $id) {
            past_due
            apr
            may
            jun
            jul
            aug
            sep
            oct
            nov
            dec
            jan
            feb
            mar
            school_student_class {
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                roll_no
                school_class_detail {
                    fees
                }
            }
        }
    }
`;

export const SCHOOL_TRANSPORT_FEE_BY_STUDENT = gql`
    query SchoolTransportFeeByStudent($id: String!) {
        transport_fees_by_pk(student_id: $id) {
            apr
            may
            jun
            jul
            aug
            sep
            oct
            nov
            dec
            jan
            feb
            mar
            stopage {
                fees
            }
            school_student_class {
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                roll_no
            }
        }
    }
`;

export const GET_ACCOUNT_SEC_GAURD_LIST = gql`
    query PermissionList($schoolId: String!) {
        school_accounts_by_pk(school_id: $schoolId) {
            sa_uid
            email_id
            read
            mod
        }
        school_secguard_by_pk(school_id: $schoolId) {
            sg_uid
            read
            mod
            email_id
            # id @client
            # role @client
            # email @client
        }
    }
`;

export const GET_CONDUCTER_LIST = gql`
    query PermissionList($schoolId: String!) {
        school_staff(where: { school_id: { _eq: $schoolId }, staff_role: { _eq: "Conductor" }, staff_active: { _eq: true } }) {
            staff_email
            staff_active
            staff_id
            read
            staff_name
        }
    }
`;

export const GET_TOTAL_CREDIT = gql`
    query SCredit($gusid: String!) {
        school_info_by_pk(gusid: $gusid) {
            credits
        }
    }
`;

export const GET_ADMISSION_CREDIT = gql`
    query AdmCredit($gusid: String!) {
        school_info_by_pk(gusid: $gusid) {
            adm_credit
        }
    }
`;

export const GET_SUB_BY_CLASS_ID = gql`
    query CSub1($classId: String!) {
        class_sub_by_pk(class_id: $classId) {
            s1Id
            s1
            s1t
            s1cp
            s1tp
            s1ad

            s2Id
            s2
            s2t
            s2cp
            s2tp
            s2ad

            s3Id
            s3
            s3t
            s3cp
            s3tp
            s3ad

            s4Id
            s4
            s4t
            s4cp
            s4tp
            s4ad

            s5Id
            s5
            s5t
            s5cp
            s5tp
            s5ad

            s6Id
            s6
            s6t
            s6cp
            s6tp
            s6ad

            s7Id
            s7
            s7t
            s7cp
            s7tp
            s7ad

            s8Id
            s8
            s8t
            s8cp
            s8tp
            s8ad

            s9Id
            s9
            s9t
            s9cp
            s9tp
            s9ad

            s10Id
            s10
            s10t
            s10cp
            s10tp
            s10ad

            s11Id
            s11
            s11t
            s11cp
            s11tp
            s11ad

            s12Id
            s12
            s12t
            s12cp
            s12tp
            s12ad

            s13Id
            s13
            s13t
            s13cp
            s13tp
            s13ad

            s14Id
            s14
            s14t
            s14cp
            s14tp
            s14ad

            s15Id
            s15
            s15t
            s15cp
            s15tp
            s15ad
        }
    }
`;

export const GET_SUB_LIST_BY_CLASS_ID = gql`
    query SubjectList($classId: String) {
        csub(where: { class_id: { _eq: $classId } }, order_by: { seq: asc }) {
            sid
            seq
            sn
            scp
            stp
            st
            sad
            timing
            days
            school_teacher {
                teacher_name
            }
        }
    }
`;

export const GET_TEACHER_NAME = gql`
    query TeacherName($teacherId: String!) {
        school_teachers_by_pk(teacher_id: $teacherId) {
            teacher_name
        }
    }
`;

export const GET_SUB_TIMING_BY_SID = gql`
    query SubTiming($sid: String!) {
        csub_by_pk(sid: $sid) {
            seq
            timing
            days
        }
    }
`;

export const SUBJECT_BY_CLASS_TOTAL_COUNT = gql`
    query SubjectByClassTotalCount($classId: String) {
        csub_aggregate(where: { class_id: { _eq: $classId } }) {
            aggregate {
                count(columns: class_id)
            }
        }
    }
`;

export const TEACHING_SUB_TEACH_ID = gql`
    query Teachings($teacherId: String!) {
        school_teachers_by_pk(teacher_id: $teacherId) {
            subjects(order_by: { tseq: asc }) {
                sid
                sn
                scp
                stp
                sad
                timing
                days
                tseq
                school_class_detail {
                    class_name
                    class_section
                }
            }
        }
    }
`;

export const RECHARGE_HISTORY = gql`
    query Recharges($schoolId: String!, $limit: Int) {
        credtxn(where: { school_id: { _eq: $schoolId } }, order_by: { created_at: desc }, limit: $limit) {
            id
            remail
            created_at
        }
    }
`;

export const PURCHAGE_HISTORY = gql`
    query PurchaseHistory($schoolId: String, $limit: Int) {
        creditplus(where: { school_id: { _eq: $schoolId } }, order_by: { created_at: desc }, limit: $limit) {
            id
            credit
            amount
            trxn_id
            trxndate
        }
    }
`;

export const VISITOR_LOG_TODAY = gql`
    query VisitorLogCustomDate($schoolId: String, $gte: String, $lte: String) {
        visitor_log(where: { school_id: { _eq: $schoolId }, in: { _gte: $gte, _lte: $lte } }, order_by: { in: desc }) {
            id
            in
            out
            person
            purpose
            sv_id
            scl_visitor {
                vname
                vrole
            }
        }
    }
`;

export const STUDENT_VISITOR_LOG_TODAY = gql`
    query ChildVisitorCustomDate($schoolId: String, $gte: String, $lte: String) {
        child_visitor(where: { school_id: { _eq: $schoolId }, in: { _gte: $gte, _lte: $lte } }, order_by: { in: desc }) {
            id
            school_student {
                first_name
                middle_name
                last_name
            }
            school_class_detail {
                class_name
                class_section
            }
            in
            out
            approved
            relation
            student_id
            visitor_id
            visitor_data {
                vname
            }
        }
    }
`;

export const IS_FEE_TYPE_INSERTED_BY_CGID_ID = gql`
    query ClassIDs($cgId: String!) {
        class_group_by_pk(cg_id: $cgId) {
            school_class_details {
                class_id
                class_feetype {
                    adm
                }
            }
        }
    }
`;

export const FEE_TYPE_BY_CLASS_ID = gql`
    query FeeTypes($classId: String!) {
        school_class_details_by_pk(class_id: $classId) {
            class_id
            class_feetype {
                adm
                f2
                f2f
                f3
                f3f
                f4
                f4f
                f5
                f5f
                f6
                f6f
                f7
                f7f
                f8
                f8f
                f9
                f9f
                f10
                f10f
            }
        }
    }
`;

export const FEES_REPORT_CLASS = gql`
    query FeeSummaryByClass($classId: String!) {
        school_class_details_by_pk(class_id: $classId) {
            fees
            class_fees_aggregate {
                aggregate {
                    count(columns: student_id)
                    sum {
                        apr
                        may
                        jun
                        jul
                        aug
                        sep
                        oct
                        nov
                        dec
                        jan
                        feb
                        mar
                    }
                }
            }
            class_feetype {
                adm
                f2
                f2f
                f3
                f3f
                f4
                f4f
                f5
                f5f
                f6
                f6f
                f7
                f7f
                f8
                f8f
                f9
                f9f
                f10
                f10f
            }
            essen_fees_aggregate {
                aggregate {
                    sum {
                        admfee
                        f2
                        f3
                        f4
                        f5
                        f6
                        f7
                        f8
                        f9
                        f10
                    }
                }
            }
        }
    }
`;

export const SEARCH_STUDENT_GUARDIAN_MAIL = gql`
    query StudentByGuardian($schoolId: String, $gContact: String, $gte: timestamptz) {
        student_guardian_details(
            where: { school_id: { _eq: $schoolId }, _and: { guardian_contact1: { _eq: $gContact } }, created_at: { _gte: $gte } }
        ) {
            school_student {
                first_name
                middle_name
                last_name
                student_id
                smod
                school_student_class {
                    school_class_detail {
                        class_name
                        class_section
                    }
                    roll_no
                    transport_id
                    admission_date
                    is_free
                }
            }
        }
    }
`;

export const GET_STUDENT_PHOTO = gql`
    query StudentPhotoPreview($studentId: String!) {
        school_students_by_pk(student_id: $studentId) {
            student_photo
        }
    }
`;

export const GET_TEACHER_PHOTO = gql`
    query TeacherPhoto($teacherId: String!) {
        school_teachers_by_pk(teacher_id: $teacherId) {
            teacher_photo
        }
    }
`;

export const GET_STAFF_PHOTO = gql`
    query StaffPhoto($staffId: String!) {
        school_staff_by_pk(staff_id: $staffId) {
            staff_photo
        }
    }
`;

export const GET_VEHICLES_NO_AND_IDS = gql`
    query VehicleList($schoolId: String) {
        trans_data(where: { school_id: { _eq: $schoolId } }, order_by: { veh_no: asc }) {
            veh_no
            td_id
        }
    }
`;

export const SEARCH_STUDENT_BY_CONTACT_CLASS = gql`
    query StudentByGuardian($schoolId: String, $gContact: String, $gte: timestamptz) {
        student_guardian_details(
            where: { school_id: { _eq: $schoolId }, _and: { guardian_contact1: { _eq: $gContact } }, created_at: { _gte: $gte } }
        ) {
            school_student {
                first_name
                middle_name
                last_name
                student_id
                smod
                school_student_class {
                    school_class_detail {
                        class_name
                        class_section
                    }
                    roll_no
                }
            }
        }
    }
`;

export const GET_CLASS_STUDENTS_FOR_UPDATE = gql`
    query StudentList($classId: String!) {
        school_class_details_by_pk(class_id: $classId) {
            school_student_classes(order_by: { roll_no: asc }) {
                student_id
                roll_no
                school_student {
                    gender
                    first_name
                    middle_name
                    last_name
                    smod
                }
                transport_fee {
                    trans_data {
                        veh_no
                    }
                }
            }
        }
    }
`;

export const GET_STOPPAGE_LIST = gql`
    query StopageList($trId: String!) {
        trans_route_by_pk(tr_id: $trId) {
            stopages(order_by: { stop: asc }) {
                stop
                fees
                st_id
                transport_fees_aggregate {
                    aggregate {
                        count
                    }
                }
            }
        }
    }
`;

export const GET_ID_CARD_INFO = gql`
    query IDData($schoolId: String!) {
        school_info_by_pk(gusid: $schoolId) {
            logo
            p_sign
            idcard_data {
                school_id
                s_name
                session
                contact
                address
            }
        }
    }
`;

export const GET_MARKSHEET_INFO = gql`
    query MarksheetData($schoolId: String!) {
        marksheet_data_by_pk(school_id: $schoolId) {
            school_id
            school_name
            address
            contact
            school_info {
                logo
                affliated_to
                affliation_no
            }
        }
    }
`;

export const SCHOOL_LOGO = gql`
    query Logo($schoolId: String!) {
        school_info_by_pk(gusid: $schoolId) {
            logo
        }
    }
`;

export const SCHOOL_SIGN = gql`
    query Sign($schoolId: String!) {
        school_info_by_pk(gusid: $schoolId) {
            p_sign
        }
    }
`;

export const STUDENT_ID_CARD_BY_CLASS_ID = gql`
    query ClassIDPrint($classId: String) {
        school_student_classes(where: { class_id: { _eq: $classId } }, order_by: { roll_no: asc }) {
            roll_no
            school_student {
                first_name
                middle_name
                last_name
                student_photo
                student_guardian_detail {
                    guardian_name
                    guardian_contact1
                }
            }
            school_class_detail {
                class_name
                class_section
            }
        }
    }
`;

export const STUDENT_ID_CARD_BY_ROLL_NO = gql`
    query SingleIDPrint($classId: String, $rollNo: String) {
        school_student_classes(where: { class_id: { _eq: $classId }, roll_no: { _eq: $rollNo } }) {
            roll_no
            school_student {
                first_name
                middle_name
                last_name
                student_photo
                student_guardian_detail {
                    guardian_name
                    guardian_contact1
                }
            }
            school_class_detail {
                class_name
                class_section
            }
        }
    }
`;

export const ID_CARD_TEMPLATE_ID = gql`
    query Template($schoolId: String!) {
        template_by_pk(school_id: $schoolId) {
            tem_id
        }
    }
`;

export const INVOICE_TEMPLATE_ID = gql`
    query Template($schoolId: String!) {
        template_by_pk(school_id: $schoolId) {
            tem_invoice
        }
    }
`;

export const MARKSHEET_TEMPLATE_ID = gql`
    query Template($schoolId: String!) {
        template_by_pk(school_id: $schoolId) {
            tem_marksheet
        }
    }
`;

export const INVOICE_SCHOOL_INFO = gql`
    query InvoiceData($schoolId: String!) {
        school_info_by_pk(gusid: $schoolId) {
            logo
            school_contact {
                email
                scl_phone1
                scl_phone2
            }
            idcard_data {
                s_name
                address
            }
        }
    }
`;

export const INVOICE_STUDENT_INFO = gql`
    query ClassFeeInvoice($studentId: String!) {
        class_fees_by_pk(student_id: $studentId) {
            school_class_detail {
                fees
                class_name
                class_section
            }
            school_student {
                first_name
                middle_name
                last_name
                adm_no
                student_guardian_detail {
                    guardian_home_address
                }
            }
            apr
            aprdt
            aprinv
            aprmode
            may
            maydt
            mayinv
            maymode
            jun
            jundt
            juninv
            junmode
            jul
            juldt
            julinv
            julmode
            aug
            augdt
            auginv
            augmode
            sep
            sepdt
            sepinv
            sepmode
            oct
            octdt
            octinv
            octmode
            nov
            novdt
            novinv
            novmode
            dec
            decdt
            decinv
            decmode
            jan
            jandt
            janinv
            janmode
            feb
            febdt
            febinv
            febmode
            mar
            mardt
            marinv
            marmode
        }
    }
`;

export const INVOICE_TRANSPORT = gql`
    query TransportFeeInvoice($studentId: String!) {
        transport_fees_by_pk(student_id: $studentId) {
            school_class_detail {
                fees
                class_name
                class_section
            }
            school_student {
                first_name
                middle_name
                last_name
                adm_no
                student_guardian_detail {
                    guardian_home_address
                }
            }
            apr
            aprdt
            aprinv
            aprmode
            may
            maydt
            mayinv
            maymode
            jun
            jundt
            juninv
            junmode
            jul
            juldt
            julinv
            julmode
            aug
            augdt
            auginv
            augmode
            sep
            sepdt
            sepinv
            sepmode
            oct
            octdt
            octinv
            octmode
            nov
            novdt
            novinv
            novmode
            dec
            decdt
            decinv
            decmode
            jan
            jandt
            janinv
            janmode
            feb
            febdt
            febinv
            febmode
            mar
            mardt
            marinv
            marmode
        }
    }
`;

export const GET_CLASS_NAME_LIST = gql`
    query ClassGroupList($schoolId: String, $session: String) {
        class_group(where: { school_id: { _eq: $schoolId }, session: { _eq: $session } }) {
            cg_id
            cg_name
        }
    }
`;

export const GET_CLASS_SECTION_LIST = gql`
    query ClassListBySession($cgId: String, $session: String) {
        school_class_details(where: { cg_id: { _eq: $cgId }, session: { _eq: $session } }, order_by: { created_at: asc }) {
            class_id
            class_name
            class_section
        }
    }
`;

export const GET_ALL_TEACHERS_TIMETABLE = gql`
    query AllTeacherTT($gusid: String!) {
        school_info_by_pk(gusid: $gusid) {
            school_teachers(where: { teacher_active: { _eq: true } }, order_by: { teacher_name: asc }) {
                teacher_name
                subjects(order_by: { tseq: asc }) {
                    school_class_detail {
                        class_name
                        class_section
                    }
                    tseq
                    sn
                    timing
                    days
                }
            }
        }
    }
`;

export const GET_ALL_CLASS_TIMETABLE = gql`
    query AllClassTT($gusid: String!, $session: String) {
        school_info_by_pk(gusid: $gusid) {
            class_groups(where: { session: { _eq: $session } }, order_by: { seq: asc }) {
                school_class_details(order_by: { seq: asc }) {
                    class_name
                    class_section
                    csubs(order_by: { seq: asc }) {
                        seq
                        sn
                        timing
                        days
                        school_teacher {
                            teacher_name
                        }
                    }
                }
            }
        }
    }
`;

export const PERMISSON_LIST_DOWNLOAD = gql`
    query PermissionDownload($schoolId: String!) {
        school_info_by_pk(gusid: $schoolId) {
            school_account {
                email_id
                read
                mod
            }
            school_secguard {
                email_id
                read
                mod
            }
            school_smr {
                email_id
                read
                mod
            }
            school_staffs(where: { staff_role: { _eq: "Conductor" }, staff_active: { _eq: true } }, order_by: { staff_name: asc }) {
                staff_name
                staff_email
                read
            }
        }
    }
`;

export const VEHICLES_LIST = gql`
    query VehicleList($schoolId: String!) {
        school_info_by_pk(gusid: $schoolId) {
            trans_data(where: { in_serv: { _eq: "Yes" } }) {
                td_id
                veh_name
                veh_no
            }
        }
    }
`;

export const EXAM_LIST = gql`
    # query SchoolExamList($schoolId: String, $session: String) {
    #     school_exams(where: { school_id: { _eq: $schoolId }, session: { _eq: $session } }, order_by: { created_at: desc }) {
    #         exam_name
    #     }
    # }

    query TeacherExams($schoolId: String, $session: String, $className: String, $classNameSection: String) {
        school_exams(
            where: {
                school_id: { _eq: $schoolId }
                session: { _eq: $session }
                exam_conducted: { _eq: true }
                _and: {
                    _or: [
                        { exam_for_class: { _eq: "School" } }
                        { exam_for_class: { _eq: $className } }
                        { exam_for_class: { _eq: $classNameSection } }
                    ]
                }
            }
            order_by: { created_at: asc }
        ) {
            exam_name
            exam_type
            exam_id
        }
    }
`;

export const GET_EXSU_ID = gql`
    query ExsuID($classId: String, $examId: String) {
        exam_subject(where: { class_id: { _eq: $classId }, exam_id: { _eq: $examId } }) {
            exsu_id
        }
    }
`;

export const GET_MARKSHEET_DIVISION_CRITERIA = gql`
    query DivisionCriteria($schoolId: String!, $session: String) {
        division_criteria(where: { school_id: { _eq: $schoolId }, session: { _eq: $session } }) {
            r1a
            r1b
            v1
            r2a
            r2b
            v2
            r3a
            r3b
            v3
            r4a
            r4b
            v4
            r5a
            r5b
            v5
            r6a
            r6b
            v6
            r7a
            r7b
            v7
        }
    }
`;

export const GET_MARKSHEET_GRADE_CRITERIA = gql`
    query GradeCriteria($schoolId: String!, $session: String) {
        grade_criteria(where: { school_id: { _eq: $schoolId }, session: { _eq: $session } }) {
            r1a
            r1b
            v1
            r2a
            r2b
            v2
            r3a
            r3b
            v3
            r4a
            r4b
            v4
            r5a
            r5b
            v5
            r6a
            r6b
            v6
            r7a
            r7b
            v7
        }
    }
`;

export const GET_MARKSHEET_SCHOOLINFO_SUBJECTANDTOTALMARKS = gql`
    query MSDataAndSM($schoolId: String!, $exsuId: String!) {
        marksheet_data_by_pk(school_id: $schoolId) {
            school_id
            school_name
            address
            contact
            school_info {
                logo
                affliated_to
                affliation_no
            }
        }
        exam_subject_by_pk(exsu_id: $exsuId) {
            result_subject {
                min_perc
                sub1
                total1
                sub2
                total2
                sub3
                total3
                sub4
                total4
                sub5
                total5
                sub6
                total6
                sub7
                total7
                sub8
                total8
                sub9
                total9
                sub10
                total10
                sub11
                total11
                sub12
                total12
                sub13
                total13
                sub14
                total14
                sub15
                total15
            }
        }
    }
`;

export const GET_MARKSHEET_FINAL_RESULT_BY_RN = gql`
    query FinalResultPrintByRN($classId: String, $rollNo: String, $exsuId: String) {
        school_student_classes(where: { class_id: { _eq: $classId }, roll_no: { _eq: $rollNo } }) {
            roll_no
            school_class_detail {
                class_name
                class_section
            }
            school_student {
                first_name
                middle_name
                last_name
                birth_date
                student_father_detail {
                    father_name
                    father_home_address
                }
                student_mother_detail {
                    mother_name
                }
            }
            final_result(where: { exsu_id: { _eq: $exsuId } }) {
                m1
                m2
                m3
                m4
                m5
                m6
                m7
                m8
                m9
                m10
                m11
                m12
                m13
                m14
                m15
                status
                grade
                rank
                obtained
            }
        }
    }
`;

export const GET_MARKSHEET_RESULT_BY_RN = gql`
    query ResultPrintByRN($classId: String, $rollNo: String, $exsuId: String) {
        school_student_classes(where: { class_id: { _eq: $classId }, roll_no: { _eq: $rollNo } }) {
            roll_no
            school_class_detail {
                class_name
                class_section
            }
            school_student {
                first_name
                middle_name
                last_name
                birth_date
                student_father_detail {
                    father_name
                    father_home_address
                }
                student_mother_detail {
                    mother_name
                }
            }
            results(where: { exsu_id: { _eq: $exsuId } }) {
                m1
                m2
                m3
                m4
                m5
                m6
                m7
                m8
                m9
                m10
                m11
                m12
                m13
                m14
                m15
                status
                grade
                rank
                obtained
            }
        }
    }
`;

export const GET_MARKSHEET_FINAL_RESULT_BY_CLASS = gql`
    query FinalResultPrintByClass($classId: String, $exsuId: String) {
        school_student_classes(where: { class_id: { _eq: $classId } }) {
            roll_no
            school_class_detail {
                class_name
                class_section
            }
            school_student {
                first_name
                middle_name
                last_name
                birth_date
                student_father_detail {
                    father_name
                    father_home_address
                }
                student_mother_detail {
                    mother_name
                }
            }
            final_result(where: { exsu_id: { _eq: $exsuId } }) {
                m1
                m2
                m3
                m4
                m5
                m6
                m7
                m8
                m9
                m10
                m11
                m12
                m13
                m14
                m15
                status
                grade
                rank
                obtained
            }
        }
    }
`;

export const GET_MARKSHEET_RESULT_BY_CLASS = gql`
    query ResultPrintByClass($classId: String, $exsuId: String) {
        school_student_classes(where: { class_id: { _eq: $classId } }) {
            roll_no
            school_class_detail {
                class_name
                class_section
            }
            school_student {
                first_name
                middle_name
                last_name
                birth_date
                student_father_detail {
                    father_name
                    father_home_address
                }
                student_mother_detail {
                    mother_name
                }
            }
            results(where: { exsu_id: { _eq: $exsuId } }) {
                m1
                m2
                m3
                m4
                m5
                m6
                m7
                m8
                m9
                m10
                m11
                m12
                m13
                m14
                m15
                status
                grade
                rank
                obtained
            }
        }
    }
`;

export const GET_IS_PIN_VERIFIED = gql`
    query PCodeRes($gusid: String, $pCode: String) {
        school_info(where: { gusid: { _eq: $gusid }, pcode: { _eq: $pCode } }) {
            gusid
        }
    }
`;

export const GET_MANAGMENT_DASH_INFO = gql`
    query Dashboard($gusid: String!, $session: String, $ses: Int) {
        school_info_by_pk(gusid: $gusid) {
            credits
            adm_credit
            class_fees_aggregate(where: { ses: { _eq: $ses } }) {
                aggregate {
                    count(columns: student_id)
                }
            }
            transport_fees_aggregate(where: { ses: { _eq: $ses } }) {
                aggregate {
                    count(columns: student_id)
                }
            }
            school_teachers_aggregate(where: { teacher_active: { _eq: true } }) {
                aggregate {
                    count(columns: teacher_id)
                }
            }
            school_staffs_aggregate(where: { staff_active: { _eq: true } }) {
                aggregate {
                    count(columns: staff_id)
                }
            }
            school_class_details_aggregate(where: { session: { _eq: $session } }) {
                aggregate {
                    count(columns: class_id)
                }
            }
            class_groups_aggregate(where: { session: { _eq: $session } }) {
                aggregate {
                    count(columns: cg_id)
                }
            }
            school_notices_aggregate(where: { session: { _eq: $session } }) {
                aggregate {
                    count(columns: id)
                }
            }
            school_exams_aggregate(where: { session: { _eq: $session } }) {
                aggregate {
                    count(columns: exam_id)
                }
            }
            trans_routes_aggregate {
                aggregate {
                    count(columns: tr_id)
                }
            }
            trans_routes {
                stopages_aggregate {
                    aggregate {
                        count(columns: st_id)
                    }
                }
            }
            trans_data_aggregate {
                aggregate {
                    count(columns: td_id)
                }
            }
            school_notices(order_by: { created_at: desc }, limit: 5, where: { published: { _eq: true } }) {
                id
                level
                subject
                created_at
            }
            events(order_by: { created_at: desc }, limit: 5) {
                id
                created_at
                name
                date
            }
        }
    }
`;

export const GET_DASH_CLASS_FEES = gql`
    query AnnualFeeSummaryBySession($gusid: String!, $ses: Int) {
        school_info_by_pk(gusid: $gusid) {
            class_fees_aggregate(where: { ses: { _eq: $ses } }) {
                aggregate {
                    count(columns: student_id)
                    sum {
                        apr
                        may
                        jun
                        jul
                        aug
                        sep
                        oct
                        nov
                        dec
                        jan
                        feb
                        mar
                    }
                }
            }
        }
    }
`;

export const GET_DASH_ESSENTIAL_FEES = gql`
    query AnnualFeeSummaryBySession($gusid: String!, $ses: Int) {
        school_info_by_pk(gusid: $gusid) {
            essen_fees_aggregate(where: { ses: { _eq: $ses } }) {
                aggregate {
                    sum {
                        admfee
                        f2
                        f3
                        f4
                        f5
                        f6
                        f7
                        f8
                        f9
                        f10
                    }
                }
            }
        }
    }
`;

export const GET_DASH_TRANSPORT_FEES = gql`
    query AnnualFeeSummaryBySession($gusid: String!, $ses: Int) {
        school_info_by_pk(gusid: $gusid) {
            transport_fees_aggregate(where: { ses: { _eq: $ses } }) {
                aggregate {
                    sum {
                        apr
                        may
                        jun
                        jul
                        aug
                        sep
                        oct
                        nov
                        dec
                        jan
                        feb
                        mar
                    }
                }
            }
        }
    }
`;

export const GET_DASH_STUDENT_INFO = gql`
    query Students($schoolId: String!, $session: String) {
        school_info_by_pk(gusid: $schoolId) {
            females: school_students_aggregate(where: { gender: { _eq: "F" }, session: { _eq: $session } }) {
                aggregate {
                    count(columns: student_id)
                }
            }
            others: school_students_aggregate(where: { gender: { _eq: "O" }, session: { _eq: $session } }) {
                aggregate {
                    count(columns: student_id)
                }
            }
            gen: school_students_aggregate(where: { category: { _eq: "Gen" }, session: { _eq: $session } }) {
                aggregate {
                    count(columns: student_id)
                }
            }
            obc: school_students_aggregate(where: { category: { _eq: "OBC" }, session: { _eq: $session } }) {
                aggregate {
                    count(columns: student_id)
                }
            }
            sc: school_students_aggregate(where: { category: { _eq: "SC" }, session: { _eq: $session } }) {
                aggregate {
                    count(columns: student_id)
                }
            }
            st: school_students_aggregate(where: { category: { _eq: "ST" }, session: { _eq: $session } }) {
                aggregate {
                    count(columns: student_id)
                }
            }
        }
    }
`;

export const PAST_DUE_FEES_BY_ID = gql`
    query PastDueByID($studentId: String!) {
        class_fees_by_pk(student_id: $studentId) {
            past_due
            pdudt
            pdudb
        }
    }
`;

export const GET_STUDENT_DETAILS_BY_RN = gql`
    query FeeStatementByRN($classId: String, $rollNo: String) {
        school_student_classes(where: { class_id: { _eq: $classId }, roll_no: { _eq: $rollNo } }) {
            student_id
            school_student {
                first_name
                middle_name
                last_name
            }
            roll_no
            admission_date
        }
    }
`;

export const GET_FEES_STATMENT = gql`
    query FeeStatementByRN($studentId: String!) {
        school_student_classes_by_pk(student_id: $studentId) {
            class_fee {
                past_due
                pdudt
                apr
                aprdt
                may
                maydt
                jun
                jundt
                jul
                juldt
                aug
                augdt
                sep
                sepdt
                oct
                octdt
                nov
                novdt
                dec
                decdt
                jan
                jandt
                feb
                febdt
                mar
                mardt
            }
            school_class_detail {
                fees
                class_feetype {
                    adm
                    f2
                    f2f
                    f3
                    f3f
                    f4
                    f4f
                    f5
                    f5f
                    f6
                    f6f
                    f7
                    f7f
                    f8
                    f8f
                    f9
                    f9f
                    f10
                    f10f
                }
            }
            essen_fee {
                admfee
                admdt
                f2
                f2dt
                f3
                f3dt
                f4
                f4dt
                f5
                f5dt
                f6
                f6dt
                f7
                f7dt
                f8
                f8dt
                f9
                f9dt
                f10
                f10dt
            }
            transport_fee {
                apr
                aprdt
                may
                maydt
                jun
                jundt
                jul
                juldt
                aug
                augdt
                sep
                sepdt
                oct
                octdt
                nov
                novdt
                dec
                decdt
                jan
                jandt
                feb
                febdt
                mar
                mardt
                stopage {
                    fees
                }
            }
        }
    }
`;

export const GET_STUDENT_DD_LIST = gql`
    query StudentIDForFeeDetails($classId: String) {
        school_student_classes(where: { class_id: { _eq: $classId }, is_free: { _eq: false } }, order_by: { roll_no: asc }) {
            roll_no
            student_id
            school_student {
                first_name
                middle_name
                last_name
            }
        }
    }
`;

export const NoFeesStudentList = gql`
    query FreeStudents($schoolId: String, $ses: Int) {
        school_student_classes(
            order_by: { school_class_detail: { seq: asc } }
            where: { school_id: { _eq: $schoolId }, ses: { _eq: $ses }, is_free: { _eq: true } }
        ) {
            school_class_detail {
                class_name
                class_section
            }
            admission_date
            roll_no
            student_id
            school_student {
                first_name
                middle_name
                last_name
                student_guardian_detail {
                    guardian_name
                    guardian_contact1
                    guardian_contact2
                }
            }
        }
    }
`;
