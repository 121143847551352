import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_ESSENTIAL_ADMFEE_STATUS = gql`
    mutation AdmFeeUpsert(
        $studentId: String!
        $admFee: Int
        $admMode: String
        $admTxn: String
        $admDt: String
        $admInv: String
        $admUdb: String
    ) {
        update_essen_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { admfee: $admFee, admmode: $admMode, admtxn: $admTxn, admdt: $admDt, adminv: $admInv, admudb: $admUdb }
        ) {
            adminv
            student_id
        }
    }
`;

export const UPDATE_ESSENTIAL_F2_STATUS = gql`
    mutation F2FeeUpsert($studentId: String!, $f2: Int, $f2Mode: String, $f2Txn: String, $f2Dt: String, $f2Inv: String, $f2Udb: String) {
        update_essen_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { f2: $f2, f2mode: $f2Mode, f2txn: $f2Txn, f2dt: $f2Dt, f2inv: $f2Inv, f2udb: $f2Udb }
        ) {
            f2inv
            student_id
        }
    }
`;

export const UPDATE_ESSENTIAL_F3_STATUS = gql`
    mutation F3FeeUpsert($studentId: String!, $f3: Int, $f3Mode: String, $f3Txn: String, $f3Dt: String, $f3Inv: String, $f3Udb: String) {
        update_essen_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { f3: $f3, f3mode: $f3Mode, f3txn: $f3Txn, f3dt: $f3Dt, f3inv: $f3Inv, f3udb: $f3Udb }
        ) {
            f3inv
            student_id
        }
    }
`;

export const UPDATE_ESSENTIAL_F4_STATUS = gql`
    mutation F4FeeUpsert($studentId: String!, $f4: Int, $f4Mode: String, $f4Txn: String, $f4Dt: String, $f4Inv: String, $f4Udb: String) {
        update_essen_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { f4: $f4, f4mode: $f4Mode, f4txn: $f4Txn, f4dt: $f4Dt, f4inv: $f4Inv, f4udb: $f4Udb }
        ) {
            f4inv
            student_id
        }
    }
`;

export const UPDATE_ESSENTIAL_F5_STATUS = gql`
    mutation F5FeeUpsert($studentId: String!, $f5: Int, $f5Mode: String, $f5Txn: String, $f5Dt: String, $f5Inv: String, $f5Udb: String) {
        update_essen_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { f5: $f5, f5mode: $f5Mode, f5txn: $f5Txn, f5dt: $f5Dt, f5inv: $f5Inv, f5udb: $f5Udb }
        ) {
            f5inv
            student_id
        }
    }
`;

export const UPDATE_ESSENTIAL_F6_STATUS = gql`
    mutation F6FeeUpsert($studentId: String!, $f6: Int, $f6Mode: String, $f6Txn: String, $f6Dt: String, $f6Inv: String, $f6Udb: String) {
        update_essen_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { f6: $f6, f6mode: $f6Mode, f6txn: $f6Txn, f6dt: $f6Dt, f6inv: $f6Inv, f6udb: $f6Udb }
        ) {
            f6inv
            student_id
        }
    }
`;

export const UPDATE_ESSENTIAL_F7_STATUS = gql`
    mutation F7FeeUpsert($studentId: String!, $f7: Int, $f7Mode: String, $f7Txn: String, $f7Dt: String, $f7Inv: String, $f7Udb: String) {
        update_essen_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { f7: $f7, f7mode: $f7Mode, f7txn: $f7Txn, f7dt: $f7Dt, f7inv: $f7Inv, f7udb: $f7Udb }
        ) {
            f7inv
            student_id
        }
    }
`;

export const UPDATE_ESSENTIAL_F8_STATUS = gql`
    mutation F8FeeUpsert($studentId: String!, $f8: Int, $f8Mode: String, $f8Txn: String, $f8Dt: String, $f8Inv: String, $f8Udb: String) {
        update_essen_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { f8: $f8, f8mode: $f8Mode, f8txn: $f8Txn, f8dt: $f8Dt, f8inv: $f8Inv, f8udb: $f8Udb }
        ) {
            f8inv
            student_id
        }
    }
`;

export const UPDATE_ESSENTIAL_F9_STATUS = gql`
    mutation F9FeeUpsert($studentId: String!, $f9: Int, $f9Mode: String, $f9Txn: String, $f9Dt: String, $f9Inv: String, $f9Udb: String) {
        update_essen_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { f9: $f9, f9mode: $f9Mode, f9txn: $f9Txn, f9dt: $f9Dt, f9inv: $f9Inv, f9udb: $f9Udb }
        ) {
            f9inv
            student_id
        }
    }
`;

export const UPDATE_ESSENTIAL_F10_STATUS = gql`
    mutation F10FeeUpsert(
        $studentId: String!
        $f10: Int
        $f10Mode: String
        $f10Txn: String
        $f10Dt: String
        $f10Inv: String
        $f10Udb: String
    ) {
        update_essen_fees_by_pk(
            pk_columns: { student_id: $studentId }
            _set: { f10: $f10, f10mode: $f10Mode, f10txn: $f10Txn, f10dt: $f10Dt, f10inv: $f10Inv, f10udb: $f10Udb }
        ) {
            f10inv
            student_id
        }
    }
`;
