import { Card, Grid, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import Body from './Body';
import Header from './Header/index';
import './custom-card.css';

function CustomCard({
    headCells,
    records = [],
    title = { isTitle: false },
    search = { isSearch: false },
    button = { isButton: false },
    session = { isSession: false },
    select = { isSelect: false },
    csvExport = { isCsvExport: false }
}) {
    const theme = useTheme();
    const [name, setName] = useState('');
    const [filteredTableData, setFilteredTableData] = useState(records);

    useEffect(() => {
        setFilteredTableData(records);
    }, [records]);

    const filter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const result = records.filter((o) => Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(keyword.toLowerCase())));
            setFilteredTableData(result);
        } else {
            setFilteredTableData(records);
        }
        setName(keyword);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} lg={12} md={12}>
                    {title.isTitle || search.isSearch || button.isButton || session.isSession || select.isSelect ? (
                        <Card
                            sx={{
                                pb: 1,
                                mb: 1,
                                borderBottom: '1px solid',
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[200]
                            }}
                        >
                            <Header
                                title={{ isTitle: title.isTitle, position: title.position ? title.position : 1, ...title }}
                                search={{
                                    isSearch: search.isSearch,
                                    position: search.position ? search.position : 2,
                                    name,
                                    filter,
                                    ...search
                                }}
                                button={{ isButton: button.isButton, position: button.position ? button.position : 3, ...button }}
                                session={{ isSession: session.isSession, position: session.position ? session.position : 4, ...session }}
                                select={{ isSelect: select.isSelect, position: select.position ? select.position : 5, ...select }}
                                csvExport={{
                                    isCsvExport: csvExport.isCsvExport,
                                    position: csvExport.position ? csvExport.position : 5,
                                    ...csvExport
                                }}
                            />
                        </Card>
                    ) : null}
                    <Body headCells={headCells} filteredTableData={filteredTableData} />
                </Grid>
            </Grid>
        </>
    );
}

export default CustomCard;
