import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    teacherDashData: {}
};

export const teacherSlice = createSlice({
    name: 'teacher',
    initialState,
    reducers: {
        setTeacherDashData: (state, action) => {
            state.teacherDashData = action.payload;
        }
    }
});

export const { setTeacherDashData } = teacherSlice.actions;

export default teacherSlice.reducer;
