import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import landingAnimation from 'assets/lotties/education.json';
import Lottie from 'react-lottie';
import useAuth from 'hooks/useAuth';

const lottieLoadingOptions = {
    loop: true,
    autoplay: true,
    animationData: landingAnimation
};

const HeaderPage = () => {
    const { isLoggedIn } = useAuth();
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Container>
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={gridSpacing}
                sx={{
                    mt: { xs: 2.5, sm: 2.5 },
                    mb: { xs: 2.5, sm: 0 }
                }}
            >
                <Grid item xs={12} md={5}>
                    <Grid container spacing={gridSpacing} sx={{ [theme.breakpoints.down('lg')]: { pr: 0, textAlign: 'center' } }}>
                        <Grid item xs={12}>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30
                                }}
                            >
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontSize: { xs: '2.25rem', sm: '3rem' },
                                        fontWeight: 900,
                                        lineHeight: 1.4
                                    }}
                                >
                                    School Management System
                                </Typography>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12}>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 0.2
                                }}
                            >
                                <Typography
                                    variant="h3"
                                    component="div"
                                    color="inherit"
                                    sx={{
                                        fontSize: { xs: '1rem', md: '1.125rem' },
                                        // fontWeight: 500,
                                        lineHeight: 1.4
                                    }}
                                >
                                    Efficiently Manage Every Aspect of Your School
                                </Typography>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12}>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 0.2
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    component="div"
                                    color="inherit"
                                    sx={{
                                        fontSize: { xs: '1rem', md: '1.125rem' },
                                        fontWeight: 400,
                                        lineHeight: 1.4
                                    }}
                                >
                                    In summary, implementing a School Management System brings efficiency, accuracy, and convenience to
                                    administrative tasks, promotes effective communication, enhances parental involvement, and enables
                                    data-driven decision-making, ultimately leading to improved educational outcomes.
                                </Typography>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} sx={{ my: 3.25 }}>
                            <motion.div
                                initial={{ opacity: 0, translateY: 550 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 150,
                                    damping: 30,
                                    delay: 0.4
                                }}
                            >
                                <Grid container spacing={2} sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }}>
                                    <Grid item>
                                        {isLoggedIn ? (
                                            <AnimateButton>
                                                <Button
                                                    // component={RouterLink}
                                                    // to="/dashboard/default"
                                                    // target="_blank"
                                                    size="large"
                                                    variant="contained"
                                                    color="secondary"
                                                    sx={{
                                                        color: 'white'
                                                    }}
                                                    onClick={() => navigate('/checkout', { replace: true })}
                                                >
                                                    Purchase Now
                                                </Button>
                                            </AnimateButton>
                                        ) : (
                                            <AnimateButton>
                                                <Button
                                                    component={RouterLink}
                                                    to="register"
                                                    size="large"
                                                    variant="contained"
                                                    color="secondary"
                                                    sx={{
                                                        color: 'white'
                                                    }}
                                                >
                                                    Register
                                                </Button>
                                            </AnimateButton>
                                        )}
                                    </Grid>
                                </Grid>
                            </motion.div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={7} sx={{ display: 'flex' }}>
                    <Box sx={{ position: 'relative', mt: 0 }}>
                        <Box sx={{ height: { sm: 700 }, width: { sm: 950 } }}>
                            <Lottie options={lottieLoadingOptions} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default HeaderPage;
