import { FiberManualRecord } from '@mui/icons-material';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, timelineItemClasses } from '@mui/lab';
import { Box, Container, Paper, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function CustomizedTimeline() {
    const isDesk = useMediaQuery('(min-width:1279px)');
    const theme = useTheme();
    const paper = {
        p: 2.5,
        boxShadow: 'none',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        border: '1px dashed',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary.dark
    };
    return (
        <Container>
            <Box paddingY={{ xs: 4, sm: 6, md: 8 }}>
                <Typography variant="h2" gutterBottom align="center" sx={{ fontWeight: 700, fontSize: '2.5em' }}>
                    Benefits of using PenBoox
                </Typography>
                <Timeline
                    position={isDesk ? 'alternate' : 'right'}
                    sx={
                        !isDesk
                            ? {
                                  [`& .${timelineItemClasses.root}:before`]: {
                                      flex: 0,
                                      padding: 0
                                  }
                              }
                            : null
                    }
                >
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="secondary">
                                <FiberManualRecord fontSize="1rem" />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ pr: { xs: 0, sm: 2 } }}>
                            <Paper elevation={3} sx={paper}>
                                <Typography variant="h4" component="h1">
                                    Efficient Administration
                                </Typography>
                                <Typography>
                                    School Management System automates various administrative tasks, such as admissions, student enrollment,
                                    attendance tracking, timetable scheduling, staff management, and fee management. This streamlines
                                    processes, reduces paperwork, and saves time and effort for administrators.
                                </Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="primary">
                                <FiberManualRecord fontSize="1rem" />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ pr: { xs: 0, sm: 2 } }}>
                            <Paper elevation={3} sx={paper}>
                                <Typography variant="h4" component="h1">
                                    Improved Communication
                                </Typography>
                                <Typography>
                                    School Management System facilitates effective communication between teachers, administrators, students,
                                    and parents. It provides a centralized platform for sharing important announcements, academic updates,
                                    event details, examination schedules, and homework assignments. This enhances transparency and ensures
                                    everyone stays informed
                                </Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="secondary">
                                <FiberManualRecord fontSize="1rem" />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ pr: { xs: 0, sm: 2 } }}>
                            <Paper elevation={3} sx={paper}>
                                <Typography variant="h4" component="h1">
                                    Enhanced Parental Involvement
                                </Typography>
                                <Typography>
                                    With an School Management System, parents can access real-time information about their child&apos;s
                                    attendance, academic progress, exam results, and fee payments. They can communicate with teachers and
                                    stay updated on school activities. This promotes parental involvement and fosters a collaborative
                                    approach to education.
                                </Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="primary">
                                <FiberManualRecord fontSize="1rem" />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ pr: { xs: 0, sm: 2 } }}>
                            <Paper elevation={3} sx={paper}>
                                <Typography variant="h4" component="h1">
                                    Accurate Attendance Tracking
                                </Typography>
                                <Typography>
                                    Traditional manual attendance systems are prone to errors and time-consuming. An School Management
                                    System automates attendance tracking, using methods like biometrics, smart cards, or online check-ins.
                                    This ensures accurate records, reduces truancy, and simplifies the overall process.
                                </Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="secondary">
                                <FiberManualRecord fontSize="1rem" />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ pr: { xs: 0, sm: 2 } }}>
                            <Paper elevation={3} sx={paper}>
                                <Typography variant="h4" component="h1">
                                    Streamlined Grading and Reporting
                                </Typography>
                                <Typography>
                                    School Management Systems provide digital platforms for teachers to record and manage student&apos;s
                                    grades, generate report cards, and share progress reports with parents. This eliminates manual
                                    calculation errors and makes the grading process more efficient.
                                </Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="primary">
                                <FiberManualRecord fontSize="1rem" />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ pr: { xs: 0, sm: 2 } }}>
                            <Paper elevation={3} sx={paper}>
                                <Typography variant="h4" component="h1">
                                    Efficient Exam Management
                                </Typography>
                                <Typography>
                                    School Management System assists in organizing examinations, including creating exam schedules,
                                    assigning classrooms, managing exam seating arrangements, and generating hall tickets or admit cards. It
                                    simplifies the entire examination process and ensures smooth coordination.
                                </Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="secondary">
                                <FiberManualRecord fontSize="1rem" />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ pr: { xs: 0, sm: 2 } }}>
                            <Paper elevation={3} sx={paper}>
                                <Typography variant="h4" component="h1">
                                    Centralized Data Management
                                </Typography>
                                <Typography>
                                    An School Management System acts as a centralized repository for storing and managing student records,
                                    including personal details, academic performance, attendance history, and health records. It enables
                                    easy retrieval of information and eliminates the need for physical file storage
                                </Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="primary">
                                <FiberManualRecord fontSize="1rem" />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ pr: { xs: 0, sm: 2 } }}>
                            <Paper elevation={3} sx={paper}>
                                <Typography variant="h4" component="h1">
                                    Financial Management
                                </Typography>
                                <Typography>
                                    School Management Systems facilitate effective management of financial aspects such as fee collection,
                                    payment tracking, generating receipts, and managing accounts. This ensures accurate financial records
                                    and simplifies auditing processes.
                                </Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="secondary">
                                <FiberManualRecord fontSize="1rem" />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ pr: { xs: 0, sm: 2 } }}>
                            <Paper elevation={3} sx={paper}>
                                <Typography variant="h4" component="h1">
                                    Customizable and Scalable
                                </Typography>
                                <Typography>
                                    School Management System solutions can be tailored to suit the specific needs of educational
                                    institutions. They can accommodate various modules and features, and as the school grows, the system can
                                    be scaled up to handle additional functionalities and accommodate more students and staff.
                                </Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="primary">
                                <FiberManualRecord fontSize="1rem" />
                            </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent sx={{ pr: { xs: 0, sm: 2 } }}>
                            <Paper elevation={3} sx={paper}>
                                <Typography variant="h4" component="h1">
                                    Data Analysis and Insights
                                </Typography>
                                <Typography>
                                    With a School Management System, administrators and teachers can access comprehensive reports and
                                    analytics on various aspects such as student performance, attendance trends, fee collections, and more.
                                    These insights aid decision-making and enable proactive interventions to improve the overall educational
                                    experience.
                                </Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </Box>
        </Container>
    );
}
