/* eslint-disable import/prefer-default-export */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
    csvExportHeader: [],
    csvExportData: [],
    cartItemList: []
};

export const commonWPSlice = createSlice({
    name: 'managment',
    initialState,
    reducers: {
        setServiceWorkerInitialized: (state, action) => {
            state.serviceWorkerInitialized = !state.serviceWorkerInitialized;
        },
        setServiceWorkerUpdated: (state, action) => {
            state.serviceWorkerUpdated = action.payload;
        },
        setServiceWorkerRegistration: (state, action) => {
            state.serviceWorkerUpdated = !state.serviceWorkerUpdated;
            state.serviceWorkerRegistration = action.payload;
        },
        setCSVExportHeader: (state, action) => {
            state.csvExportHeader = action.payload;
        },
        setCSVExportData: (state, action) => {
            state.csvExportData = action.payload;
        },
        setCartItemList: (state, action) => {
            state.cartItemList = action.payload;
        }
    }
});

export const {
    setServiceWorkerInitialized,
    setServiceWorkerUpdated,
    setServiceWorkerRegistration,
    setCSVExportHeader,
    setCSVExportData,
    setCartItemList
} = commonWPSlice.actions;

export default commonWPSlice.reducer;
