import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Parent Imports
const ParentHome = Loadable(lazy(() => import('views/parent/Home')));
const ParentChildren = Loadable(lazy(() => import('views/parent/Childrens')));
const Approval = Loadable(lazy(() => import('views/parent/Approval')));
const Transport = Loadable(lazy(() => import('views/parent/Transport')));

// Children Iport
const StudentClasses = Loadable(lazy(() => import('views/student/Classes')));
const StudentNotice = Loadable(lazy(() => import('views/student/Notice')));
const StudentExams = Loadable(lazy(() => import('views/student/Exams')));
const StudentResults = Loadable(lazy(() => import('views/student/Results')));
const ResultsByExam = Loadable(lazy(() => import('views/student/Results/ResultDetails/ViewResult')));
const StudentAttendance = Loadable(lazy(() => import('views/student/Attendance')));
const StudentFees = Loadable(lazy(() => import('views/student/Fees')));
const StudentSubjects = Loadable(lazy(() => import('views/student/Subjects')));
const StudentOnlineClasses = Loadable(lazy(() => import('views/student/OnlineClasses')));
const StudentHomework = Loadable(lazy(() => import('views/student/HomeWork')));
const StudentVisitors = Loadable(lazy(() => import('views/parent/Approval/Visitors')));

// const StudentFindSchool = Loadable(lazy(() => import('views/common/FindSchool')));
// const StudentDetailsAndExamResult = Loadable(lazy(() => import('views/student/ResultOld/ViewResult')));
const Profile = Loadable(lazy(() => import('views/common/Profile')));
const Account = Loadable(lazy(() => import('views/common/Account')));

// ==============================|| MAIN ROUTING ||============================== //

const ParentRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <ParentHome />
        },
        {
            path: '/children',
            element: <ParentChildren />
        },
        {
            path: '/children/classes',
            element: <StudentClasses />
        },
        {
            path: '/children/notice',
            element: <StudentNotice />
        },
        {
            path: '/children/exam',
            element: <StudentExams />
        },
        {
            path: '/children/result',
            element: <StudentResults />
            // children: [
            //     {
            //         path: ':resultId',
            //         element: <StudentDetailsAndExamResult />
            //     }
            // ]
        },
        {
            path: '/children/result/resultbyexam',
            element: <ResultsByExam />
        },
        {
            path: '/children/attendance',
            element: <StudentAttendance />
        },
        {
            path: '/children/fees',
            element: <StudentFees />
        },
        {
            path: '/children/subjects',
            element: <StudentSubjects />
        },
        {
            path: '/children/onlineclasses',
            element: <StudentOnlineClasses />
        },
        {
            path: '/children/homework',
            element: <StudentHomework />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/account',
            element: <Account />
        },
        {
            path: '/approval',
            element: <Approval />
        },
        {
            path: '/approval/visitors',
            element: <StudentVisitors />
        },
        {
            path: '/transport',
            element: <Transport />
        },
        {
            path: '/transport/transportdetails',
            element: <></>
        }
        // ,
        // {
        //     path: '/findschool',
        //     element: <StudentFindSchool />
        // }
    ]
};

export default ParentRoutes;
