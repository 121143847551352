/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const ACCOUNTANT_ACESSINFO_INFO = gql`
    query AccountantAccess($sauid: String) {
        school_accounts(where: { sa_uid: { _eq: $sauid } }) {
            mod
            read
            school_id
            school_info {
                school_name
            }
        }
    }
`;

export const CLASS_GROUP_LIST = gql`
    query ClassGroupList($schoolId: String, $session: String) {
        class_group(where: { school_id: { _eq: $schoolId }, session: { _eq: $session } }) {
            cg_name
            cg_id
        }
    }
`;

export const CLASS_ROOM_LIST = gql`
    query ClassRooms($cgId: String) {
        school_class_details(where: { cg_id: { _eq: $cgId } }) {
            class_id
            class_section
            fees
        }
    }
`;

export const FEES_BY_ROLLNO = gql`
    query FeesByRN($classId: String, $rollNo: String) {
        school_student_classes(where: { class_id: { _eq: $classId }, roll_no: { _eq: $rollNo } }) {
            student_id
            # school_student {
            #     first_name
            #     middle_name
            #     last_name
            # }
            # class_fee {
            #     apr
            #     may
            #     jun
            #     jul
            #     aug
            #     sep
            #     oct
            #     nov
            #     dec
            #     jan
            #     feb
            #     mar
            # }
            # transport_fee {
            #     apr
            #     may
            #     jun
            #     jul
            #     aug
            #     sep
            #     oct
            #     nov
            #     dec
            #     jan
            #     feb
            #     mar
            # }
        }
    }
`;

export const CLASS_FEE_BY_SID = gql`
    query ClassFeesByRN($studentId: String) {
        school_student_classes(where: { student_id: { _eq: $studentId } }) {
            school_class_detail {
                fees
            }
            class_fee {
                apr
                may
                jun
                jul
                aug
                sep
                oct
                nov
                dec
                jan
                feb
                mar
            }
        }
    }
`;

export const TRANSPORT_FEE_BY_SID = gql`
    query TransportFeeBySID($studentId: String!) {
        transport_fees_by_pk(student_id: $studentId) {
            apr
            may
            jun
            jul
            aug
            sep
            oct
            nov
            dec
            jan
            feb
            mar
            stopage {
                fees
            }
        }
    }
`;

// export const ESSEN_FEE_ONE = gql`
//     query EssenFeeOne($studentId: String!) {
//         essen_fees_by_pk(student_id: $studentId) {
//             regfee
//             admfee
//             idfee
//         }
//     }
// `;

export const ESSEN_FEE_TYPE = gql`
    query EssenFeeType($classId: String!) {
        class_feetype_by_pk(class_id: $classId) {
            f2
            f3
            f4
            f5
            f6
            f7
            f8
            f9
            f10
            f2f
            f3f
            f4f
            f5f
            f6f
            f7f
            f8f
            f9f
            f10f
        }
    }
`;

export const ESSEN_FEE_AMOUNT = gql`
    query EssenFeeTotalAmount($studentId: String!) {
        essen_fees_by_pk(student_id: $studentId) {
            admfee
            f2
            f3
            f4
            f5
            f6
            f7
            f8
            f9
            f10
        }
    }
`;

export const ADMFEE = gql`
    query AdmFee($studentId: String!) {
        essen_fees_by_pk(student_id: $studentId) {
            admfee
            admmode
            admtxn
            admdt
            adminv
            admudb
        }
    }
`;

export const F2FEE = gql`
    query F2Fee($studentId: String!) {
        essen_fees_by_pk(student_id: $studentId) {
            f2
            f2mode
            f2txn
            f2dt
            f2inv
            f2udb
        }
    }
`;

export const F3FEE = gql`
    query F3Fee($studentId: String!) {
        essen_fees_by_pk(student_id: $studentId) {
            f3
            f3mode
            f3txn
            f3dt
            f3inv
            f3udb
        }
    }
`;

export const F4FEE = gql`
    query F4Fee($studentId: String!) {
        essen_fees_by_pk(student_id: $studentId) {
            f4
            f4mode
            f4txn
            f4dt
            f4inv
            f4udb
        }
    }
`;

export const F5FEE = gql`
    query F5Fee($studentId: String!) {
        essen_fees_by_pk(student_id: $studentId) {
            f5
            f5mode
            f5txn
            f5dt
            f5inv
            f5udb
        }
    }
`;

export const F6FEE = gql`
    query F6Fee($studentId: String!) {
        essen_fees_by_pk(student_id: $studentId) {
            f6
            f6mode
            f6txn
            f6dt
            f6inv
            f6udb
        }
    }
`;

export const F7FEE = gql`
    query F7Fee($studentId: String!) {
        essen_fees_by_pk(student_id: $studentId) {
            f7
            f7mode
            f7txn
            f7dt
            f7inv
            f7udb
        }
    }
`;

export const F8FEE = gql`
    query F8Fee($studentId: String!) {
        essen_fees_by_pk(student_id: $studentId) {
            f8
            f8mode
            f8txn
            f8dt
            f8inv
            f8udb
        }
    }
`;

export const F9FEE = gql`
    query F9Fee($studentId: String!) {
        essen_fees_by_pk(student_id: $studentId) {
            f9
            f9mode
            f9txn
            f9dt
            f9inv
            f9udb
        }
    }
`;

export const F10FEE = gql`
    query F10Fee($studentId: String!) {
        essen_fees_by_pk(student_id: $studentId) {
            f10
            f10mode
            f10txn
            f10dt
            f10inv
            f10udb
        }
    }
`;
