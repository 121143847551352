/* eslint-disable react/no-unescaped-entities */
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useAuth from 'hooks/useAuth';
import { useTheme } from '@mui/material/styles';
import {
    Analytics,
    Apartment,
    AppRegistration,
    ArrowForwardIosSharp,
    DirectionsBus,
    Groups,
    Inventory,
    LocalLibrary,
    ManageAccounts,
    Money,
    Schedule,
    Wc
} from '@mui/icons-material';
import {
    Container,
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary
} from '@mui/material';
import { styled } from '@mui/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const mock = [
    {
        key: 1,
        title: 'Student Information Management',
        icon: <ManageAccounts />,
        items: [
            {
                title: '1. Centralized and Accessible Student Data',
                subtitle:
                    'A Student Information Management system serves as a centralized repository for storing and managing student data. It allows administrators, teachers, and parents to access relevant information about students, including personal details, contact information, attendance records, academic performance, disciplinary records, and more. This centralized and easily accessible data improves the efficiency of administrative tasks, enhances communication, and enables informed decision-making.'
            },
            {
                title: '2. Streamlined Administrative Processes',
                subtitle:
                    'Student Information Management systems streamline various administrative processes, reducing manual paperwork and improving operational efficiency. These systems automate tasks such as student registration, enrollment, scheduling, grading, report generation, and transcript management. This automation saves time, minimizes errors, and allows administrators to focus on strategic initiatives, student support, and educational planning.'
            },
            {
                title: '3. Enhanced Communication and Collaboration',
                subtitle:
                    "Student Information Management systems facilitate effective communication and collaboration among stakeholders. Administrators can communicate important announcements, events, or updates to teachers, parents, and students through the system. Teachers can share progress reports, attendance records, and assignments with parents and students. Parents can access their child's academic information, communicate with teachers, and stay informed about their child's educational journey. This improved communication and collaboration promote parental involvement, student engagement, and a supportive learning environment."
            }
        ]
    },
    {
        key: 2,
        title: 'Attendance Management',
        icon: <AppRegistration />,
        items: [
            {
                title: '1. Accurate and Reliable Attendance Tracking',
                subtitle:
                    'An Attendance Management system allows for accurate and reliable tracking of student attendance. It eliminates manual processes prone to errors and ensures precise attendance records. This helps in maintaining accurate attendance data for individual students and the entire institution. Accurate attendance tracking is crucial for monitoring student attendance patterns, identifying absenteeism, and taking appropriate intervention measures.'
            },
            {
                title: '2. Time-Saving and Efficiency',
                subtitle:
                    'Automated Attendance Management systems save time for both teachers and administrators. They eliminate the need for manual attendance taking, reducing the administrative burden on teachers. The system quickly captures attendance data, allowing teachers to focus more on instructional activities. It also saves administrative time by automating tasks such as generating attendance reports and managing attendance records.'
            },
            {
                title: '3. Improved Student Accountability and Engagement',
                subtitle:
                    'Attendance Management systems promote student accountability and engagement. With real-time attendance tracking, students are aware that their attendance is being monitored, which encourages regular attendance and punctuality. By tracking attendance patterns, teachers and administrators can identify students who are frequently absent or tardy and provide timely support and intervention. This helps in fostering a sense of responsibility and active participation among students.'
            },
            {
                title: '4. Enhanced Parental Involvement',
                subtitle:
                    "Attendance Management systems facilitate better communication and involvement of parents in monitoring their child's attendance. Parents can receive notifications or access online portals to view their child's attendance records in real-time. This allows parents to stay informed about their child's attendance status, address any attendance concerns promptly, and collaborate with teachers and administrators to support their child's academic progress."
            }
        ]
    },
    {
        key: 3,
        title: 'Timetable Management',
        icon: <Schedule />,
        items: [
            {
                title: '1. Optimal Resource Utilization',
                subtitle:
                    'Timetable Management systems help optimize the allocation of resources such as classrooms, teachers, and facilities. By considering various constraints and preferences, the system creates an optimized timetable that maximizes resource utilization and minimizes conflicts or overlaps. This ensures that resources are efficiently utilized, leading to effective use of available infrastructure and personnel.'
            },
            {
                title: '2. Streamlined Scheduling Process',
                subtitle:
                    'Manual timetable creation can be a complex and time-consuming task. Timetable Management systems automate the scheduling process, saving significant time and effort for administrators and coordinators. The system considers various factors like teacher availability, subject requirements, and class capacities to generate an optimized timetable quickly and accurately. This streamlines the scheduling process and reduces the chances of errors or conflicts.'
            },
            {
                title: '3. Improved Student Experience',
                subtitle:
                    'A well-structured and optimized timetable enhances the learning experience for students. Timetable Management systems ensure that classes are scheduled in a logical sequence, avoiding long gaps between classes, and optimizing the utilization of resources. This results in a more organized and efficient learning environment, where students can follow a consistent schedule, engage in a balanced curriculum, and have sufficient time for academic activities and extracurricular pursuits.'
            },
            {
                title: '4. Enhanced Flexibility and Adaptability',
                subtitle:
                    'Timetable Management systems offer flexibility and adaptability to accommodate changes or unforeseen circumstances. If there are changes in teacher availability, student preferences, or resource constraints, the system can quickly generate revised timetables that meet the new requirements while minimizing disruptions. This flexibility allows educational institutions to adapt to changing needs and optimize the timetable accordingly.'
            }
        ]
    },
    {
        key: 4,
        title: 'Fee Management',
        icon: <Money />,
        items: [
            {
                title: '1. Efficient and Streamlined Fee Collection',
                subtitle:
                    'Fee management systems automate and streamline the fee collection process, making it efficient and convenient for both the educational institution and parents. These systems eliminate the need for manual paperwork, reduce administrative tasks, and allow for online fee payment options. This saves time and effort for administrators and parents, leading to a smoother fee collection process.'
            },
            {
                title: '2. Enhanced Financial Management',
                subtitle:
                    'Fee management systems provide accurate and up-to-date financial information for educational institutions. Administrators can track fee payments, generate financial reports, and analyze revenue streams. This helps in effective financial management, budgeting, and decision-making. The system allows administrators to have a clear overview of the financial health of the institution and make informed financial decisions based on real-time data.'
            },
            {
                title: '3. Transparent and Timely Communication',
                subtitle:
                    "Fee management systems facilitate transparent and timely communication between educational institutions and parents. Parents can receive automated fee reminders, notifications, and invoices through the system. They can also access their child's fee payment history and view outstanding balances. This transparency in fee-related communication builds trust, improves parent-school relationships, and ensures that parents stay informed about their financial obligations."
            },
            {
                title: '4. Customizable Fee Structure Management',
                subtitle:
                    'Fee management systems allow educational institutions to define and manage various fee structures. Institutions can set up different fee categories, payment schedules, discounts, or late fee policies based on their specific requirements. This flexibility enables institutions to cater to different student groups, such as day scholars, boarders, or students with scholarships. The system ensures accurate fee calculations and generates customized fee receipts, providing a clear breakdown of the fee structure to parents. '
            }
        ]
    },
    {
        key: 5,
        title: 'Communication and Collaboration',
        icon: (
            <svg height={24} width={24} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
            </svg>
        ),
        items: [
            {
                title: '1. Improved Student Engagement and Learning Outcomes',
                subtitle:
                    'Effective communication and collaboration practices foster student engagement and enhance learning outcomes. When students can effectively communicate with their teachers and peers, ask questions, seek clarifications, and participate in collaborative activities, they become more actively involved in the learning process. This engagement leads to a deeper understanding of concepts, increased retention of knowledge, and improved academic performance. '
            },
            {
                title: '2. Enhanced Parental Involvement and Support',
                subtitle:
                    "Communication and collaboration systems facilitate better involvement and support from parents. Through regular communication channels, such as emails, messaging platforms, or parent portals, parents can stay updated about their child's progress, receive timely notifications about events or announcements, and communicate with teachers or administrators. This involvement allows parents to actively participate in their child's education, provide necessary support, and address any concerns or challenges promptly."
            },
            {
                title: '3. Efficient and Transparent Communication',
                subtitle:
                    'Effective communication and collaboration systems ensure efficient and transparent information exchange between all stakeholders, including administrators, teachers, parents, and students. Timely and accurate communication about important school updates, events, assignments, and deadlines helps in avoiding misunderstandings, reducing confusion, and keeping everyone informed. This transparency creates a supportive and cohesive educational community where everyone is on the same page.'
            },
            {
                title: '4. Collaboration and Teamwork Skills Development',
                subtitle:
                    'Communication and collaboration systems provide opportunities for students to develop essential collaboration and teamwork skills. Through group projects, online discussions, or collaborative platforms, students learn how to effectively communicate their ideas, listen to others, negotiate, and work together towards common goals. These skills are not only valuable for academic success but also essential for their future professional and personal endeavors.'
            }
        ]
    },
    {
        key: 6,
        title: 'Library Management',
        icon: <LocalLibrary />,
        items: [
            {
                title: '1. Efficient and Accurate Book Management',
                subtitle:
                    'Library management systems streamline the process of cataloging, tracking, and managing books and resources. Librarians can easily input book information, including titles, authors, publication details, and subjects, into the system. The system automates tasks such as book check-in, check-out, and reservations, reducing manual paperwork and minimizing errors. This efficiency ensures that books are properly organized, available when needed, and easily accessible to library users.'
            },
            {
                title: '2. Improved Accessibility and Discovery of Resources',
                subtitle:
                    'Library management systems enhance the accessibility and discovery of resources for library users. These systems often include online catalogs or search portals that allow users to search for books, journals, articles, and other resources based on various criteria such as title, author, subject, or keywords. This makes it easier for users to find and access the materials they need, improving the overall research and learning experience.'
            },
            {
                title: '3. Enhanced Library Services and User Experience',
                subtitle:
                    'Library management systems enable librarians to provide enhanced services and a better user experience. The systems can send automated notifications or reminders to users about due dates, hold requests, or library events. They also facilitate online renewals and reservations, reducing the need for physical visits to the library. Additionally, library management systems can generate reports and analytics, providing librarians with valuable insights into resource usage, popular topics, or user preferences. This information helps in making informed decisions, improving collection development, and tailoring library services to meet the needs of users.'
            },
            {
                title: '4. Streamlined Administration and Reporting',
                subtitle:
                    "Library management systems simplify administrative tasks for librarians and administrators. These systems automate processes such as inventory management, circulation, and fine calculations, reducing manual work and saving time. Librarians can generate reports on various library metrics, such as circulation statistics, popular books, or overdue items. This data aids in decision-making, resource allocation, and assessing the library's performance."
            }
        ]
    },
    {
        key: 7,
        title: 'Staff Management',
        icon: <Groups />,
        items: [
            {
                title: 'Optimal Workforce Allocation',
                subtitle:
                    'Staff management systems help educational institutions allocate their workforce optimally. By considering factors such as staff qualifications, experience, availability, and workload, the system can efficiently assign staff members to specific roles, classes, or departments. This ensures that the right staff members with the appropriate skills and expertise are assigned to tasks, leading to improved productivity and efficiency.'
            },
            {
                title: '2. Streamlined Communication and Collaboration',
                subtitle:
                    'Staff management systems facilitate effective communication and collaboration among staff members. These systems often include features such as internal messaging platforms, shared calendars, and task management tools. Such tools enable staff members to communicate, coordinate schedules, assign tasks, and collaborate on projects. This streamlined communication and collaboration enhance teamwork, increase efficiency, and improve overall productivity.'
            },
            {
                title: '3. Performance Evaluation and Professional Development',
                subtitle:
                    'Staff management systems provide a platform for performance evaluation and professional development of staff members. These systems can track staff performance, including attendance, punctuality, and job-related achievements. They can also generate performance reports or conduct performance reviews. This enables educational institutions to identify areas of improvement, provide feedback, and offer opportunities for professional development. Regular evaluation and development of staff members contribute to their growth, job satisfaction, and the overall success of the institution.'
            },
            {
                title: '4. Compliance with Regulations and Policies',
                subtitle:
                    'Staff management systems help educational institutions ensure compliance with regulations and policies related to staff management. These systems can maintain records of staff qualifications, certifications, and background checks. They can also track staff contracts, leave requests, and work hours to ensure adherence to labor laws and institutional policies. By ensuring compliance, staff management systems help institutions avoid legal issues and create a fair and transparent work environment.'
            }
        ]
    },
    {
        key: 8,
        title: 'Transport Management',
        icon: <DirectionsBus />,
        items: [
            {
                title: '1. Efficient and Safe Transportation',
                subtitle:
                    'Transport management systems ensure efficient and safe transportation for students. These systems streamline the process of managing routes, schedules, and vehicle assignments. By optimizing routes and minimizing travel time, they help reduce delays and ensure timely arrival and departure of students. Transport management systems also assist in tracking vehicle maintenance schedules, ensuring that vehicles are in good condition and safe for transportation. This efficient and safe transportation contributes to the overall well-being and satisfaction of students and parents.'
            },
            {
                title: '2. Improved Safety and Security',
                subtitle:
                    'Transport management systems enhance safety and security measures for students during transportation. These systems provide features like GPS tracking, real-time vehicle monitoring, and alerts for any deviations or incidents. Administrators and parents can track the location of vehicles and ensure that students are safely transported to and from the institution. In case of emergencies or unforeseen circumstances, transport management systems enable quick communication and coordination between drivers, administrators, and parents, ensuring prompt action and timely assistance.'
            },
            {
                title: '3. Cost Optimization',
                subtitle:
                    'Transport management systems help educational institutions optimize transportation costs. By efficiently managing routes, vehicle utilization, and fuel consumption, these systems reduce operational expenses. They can identify and eliminate inefficient routes or excessive fuel consumption, leading to cost savings for the institution. Transport management systems also provide data and analytics on transportation usage, allowing administrators to make informed decisions regarding fleet size, maintenance, and resource allocation.'
            },
            {
                title: '4. Enhanced Communication and Parental Satisfaction',
                subtitle:
                    "Transport management systems improve communication between educational institutions and parents regarding transportation. These systems can send automated notifications to parents regarding pick-up and drop-off times, delays, or changes in schedules. Parents can access real-time updates about their child's transportation status, providing them with peace of mind and reducing anxiety. This enhanced communication fosters parental satisfaction and trust in the institution's transportation services."
            }
        ]
    },
    {
        key: 9,
        title: 'Hostel Management',
        icon: <Apartment />,
        items: [
            {
                title: '1. Efficient Room Allocation and Management',
                subtitle:
                    'Hostel management systems automate the process of room allocation and management. These systems help in efficiently assigning rooms to students based on their preferences, gender, course, or any other criteria. The system keeps track of room availability, facilitates online bookings, and manages room allotments throughout the academic year. This streamlines the entire room allocation process, saves time for administrators, and ensures optimal utilization of hostel resources.'
            },
            {
                title: '2. Improved Safety and Security',
                subtitle:
                    'Hostel management systems enhance safety and security measures within the hostel premises. These systems often include features such as access control, visitor management, and surveillance monitoring. Hostel staff can manage access to different areas, track visitor entry and exit, and maintain a secure environment. In case of emergencies or incidents, the system can send alerts to the appropriate staff members, enabling quick response and ensuring the safety of students.'
            },
            {
                title: '3. Streamlined Mess and Inventory Management',
                subtitle:
                    "Hostel management systems facilitate efficient management of mess facilities and inventory. The system can handle meal planning, menu management, attendance tracking, and billing for hostel meals. It helps in ensuring that students' dietary requirements are met, meals are provided on time, and billing is accurate. Additionally, the system can track inventory levels, manage stock replenishment, and generate reports on consumption patterns, optimizing inventory management and reducing wastage."
            },
            {
                title: '4. Enhanced Communication and Administrative Efficiency',
                subtitle:
                    'Hostel management systems improve communication and administrative efficiency between the hostel staff, administrators, and students. These systems provide platforms for sending notifications, announcements, and updates to students regarding hostel rules, events, or any other important information. They also allow students to raise requests, report issues, or communicate with hostel authorities easily. This streamlined communication and administrative efficiency contribute to a well-organized and responsive hostel management system.'
            }
        ]
    },
    {
        key: 10,
        title: 'Inventory Management',
        icon: <Inventory />,
        items: [
            {
                title: '1. Efficient Resource Utilization',
                subtitle:
                    'Inventory management systems help educational institutions optimize their resource utilization. These systems keep track of inventory levels, monitor stock movement, and provide real-time visibility into the availability of supplies, equipment, and other resources. Administrators can track usage patterns, identify areas of excess or shortage, and make informed decisions regarding procurement, storage, and distribution of inventory. This efficient resource utilization reduces waste, lowers costs, and ensures that the necessary resources are readily available when needed.'
            },
            {
                title: '2. Improved Inventory Control and Accuracy',
                subtitle:
                    'Inventory management systems enable better control and accuracy in managing inventory. The systems automate processes such as stock tracking, item categorization, barcode scanning, and inventory reconciliation. This eliminates manual errors, reduces the likelihood of stockouts or overstocking, and ensures accurate inventory records. Administrators can generate reports and analytics to gain insights into inventory performance, turnover rates, and forecast future needs. This improved inventory control allows institutions to streamline their operations, reduce inventory holding costs, and maintain optimal stock levels.'
            },
            {
                title: '3. Time and Cost Savings',
                subtitle:
                    'Inventory management systems save time and reduce costs associated with inventory management processes. These systems automate manual tasks such as inventory counting, order processing, and stock reconciliation, minimizing the need for labor-intensive activities. By providing real-time information on inventory levels and locations, the systems enable faster and more accurate decision-making. This streamlines the procurement process, reduces the risk of stockouts, and eliminates unnecessary or duplicate purchases. Overall, the time and cost savings achieved through efficient inventory management contribute to improved operational efficiency and financial sustainability.'
            },
            {
                title: '4. Enhanced Accountability and Compliance',
                subtitle:
                    'Inventory management systems promote accountability and compliance in handling institutional assets. The systems track the movement of inventory items, record transactions, and maintain an audit trail of inventory activities. This ensures transparency, reduces the risk of theft or misuse, and enhances accountability among staff members. Additionally, inventory management systems can help institutions comply with regulatory requirements and internal policies related to inventory control, reporting, and asset management.'
            }
        ]
    },
    {
        key: 11,
        title: 'Reporting and Analytics',
        icon: <Analytics />,
        items: [
            {
                title: '1. Data-Driven Decision Making',
                subtitle:
                    'Reporting and analytics systems empower educational institutions to make informed decisions based on accurate data and insights. These systems collect and analyze data from various sources, such as student performance, attendance, financials, and enrollment. By generating comprehensive reports and visualizations, administrators can identify trends, patterns, and areas for improvement. This data-driven decision-making approach allows institutions to develop effective strategies, allocate resources efficiently, and enhance overall performance.'
            },
            {
                title: '2. Performance Monitoring and Evaluation',
                subtitle:
                    'Reporting and analytics systems facilitate performance monitoring and evaluation across various aspects of an educational institution. By tracking key performance indicators (KPIs) and generating performance reports, administrators can assess the effectiveness of teaching methods, curriculum, and administrative processes. This enables them to identify strengths, weaknesses, and areas of improvement, leading to targeted interventions and continuous enhancement of educational quality.'
            },
            {
                title: '3. Predictive Analytics and Early Intervention',
                subtitle:
                    'Reporting and analytics systems enable predictive analytics, which can help identify potential issues or opportunities in advance. By analyzing historical data and patterns, institutions can develop predictive models that forecast student performance, enrollment trends, or resource requirements. This allows for early intervention and proactive measures to address challenges or capitalize on opportunities. For example, early identification of students at risk of academic underperformance can enable targeted interventions and support, improving student outcomes.'
            },
            {
                title: '4. Compliance and Accountability',
                subtitle:
                    'Reporting and analytics systems assist educational institutions in meeting compliance requirements and maintaining accountability. These systems can generate reports and dashboards that demonstrate adherence to regulatory standards, accreditation criteria, and institutional policies. Administrators can track and analyze data related to finances, student demographics, program outcomes, and more, ensuring compliance with external regulations and internal benchmarks. This promotes transparency, accountability, and trust among stakeholders.'
            }
        ]
    },
    {
        key: 12,
        title: 'School Visitor Management',
        icon: <Wc />,
        items: [
            {
                title: '1. Child Protection and Compliance with Regulations',
                subtitle:
                    'Schools must comply with various regulations and standards related to child protection and visitor tracking. A visitor management system helps meet these requirements by maintaining accurate records of visitor information, including their purpose of visit, entry and exit times, and any other relevant details. In case of any incidents or emergencies, these records can serve as essential documentation. Moreover, the system can perform instant background checks on registered sex offenders or individuals with restricted access, helping prevent potential risks and ensuring compliance with child protection guidelines.'
            },
            {
                title: '2. Enhanced Safety and Security',
                subtitle:
                    'The safety and security of students, staff, and faculty are of paramount importance in schools. A visitor management system helps bolster safety measures by providing better control over who enters the premises. By requiring all visitors to register, provide identification, and obtain a visitor badge, the system ensures that only authorized individuals have access to the school. This helps prevent unauthorized entry, reduces the risk of incidents like trespassing or abduction, and enables quick identification of individuals in case of emergencies. Overall, it promotes a secure environment for students and staff.'
            },
            {
                title: '3. Improved Efficiency and Streamlined Processes',
                subtitle:
                    ' Traditional paper-based visitor registration processes can be time-consuming and inefficient. A school visitor management system streamlines these processes by automating registration, check-in, and check-out procedures. Visitors can pre-register online, provide necessary information, and upload identification documents in advance, reducing waiting times during the actual visit. The system also notifies the appropriate personnel when a visitor arrives, ensuring a prompt response. By saving time and improving operational efficiency, the system allows school staff to focus on their primary responsibilities.'
            }
        ]
    }
];

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&::before': {
        display: 'none'
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

const Advantages = () => {
    const { isLoggedIn } = useAuth();
    const theme = useTheme();
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState('');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleContactUs = () => {
        let targetElement = document.getElementById('contact-us');
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            navigate('/');

            const intervalId = setInterval(() => {
                targetElement = document.getElementById('contact-us');
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    clearInterval(intervalId);
                }
            }, 2);
        }
    };

    return (
        <Container>
            <Box paddingY={{ xs: 4, sm: 6, md: 8 }}>
                <Box marginBottom={4}>
                    <Typography variant="h2" gutterBottom align="center" sx={{ fontWeight: 700, fontSize: '2.5em' }}>
                        Modules in School Management System
                    </Typography>
                    <Typography variant="h4" component="p" color="text.secondary" align="center" sx={{ fontSize: '1.25em' }}>
                        A School Management System typically consists of several modules or components that cater to different aspects of
                        school administration. The specific modules can vary based on the software provider and the requirements of the
                        educational institution. Here are some common modules found in a School Management System
                    </Typography>
                    <Box marginTop={3} display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            endIcon={
                                <svg
                                    width={16}
                                    height={16}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                                </svg>
                            }
                            onClick={() => handleContactUs()}
                        >
                            Purchase now
                        </Button>
                    </Box>
                </Box>
                <Box>
                    <Grid container spacing={4}>
                        {mock.map((item, i) => (
                            <Grid item xs={12} sm={6} md={4} key={i}>
                                <Box
                                    component={Card}
                                    padding={3}
                                    width={1}
                                    height={1}
                                    data-aos="fade-up"
                                    data-aos-delay={i * 100}
                                    data-aos-offset={100}
                                    data-aos-duration={600}
                                    boxShadow={5}
                                >
                                    <Box display="flex" flexDirection="column">
                                        <Box
                                            component={Avatar}
                                            width={50}
                                            height={50}
                                            marginBottom={2}
                                            bgcolor={theme.palette.primary.main}
                                            color={theme.palette.background.paper}
                                        >
                                            {item.icon}
                                        </Box>
                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 500, fontSize: '1.25rem' }}>
                                            {item.title}
                                        </Typography>
                                        <div>
                                            {item.items.map((subitem, j) => (
                                                <Accordion
                                                    expanded={expanded === `panel${item.key}${j}`}
                                                    onChange={handleChange(`panel${item.key}${j}`)}
                                                >
                                                    <AccordionSummary
                                                        aria-controls={`panel${item.key}${j}d-content`}
                                                        id={`panel${item.key}${j}d-header`}
                                                    >
                                                        <Typography>{subitem.title}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>{subitem.subtitle}</Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                            {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                                    <Typography>Collapsible Group Item #2</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus
                                                        ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                                    <Typography>Collapsible Group Item #3</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus
                                                        ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion> */}
                                        </div>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

export default Advantages;
