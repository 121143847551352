import { Close, Print } from '@mui/icons-material';
import { Alert, Button, Dialog, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import DatePicker from '../UtilsComponent/BasicDate';
import * as yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_PAST_DUE_FEES } from 'services/managment/mutation';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

const validationSchema = yup.object({
    amount: yup.number().typeError('Must be a number type').min(0, 'Must be a positve number').required('Please enter fee amount'),
    date: yup.string().nullable()
});

const PDDialog = ({ open, close, selectedRecord, isFormDisabled, handlePDUpsert }) => {
    const { user } = useAuth();
    const [updatePastDueFees] = useMutation(UPDATE_PAST_DUE_FEES);
    const [isUpdated, setIsUpdated] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: !isEmpty(selectedRecord) && selectedRecord.pdFees ? selectedRecord.pdFees : null,
            date: !isEmpty(selectedRecord) && selectedRecord.date ? selectedRecord.date : format(new Date(), 'yyyy-MM-dd')
        },
        validationSchema,
        onSubmit: (values) => {
            updatePastDueFees({
                variables: {
                    studentId: selectedRecord.id,
                    pastDue: values.amount,
                    pdudt: values.date,
                    pdudb: user.email
                },
                onCompleted: (data) => {
                    const response = data.update_class_fees_by_pk.student_id;
                    if (response) {
                        setIsUpdated(true);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Past Due Fees Updated',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false,
                                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                                transition: 'SlideLeft'
                            })
                        );
                        handlePDUpsert({
                            id: selectedRecord.id,
                            pd: values.amount
                        });
                    }
                },
                onError: (error) => {
                    // console.log(error);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'There is some error please check the required field',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true,
                            anchorOrigin: { vertical: 'top', horizontal: 'right' },
                            transition: 'SlideLeft'
                        })
                    );
                }
            });
        }
    });

    return (
        <>
            <Dialog
                open={open && !isEmpty(selectedRecord)}
                keepMounted
                onClose={close}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                sx={{
                    '& .MuiDialog-paper': {
                        p: 0,
                        width: 300
                    }
                }}
            >
                {open && (
                    <Grid item xs={12} md={12} lg={12}>
                        {isUpdated && (
                            <Alert variant="filled" severity="success" sx={{ borderRadius: '4px 4px 0px 0px' }}>
                                Fees successfully taken.
                            </Alert>
                        )}
                        <MainCard
                            title={
                                <Stack direction="row" alignItems="center">
                                    {`${selectedRecord.rollNo} - Past Due Fees`}
                                </Stack>
                            }
                            secondary={
                                <IconButton sx={{ p: 0, pb: 0 }} onClick={close} size="large">
                                    <Close fontSize="small" />
                                </IconButton>
                            }
                            sx={{
                                '.MuiCardHeader-root': {
                                    p: 1.5
                                },
                                '.MuiCardContent-root': {
                                    p: 2
                                },
                                borderRadius: '0px 0px 4px 4px'
                            }}
                        >
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Grid item xs={12}>
                                        <TextField
                                            InputLabelProps={{ shrink: true }}
                                            name="amount"
                                            value={formik.values.amount}
                                            onChange={formik.handleChange}
                                            error={formik.touched.amount && Boolean(formik.errors.amount)}
                                            helperText={formik.touched.amount && formik.errors.amount}
                                            fullWidth
                                            label="Amount"
                                            size="small"
                                            disabled={isFormDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DatePicker
                                            id="date"
                                            name="date"
                                            value={formik.values.date}
                                            handleFormik={formik}
                                            error={formik.touched.date && Boolean(formik.errors.date)}
                                            helperText={formik.touched.date && formik.errors.date}
                                            fullWidth
                                            label="Date"
                                            size="small"
                                            disabled={isFormDisabled}
                                        />
                                    </Grid>
                                    {selectedRecord?.invoiceNo && (
                                        <>
                                            <Grid item xs={10} display="flex" alignItems="center">
                                                <Typography style={{ fontSize: 11 }}>Invoice No -</Typography>
                                                <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                                                    &nbsp;{selectedRecord.invoiceNo}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} justifyContent="right" display="flex">
                                                <IconButton disabled size="small" color="primary">
                                                    <Print />
                                                </IconButton>
                                            </Grid>

                                            <Grid item xs={12} sx={{ pt: '4px !important' }} display="flex" alignItems="center">
                                                <Typography style={{ fontSize: 11 }}>Taken By - </Typography>
                                                <Typography style={{ fontSize: 14, fontWeight: 550 }}>
                                                    &nbsp;
                                                    {selectedRecord?.updatedBy ? selectedRecord?.updatedBy : ''}
                                                </Typography>
                                            </Grid>
                                        </>
                                    )}

                                    <Grid item xs={6}>
                                        <Stack direction="row" spacing={1} justifyContent="center">
                                            <AnimateButton>
                                                <Button variant="contained" type="submit" size="small">
                                                    Submit Fee
                                                </Button>
                                            </AnimateButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </form>
                        </MainCard>
                    </Grid>
                )}
            </Dialog>
        </>
    );
};

PDDialog.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func
};

export default PDDialog;
