import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    parentChildrens: [],
    parentDashDetails: [],
    selectedChildrenDetails: {}
};

export const parentSlice = createSlice({
    name: 'parent',
    initialState,
    reducers: {
        setParentChildrens: (state, action) => {
            state.parentChildrens = action.payload;
        },
        setParentDashDetails: (state, action) => {
            state.parentDashDetails = action.payload;
        },
        setSelectedChildrenDetails: (state, action) => {
            state.selectedChildrenDetails = action.payload;
        }
    }
});

export const { setParentChildrens, setParentDashDetails, setSelectedChildrenDetails } = parentSlice.actions;

export default parentSlice.reducer;
