/* eslint-disable import/prefer-default-export */

export const sessionDropdownList = [
    // { label: '', id: 0 },
    { label: '2024-25', id: 3 },
    { label: '2023-24', id: 0 },
    { label: '2022-23', id: 1 },
    { label: '2021-22', id: 2 }
];

export const sessionList = [
    { sessionName: '2024-25', sessionId: 3 },
    { sessionName: '2023-24', sessionId: 0 },
    { sessionName: '2022-23', sessionId: 1 },
    { sessionName: '2021-22', sessionId: 2 }
];

export const sessions = ['2024-25', '2023-24', '2022-23', '2021-22'];

export const religionDropdownList = [
    { label: 'Buddh', id: 0 },
    { label: 'Christian', id: 1 },
    { label: 'Jain', id: 2 },
    { label: 'Hindu', id: 3 },
    { label: 'Muslim', id: 4 },
    { label: 'Parsi', id: 5 },
    { label: 'Sikh', id: 6 }
];

export const relationDropdownList = [
    { label: 'Father', id: 0 },
    { label: 'Mother', id: 1 },
    { label: 'Grandfather - Paternal', id: 2 },
    { label: 'Grandmother - Paternal', id: 3 },
    { label: 'Uncle - Paternal', id: 4 },
    { label: 'Aunty - Paternal', id: 5 },
    { label: 'Grandfather - Maternal', id: 6 },
    { label: 'Grandmother - Maternal', id: 7 },
    { label: 'Uncle - Maternal', id: 8 },
    { label: 'Aunty - Maternal', id: 9 },
    { label: 'Brother', id: 10 },
    { label: 'Sister', id: 11 },
    { label: 'Elder Brother', id: 12 },
    { label: 'Elder Sister', id: 13 },
    { label: 'Uncle', id: 14 },
    { label: 'Aunty', id: 15 }
];

export const schoolCategoryList = [
    { schoolCategoryName: 'Private', schoolCategoryId: 0 },
    { schoolCategoryName: 'Government', schoolCategoryId: 1 }
];

export const schoolForList = [
    { schoolForName: 'Girls', schoolForId: 0 },
    { schoolForName: 'Boys', schoolForId: 1 },
    { schoolForName: 'Coed', schoolForId: 2 }
];

export const stringIncludeNumSort = function (a, b) {
    return Number(a.match(/(\d+)/g)[0]) - Number(b.match(/(\d+)/g)[0]);
};

export const roleInSchool = [
    { roleName: 'Teacher', roleId: 0 },
    { roleName: 'Student', roleId: 1 },
    { roleName: 'School Admin', roleId: 2 },
    { roleName: 'Principal', roleId: 3 },
    { roleName: 'School Owner', roleId: 4 },
    { roleName: 'Parent', roleId: 5 }
];
