/* eslint-disable no-empty */
import { useQuery } from '@apollo/client';
import { Print } from '@mui/icons-material';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { INVOICE_SCHOOL_INFO, INVOICE_STUDENT_INFO, INVOICE_TEMPLATE_ID, INVOICE_TRANSPORT } from 'services/managment/query';
import { fullName } from 'utils/common';
import useAuth from 'hooks/useAuth';
import Modal from 'component/Modal';
import PrintableList from './PrintableList';
import { useSelector } from 'react-redux';

function Invoice({ open, close, isClassFee, id }) {
    const { user, defaultRole } = useAuth();
    const componentRef = useRef();
    const [templateId, setTemplateId] = useState('');
    const [schoolInfo, setSchoolInfo] = useState({});
    const [studentInfo, setStudentInfo] = useState({});
    const [fees, setFees] = useState([]);
    const { accountantDashInfo } = useSelector((state) => state.accountant);

    useQuery(INVOICE_TEMPLATE_ID, {
        variables: {
            schoolId: defaultRole === 'accountant' ? accountantDashInfo.school_id : user.id
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data.template_by_pk?.tem_invoice) {
                setTemplateId(data.template_by_pk.tem_invoice);
            }
        },
        onError: (error) => {
            // console.log(error);
        }
    });

    useQuery(INVOICE_SCHOOL_INFO, {
        variables: {
            schoolId: defaultRole === 'accountant' ? accountantDashInfo.school_id : user.id
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data.school_info_by_pk) {
                setSchoolInfo({
                    logo: data.school_info_by_pk?.logo,
                    name: data.school_info_by_pk?.idcard_data?.s_name,
                    address: data.school_info_by_pk?.idcard_data?.address,
                    email: data.school_info_by_pk?.school_contact?.email,
                    contact: data.school_info_by_pk?.school_contact?.scl_phone1
                        ? `${data.school_info_by_pk?.school_contact?.scl_phone1} ${
                              data.school_info_by_pk?.school_contact?.scl_phone2
                                  ? ` , ${data.school_info_by_pk?.school_contact?.scl_phone2}`
                                  : ''
                          }`
                        : data.school_info_by_pk?.school_contact?.scl_phone2
                });
            }
        },
        onError: (error) => {
            // console.log(error);
        }
    });

    useQuery(isClassFee ? INVOICE_STUDENT_INFO : INVOICE_TRANSPORT, {
        variables: {
            studentId: id
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data.class_fees_by_pk) {
                const fees = [];
                if (data.class_fees_by_pk.apr)
                    fees.push({
                        feetype: 'Apr',
                        invoice_no: data.class_fees_by_pk.aprinv,
                        method: data.class_fees_by_pk.aprmode,
                        amount: data.class_fees_by_pk?.school_class_detail?.fees,
                        paid: data.class_fees_by_pk.apr,
                        date: data.class_fees_by_pk.aprdt ? format(new Date(data.class_fees_by_pk.aprdt), 'dd/MM/yyyy') : ''
                    });
                if (data.class_fees_by_pk.may)
                    fees.push({
                        feetype: 'May',
                        invoice_no: data.class_fees_by_pk.mayinv,
                        method: data.class_fees_by_pk.maymode,
                        amount: data.class_fees_by_pk?.school_class_detail?.fees,
                        paid: data.class_fees_by_pk.may,
                        date: data.class_fees_by_pk.maydt ? format(new Date(data.class_fees_by_pk.maydt), 'dd/MM/yyyy') : ''
                    });
                if (data.class_fees_by_pk.jun)
                    fees.push({
                        feetype: 'Jun',
                        invoice_no: data.class_fees_by_pk.juninv,
                        method: data.class_fees_by_pk.junmode,
                        amount: data.class_fees_by_pk?.school_class_detail?.fees,
                        paid: data.class_fees_by_pk.jun,
                        date: data.class_fees_by_pk.jundt ? format(new Date(data.class_fees_by_pk.jundt), 'dd/MM/yyyy') : ''
                    });
                if (data.class_fees_by_pk.jul)
                    fees.push({
                        feetype: 'Jul',
                        invoice_no: data.class_fees_by_pk.julinv,
                        method: data.class_fees_by_pk.julmode,
                        amount: data.class_fees_by_pk?.school_class_detail?.fees,
                        paid: data.class_fees_by_pk.jul,
                        date: data.class_fees_by_pk.juldt ? format(new Date(data.class_fees_by_pk.juldt), 'dd/MM/yyyy') : ''
                    });
                if (data.class_fees_by_pk.aug)
                    fees.push({
                        feetype: 'Aug',
                        invoice_no: data.class_fees_by_pk.auginv,
                        method: data.class_fees_by_pk.augmode,
                        amount: data.class_fees_by_pk?.school_class_detail?.fees,
                        paid: data.class_fees_by_pk.aug,
                        date: data.class_fees_by_pk.augdt ? format(new Date(data.class_fees_by_pk.augdt), 'dd/MM/yyyy') : ''
                    });
                if (data.class_fees_by_pk.sep)
                    fees.push({
                        feetype: 'Sep',
                        invoice_no: data.class_fees_by_pk.sepinv,
                        method: data.class_fees_by_pk.sepmode,
                        amount: data.class_fees_by_pk?.school_class_detail?.fees,
                        paid: data.class_fees_by_pk.sep,
                        date: data.class_fees_by_pk.sepdt ? format(new Date(data.class_fees_by_pk.sepdt), 'dd/MM/yyyy') : ''
                    });
                if (data.class_fees_by_pk.oct)
                    fees.push({
                        feetype: 'Oct',
                        invoice_no: data.class_fees_by_pk.octinv,
                        method: data.class_fees_by_pk.octmode,
                        amount: data.class_fees_by_pk?.school_class_detail?.fees,
                        paid: data.class_fees_by_pk.oct,
                        date: data.class_fees_by_pk.octdt ? format(new Date(data.class_fees_by_pk.octdt), 'dd/MM/yyyy') : ''
                    });
                if (data.class_fees_by_pk.nov)
                    fees.push({
                        feetype: 'Nov',
                        invoice_no: data.class_fees_by_pk.novinv,
                        method: data.class_fees_by_pk.novmode,
                        amount: data.class_fees_by_pk?.school_class_detail?.fees,
                        paid: data.class_fees_by_pk.nov,
                        date: data.class_fees_by_pk.novdt ? format(new Date(data.class_fees_by_pk.novdt), 'dd/MM/yyyy') : ''
                    });
                if (data.class_fees_by_pk.dec)
                    fees.push({
                        feetype: 'Dec',
                        invoice_no: data.class_fees_by_pk.decinv,
                        method: data.class_fees_by_pk.decmode,
                        amount: data.class_fees_by_pk?.school_class_detail?.fees,
                        paid: data.class_fees_by_pk.dec,
                        date: data.class_fees_by_pk.decdt ? format(new Date(data.class_fees_by_pk.decdt), 'dd/MM/yyyy') : ''
                    });
                if (data.class_fees_by_pk.jan)
                    fees.push({
                        feetype: 'Jan',
                        invoice_no: data.class_fees_by_pk.janinv,
                        method: data.class_fees_by_pk.janmode,
                        amount: data.class_fees_by_pk?.school_class_detail?.fees,
                        paid: data.class_fees_by_pk.jan,
                        date: data.class_fees_by_pk.jandt ? format(new Date(data.class_fees_by_pk.jandt), 'dd/MM/yyyy') : ''
                    });
                if (data.class_fees_by_pk.feb)
                    fees.push({
                        feetype: 'Feb',
                        invoice_no: data.class_fees_by_pk.febinv,
                        method: data.class_fees_by_pk.febmode,
                        amount: data.class_fees_by_pk?.school_class_detail?.fees,
                        paid: data.class_fees_by_pk.feb,
                        date: data.class_fees_by_pk.febdt ? format(new Date(data.class_fees_by_pk.febdt), 'dd/MM/yyyy') : ''
                    });
                if (data.class_fees_by_pk.mar)
                    fees.push({
                        feetype: 'Mar',
                        invoice_no: data.class_fees_by_pk.marinv,
                        method: data.class_fees_by_pk.marmode,
                        amount: data.class_fees_by_pk?.school_class_detail?.fees,
                        paid: data.class_fees_by_pk.mar,
                        date: data.class_fees_by_pk.mardt ? format(new Date(data.class_fees_by_pk.mardt), 'dd/MM/yyyy') : ''
                    });
                setStudentInfo({
                    name: fullName(
                        data.class_fees_by_pk?.school_student?.first_name,
                        data.class_fees_by_pk?.school_student?.middle_name,
                        data.class_fees_by_pk?.school_student?.last_name
                    ),
                    address: data.class_fees_by_pk?.school_student?.student_guardian_detail?.guardian_home_address,
                    class: data.class_fees_by_pk?.school_class_detail?.class_name,
                    section: data.class_fees_by_pk?.school_class_detail?.class_section,
                    id_no: data.class_fees_by_pk?.school_student?.adm_no
                });
                setFees([...fees]);
            }
            if (data.transport_fees_by_pk) {
                const fees = [];
                if (data.transport_fees_by_pk.apr)
                    fees.push({
                        feetype: 'Apr',
                        invoice_no: data.transport_fees_by_pk.aprinv,
                        method: data.transport_fees_by_pk.aprmode,
                        amount: data.transport_fees_by_pk?.school_class_detail?.fees,
                        paid: data.transport_fees_by_pk.apr,
                        date: data.transport_fees_by_pk.aprdt ? format(new Date(data.transport_fees_by_pk.aprdt), 'dd/MM/yyyy') : ''
                    });
                if (data.transport_fees_by_pk.may)
                    fees.push({
                        feetype: 'May',
                        invoice_no: data.transport_fees_by_pk.mayinv,
                        method: data.transport_fees_by_pk.maymode,
                        amount: data.transport_fees_by_pk?.school_class_detail?.fees,
                        paid: data.transport_fees_by_pk.may,
                        date: data.transport_fees_by_pk.maydt ? format(new Date(data.transport_fees_by_pk.maydt), 'dd/MM/yyyy') : ''
                    });
                if (data.transport_fees_by_pk.jun)
                    fees.push({
                        feetype: 'Jun',
                        invoice_no: data.transport_fees_by_pk.juninv,
                        method: data.transport_fees_by_pk.junmode,
                        amount: data.transport_fees_by_pk?.school_class_detail?.fees,
                        paid: data.transport_fees_by_pk.jun,
                        date: data.transport_fees_by_pk.jundt ? format(new Date(data.transport_fees_by_pk.jundt), 'dd/MM/yyyy') : ''
                    });
                if (data.transport_fees_by_pk.jul)
                    fees.push({
                        feetype: 'Jul',
                        invoice_no: data.transport_fees_by_pk.julinv,
                        method: data.transport_fees_by_pk.julmode,
                        amount: data.transport_fees_by_pk?.school_class_detail?.fees,
                        paid: data.transport_fees_by_pk.jul,
                        date: data.transport_fees_by_pk.juldt ? format(new Date(data.transport_fees_by_pk.juldt), 'dd/MM/yyyy') : ''
                    });
                if (data.transport_fees_by_pk.aug)
                    fees.push({
                        feetype: 'Aug',
                        invoice_no: data.transport_fees_by_pk.auginv,
                        method: data.transport_fees_by_pk.augmode,
                        amount: data.transport_fees_by_pk?.school_class_detail?.fees,
                        paid: data.transport_fees_by_pk.aug,
                        date: data.transport_fees_by_pk.augdt ? format(new Date(data.transport_fees_by_pk.augdt), 'dd/MM/yyyy') : ''
                    });
                if (data.transport_fees_by_pk.sep)
                    fees.push({
                        feetype: 'Sep',
                        invoice_no: data.transport_fees_by_pk.sepinv,
                        method: data.transport_fees_by_pk.sepmode,
                        amount: data.transport_fees_by_pk?.school_class_detail?.fees,
                        paid: data.transport_fees_by_pk.sep,
                        date: data.transport_fees_by_pk.sepdt ? format(new Date(data.transport_fees_by_pk.sepdt), 'dd/MM/yyyy') : ''
                    });
                if (data.transport_fees_by_pk.oct)
                    fees.push({
                        feetype: 'Oct',
                        invoice_no: data.transport_fees_by_pk.octinv,
                        method: data.transport_fees_by_pk.octmode,
                        amount: data.transport_fees_by_pk?.school_class_detail?.fees,
                        paid: data.transport_fees_by_pk.oct,
                        date: data.transport_fees_by_pk.octdt ? format(new Date(data.transport_fees_by_pk.octdt), 'dd/MM/yyyy') : ''
                    });
                if (data.transport_fees_by_pk.nov)
                    fees.push({
                        feetype: 'Nov',
                        invoice_no: data.transport_fees_by_pk.novinv,
                        method: data.transport_fees_by_pk.novmode,
                        amount: data.transport_fees_by_pk?.school_class_detail?.fees,
                        paid: data.transport_fees_by_pk.nov,
                        date: data.transport_fees_by_pk.novdt ? format(new Date(data.transport_fees_by_pk.novdt), 'dd/MM/yyyy') : ''
                    });
                if (data.transport_fees_by_pk.dec)
                    fees.push({
                        feetype: 'Dec',
                        invoice_no: data.transport_fees_by_pk.decinv,
                        method: data.transport_fees_by_pk.decmode,
                        amount: data.transport_fees_by_pk?.school_class_detail?.fees,
                        paid: data.transport_fees_by_pk.dec,
                        date: data.transport_fees_by_pk.decdt ? format(new Date(data.transport_fees_by_pk.decdt), 'dd/MM/yyyy') : ''
                    });
                if (data.transport_fees_by_pk.jan)
                    fees.push({
                        feetype: 'Jan',
                        invoice_no: data.transport_fees_by_pk.janinv,
                        method: data.transport_fees_by_pk.janmode,
                        amount: data.transport_fees_by_pk?.school_class_detail?.fees,
                        paid: data.transport_fees_by_pk.jan,
                        date: data.transport_fees_by_pk.jandt ? format(new Date(data.transport_fees_by_pk.jandt), 'dd/MM/yyyy') : ''
                    });
                if (data.transport_fees_by_pk.feb)
                    fees.push({
                        feetype: 'Feb',
                        invoice_no: data.transport_fees_by_pk.febinv,
                        method: data.transport_fees_by_pk.febmode,
                        amount: data.transport_fees_by_pk?.school_class_detail?.fees,
                        paid: data.transport_fees_by_pk.feb,
                        date: data.transport_fees_by_pk.febdt ? format(new Date(data.transport_fees_by_pk.febdt), 'dd/MM/yyyy') : ''
                    });
                if (data.transport_fees_by_pk.mar)
                    fees.push({
                        feetype: 'Mar',
                        invoice_no: data.transport_fees_by_pk.marinv,
                        method: data.transport_fees_by_pk.marmode,
                        amount: data.transport_fees_by_pk?.school_class_detail?.fees,
                        paid: data.transport_fees_by_pk.mar,
                        date: data.transport_fees_by_pk.mardt ? format(new Date(data.transport_fees_by_pk.mardt), 'dd/MM/yyyy') : ''
                    });
                setStudentInfo({
                    name: fullName(
                        data.transport_fees_by_pk?.school_student?.first_name,
                        data.transport_fees_by_pk?.school_student?.middle_name,
                        data.transport_fees_by_pk?.school_student?.last_name
                    ),
                    address: data.transport_fees_by_pk?.school_student?.student_guardian_detail?.guardian_home_address,
                    class: data.transport_fees_by_pk?.school_class_detail?.class_name,
                    section: data.transport_fees_by_pk?.school_class_detail?.class_section,
                    id_no: data.transport_fees_by_pk?.school_student?.adm_no
                });
                setFees([...fees]);
            }
        },
        onError: (error) => {
            // console.log(error);
        }
    });

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    return (
        <Modal title="Print Invoice" open={open} close={close} width="auto">
            {templateId ? (
                <>
                    <Grid item xs={12} display="flex">
                        <Button variant="outlined" fullWidth onClick={handlePrint}>
                            <Print />
                            Print
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={{ py: 2 }}>
                        <Divider className="divider-formTwo" />
                    </Grid>

                    {!isEmpty(schoolInfo) && !isEmpty(studentInfo) ? (
                        <div ref={componentRef}>
                            <PrintableList
                                schoolInfo={schoolInfo}
                                studentInfo={studentInfo}
                                fees={fees}
                                isClassFee={isClassFee}
                                templateId={templateId}
                            />
                        </div>
                    ) : null}
                </>
            ) : (
                <Typography>Please contact XSoftex field supervisor for ID selection and preparation.</Typography>
            )}
        </Modal>
    );
}

export default Invoice;
