import { TableCell } from '@mui/material';

function NoDataTable({ colSpan, cellHeight }) {
    return (
        <TableCell colSpan={colSpan} align="center" sx={{ borderBottom: 0, height: `${cellHeight} !important` }}>
            No Data, Please check with your School.
            <br />
        </TableCell>
    );
}

export default NoDataTable;
