import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Avatar,
    Box,
    Button,
    ClickAwayListener,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Update } from '@mui/icons-material';
import { IconLogout, IconSettings, IconUser } from '@tabler/icons';
import { capitalize, every, includes, lowerCase } from 'lodash';
import { useDispatch } from 'store';
import { setReset } from 'store/slices/common';
import { activeItem } from 'store/slices/menu';
import useAuth from 'hooks/useAuth';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import useConfig from 'hooks/useConfig';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const { defaultRole, logout, user, handleRole, handleRefreshToken } = useAuth();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
    const openSubMenu = Boolean(subMenuAnchorEl);

    const anchorRef = useRef(null);
    const handleLogout = async () => {
        try {
            const isLogedOut = await logout();
            if (isLogedOut) {
                dispatch(setReset(true));
                navigate('/', { replace: true });
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route, { replace: true });
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleRoleChange = (e) => {
        const selectedRole = e.target.innerText === 'Security Guard' ? 'Securitygaurd' : e.target.innerText;

        if (selectedRole) {
            handleRole(
                lowerCase(selectedRole),
                selectedRole === 'Administrator' && every(['Managment', 'Administrator'], (value) => includes(user.userRoles, value))
                    ? 'managment'
                    : '',
                selectedRole === 'Administrator' && every(['Managment', 'Administrator'], (value) => includes(user.userRoles, value))
                    ? user
                    : undefined
            );
            if (selectedRole !== 'Securitygaurd') dispatch(activeItem([selectedRole === 'Managment' ? 'info' : 'home']));
            localStorage.setItem('selctedRole', lowerCase(selectedRole));
        }
        setSubMenuAnchorEl(null);
    };

    const handleSubMenuClick = (event) => {
        setSubMenuAnchorEl(event.currentTarget);
    };

    // const handleSubMenuClose = () => {
    //     setSubMenuAnchorEl(null);
    // };

    const refreshAndUpdate = () => {
        handleRefreshToken();
        if (navigator.serviceWorker.controller && navigator.serviceWorker.controller.state === 'activated') {
            navigator.serviceWorker.getRegistration().then((registration) => {
                if (registration && registration.waiting) {
                    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                    registration.waiting.addEventListener('statechange', (e) => {
                        if (e.target.state === 'activated') {
                            window.location.reload();
                        }
                    });
                }
            });
        }
    };

    return (
        <>
            <Avatar
                src={user?.userData?.photoURL}
                sx={{
                    alignItems: 'center',
                    cursor: 'pointer',
                    height: 38,
                    width: 38
                }}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="inherit"
                onClick={handleToggle}
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
                sx={{ width: 196 }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={open} {...TransitionProps}>
                            <Paper sx={{ width: 196 }}>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Box sx={{ p: 0, pb: 1, pl: 2, pt: 1.5 }}>
                                            <Stack>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <Typography component="span" variant="h5" sx={{ fontWeight: 400 }}>
                                                        Hi, {user?.name}
                                                    </Typography>
                                                </Stack>
                                                {/* <Typography variant="subtitle2">{capitalize(defaultRole)}</Typography> */}
                                            </Stack>
                                        </Box>
                                        <Divider />
                                        <Box sx={{ p: 0, pt: 0 }}>
                                            {/* <Autocomplete
                                                // {...defaultProps}
                                                id="auto-highlight"
                                                popupIcon={<></>}
                                                autoHighlight
                                                disableClearable
                                                handleHomeEndKeys
                                                options={user?.userRoles}
                                                defaultValue={capitalize(defaultRole)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        margin="normal"
                                                        size="small"
                                                        sx={{
                                                            '.MuiInputBase-sizeSmall': {
                                                                padding: '0px !important',
                                                                margin: '0px 42px',
                                                                width: 'initial'
                                                            }
                                                        }}
                                                    />
                                                )}
                                                sx={{
                                                    '.MuiTextField-root .MuiOutlinedInput-notchedOutline': {
                                                        borderWidth: 0
                                                    },
                                                    '.MuiAutocomplete-input': {
                                                        bgcolor: '#3f51b5',
                                                        color: 'white',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                        caretColor: 'transparent'
                                                    }
                                                }}
                                                onChange={(e) => handleRoleChange(e)}
                                            /> */}

                                            <Grid item sm sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Button
                                                    id="demo-customized-button"
                                                    aria-controls={openSubMenu ? 'demo-customized-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={openSubMenu ? 'true' : undefined}
                                                    variant="contained"
                                                    disableElevation
                                                    onClick={handleSubMenuClick}
                                                    sx={{ width: 112, p: 0, mt: 2 }}
                                                >
                                                    {capitalize(defaultRole === 'securitygaurd' ? 'Security Guard' : defaultRole)}
                                                </Button>
                                                <Menu
                                                    id="long-menu"
                                                    MenuListProps={{
                                                        'aria-labelledby': 'long-button'
                                                    }}
                                                    anchorEl={subMenuAnchorEl}
                                                    open={openSubMenu}
                                                    // onClose={handleSubMenuClose}
                                                    PaperProps={{
                                                        style: {
                                                            maxHeight: 48 * 4.5,
                                                            width: 112
                                                        }
                                                    }}
                                                    onClick={(e) => handleRoleChange(e)}
                                                >
                                                    {user?.userRoles.map((item, i) => (
                                                        <MenuItem
                                                            key={i}
                                                            value={item}
                                                            selected={lowerCase(item) === defaultRole}
                                                            sx={{ pl: 1, pr: 1, minHeight: 30 }}
                                                        >
                                                            {item === 'Securitygaurd' ? 'Security Guard' : item}
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            </Grid>

                                            <List
                                                component="nav"
                                                sx={{
                                                    px: 1,
                                                    width: '100%',
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    },
                                                    pb: 0
                                                }}
                                            >
                                                <ListItemButton
                                                    sx={{ borderRadius: `${borderRadius}px` }}
                                                    selected={selectedIndex === 0}
                                                    onClick={(event) => handleListItemClick(event, 0, '/account')}
                                                    disabled={
                                                        defaultRole === 'managment' &&
                                                        every(['Managment', 'Administrator'], (value) => includes(user.userRoles, value))
                                                    }
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="20px" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                                                </ListItemButton>
                                                <ListItemButton
                                                    disabled={defaultRole !== 'student' || !user?.isEmailVerfied}
                                                    sx={{ borderRadius: `${borderRadius}px` }}
                                                    selected={selectedIndex === 1}
                                                    onClick={(event) => handleListItemClick(event, 1, '/profile')}
                                                >
                                                    <ListItemIcon>
                                                        <IconUser stroke={1.5} size="20px" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Grid container spacing={1} justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="body2">Manage Profile</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ borderRadius: `${borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="20px" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ borderRadius: `${borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={refreshAndUpdate}
                                                >
                                                    <ListItemIcon>
                                                        <Update sx={{ fontSize: '20px' }} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Refresh & Update</Typography>} />
                                                </ListItemButton>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="subtitle2" align="center">
                                                            4.5.9
                                                        </Typography>
                                                    }
                                                />
                                            </List>
                                        </Box>
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
