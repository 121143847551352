import Loadable from 'ui-component/Loadable';
import { lazy, useState } from 'react';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { replace } from 'lodash';

const Pricing = Loadable(lazy(() => import('views/common/Pricing')));

// styles
const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary.light,
    marginTop: '16px',
    marginBottom: '16px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '19px solid ',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
        borderRadius: '50%',
        top: '50px',
        right: '-150px'
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '3px solid ',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
        borderRadius: '50%',
        top: '100px',
        right: '-90px'
    }
}));

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const BuyNowCard = () => {
    const { defaultRole, isLoggedIn, user } = useAuth();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(!open);
    };

    const handleBuyNow = () => {
        if ((defaultRole === 'free' || defaultRole === 'anon') && !isLoggedIn) {
            navigate('register', { replace: true });
        } else {
            setOpen(!open);
        }
    };

    return (
        <>
            <CardStyle>
                <CardContent>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <Typography variant="h4">Upgrade your plan</Typography>
                        </Grid>
                        <Grid item>
                            {/* <Typography
                            variant="subtitle2"
                            color={theme.palette.mode === 'dark' ? 'textSecondary' : 'grey.900'}
                            sx={{ opacity: theme.palette.mode === 'dark' ? 1 : 0.6 }}
                        >
                            70% discount for 1 years <br />
                            subscriptions.
                        </Typography> */}
                        </Grid>
                        <Grid item>
                            <Stack direction="row">
                                <AnimateButton>
                                    <Button variant="contained" sx={{ boxShadow: 'none' }} onClick={handleBuyNow}>
                                        Buy Now
                                    </Button>
                                </AnimateButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardStyle>
            {defaultRole === 'free' && user.isEmailVerfied && <Pricing open={open} handleClose={handleClose} />}
        </>
    );
};

export default BuyNowCard;
