/* eslint-disable dot-notation */
import { Grid, Table } from '@mui/material';
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SubCard from 'ui-component/cards/SubCard';
import './custom-table.css';
import Header from './Header';
import Pagination from './Pagination';
import TableBody from './TableBody';
import TableHeader from './TableHeader';

function CustomTable({
    headCells = false,
    records = [],
    title = false,
    search = false,
    button = false,
    session = false,
    select = false,
    selectedOrder = 'asc',
    selectedOrderBy = '',
    accordianBtn = false,
    header = false,
    pagination = false,
    isLoading = false,
    csvExport = false
}) {
    const [order, setOrder] = useState(selectedOrder);
    const [orderBy, setOrderBy] = useState(selectedOrderBy);
    const [name, setName] = useState('');
    const [filteredTableData, setFilteredTableData] = useState(records);

    useEffect(() => {
        setFilteredTableData(records);
    }, [records]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const filter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const result = records.filter((o) => Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(keyword.toLowerCase())));
            setFilteredTableData(result);
        } else {
            setFilteredTableData(records);
        }
        setName(keyword);
    };

    const handleRemove = (e) => {
        const data = filteredTableData.filter((i) => i.id !== e);
        setFilteredTableData(data);
    };

    return (
        <Grid item xs={12} lg={12} md={12}>
            <SubCard
                title={
                    header ? (
                        <Header
                            title={title}
                            search={search ? { ...search, name, filter } : false}
                            button={button}
                            session={session}
                            select={select}
                            headCells={headCells}
                            records={records}
                            csvExport={csvExport}
                        />
                    ) : null
                }
                content={false}
                className="pbx-annoucement-parent-card"
                sx={{
                    '.MuiCardHeader-root': {
                        pt: '12px !important',
                        pb:
                            (title && button && search) || (title && csvExport && search) || (search && select)
                                ? '12px !important'
                                : '0px !important',
                        p: 0
                    }
                }}
            >
                <PerfectScrollbar
                    className={
                        (title && button && search) || (title && csvExport && search) || (search && select)
                            ? 'ps-height-double-row-head'
                            : 'ps-height-signle-row-head'
                    }
                >
                    <Table
                        sx={{
                            maxHeight: 'calc(100vh - 88px)',
                            width: '100%'
                        }}
                        stickyHeader
                        aria-label="sticky table"
                    >
                        <TableHeader order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />
                        <TableBody
                            headCells={headCells}
                            order={order}
                            orderBy={orderBy}
                            accordianBtn={accordianBtn}
                            filteredTableData={filteredTableData}
                            handleRemove={handleRemove}
                            isLoading={isLoading}
                        />
                    </Table>
                </PerfectScrollbar>

                {/* {pagination ? <Pagination foundUsers={filteredTableData} /> : null} */}
            </SubCard>
        </Grid>
    );
}

export default CustomTable;
