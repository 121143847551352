import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';

// material-ui
import { Box, Divider, Drawer, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import useAuth from 'hooks/useAuth';
import useMenuItems from 'hooks/useMenuItems';
import { useDispatch, useSelector } from 'store';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import ProfileSection from '../Header/ProfileSection';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard/BuyNowCard';
import MenuList from './MenuList';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ window }) => {
    const { defaultRole, isLoggedIn } = useAuth();
    const theme = useTheme();
    const menuItem = useMenuItems();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const logo = useMemo(
        () => (
            <Box sx={{ display: { sm: 'block' } }}>
                <Box sx={{ display: 'flex', px: 2, py: 1.5, mx: 'auto', pt: 2.5, justifyContent: 'center' }}>
                    <LogoSection />
                </Box>
            </Box>
        ),
        []
    );

    const drawer = useMemo(
        () => (
            <PerfectScrollbar
                component="div"
                style={{
                    height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 52px)',
                    paddingLeft: '12px',
                    paddingRight: '12px'
                    // paddingTop: '20px'
                }}
            >
                <MenuList menuItem={menuItem} />
                {(defaultRole === 'anon' || defaultRole === 'free') && <MenuCard />}
            </PerfectScrollbar>
        ),
        [matchUpMd, defaultRole]
    );

    const profile = useMemo(
        () => (
            <>
                <Divider />
                <Box sx={{ p: 1.5, pl: 2 }}>
                    <Grid item container>
                        <Grid item xs={12} alignItems="center" display="flex" justifyContent="center">
                            <ProfileSection />
                        </Grid>
                    </Grid>
                </Box>
            </>
        ),
        []
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={() => dispatch(openDrawer(!drawerOpen))}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '0px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawerOpen && logo}
                {drawerOpen && drawer}
                {drawerOpen && (defaultRole !== 'anon' || defaultRole !== 'free') && isLoggedIn && profile}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    window: PropTypes.object
};

export default memo(Sidebar);
