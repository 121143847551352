import { InputAdornment, OutlinedInput, useTheme } from '@mui/material';
import { IconSearch } from '@tabler/icons';

function SearchField({ name, filter }) {
    const theme = useTheme();

    return (
        <OutlinedInput
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'right',
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: `${theme.palette.divider} !important`
                }
            }}
            id="input-search-list-style1"
            placeholder="Search"
            value={name}
            onChange={filter}
            startAdornment={
                <InputAdornment position="start">
                    <IconSearch stroke={1.5} size="16px" />
                </InputAdornment>
            }
            size="small"
        />
    );
}

export default SearchField;
