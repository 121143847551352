import { useMutation } from '@apollo/client';
import { Button, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { toNumber } from 'lodash';
import { useEffect } from 'react';
import { INSERT_CONTACT_US } from 'services/common/mutation';
import { dispatch, useSelector } from 'store';
import { setCities, setStates } from 'store/slices/common';
import { openSnackbar } from 'store/slices/snackbar';
import { roleInSchool } from 'utils/staticData';
import Autocomplete from 'component/Autocomplete';
import TextField from 'component/TextField';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    schoolName: Yup.string().required('School Name is required'),
    stateName: Yup.string().required('State Name is required'),
    stateId: Yup.string().nullable(),
    districtName: Yup.string().required('District Name is required'),
    districtId: Yup.string().nullable(),
    pinCode: Yup.string()
        .matches(/^[0-9]{6}$/, 'PIN Code must be 6 digits')
        .required('PIN Code is required'),
    name: Yup.string().required('Name is required'),
    phone: Yup.string()
        .matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits')
        .required('Mobile number is required'),
    roleName: Yup.string().required('Role is required'),
    roleId: Yup.string().nullable(),
    noOfstudents: Yup.number().typeError('Must be a number type').min(0, 'Must be a positve number').required('Please enter No of Students')
});

const ContactUsForm = () => {
    const { states, cities } = useSelector((states) => states.common);

    useEffect(() => {
        dispatch(setStates());
    }, []);

    const [insertContactUs] = useMutation(INSERT_CONTACT_US, {
        onCompleted: () => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'We have received your details, will contact you sooner',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    transition: 'SlideLeft'
                })
            );
        },
        onError: () => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'There is some error, Plese try again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    transition: 'SlideLeft'
                })
            );
        }
    });

    const formik = useFormik({
        initialValues: {
            schoolName: '',
            stateName: '',
            stateId: '',
            districtName: '',
            districtId: '',
            pinCode: '',
            name: '',
            phone: '',
            roleName: '',
            roleId: '',
            noOfstudents: ''
        },
        validationSchema,
        onSubmit: (values) => {
            console.log({
                schoolName: values.schoolName,
                stateName: values.stateName,
                stateId: values.stateId,
                districtName: values.districtName,
                districtId: values.districtId,
                pinCode: values.pinCode,
                name: values.name,
                phone: values.phone,
                roleName: values.roleName,
                roleId: values.roleId,
                students: toNumber(values.noOfstudents)
            });
            insertContactUs({
                variables: {
                    schoolName: values.schoolName,
                    state: values.stateName,
                    district: values.districtName,
                    pinCode: values.pinCode,
                    name: values.name,
                    phone: values.phone,
                    role: values.roleName,
                    students: toNumber(values.noOfstudents)
                }
            });
        }
    });

    const handleState = (id) => {
        dispatch(setCities(id));
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField formik={formik} fieldName="schoolName" label="School Name" rows={2} />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        label="State"
                        formik={formik}
                        option={states}
                        fieldName={{ title: 'stateName', id: 'stateId' }}
                        actions={handleState}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        label="District/City"
                        formik={formik}
                        option={cities}
                        fieldName={{ title: 'districtName', id: 'districtId' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField formik={formik} fieldName="pinCode" label="PIN Code" />
                </Grid>
                <Grid item xs={12}>
                    <TextField formik={formik} fieldName="name" label="Name" />
                </Grid>
                <Grid item xs={12}>
                    <TextField formik={formik} fieldName="phone" label="Mobile Number" />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete label="Role" formik={formik} option={roleInSchool} fieldName={{ title: 'roleName', id: 'roleId' }} />
                </Grid>

                <Grid item xs={12}>
                    <TextField formik={formik} fieldName="noOfstudents" label="No of Students" />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default ContactUsForm;
