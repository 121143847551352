/* eslint-disable no-empty */
import Modal from 'component/Modal';
import VerticalTab from '../Tab/VerticalTab';
import GuardianInfo from './GuardianInfo';
import ParentInfo from './ParentInfo';
import StudentInfo from './StudentInfo';

function StudentProfileDialog({ open, close, id }) {
    const titleList = [
        {
            id: 0,
            title: 'Student Info'
        },
        {
            id: 1,
            title: 'Parent Info'
        },
        {
            id: 2,
            title: 'Guardian Info'
        }
    ];

    const contentList = [
        {
            id: 0,
            content: open ? <StudentInfo id={id} /> : null
        },
        {
            id: 1,
            content: open ? <ParentInfo id={id} /> : null
        },
        {
            id: 2,
            content: open ? <GuardianInfo id={id} /> : null
        }
    ];
    return (
        <Modal title="Student Details" open={open} close={close} width={600}>
            <VerticalTab titleList={titleList} contentList={contentList} />
        </Modal>
    );
}

export default StudentProfileDialog;
