/* eslint-disable import/prefer-default-export */
import { InMemoryCache } from '@apollo/client';

export const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                school_info_by_pk: {
                    merge(existing, incoming, { mergeObjects }) {
                        return mergeObjects(existing, incoming);
                    }
                },
                class_fees_by_pk: {
                    merge(existing = {}, incoming) {
                        return { ...existing, ...incoming };
                    }
                },
                transport_fees_by_pk: {
                    merge(existing = {}, incoming) {
                        return { ...existing, ...incoming };
                    }
                },
                school_class_details_by_pk: {
                    merge(existing = {}, incoming) {
                        return { ...existing, ...incoming };
                    }
                }
            }
        }
    }
});

// Use it while using apollo cache persist

// export const cache = new InMemoryCache({
//     typePolicies: {
//         school_accounts: {
//             keyFields: ['sa_uid']
//         },
//         school_secguard: {
//             keyFields: ['sg_uid']
//         },
//         school_staff: {
//             keyFields: ['staff_id']
//         },
//         class_group: {
//             keyFields: ['cg_id', 'session']
//         },
//         stream: {
//             keyFields: ['str_id', 'session']
//         }
//     }
// });
