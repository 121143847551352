/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const INSERT_SUBJECT_FOR_EXAM_ONE = gql`
    mutation InsertSubjectForExamOne(
        $subjectId: String
        $classId: String
        $schoolId: String
        $subjectsOfExam: String
        $sub1: String
        $total1: Int
        $sub2: String
        $total2: Int
        $sub3: String
        $total3: Int
        $sub4: String
        $total4: Int
        $sub5: String
        $total5: Int
        $sub6: String
        $total6: Int
        $sub7: String
        $total7: Int
        $sub8: String
        $total8: Int
        $sub9: String
        $total9: Int
        $sub10: String
        $total10: Int
        $sub11: String
        $total11: Int
        $sub12: String
        $total12: Int
        $sub13: String
        $total13: Int
        $sub14: String
        $total14: Int
        $sub15: String
        $total15: Int
    ) {
        insert_result_subjects_one(
            object: {
                subject_id: $subjectId
                class_id: $classId
                school_id: $schoolId
                subjects_of_exam: $subjectsOfExam
                sub1: $sub1
                total1: $total1
                sub2: $sub2
                total2: $total2
                sub3: $sub3
                total3: $total3
                sub4: $sub4
                total4: $total4
                sub5: $sub5
                total5: $total5
                sub6: $sub6
                total6: $total6
                sub7: $sub7
                total7: $total7
                sub8: $sub8
                total8: $total8
                sub9: $sub9
                total9: $total9
                sub10: $sub10
                total10: $total10
                sub11: $sub11
                total11: $total11
                sub12: $sub12
                total12: $total12
                sub13: $sub13
                total13: $total13
                sub14: $sub14
                total14: $total14
                sub15: $sub15
                total15: $total15
            }
        ) {
            subject_id
            subjects_of_exam
        }
    }
`;

export const UPDATE_SUBJECT_FOR_EXAM_ONE = gql`
    mutation UpdateSubjectOfExamOne(
        $subjectId: String!
        $subjectsOfExam: String
        $sub1: String
        $total1: Int
        $sub2: String
        $total2: Int
        $sub3: String
        $total3: Int
        $sub4: String
        $total4: Int
        $sub5: String
        $total5: Int
        $sub6: String
        $total6: Int
        $sub7: String
        $total7: Int
        $sub8: String
        $total8: Int
        $sub9: String
        $total9: Int
        $sub10: String
        $total10: Int
        $sub11: String
        $total11: Int
        $sub12: String
        $total12: Int
        $sub13: String
        $total13: Int
        $sub14: String
        $total14: Int
        $sub15: String
        $total15: Int
    ) {
        update_result_subjects_by_pk(
            pk_columns: { subject_id: $subjectId }
            _set: {
                subjects_of_exam: $subjectsOfExam
                sub1: $sub1
                total1: $total1
                sub2: $sub2
                total2: $total2
                sub3: $sub3
                total3: $total3
                sub4: $sub4
                total4: $total4
                sub5: $sub5
                total5: $total5
                sub6: $sub6
                total6: $total6
                sub7: $sub7
                total7: $total7
                sub8: $sub8
                total8: $total8
                sub9: $sub9
                total9: $total9
                sub10: $sub10
                total10: $total10
                sub11: $sub11
                total11: $total11
                sub12: $sub12
                total12: $total12
                sub13: $sub13
                total13: $total13
                sub14: $sub14
                total14: $total14
                sub15: $sub15
                total15: $total15
            }
        ) {
            subject_id
            subjects_of_exam
        }
    }
`;

export const UPDATE_STUDENT_RESULT_1 = gql`
    mutation UpdateStudentResult1(
        $resultId: String!
        $examId: String
        $subjectId: String
        $mark1: Int
        $mark2: Int
        $mark3: Int
        $mark4: Int
        $mark5: Int
        $mark6: Int
        $mark7: Int
        $mark8: Int
        $mark9: Int
        $mark10: Int
        $status: String
        $grade: String
        $obtained: Int
        $totalMarks: Int
        $updatedBy: String
    ) {
        update_student_result_1_by_pk(
            pk_columns: { result_id: $resultId }
            _set: {
                exam_id: $examId
                subject_id: $subjectId
                mark1: $mark1
                mark2: $mark2
                mark3: $mark3
                mark4: $mark4
                mark5: $mark5
                mark6: $mark6
                mark7: $mark7
                mark8: $mark8
                mark9: $mark9
                mark10: $mark10
                status: $status
                grade: $grade
                obtained: $obtained
                total_marks: $totalMarks
                updated_by: $updatedBy
            }
        ) {
            mark1
            mark2
            mark3
            mark4
            mark5
            mark6
            mark7
            mark8
            mark9
            mark10
            status
            grade
            obtained
            total_marks
            updated_by
        }
    }
`;

export const UPDATE_STUDENT_RESULT_2 = gql`
    mutation UpdateStudentResult2(
        $resultId: String!
        $examId: String
        $subjectId: String
        $mark1: Int
        $mark2: Int
        $mark3: Int
        $mark4: Int
        $mark5: Int
        $mark6: Int
        $mark7: Int
        $mark8: Int
        $mark9: Int
        $mark10: Int
        $status: String
        $grade: String
        $obtained: Int
        $totalMarks: Int
        $updatedBy: String
    ) {
        update_student_result_2_by_pk(
            pk_columns: { result_id: $resultId }
            _set: {
                exam_id: $examId
                subject_id: $subjectId
                mark1: $mark1
                mark2: $mark2
                mark3: $mark3
                mark4: $mark4
                mark5: $mark5
                mark6: $mark6
                mark7: $mark7
                mark8: $mark8
                mark9: $mark9
                mark10: $mark10
                status: $status
                grade: $grade
                obtained: $obtained
                total_marks: $totalMarks
                updated_by: $updatedBy
            }
        ) {
            mark1
            mark2
            mark3
            mark4
            mark5
            mark6
            mark7
            mark8
            mark9
            mark10
            status
            grade
            obtained
            total_marks
            updated_by
        }
    }
`;

export const UPDATE_STUDENT_RESULT_3 = gql`
    mutation UpdateStudentResult3(
        $resultId: String!
        $examId: String
        $subjectId: String
        $mark1: Int
        $mark2: Int
        $mark3: Int
        $mark4: Int
        $mark5: Int
        $mark6: Int
        $mark7: Int
        $mark8: Int
        $mark9: Int
        $mark10: Int
        $status: String
        $grade: String
        $obtained: Int
        $totalMarks: Int
        $updatedBy: String
    ) {
        update_student_result_3_by_pk(
            pk_columns: { result_id: $resultId }
            _set: {
                exam_id: $examId
                subject_id: $subjectId
                mark1: $mark1
                mark2: $mark2
                mark3: $mark3
                mark4: $mark4
                mark5: $mark5
                mark6: $mark6
                mark7: $mark7
                mark8: $mark8
                mark9: $mark9
                mark10: $mark10
                status: $status
                grade: $grade
                obtained: $obtained
                total_marks: $totalMarks
                updated_by: $updatedBy
            }
        ) {
            mark1
            mark2
            mark3
            mark4
            mark5
            mark6
            mark7
            mark8
            mark9
            mark10
            status
            grade
            obtained
            total_marks
            updated_by
        }
    }
`;

export const UPDATE_STUDENT_RESULT_4 = gql`
    mutation UpdateStudentResult4(
        $resultId: String!
        $examId: String
        $subjectId: String
        $mark1: Int
        $mark2: Int
        $mark3: Int
        $mark4: Int
        $mark5: Int
        $mark6: Int
        $mark7: Int
        $mark8: Int
        $mark9: Int
        $mark10: Int
        $status: String
        $grade: String
        $obtained: Int
        $totalMarks: Int
        $updatedBy: String
    ) {
        update_student_result_4_by_pk(
            pk_columns: { result_id: $resultId }
            _set: {
                exam_id: $examId
                subject_id: $subjectId
                mark1: $mark1
                mark2: $mark2
                mark3: $mark3
                mark4: $mark4
                mark5: $mark5
                mark6: $mark6
                mark7: $mark7
                mark8: $mark8
                mark9: $mark9
                mark10: $mark10
                status: $status
                grade: $grade
                obtained: $obtained
                total_marks: $totalMarks
                updated_by: $updatedBy
            }
        ) {
            mark1
            mark2
            mark3
            mark4
            mark5
            mark6
            mark7
            mark8
            mark9
            mark10
            status
            grade
            obtained
            total_marks
            updated_by
        }
    }
`;

export const UPSERT_CRED_PLUS = gql`
    # mutation CredPlus($schoolId: String!, $credit: Int, $assigner: String, $ncredit: Int) {
    #     insert_creditplus_one(object: { school_id: $schoolId, credit: $credit, assigner: $assigner }) {
    #         id
    #     }
    #     update_school_info_by_pk(pk_columns: { gusid: $schoolId }, _set: { credits: $ncredit }) {
    #         credits
    #         gusid
    #     }
    # }
    mutation CredPlus(
        $schoolId: String!
        $credit: Int
        $assigner: String
        $ncredit: Int
        $amount: Int
        $txnId: String
        $bank: Int
        $txnDate: String
    ) {
        insert_creditplus_one(
            object: {
                school_id: $schoolId
                credit: $credit
                assigner: $assigner
                amount: $amount
                trxn_id: $txnId
                bank: $bank
                trxndate: $txnDate
            }
        ) {
            credit
        }
        update_school_info_by_pk(pk_columns: { gusid: $schoolId }, _set: { credits: $ncredit }) {
            credits
            gusid
        }
    }
`;

export const UPSERT_STATUS = gql`
    mutation BCStatusChange($schoolId: String!, $status: String) {
        update_school_info_by_pk(pk_columns: { gusid: $schoolId }, _set: { status: $status }) {
            status
            gusid
        }
    }
`;

export const DELETE_ONLINE_CLASS = gql`
    mutation DeleteOC($sid: String!) {
        delete_oc_by_pk(sid: $sid) {
            sid
        }
    }
`;

export const INSERT_ONLINE_CLASS = gql`
    mutation InsertOC($sid: String, $topic: String, $time: String, $murl: String) {
        insert_oc_one(object: { sid: $sid, topic: $topic, time: $time, murl: $murl }) {
            sid
            topic
            time
            murl
        }
    }
`;

export const DELETE_HOME_WORK = gql`
    mutation DeleteHomework($id: Int!) {
        delete_hw_by_pk(id: $id) {
            id
        }
    }
`;

export const INSERT_HOME_WORK = gql`
    mutation NewHomework($sid: String, $task: String) {
        insert_hw_one(object: { sid: $sid, task: $task }) {
            id
            task
            created_at
        }
    }
`;

export const INSERT_SUBJECT_OF_EXAM = gql`
    mutation ExamSubjectBind($exsuId: String, $classId: String, $examId: String, $subId: String) {
        insert_exam_subject_one(object: { exsu_id: $exsuId, class_id: $classId, exam_id: $examId, sub_id: $subId }) {
            exsu_id
            exam_id
            sub_id
            result_subject {
                subjects_of_exam
            }
        }
    }
`;

export const UPDATE_SUBJECT_OF_EXAM = gql`
    mutation UpdateExamSubject($classId: String, $examId: String, $subId: String) {
        update_exam_subject(where: { class_id: { _eq: $classId }, exam_id: { _eq: $examId } }, _set: { sub_id: $subId }) {
            returning {
                exam_id
                sub_id
                result_subject {
                    subjects_of_exam
                }
            }
        }
    }
`;

export const INSERT_RESULT_ONE = gql`
    mutation InsertResultOne(
        $resultId: String
        $studentId: String
        $classId: String
        $schoolId: String
        $exsuId: String
        $m1: Int
        $m2: Int
        $m3: Int
        $m4: Int
        $m5: Int
        $m6: Int
        $m7: Int
        $m8: Int
        $m9: Int
        $m10: Int
        $m11: Int
        $m12: Int
        $m13: Int
        $m14: Int
        $m15: Int
        $status: String
        $grade: String
        $obtained: Int
        $rank: Int
        $total: Int
    ) {
        insert_results_one(
            object: {
                result_id: $resultId
                student_id: $studentId
                class_id: $classId
                school_id: $schoolId
                exsu_id: $exsuId
                m1: $m1
                m2: $m2
                m3: $m3
                m4: $m4
                m5: $m5
                m6: $m6
                m7: $m7
                m8: $m8
                m9: $m9
                m10: $m10
                m11: $m11
                m12: $m12
                m13: $m13
                m14: $m14
                m15: $m15
                status: $status
                grade: $grade
                obtained: $obtained
                rank: $rank
                total: $total
            }
        ) {
            result_id
            student_id
            m1
            m2
            m3
            m4
            m5
            m6
            m7
            m8
            m9
            m10
            m11
            m12
            m13
            m14
            m15
            status
            grade
            obtained
            rank
        }
    }
`;

export const UPDATE_RESULT_ONE = gql`
    mutation UpdateResultByID(
        $resultId: String!
        $m1: Int
        $m2: Int
        $m3: Int
        $m4: Int
        $m5: Int
        $m6: Int
        $m7: Int
        $m8: Int
        $m9: Int
        $m10: Int
        $m11: Int
        $m12: Int
        $m13: Int
        $m14: Int
        $m15: Int
        $status: String
        $grade: String
        $obtained: Int
        $rank: Int
        $total: Int
    ) {
        update_results_by_pk(
            pk_columns: { result_id: $resultId }
            _set: {
                m1: $m1
                m2: $m2
                m3: $m3
                m4: $m4
                m5: $m5
                m6: $m6
                m7: $m7
                m8: $m8
                m9: $m9
                m10: $m10
                m11: $m11
                m12: $m12
                m13: $m13
                m14: $m14
                m15: $m15
                status: $status
                grade: $grade
                obtained: $obtained
                rank: $rank
                total: $total
            }
        ) {
            result_id
            student_id
            m1
            m2
            m3
            m4
            m5
            m6
            m7
            m8
            m9
            m10
            m11
            m12
            m13
            m14
            m15
            status
            grade
            obtained
            rank
        }
    }
`;

export const INSERT_FINAL_RESULT = gql`
    mutation InsertFinalResultOne(
        $resultId: String
        $studentId: String
        $classId: String
        $schoolId: String
        $exsuId: String
        $m1: Int
        $m2: Int
        $m3: Int
        $m4: Int
        $m5: Int
        $m6: Int
        $m7: Int
        $m8: Int
        $m9: Int
        $m10: Int
        $m11: Int
        $m12: Int
        $m13: Int
        $m14: Int
        $m15: Int
        $status: String
        $grade: String
        $obtained: Int
        $rank: Int
        $total: Int
    ) {
        insert_final_result_one(
            object: {
                result_id: $resultId
                student_id: $studentId
                class_id: $classId
                school_id: $schoolId
                exsu_id: $exsuId
                m1: $m1
                m2: $m2
                m3: $m3
                m4: $m4
                m5: $m5
                m6: $m6
                m7: $m7
                m8: $m8
                m9: $m9
                m10: $m10
                m11: $m11
                m12: $m12
                m13: $m13
                m14: $m14
                m15: $m15
                status: $status
                grade: $grade
                obtained: $obtained
                rank: $rank
                total: $total
            }
        ) {
            result_id
            student_id
            m1
            m2
            m3
            m4
            m5
            m6
            m7
            m8
            m9
            m10
            m11
            m12
            m13
            m14
            m15
            status
            grade
            obtained
            rank
        }
    }
`;

export const UPDATE_FINAL_RESULT = gql`
    mutation UpdateFinalResultByID(
        $resultId: String!
        $m1: Int
        $m2: Int
        $m3: Int
        $m4: Int
        $m5: Int
        $m6: Int
        $m7: Int
        $m8: Int
        $m9: Int
        $m10: Int
        $m11: Int
        $m12: Int
        $m13: Int
        $m14: Int
        $m15: Int
        $status: String
        $grade: String
        $obtained: Int
        $rank: Int
        $total: Int
    ) {
        update_final_result_by_pk(
            pk_columns: { result_id: $resultId }
            _set: {
                m1: $m1
                m2: $m2
                m3: $m3
                m4: $m4
                m5: $m5
                m6: $m6
                m7: $m7
                m8: $m8
                m9: $m9
                m10: $m10
                m11: $m11
                m12: $m12
                m13: $m13
                m14: $m14
                m15: $m15
                status: $status
                grade: $grade
                obtained: $obtained
                rank: $rank
                total: $total
            }
        ) {
            result_id
            student_id
            m1
            m2
            m3
            m4
            m5
            m6
            m7
            m8
            m9
            m10
            m11
            m12
            m13
            m14
            m15
            status
            grade
            obtained
            rank
        }
    }
`;

export const INSERT_ITEM = gql`
    mutation InsertItemOne($pId: Int, $eName: String, $nDesc: String, $price: Int, $class: String, $schoolId: String) {
        insert_inventory_one(object: { p_id: $pId, e_name: $eName, n_desc: $nDesc, price: $price, class: $class, school_id: $schoolId }) {
            iid
            product {
                item
                desc
            }
            e_name
            n_desc
            class
            qty
            price
        }
    }
`;

export const UPDATE_ITEM = gql`
    mutation UpdateItem($iid: Int!, $eName: String, $nDesc: String, $price: Int, $class: String) {
        update_inventory_by_pk(pk_columns: { iid: $iid }, _set: { e_name: $eName, n_desc: $nDesc, price: $price, class: $class }) {
            iid
            e_name
            qty
            price
            n_desc
            class
        }
    }
`;

export const INSERT_QUANTITY = gql`
    mutation InsertQty($iid: Int!, $qty: Int, $session: String, $nQty: Int) {
        insert_stock_data_one(object: { iid: $iid, qty: $qty, session: $session }) {
            iid
        }
        update_inventory_by_pk(pk_columns: { iid: $iid }, _set: { qty: $nQty }) {
            qty
        }
    }
`;

export const COMPLETE_ORDER = gql`
    mutation InsertOrders($productData: [orders_insert_input!]!, $qtyData: [inventory_updates!]!) {
        insert_orders(objects: $productData) {
            affected_rows
        }
        update_inventory_many(updates: $qtyData) {
            affected_rows
        }
    }
`;

export const INSERT_CONTACT_US = gql`
    mutation InsertContactUs(
        $schoolName: String
        $state: String
        $district: String
        $pinCode: String
        $name: String
        $phone: String
        $role: String
        $students: Int
    ) {
        insert_contact_us_one(
            object: {
                school_name: $schoolName
                state: $state
                district: $district
                pin_code: $pinCode
                name: $name
                phone: $phone
                role: $role
                students: $students
            }
        ) {
            id
        }
    }
`;
