import PropTypes from 'prop-types';
import { Card, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { gridSpacing } from 'store/constant';
import Avatar from 'ui-component/extended/Avatar';
import { useQuery } from '@apollo/client';
import { GET_STUDENT_DETAILS } from 'services/common/query';
import { useState } from 'react';
import { fullName } from 'utils/common';
import { format } from 'date-fns';

const StudentInfo = ({ id }) => {
    const theme = useTheme();
    const [studentInformation, setStudentInformation] = useState({});

    useQuery(GET_STUDENT_DETAILS, {
        variables: {
            studentId: id
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            // console.log(data);
            if (data.school_student_classes_by_pk) {
                const firstName = data.school_student_classes_by_pk?.school_student?.first_name;
                const middleName = data.school_student_classes_by_pk?.school_student?.middle_name;
                const lastName = data.school_student_classes_by_pk?.school_student?.last_name;
                setStudentInformation({
                    studentName: fullName(firstName, middleName, lastName),
                    gender: data.school_student_classes_by_pk?.school_student?.gender,
                    dateOfBirth: data.school_student_classes_by_pk?.school_student?.birth_date
                        ? format(new Date(data.school_student_classes_by_pk?.school_student?.birth_date), 'dd/MM/yyyy')
                        : '',
                    studentPhoto: data.school_student_classes_by_pk?.school_student?.student_photo,
                    rollNo: data.school_student_classes_by_pk?.roll_no,
                    className: `${data.school_student_classes_by_pk?.school_class_detail?.class_name} ${
                        data.school_student_classes_by_pk?.school_class_detail?.class_section
                            ? data.school_student_classes_by_pk?.school_class_detail?.class_section
                            : ''
                    }`,
                    admission: data.school_student_classes_by_pk?.admission_date
                        ? format(new Date(data.school_student_classes_by_pk?.admission_date), 'dd/MM/yyyy')
                        : '',
                    phoneNo: data.school_student_classes_by_pk?.school_student?.contact_no,
                    email: data.school_student_classes_by_pk?.school_student?.email_id,
                    admissionNo: data.school_student_classes_by_pk?.school_student?.adm_no
                });
            }
        },
        onError: (error) => {
            // console.log(error);
        }
    });
    return (
        <Card
            sx={{
                p: 2,
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                border: theme.palette.mode === 'dark' ? '1px solid transparent' : `1px solid${theme.palette.grey[100]}`,
                '&:hover': {
                    borderColor: theme.palette.primary.main
                }
            }}
        >
            <Grid container spacing={gridSpacing}>
                <Grid item xs={8} display="flex" flexDirection="column" justifyContent="center">
                    <Typography variant="h3" component="div">
                        {studentInformation.studentName}
                    </Typography>
                    <Typography variant="caption">{`${studentInformation.gender ? `${studentInformation.gender},` : ''} ${
                        studentInformation.dateOfBirth
                    } ${studentInformation.admissionNo ? `, ${studentInformation.admissionNo}` : ''}`}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs zeroMinWidth display="flex" justifyContent="flex-end">
                            <Avatar alt="Student Photo" size="lg" src={studentInformation.studentPhoto} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{ pb: { xs: 1, sm: 1 } }}>
                        <Grid item xs={4} sm={3.5}>
                            <Typography variant="caption">Roll No</Typography>
                            <Typography variant="h6">{studentInformation.rollNo}</Typography>
                        </Grid>
                        <Grid item xs={8} sm={6}>
                            <Typography variant="caption" align="center">
                                Class Name
                            </Typography>
                            <Typography variant="h6">{studentInformation.className}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2.5} sx={{ pt: { xs: 1, sm: 0 } }}>
                            <Typography variant="caption">Admission</Typography>
                            <Typography variant="h6">{studentInformation.admission}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={3.5} sx={{ pb: { xs: 1, sm: 'auto' } }}>
                            <Typography variant="caption">Phone</Typography>
                            <Typography variant="h6">{studentInformation.phoneNo}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8.5}>
                            <Typography variant="caption">Email</Typography>
                            <Typography variant="h6">{studentInformation.email}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

StudentInfo.propTypes = {
    id: PropTypes.string
};

export default StudentInfo;
