import { lazy } from 'react';

import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const BizCenter = Loadable(lazy(() => import('views/zcon/BizCenter')));
const RoleAssign = Loadable(lazy(() => import('views/zcon/RoleAssign')));

const ZconRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <BizCenter />
        },
        {
            path: '/role-Assign',
            element: <RoleAssign />
        }
    ]
};

export default ZconRoutes;
