/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_TEACHER_DASH_CLASS_DETAILS = gql`
    query TeacherDashClassDetails($teacherGuid: String) {
        school_teachers(where: { teacher_guid: { _eq: $teacherGuid } }, limit: 1, order_by: { created_at: desc }) {
            teacher_id
            take_atten
            mod_res
            read_fees
            school_class_details(limit: 1, order_by: { updated_at: desc }) {
                class_id
                class_name
                class_section
                session
                class_start_date
                school_id
                school_student_classes_aggregate {
                    aggregate {
                        count(columns: student_id)
                    }
                }
            }
            school_info {
                school_name
            }
        }
    }
`;

export const GET_TEACHER_DASH_CLASS_DETAILS_GENDERS = gql`
    query TeacherDashClassDetailsGenders($ClassId: String) {
        school_student_classes_aggregate(where: { class_id: { _eq: $ClassId }, school_student: { gender: { _eq: "F" } } }) {
            aggregate {
                count(columns: student_id)
            }
        }
    }
`;

export const GET_TEACHER_DASH_EXAMS = gql`
    # query TeacherDashExams($teacherGuid: String) {
    #     school_teachers(where: { teacher_guid: { _eq: $teacherGuid } }, order_by: { created_at: desc }, limit: 1) {
    #         school_info {
    #             school_exams(limit: 5) {
    #                 created_at
    #                 exam_name
    #                 exam_date
    #             }
    #         }
    #     }
    # }

    query TeacherExams($teacherGuid: String, $className: String, $classNameSection: String, $session: String) {
        school_teachers(where: { teacher_guid: { _eq: $teacherGuid } }, limit: 1, order_by: { created_at: desc }) {
            school_info {
                school_exams(
                    order_by: { created_at: desc }
                    where: {
                        session: { _eq: $session }
                        _and: {
                            _or: [
                                { exam_for_class: { _eq: "School" } }
                                { exam_for_class: { _eq: $className } }
                                { exam_for_class: { _eq: $classNameSection } }
                            ]
                        }
                    }
                    limit: 5
                ) {
                    created_at
                    exam_name
                    exam_date
                    exam_type
                }
            }
        }
    }
`;

export const GET_TEACHER_DASH_NOTICES = gql`
    # query TeacherDashNotices($teacherGuid: String) {
    #     school_teachers(where: { teacher_guid: { _eq: $teacherGuid } }, order_by: { created_at: desc }, limit: 1) {
    #         school_info {
    #             school_notices(limit: 5) {
    #                 created_at
    #                 level
    #                 subject
    #             }
    #         }
    #     }
    # }
    query TeacherNotice($teacherGuid: String, $className: String, $classNameSection: String, $session: String) {
        school_teachers(where: { teacher_guid: { _eq: $teacherGuid } }, limit: 1, order_by: { created_at: desc }) {
            school_info {
                school_notices(
                    order_by: { created_at: desc }
                    where: {
                        session: { _eq: $session }
                        _and: {
                            _or: [
                                { level: { _eq: "School" } }
                                { level: { _eq: "Teacher" } }
                                { level: { _eq: $className } }
                                { level: { _eq: $classNameSection } }
                            ]
                        }
                    }
                    limit: 5
                ) {
                    id
                    created_at
                    level
                    subject
                }
            }
        }
    }
`;

export const GET_TEACHER_EXAMS = gql`
    # query TeacherExams($teacherGuid: String) {
    #     school_teachers(where: { teacher_guid: { _eq: $teacherGuid } }, order_by: { created_at: desc }, limit: 1) {
    #         school_info {
    #             school_name
    #             school_exams {
    #                 created_at
    #                 exam_name
    #                 exam_date
    #             }
    #         }
    #     }
    # }
    query TeacherExams($teacherGuid: String, $className: String, $classNameSection: String, $session: String) {
        school_teachers(where: { teacher_guid: { _eq: $teacherGuid } }, limit: 1, order_by: { created_at: desc }) {
            school_info {
                school_exams(
                    order_by: { created_at: desc }
                    where: {
                        session: { _eq: $session }
                        _and: {
                            _or: [
                                { exam_for_class: { _eq: "School" } }
                                { exam_for_class: { _eq: $className } }
                                { exam_for_class: { _eq: $classNameSection } }
                            ]
                        }
                    }
                ) {
                    created_at
                    exam_name
                    exam_date
                    exam_type
                }
            }
        }
    }
`;

export const GET_TEACHER_NOTICES = gql`
    # query TeacherNotice($teacherGuid: String) {
    #     school_teachers(where: { teacher_guid: { _eq: $teacherGuid } }, order_by: { created_at: desc }, limit: 1) {
    #         school_info {
    #             school_name
    #             school_notices {
    #                 created_at
    #                 level
    #                 subject
    #                 message
    #             }
    #         }
    #     }
    # }

    query TeacherNotice($teacherGuid: String, $className: String, $classNameSection: String, $session: String) {
        school_teachers(where: { teacher_guid: { _eq: $teacherGuid } }, limit: 1, order_by: { created_at: desc }) {
            school_info {
                school_notices(
                    order_by: { created_at: desc }
                    where: {
                        session: { _eq: $session }
                        _and: {
                            _or: [
                                { level: { _eq: "School" } }
                                { level: { _eq: "Teacher" } }
                                { level: { _eq: $className } }
                                { level: { _eq: $classNameSection } }
                            ]
                        }
                    }
                ) {
                    id
                    created_at
                    level
                    subject
                }
            }
        }
    }
`;

export const GET_TEACHER_CLASS_STUDENT = gql`
    query TeacherStudents($classTeacherID: String, $classId: String) {
        school_class_details(where: { class_teacher_id: { _eq: $classTeacherID }, class_id: { _eq: $classId } }) {
            school_student_classes(order_by: { school_student: { first_name: asc } }) {
                roll_no
                admission_date
                school_student {
                    student_guid
                    student_id
                    first_name
                    middle_name
                    last_name
                    gender
                    contact_no
                    email_id
                    birth_date
                    student_guardian_details {
                        guardian_name
                    }
                }
            }
        }
    }
`;

export const GET_SCHOOL_CLASS_STUDENT = gql`
    query TeacherStudents($classId: String) {
        school_class_details(where: { class_id: { _eq: $classId } }) {
            school_student_classes(order_by: { roll_no: asc }) {
                roll_no
                admission_date
                school_student {
                    student_id
                    first_name
                    middle_name
                    last_name
                    gender
                    contact_no
                    email_id
                    birth_date
                    adm_no
                    smod
                    student_guardian_detail {
                        guardian_name
                    }
                }
            }
        }
    }
`;

export const GET_TEACHER_STUDENT_GAURDIAN_DETAILS = gql`
    query GuardianDetails($studentId: String!) {
        student_guardian_details_by_pk(student_id: $studentId) {
            guardian_name
            guardian_contact1
            guardian_contact2
            guardian_email
            guardian_home_address
            guardian_office_address
            relation
        }
    }
`;

export const GET_TEACHER_STUDENT_PARENT_DETAILS = gql`
    query TeacherStudentParentDetails($studentId: String!) {
        school_students_by_pk(student_id: $studentId) {
            student_father_detail {
                father_name
                father_contact1
                father_contact2
            }
            student_mother_detail {
                mother_name
                mother_contact1
                mother_contact2
            }
        }
    }
`;

export const FEES_STATUS_BY_CLASS_TEACHER = gql`
    query SchoolClassFeeByStudent($id: String!) {
        school_class_details_by_pk(class_id: $id) {
            fees
            school_teacher {
                fees_value
            }
            school_student_classes(order_by: { roll_no: asc }) {
                roll_no
                student_id
                transport_id
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                class_fee {
                    apr
                    may
                    jun
                    jul
                    aug
                    sep
                    oct
                    nov
                    dec
                    jan
                    feb
                    mar
                    updated_at
                }
                transport_fee {
                    apr
                    may
                    jun
                    jul
                    aug
                    sep
                    oct
                    nov
                    dec
                    jan
                    feb
                    mar
                    updated_at
                    stopage {
                        fees
                    }
                }
            }
        }
    }
`;

export const GET_STUDENTS_ATTENDANCE_BY_CLASS_TEACHER_DETAILS_APR = gql`
    query APRIL_ATTENDANCE($classId: String) {
        school_student_classes(where: { school_class_detail: { class_id: { _eq: $classId } } }) {
            student_id
            roll_no
            school_student {
                first_name
                middle_name
                last_name
            }
            apr {
                aa
                ab
                ac
                ad
                ae
                af
                ag
                ah
                ai
                aj
                ak
                al
                am
                an
                ao
                ap
                aq
                ar
                as
                at
                au
                av
                aw
                ax
                ay
                az
                ba
                bb
                bc
                bd
            }
        }
    }
`;

export const GET_STUDENTS_ATTENDANCE_BY_CLASS_TEACHER_DETAILS_MAY = gql`
    query MAY_ATTENDANCE($classId: String) {
        school_student_classes(
            where: { school_class_detail: { class_id: { _eq: $classId } } }
            order_by: { school_student: { first_name: asc } }
        ) {
            student_id
            roll_no
            student_attendance {
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                atten_may {
                    may_1
                    may_2
                    may_3
                    may_4
                    may_5
                    may_6
                    may_7
                    may_8
                    may_9
                    may_10
                    may_11
                    may_12
                    may_13
                    may_14
                    may_15
                    may_16
                    may_17
                    may_18
                    may_19
                    may_20
                    may_21
                    may_22
                    may_23
                    may_24
                    may_25
                    may_26
                    may_27
                    may_28
                    may_29
                    may_30
                    may_31
                }
            }
        }
    }
`;

export const GET_STUDENTS_ATTENDANCE_BY_CLASS_TEACHER_DETAILS_JUN = gql`
    query JUNE_ATTENDANCE($classId: String) {
        school_student_classes(
            where: { school_class_detail: { class_id: { _eq: $classId } } }
            order_by: { school_student: { first_name: asc } }
        ) {
            student_id
            roll_no
            student_attendance {
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                atten_june {
                    jun_1
                    jun_2
                    jun_3
                    jun_4
                    jun_5
                    jun_6
                    jun_7
                    jun_8
                    jun_9
                    jun_10
                    jun_11
                    jun_12
                    jun_13
                    jun_14
                    jun_15
                    jun_16
                    jun_17
                    jun_18
                    jun_19
                    jun_20
                    jun_21
                    jun_22
                    jun_23
                    jun_24
                    jun_25
                    jun_26
                    jun_27
                    jun_28
                    jun_29
                    jun_30
                }
            }
        }
    }
`;

export const GET_STUDENTS_ATTENDANCE_BY_CLASS_TEACHER_DETAILS_JUL = gql`
    query JULY_ATTENDANCE($classId: String) {
        school_student_classes(
            where: { school_class_detail: { class_id: { _eq: $classId } } }
            order_by: { school_student: { first_name: asc } }
        ) {
            student_id
            roll_no
            student_attendance {
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                atten_july {
                    jul_1
                    jul_2
                    jul_3
                    jul_4
                    jul_5
                    jul_6
                    jul_7
                    jul_8
                    jul_9
                    jul_10
                    jul_11
                    jul_12
                    jul_13
                    jul_14
                    jul_15
                    jul_16
                    jul_17
                    jul_18
                    jul_19
                    jul_20
                    jul_21
                    jul_22
                    jul_23
                    jul_24
                    jul_25
                    jul_26
                    jul_27
                    jul_28
                    jul_29
                    jul_30
                    jul_31
                }
            }
        }
    }
`;

export const GET_STUDENTS_ATTENDANCE_BY_CLASS_TEACHER_DETAILS_AUG = gql`
    query AUGUST_ATTENDANCE($classId: String) {
        school_student_classes(
            where: { school_class_detail: { class_id: { _eq: $classId } } }
            order_by: { school_student: { first_name: asc } }
        ) {
            student_id
            roll_no
            student_attendance {
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                atten_august {
                    aug_1
                    aug_2
                    aug_3
                    aug_4
                    aug_5
                    aug_6
                    aug_7
                    aug_8
                    aug_9
                    aug_10
                    aug_11
                    aug_12
                    aug_13
                    aug_14
                    aug_15
                    aug_16
                    aug_17
                    aug_18
                    aug_19
                    aug_20
                    aug_21
                    aug_22
                    aug_23
                    aug_24
                    aug_25
                    aug_26
                    aug_27
                    aug_28
                    aug_29
                    aug_30
                    aug_31
                }
            }
        }
    }
`;

export const GET_STUDENTS_ATTENDANCE_BY_CLASS_TEACHER_DETAILS_SEP = gql`
    query SEPTEMBER_ATTENDANCE($classId: String) {
        school_student_classes(
            where: { school_class_detail: { class_id: { _eq: $classId } } }
            order_by: { school_student: { first_name: asc } }
        ) {
            student_id
            roll_no
            student_attendance {
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                atten_september {
                    sep_1
                    sep_2
                    sep_3
                    sep_4
                    sep_5
                    sep_6
                    sep_7
                    sep_8
                    sep_9
                    sep_10
                    sep_11
                    sep_12
                    sep_13
                    sep_14
                    sep_15
                    sep_16
                    sep_17
                    sep_18
                    sep_19
                    sep_20
                    sep_21
                    sep_22
                    sep_23
                    sep_24
                    sep_25
                    sep_26
                    sep_27
                    sep_28
                    sep_29
                    sep_30
                }
            }
        }
    }
`;

export const GET_STUDENTS_ATTENDANCE_BY_CLASS_TEACHER_DETAILS_OCT = gql`
    query OCTOBER_ATTENDANCE($classId: String) {
        school_student_classes(
            where: { school_class_detail: { class_id: { _eq: $classId } } }
            order_by: { school_student: { first_name: asc } }
        ) {
            student_id
            roll_no
            student_attendance {
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                atten_october {
                    oct_1
                    oct_2
                    oct_3
                    oct_4
                    oct_5
                    oct_6
                    oct_7
                    oct_8
                    oct_9
                    oct_10
                    oct_11
                    oct_12
                    oct_13
                    oct_14
                    oct_15
                    oct_16
                    oct_17
                    oct_18
                    oct_19
                    oct_20
                    oct_21
                    oct_22
                    oct_23
                    oct_24
                    oct_25
                    oct_26
                    oct_27
                    oct_28
                    oct_29
                    oct_30
                    oct_31
                }
            }
        }
    }
`;

export const GET_STUDENTS_ATTENDANCE_BY_CLASS_TEACHER_DETAILS_NOV = gql`
    query NOVEMBER_ATTENDANCE($classId: String) {
        school_student_classes(
            where: { school_class_detail: { class_id: { _eq: $classId } } }
            order_by: { school_student: { first_name: asc } }
        ) {
            student_id
            roll_no
            student_attendance {
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                atten_november {
                    nov_1
                    nov_2
                    nov_3
                    nov_4
                    nov_5
                    nov_6
                    nov_7
                    nov_8
                    nov_9
                    nov_10
                    nov_11
                    nov_12
                    nov_13
                    nov_14
                    nov_15
                    nov_16
                    nov_17
                    nov_18
                    nov_19
                    nov_20
                    nov_21
                    nov_22
                    nov_23
                    nov_24
                    nov_25
                    nov_26
                    nov_27
                    nov_28
                    nov_29
                    nov_30
                }
            }
        }
    }
`;

export const GET_STUDENTS_ATTENDANCE_BY_CLASS_TEACHER_DETAILS_DEC = gql`
    query DECEMBER_ATTENDANCE($classId: String) {
        school_student_classes(
            where: { school_class_detail: { class_id: { _eq: $classId } } }
            order_by: { school_student: { first_name: asc } }
        ) {
            student_id
            roll_no
            student_attendance {
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                atten_december {
                    dec_1
                    dec_2
                    dec_3
                    dec_4
                    dec_5
                    dec_6
                    dec_7
                    dec_8
                    dec_9
                    dec_10
                    dec_11
                    dec_12
                    dec_13
                    dec_14
                    dec_15
                    dec_16
                    dec_17
                    dec_18
                    dec_19
                    dec_20
                    dec_21
                    dec_22
                    dec_23
                    dec_24
                    dec_25
                    dec_26
                    dec_27
                    dec_28
                    dec_29
                    dec_30
                    dec_31
                }
            }
        }
    }
`;

export const GET_STUDENTS_ATTENDANCE_BY_CLASS_TEACHER_DETAILS_JAN = gql`
    query JANUARY_ATTENDANCE($classId: String) {
        school_student_classes(
            where: { school_class_detail: { class_id: { _eq: $classId } } }
            order_by: { school_student: { first_name: asc } }
        ) {
            student_id
            roll_no
            student_attendance {
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                atten_january {
                    jan_1
                    jan_2
                    jan_3
                    jan_4
                    jan_5
                    jan_6
                    jan_7
                    jan_8
                    jan_9
                    jan_10
                    jan_11
                    jan_12
                    jan_13
                    jan_14
                    jan_15
                    jan_16
                    jan_17
                    jan_18
                    jan_19
                    jan_20
                    jan_21
                    jan_22
                    jan_23
                    jan_24
                    jan_25
                    jan_26
                    jan_27
                    jan_28
                    jan_29
                    jan_30
                    jan_31
                }
            }
        }
    }
`;

export const GET_STUDENTS_ATTENDANCE_BY_CLASS_TEACHER_DETAILS_FEB = gql`
    query FEBRUARY_ATTENDANCE($classId: String) {
        school_student_classes(
            where: { school_class_detail: { class_id: { _eq: $classId } } }
            order_by: { school_student: { first_name: asc } }
        ) {
            student_id
            roll_no
            student_attendance {
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                atten_february {
                    feb_1
                    feb_2
                    feb_3
                    feb_4
                    feb_5
                    feb_6
                    feb_7
                    feb_8
                    feb_9
                    feb_10
                    feb_11
                    feb_12
                    feb_13
                    feb_14
                    feb_15
                    feb_16
                    feb_17
                    feb_18
                    feb_19
                    feb_20
                    feb_21
                    feb_22
                    feb_23
                    feb_24
                    feb_25
                    feb_26
                    feb_27
                    feb_28
                    feb_29
                }
            }
        }
    }
`;

export const GET_STUDENTS_ATTENDANCE_BY_CLASS_TEACHER_DETAILS_MAR = gql`
    query MARCH_ATTENDANCE($classId: String) {
        school_student_classes(
            where: { school_class_detail: { class_id: { _eq: $classId } } }
            order_by: { school_student: { first_name: asc } }
        ) {
            student_id
            roll_no
            student_attendance {
                school_student {
                    first_name
                    middle_name
                    last_name
                }
                atten_march {
                    mar_1
                    mar_2
                    mar_3
                    mar_4
                    mar_5
                    mar_6
                    mar_7
                    mar_8
                    mar_9
                    mar_10
                    mar_11
                    mar_12
                    mar_13
                    mar_14
                    mar_15
                    mar_16
                    mar_17
                    mar_18
                    mar_19
                    mar_20
                    mar_21
                    mar_22
                    mar_23
                    mar_24
                    mar_25
                    mar_26
                    mar_27
                    mar_28
                    mar_29
                    mar_30
                    mar_31
                }
            }
        }
    }
`;

export const GET_STUDENTS_ATTENDANCE_TO_TAKE_ATTENDANCE = gql`
    query TakeAttendanceOBO(
        $classId: String
        $apr: Boolean!
        $may: Boolean!
        $jun: Boolean!
        $jul: Boolean!
        $aug: Boolean!
        $sep: Boolean!
        $oct: Boolean!
        $nov: Boolean!
        $dec: Boolean!
        $jan: Boolean!
        $feb: Boolean!
        $mar: Boolean!
    ) {
        student_attendance(where: { class_id: { _eq: $classId } }) {
            attendence_id
            apr @skip(if: $apr)
            may @skip(if: $may)
            jun @skip(if: $jun)
            jul @skip(if: $jul)
            aug @skip(if: $aug)
            sep @skip(if: $sep)
            oct @skip(if: $oct)
            nov @skip(if: $nov)
            dec @skip(if: $dec)
            jan @skip(if: $jan)
            feb @skip(if: $feb)
            mar @skip(if: $mar)
            total_present
            school_student_class {
                roll_no
            }
            school_student {
                first_name
                middle_name
                last_name
            }
        }
    }
`;
